export default class SharedZipCodeInput extends React.Component {
  static defaultProps = {
    rawInput: false
  }

  static propTypes = {
    onNewZipCode: PropTypes.func.isRequired,
    onRequestFinished: PropTypes.func,
    onRequestScheduled: PropTypes.func,
    rawInput: PropTypes.bool.isRequired
  }

  render() {
    const {className, onChange, onNewZipCode, onRequestFinished, onRequestScheduled, rawInput, ...restProps} = this.props
    const inputProps = {
      onBlur: this.onZipCodeInputBlur,
      className: classNames("components-zip-code-input", className),
      onChange: this.onZipCodeChanged,
      pattern: "\\d{4}",
      type: "number",
      ...restProps
    }

    if (rawInput) {
      return (
        <Input {...inputProps} />
      )
    }

    return (
      <BootstrapInput {...inputProps} />
    )
  }

  onZipCodeChanged = (e) => {
    if (this.onZipCodeChangedTimeout) {
      clearTimeout(this.onZipCodeChangedTimeout)
    }

    const zipCode = digg(e, "target", "value")

    this.onZipCodeChangedTimeout = setTimeout(() => this.findCityFromZipCode(zipCode), 250)

    const {onRequestScheduled} = this.props

    if (onRequestScheduled) {
      onRequestScheduled()
    }
  }

  findCityFromZipCode = async (zipCode) => {
    const {onNewZipCode} = digs(this.props, "onNewZipCode")
    const {onRequestFinished} = this.props
    const response = await Address.findCityFromZipCode({zip_code: zipCode})
    const cityName = digg(response, "city_name")
    const communeCode = digg(response, "commune_code")
    const communeName = digg(response, "commune_name")
    const result = {cityName, communeCode, communeName, regionName: null}

    if (communeCode) {
      const communeResponse = await Address.communeByCode({commune_code: communeCode})
      const regionName = digg(communeResponse, "region_name")

      if (regionName) {
        result.regionName = regionName
      }
    }

    onNewZipCode(result)

    if (onRequestFinished) {
      onRequestFinished()
    }
  }
}
