import PickupPoint from "./pickup-point"

// TODO simplify the state updates
export default class MerfotoOrderDeliveriesDeliveryMethodsShipmondoPickupPoints extends React.Component {
  static propTypes = {
    carrierCode: PropTypes.string.isRequired,
    order: PropTypes.instanceOf(Order).isRequired,
    orderDelivery: PropTypes.instanceOf(OrderDelivery).isRequired,
    productCode: PropTypes.string.isRequired
  }

  state = {
    currentPickupPoint: undefined,
    currentZipCode: this.props.order.orderDelivery()?.deliveryAddress()?.zipCode() || this.props.order.invoiceAddress()?.zipCode(),
    pickupPoints: undefined
  }

  componentDidMount() {
    this.loadPickupPoints().then((pickupPoints) => {
      this.setInitialCurrentPickupPoint(pickupPoints)
    })
  }

  componentDidUpdate(prevProps) {
    const {carrierCode, productCode} = this.props

    if (digg(prevProps, "carrierCode") !== carrierCode || digg(prevProps, "productCode") !== productCode) {
      this.loadPickupPointsLater()
    }
  }

  setInitialCurrentPickupPoint(pickupPoints) {
    const {orderDelivery} = this.props
    const currentPickupPoint = pickupPoints
      .find((pickupPoint) => digg(pickupPoint, "identifier") == orderDelivery.orderDeliveryShipmondo()?.pickupPointIdentifier())

    this.setState(({currentPickupPoint}))
  }

  loadPickupPoints = async () => {
    const {currentZipCode} = this.state
    const {carrierCode, order, productCode} = digs(this.props, "carrierCode", "order", "productCode")
    const invoiceAddress = order.invoiceAddress()
    const response = await OrderDeliveryShipmondo.pickupPoints({
      carrier_code: carrierCode,
      country: invoiceAddress.country(),
      product_code: productCode,
      zip_code: currentZipCode
    })
    const pickupPoints = digg(response, "pickup_points")

    this.setState({pickupPoints})

    return pickupPoints
  }

  loadPickupPointsLater = debounce(this.loadPickupPoints, 250)

  render() {
    const {carrierCode, className, onChecked, order, orderDelivery, productCode, ...restProps} = this.props
    const {currentPickupPoint, currentZipCode, pickupPoints} = this.state

    return (
      <div
        className={classNames(
          "d-flex",
          "flex-column",
          "merfoto-components-order-deliveries-methods-shipmondo",
          "overflow-hidden",
          className
        )}
        {...restProps}
      >
        {currentPickupPoint &&
          <>
            <input
              name="order[order_delivery_attributes][order_delivery_shipmondo_attributes][pickup_point_identifier]"
              type="hidden"
              value={digg(currentPickupPoint, "identifier")}
            />
            {orderDelivery.deliveryAddressId() &&
              <input name="order[order_delivery_attributes][delivery_address_attributes][id]" type="hidden" value={orderDelivery.deliveryAddressId()} />
            }
            <input name="order[order_delivery_attributes][delivery_address_attributes][city]" type="hidden" value={digg(currentPickupPoint, "city")} />
            <input name="order[order_delivery_attributes][delivery_address_attributes][country]" type="hidden" value={digg(currentPickupPoint, "country")} />
            <input name="order[order_delivery_attributes][delivery_address_attributes][place_name]" type="hidden" value={digg(currentPickupPoint, "name")} />
            <input name="order[order_delivery_attributes][delivery_address_attributes][street]" type="hidden" value={digg(currentPickupPoint, "address")} />
            <input name="order[order_delivery_attributes][delivery_address_attributes][zip_code]" type="hidden" value={digg(currentPickupPoint, "zip_code")} />
          </>
        }
        <BootstrapInput
          id="current_pickup_point_zip_code"
          label={I18n.t("js.merfoto.order_deliveries.delivery_methods.shipmondo.index.zip_code")}
          onChange={this.onCurrentZipCodeChanged}
          value={currentZipCode}
        />
        <div className="overflow-auto">
          {pickupPoints?.map((pickupPoint, index) =>
            <PickupPoint
              checked={digg(pickupPoint, "identifier") == currentPickupPoint?.identifier}
              grey={index % 2 == 1}
              key={digg(pickupPoint, "identifier")}
              onChecked={this.onPickupPointChanged}
              pickupPoint={pickupPoint}
            />
          )}
        </div>
      </div>
    )
  }

  onCurrentZipCodeChanged = (e) => {
    this.setState({currentZipCode: digg(e, "target", "value")}, () => this.loadPickupPointsLater())
  }

  onPickupPointChanged = (pickupPoint) => {
    this.setState({currentPickupPoint: pickupPoint})
  }
}
