import "merfoto/components/checkout/payment/style"
import {Event, InlineCheckout} from "@bambora/checkout-sdk-web"
import {useContext, useEffect, useState} from "react"
import CheckoutNavbar from "merfoto/components/checkout/navbar"
import MerfotoCard from "merfoto/components/card"
import {useNavigate} from "react-router-dom"
import {UserContext} from "shared/user-context"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoCheckoutPayment() {
  const [order, setOrder] = useState()
  const [paymentAuthorized, setPaymentAuthorized] = useState()
  const navigate = useNavigate()
  const [currentUser] = useContext(UserContext)
  const onCheckoutCompleted = () => {
    setPaymentAuthorized(true)
  }

  useTitle(I18n.t("js.merfoto.checkout.payment.payment"))

  useEffect(() => {
    async function loadOrder() {
      const order = await Order
        .ransack({
          customer_user_id_eq: currentUser.id(),
          state_eq: "basket"
        })
        .preload("order_delivery")
        .select({
          Order: ["id", "invoiceAddressId", "state"],
          OrderDelivery: ["id"]
        })
        .first()

      if (!order) {
        navigate(MerfotoRoutes.basketPath(), {replace: true})

        return
      }

      setOrder(order)
    }

    if (currentUser) {
      loadOrder()
    }
  }, [currentUser, navigate])

  useEffect(() => {
    let inlineCheckout

    async function generatePaymentSessionToken() {
      const response = await fetch(`/api/orders/${order.id()}/session_tokens`, {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST"
      })
      const body = await response.json()

      if (response.status !== 201) {
        FlashMessage.error(body.errors.map((error) => error.detail).join(". "))

        const errorCodes = body.errors.map((error) => error.code)

        if (errorCodes.includes("invalid_order_delivery") || errorCodes.includes("no_order_delivery")) {
          navigate("/checkout/delivery")
        }

        return
      }

      const {token} = body

      inlineCheckout = new InlineCheckout(token, {container: document.querySelector(".bambora-checkout-container")})

      inlineCheckout.on(
        Event.Authorize,
        (pay) => onCheckoutCompleted(pay)
      )

      return true
    }

    if (order) {
      if (generatePaymentSessionToken()) {
        return () => inlineCheckout?.destroy()
      }
    }
  }, [navigate, order])

  const onOrderUpdated = ({model: order}) => {
    if (order.state() === "ordered") {
      navigate(MerfotoRoutes.checkoutCompletedPath(order.id()), {replace: true})
    }
  }

  return (
    <div className="merfoto-routes-checkout-payment">
      {order &&
        <div className="content-container">
          <EventUpdated model={order} onUpdated={onOrderUpdated} />
          <CheckoutNavbar activeIdentifier="payment" className="mb-4" order={order} />
          <MerfotoCard.Outer>
            <MerfotoCard.Header>
              {I18n.t("js.merfoto.checkout.payment.payment")}
            </MerfotoCard.Header>
            <MerfotoCard.Body>
              {!paymentAuthorized &&
                <div className="bambora-checkout-container" />
              }
              {paymentAuthorized &&
                <div className="please-wait-while-we-process-your-order-container">
                  {I18n.t("js.merfoto.checkout.payment.please_wait_while_we_process_your_order")}
                </div>
              }
            </MerfotoCard.Body>
          </MerfotoCard.Outer>
        </div>
      }
    </div>
  )
}
