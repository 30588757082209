import SanitizedHtml from "../../components/sanitized-html"
import {tenantShopSettingSelector} from "shared/selectors/tenant-shop-setting-selector"
import {useRecoilValue} from "recoil"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoRoutesTermsAndConditions() {
  const tenantShopSetting = useRecoilValue(tenantShopSettingSelector)

  useTitle(TenantShopSetting.humanAttributeName("termsAndConditions"))

  return (
    <div className="merfoto-routes-terms-and-conditions">
      <h1>{TenantShopSetting.humanAttributeName("termsAndConditions")}</h1>
      <SanitizedHtml className="terms-and-conditions-text" dirty={tenantShopSetting?.termsAndConditions()} />
    </div>
  )
}
