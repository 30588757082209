import {selector} from "recoil"
import {tenantAtom} from "../atoms/tenant-atom"

const tenantShopSettingSelector = selector({
  key: "tenantShopSettingSelector",
  get: async ({get}) => {
    const tenant = get(tenantAtom)

    return await TenantShopSetting.ransack({tenant_id_eq: tenant.id()}).first()
  }
})

export {tenantShopSettingSelector}
