import "./style"
import BodyPortal from "components/body-portal"
import debounce from "debounce"

export default class PositionRelativeTo extends React.Component {
  static propTypes = PropTypesExact({
    children: PropTypes.node.isRequired,
    element: PropTypes.object.isRequired
  })

  constructor(props) {
    super(props)

    const position = this.getPosition()
    const {left, top} = digs(position, "left", "top")

    this.state = ({left, top})
  }

  componentDidUpdate() {
    this.updatePositionWithDelay()
  }

  render() {
    const {children} = this.props
    const {left, top} = this.state

    return (
      <BodyPortal>
        <EventListener event="resize" onCalled={this.updatePositionWithDelay} target={window} />

        <div className="components-position-relative-to" style={{left, top}}>
          {children}
        </div>
      </BodyPortal>
    )
  }

  getPosition() {
    const {element} = this.props
    const rect = element.getBoundingClientRect()
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop

    return {
      left: rect.left + scrollLeft,
      top: rect.top + scrollTop
    }
  }

  updatePosition = () => {
    const position = this.getPosition()
    const {left, top} = digs(position, "left", "top")

    if (this.state.left != left || this.state.top != top) {
      this.setState({left, top})
    }
  }

  updatePositionWithDelay = debounce(this.updatePosition, 100)
}
