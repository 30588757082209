import "./style"

export default class MerfotoComponentsOrderLinesEditResourcesBundleHeader extends BaseComponent {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    bundleOrderLineElement: PropTypes.instanceOf(BundleOrderLineElement),
    bundleTenantPhotoProduct: PropTypes.instanceOf(BundleTenantPhotoProduct).isRequired,
    onClick: PropTypes.func.isRequired
  }

  render() {
    const {active, bundleOrderLineElement, bundleTenantPhotoProduct, className, onClick, ...restProps} = this.props
    const partialRequireName = Inflection.dasherize(Inflection.underscore(bundleTenantPhotoProduct.tenantPhotoProduct().photoProduct().resourceType()))
    const PartialComponent = require(`merfoto/components/order-lines/edit/resources/bundle/headers/${partialRequireName}`).default

    return (
      <div
        className={classNames("merfoto-components-order-lines-edit-resources-bundle-header", className)}
        data-active={active}
        data-bundle-tenant-photo-product-id={bundleTenantPhotoProduct.id()}
        onClick={this.onClick}
        {...restProps}
      >
        <PartialComponent
          active={active}
          bundleOrderLineElement={bundleOrderLineElement}
          bundleTenantPhotoProduct={bundleTenantPhotoProduct}
          className="partial-component-container"
        />
      </div>
    )
  }

  onClick = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {bundleTenantPhotoProduct} = digs(this.props, "bundleTenantPhotoProduct")

    this.props.onClick({bundleTenantPhotoProduct})
  }
}
