import "./style"

export default class AdminComponentsLayoutBottomBarContent extends React.Component {
  render() {
    const {className, ...restProps} = this.props

    return (
      <div className={classNames("admin-components-layout-bottom-bar-content", className)} {...restProps} />
    )
  }
}
