import "controllers"
import {BrowserRouter, Outlet, Route, Routes, useLocation, useNavigate} from "react-router-dom"
import {QueryClient, QueryClientProvider} from "react-query"
import {useContext, useEffect} from "react"
import Albums from "../merfoto/routes/albums"
import {App} from "../components/app"
import FlashMessages from "nemoa/components/flash-messages"
import MerfotoAlbumShow from "../merfoto/routes/albums/show"
import MerfotoBasket from "../merfoto/routes/basket"
import MerfotoCheckoutConfirm from "../merfoto/routes/checkout/confirm"
import MerfotoCheckoutCustomerInformation from "../merfoto/routes/checkout/customer-information"
import MerfotoCheckoutDelivery from "../merfoto/routes/checkout/delivery"
import MerfotoCheckoutOrderCompleted from "../merfoto/routes/checkout/completed"
import MerfotoCheckoutPayment from "../merfoto/routes/checkout/payment"
import MerfotoLayout from "../merfoto/components/layout"
import MerfotoRoutesCookiesAndPrivacyPolicy from "../merfoto/routes/cookies-and-privacy-policy"
import MerfotoRoutesDownloads from "../merfoto/routes/downloads"
import MerfotoRoutesOrderLinesEdit from "../merfoto/routes/order-lines/edit"
import MerfotoRoutesOrders from "../merfoto/routes/orders"
import MerfotoRoutesTermsAndConditions from "../merfoto/routes/terms-and-conditions"
import MerfotoRoutesUsersConfirmationNew from "../merfoto/routes/users/confirmation/new"
import MerfotoUsersConfirmation from "../merfoto/routes/users/confirmation"
import MerfotoUsersPasswordsEdit from "../merfoto/routes/users/passwords/edit"
import MerfotoUsersPasswordsNew from "../merfoto/routes/users/passwords/new"
import MerfotoUsersSessionsNew from "../merfoto/routes/users/sessions/new"
import ReactDOM from "react-dom"
import ScrollToTop from "shared/scroll-to-top"
import {UserContext} from "shared/user-context"
import Welcome from "../merfoto/routes/welcome"

require("stylesheets/merfoto")
require("stylesheets/contained-image")
require("shared/devise")
require("shared/error-logger")
require("shared/i18n")
require("shared/session-status-updater")

const queryClient = new QueryClient()

function AuthenticatedProxy() {
  const [currentUser] = useContext(UserContext)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const returnUrl = encodeURI(`${location.pathname}${location.search}`)

    if (!currentUser) {
      navigate(`/users/sign_in?returnUrl=${returnUrl}`)
    }
  }, [currentUser, navigate])

  if (!currentUser) {
    return null
  }

  return <Outlet />
}

document.addEventListener("DOMContentLoaded", () => {
  const reactRoot = document.querySelector(".react-root")

  ReactDOM.render((
    <BrowserRouter>
      <App>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <FlashMessages />
          <Routes>
            <Route element={<AuthenticatedProxy />}>
              <Route element={<MerfotoLayout />}>
                <Route element={<Welcome />} path="/" />
                <Route path="/albums">
                  <Route element={<MerfotoAlbumShow />} path=":id" />
                  <Route element={<Albums />} index />
                </Route>
                <Route element={<MerfotoRoutesDownloads />} path="/downloads" />
                <Route element={<MerfotoRoutesOrders />} path="/orders" />
                <Route element={<MerfotoBasket />} path="/basket" />
                <Route path="/order_lines">
                  <Route element={<MerfotoRoutesOrderLinesEdit />} path=":id/edit" />
                  <Route element={<MerfotoRoutesOrderLinesEdit />} path="new" />
                </Route>
                <Route path="/checkout">
                  <Route element={<MerfotoCheckoutCustomerInformation />} path="customer_information" />
                  <Route element={<MerfotoCheckoutConfirm />} path="confirm" />
                  <Route element={<MerfotoCheckoutDelivery />} path="delivery" />
                  <Route element={<MerfotoCheckoutPayment />} path="payment" />
                  <Route element={<MerfotoCheckoutOrderCompleted />} path="completed/:id" />
                </Route>
              </Route>
            </Route>
            <Route element={<MerfotoLayout />}>
              <Route path="/users">
                <Route element={<MerfotoUsersConfirmation />} path="confirmation" />
                <Route element={<MerfotoRoutesUsersConfirmationNew />} path="confirmation/new" />
                <Route element={<MerfotoUsersSessionsNew />} path="sign_in" />
                <Route element={<MerfotoUsersPasswordsNew />} path="passwords/new" />
                <Route element={<MerfotoUsersPasswordsEdit />} path="passwords/edit" />
              </Route>
              <Route element={<MerfotoRoutesCookiesAndPrivacyPolicy />} path="/cookies_and_privacy_policy" />
              <Route element={<MerfotoRoutesTermsAndConditions />} path="/terms_and_conditions" />
            </Route>
          </Routes>
        </QueryClientProvider>
      </App>
    </BrowserRouter>
  ), reactRoot)
})
