import debounce from "debounce"

export default class SameWidthAs extends React.Component {
  static propTypes = PropTypesExact({
    children: PropTypes.node.isRequired,
    element: PropTypes.object.isRequired
  })

  state = ({
    width: this.getWidth()
  })

  componentDidUpdate() {
    this.updateWidthWithDelay()
  }

  render() {
    const {children} = digs(this.props, "children")
    const {width} = digs(this.state, "width")

    return (
      <div className="components-same-width-as" style={{width}}>
        <EventListener event="resize" onCalled={this.updateWidthWithDelay} target={window} />

        {children}
      </div>
    )
  }

  getWidth() {
    const {element} = digs(this.props, "element")
    const rect = element.getBoundingClientRect()
    const width = digg(rect, "width")

    return `${width}px`
  }

  updateWidth = () => {
    const width = this.getWidth()

    if (this.state.width != width) {
      this.setState({width})
    }
  }

  updateWidthWithDelay = debounce(this.updateWidth, 100)
}
