import {atom} from "recoil"

function readPhotoSessionGroupContactIdFromLocalStorage() {
  return localStorage.getItem("photoSessionGroupContactId")
}

export const currentPhotoSessionGroupContactIdAtom = atom({
  default: readPhotoSessionGroupContactIdFromLocalStorage(),
  key: "currentPhotoSessionGroupContactIdAtom",
  effects: [
    ({onSet}) => {
      onSet((value) => {
        localStorage.setItem("photoSessionGroupContactId", value)
      })
    }
  ]
})
