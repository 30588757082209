export default class AdminLogsLogLabel {
  constructor(props) {
    this.props = props
  }

  label() {
    const {key, subject} = this.props

    let LabelClass

    if (subject) {
      LabelClass = this.requireOrFail(`${subject}/${key}`)
    }

    if (!LabelClass) {
      LabelClass = this.requireOrFail(`${key}`)
    }

    if (LabelClass) {
      const labelInstance = new LabelClass({key})

      return labelInstance.label()
    }

    if (subject) {
      return `${subject}.${key}`
    }

    return key
  }

  requireOrFail = (path) => {
    try {
      return require(`admin/components/logs/labels/${path}`).default
    } catch (error) {
      if ((error instanceof Error) && error.code == "MODULE_NOT_FOUND") {
        return null
      }

      throw error
    }
  }
}
