import UsersPasswordsEdit from "components/users/passwords/edit"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoUsersPasswordsEdit() {
  useTitle(I18n.t("js.users.passwords.edit.change_your_password"))

  return (
    <div className="merfoto-routes-users-passwords-edit">
      <h1>{I18n.t("js.users.passwords.edit.change_your_password")}</h1>
      <div className="width-md-500px">
        <UsersPasswordsEdit />
      </div>
    </div>
  )
}
