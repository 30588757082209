import "./style"
import MenuItem from "./menu-item"
import {useLayoutRedirector} from "shared/hooks/use-layout-redirector"
import {UserContext} from "shared/user-context"
import usersSignOut from "nemoa/components/users/sign-out"

class MerfotoLayoutTopBarSignedInUserButtonMenuModal extends React.Component {
  static propTypes = {
    left: PropTypes.number.isRequired,
    top: PropTypes.number.isRequired
  }

  render() {
    const {left, top} = digs(this.props, "left", "top")

    const [currentUser] = this.context

    return (
      <div
        className="merfoto-components-layout-top-bar-signed-in-user-button-menu-modal"
        ref="rootElement"
        style={{left, top}}
      >
        <EventListener event="mouseup" onCalled={this.onWindowMouseUp} target={window} />

        <div className="current-user-name">
          {currentUser?.name()}
        </div>
        <div className="menu-items">
          <MenuItem
            icon="user"
            label={I18n.t("js.merfoto.layout.top_bar.signed_in_user_button.menu_modal.account")}
          />
          <MenuItem
            className="sign-out-menu-item"
            icon="sign-out"
            label={I18n.t("js.merfoto.layout.top_bar.signed_in_user_button.menu_modal.sign_out")}
            onClick={this.onSignOutClicked}
          />
        </div>
      </div>
    )
  }

  onSignOutClicked = async (e) => {
    e.preventDefault()

    await usersSignOut()
    FlashMessage.success(I18n.t("js.merfoto.layout.top_bar.signed_in_user_button.menu_modal.you_have_been_signed_out"))
    this.props.layoutRedirector("merfoto", "reactRoot")
  }

  onWindowMouseUp = (e) => {
    if (this.refs.rootElement.contains(e.target)) {
      return
    }

    this.props.onRequestClose()
  }
}

MerfotoLayoutTopBarSignedInUserButtonMenuModal.contextType = UserContext

export default function WrappedMerfotoLayoutTopBarSignedInUserButtonMenuModal(props) {
  const layoutRedirector = useLayoutRedirector()

  return (
    <MerfotoLayoutTopBarSignedInUserButtonMenuModal layoutRedirector={layoutRedirector} {...props} />
  )
}
