import {Collection} from "@kaspernj/api-maker"

export default class SortByScopeLink extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    query: PropTypes.instanceOf(Collection),
    scope: PropTypes.string.isRequired,
    title: PropTypes.node,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired
  }

  render() {
    const {className, query, scope, title, value, ...restProps} = this.props

    return (
      <Link className={classNames("components-shared-sort-by-scope-link", className)} to={this.to()} {...restProps}>
        {title}
      </Link>
    )
  }

  to() {
    const {query, scope, value} = digs(this.props, "query", "scope", "value")
    const ascKey = `${scope}_asc`
    const descKey = `${scope}_desc`
    const params = Params.parse()
    const searchKey = query.queryArgs.searchKey || "q"

    if (!params[searchKey]) {
      params[searchKey] = {}
    }

    const newParams = params[searchKey]

    // Remove existing sort if there is any
    newParams.s = undefined

    // Add scoped sort
    if (newParams[ascKey]) {
      newParams[ascKey] = undefined
      newParams[descKey] = value
    } else {
      newParams[ascKey] = value
      newParams[descKey] = undefined
    }

    // Generate the URL as a string that should be used in the sort link
    const newPath = `${location.pathname}?${Params.stringify(params)}`

    return newPath
  }
}
