import Stack from "react-bootstrap/Stack"
import updateCurrentUser from "components/users/update-current-user"
import {ValidationError} from "@kaspernj/api-maker"

export default class RegistrationsForm extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    onSignedIn: PropTypes.func
  })

  state = {
    email: new Email(),
    person: new Person(),
    user: new User()
  }

  render() {
    const {className} = this.props
    const {email, person} = digs(this.state, "email", "person")

    return (
      <div className={classNames("nemoa-components-registrations-form", className)}>
        <form onSubmit={this.onSubmit}>
          <Stack gap={3}>
            <BootstrapInput
              attribute="firstName"
              label={false}
              model={person}
              name="user[person_attributes][first_name]"
              placeholder={Person.humanAttributeName("firstName")}
            />
            <BootstrapInput
              attribute="lastName"
              label={false}
              model={person}
              name="user[person_attributes][last_name]"
              placeholder={Person.humanAttributeName("lastName")}
            />
            <BootstrapInput
              attribute="email"
              autoComplete="email"
              label={false}
              model={email}
              name="email"
              placeholder={Email.humanAttributeName("email")}
            />
            <BootstrapInput
              id="user_password"
              label={false}
              name="user[password]"
              placeholder={I18n.t("js.registrations.form.password")}
              type="password"
            />
            <BootstrapInput
              id="user_password_confirmation"
              label={false}
              name="user[password_confirmation]"
              placeholder={I18n.t("js.registrations.form.confirm_password")}
              type="password"
            />
            <SubmitButton label={I18n.t("js.registrations.form.sign_up")} large />
          </Stack>
        </form>
      </div>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const {onSignedIn} = this.props

    const {user} = this.state

    let response

    try {
      response = await User.registrate(formData)
    } catch (error) {
      if (error instanceof ValidationError) {
        user.parseValidationErrors(error, {form, throwValidationError: false})
      }

      FlashMessage.errorResponse(error)

      return
    }

    const userFromResponse = digg(response, "user")

    await updateCurrentUser(userFromResponse)
    FlashMessage.success(I18n.t("js.registrations.form.your_user_have_been_created_and_you_have_been_signed_in"))

    if (onSignedIn) {
      onSignedIn()
    }
  }
}
