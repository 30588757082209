export default function sheetLayoutsPresentationSizeCalculator(sheetSize, size) {
  if (!sheetSize) {
    throw new Error("No sheet size was given")
  }

  if (sheetSize.heightMm() >= sheetSize.widthMm()) {
    return {
      height: size,
      width: (sheetSize.widthMm() / sheetSize.heightMm()) * size
    }
  }

  return {
    height: (sheetSize.heightMm() / sheetSize.widthMm()) * size,
    width: size
  }
}
