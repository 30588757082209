import {useCallback, useMemo} from "react"
import {useQuery} from "react-query"

const loadOrder = ({currentUserId}) => Order
    .ransack({
      customer_user_id_eq: currentUserId,
      state_eq: "basket"
    })
    .abilities({
      OrderLine: ["destroy", "update"]
    })
    .preload([
      "order_delivery",

      "order_lines.bundle_order_line.bundle",
      "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.photo.photo_session_group",
      "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.photo.photo_session_group_contact.contact",
      "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.sheet_layout_box",
      "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.sheet_layout.boxes",
      "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.sheet_size",
      "order_lines.bundle_order_line.tenant_photo_product",

      "order_lines.order_line_photo",
      "order_lines.order_line_photo.photo.attachment_attachment.blob",
      "order_lines.order_line_photo.photo.photo_session_group",
      "order_lines.order_line_photo.photo.photo_session_group_contact.contact",
      "order_lines.order_line_photo.tenant_photo_product.photo_product",

      "order_lines.photo_sheet_product_order_line.boxes.photo.photo_session_group",
      "order_lines.photo_sheet_product_order_line.boxes.photo.photo_session_group_contact.contact",
      "order_lines.photo_sheet_product_order_line.boxes.sheet_layout_box",
      "order_lines.photo_sheet_product_order_line.sheet_layout.boxes",
      "order_lines.photo_sheet_product_order_line.sheet_size"
    ])
    .select({
      Bundle: ["name"],
      BundleOrderLine: ["id"],
      BundleOrderLineElement: ["id", "resourceType"],
      Contact: ["name"],
      Order: ["id", "orderLinesCount", "priceWithVat", "updatedAt"],
      OrderDelivery: ["id", "priceWithVat", "productId"],
      OrderLine: ["createdAt", "id", "orderableId", "orderableType", "quantity", "totalPriceWithVat"],
      OrderLinePhoto: ["id"],
      Photo: ["height", "fullSizeUrl", "id", "photoIdentifier", "photoSessionGroupId", "photoSessionGroupContactId", "thumbnailUrl", "width"],
      PhotoSessionGroup: ["id", "name"],
      PhotoSessionGroupContact: ["id"],
      PhotoSheetProductOrderLine: ["id"],
      PhotoSheetProductOrderLineBox: [
        "id",
        "photoHeightPercent",
        "photoLeftPercent",
        "photoTopPercent",
        "photoWidthPercent",
        "rotation",
        "sheetLayoutBoxId",
        "updatedAt"
      ],
      SheetLayout: ["id", "webshopName"],
      SheetLayoutBox: ["heightPercent", "id", "leftPercent", "topPercent", "widthPercent"],
      SheetSize: ["heightMm", "id", "widthMm"],
      TenantPhotoProduct: ["id", "name"]
    })
    .first()

export function useOrder({currentUserId}) {
  const queryKey = useMemo(() => ["orders", {currentUserId}], [currentUserId])
  const loader = useCallback(() => loadOrder({currentUserId}), [currentUserId])

  return useQuery(queryKey, loader)
}
