export default function buildBoxesToPhotosMapping(sheetLayout, primaryPhotos, otherPhotos) {
  const boxesToPhotosMapping = {}

  for (const sheetLayoutBox of sheetLayout.boxes().loaded()) {
    const photoMatchingTypeOfSheetLayoutBox = primaryPhotos.find((photo) =>
      photo.photoTypes().loaded().find((photoType) =>
        photoType.id() === sheetLayoutBox.photoTypeId()
      )
    )
    const otherPhotoMatchingTypeOfSheetLayoutBox = otherPhotos?.find((photo) =>
      photo.photoTypes().loaded().find((photoType) =>
        photoType.id() === sheetLayoutBox.photoTypeId()
      )
    )

    if (photoMatchingTypeOfSheetLayoutBox) {
      boxesToPhotosMapping[sheetLayoutBox.id()] = photoMatchingTypeOfSheetLayoutBox
    } else if (otherPhotoMatchingTypeOfSheetLayoutBox) {
      boxesToPhotosMapping[sheetLayoutBox.id()] = otherPhotoMatchingTypeOfSheetLayoutBox
    } else {
      boxesToPhotosMapping[sheetLayoutBox.id()] = null
    }
  }

  return boxesToPhotosMapping
}
