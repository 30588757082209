import {useContext, useEffect, useState} from "react"
import PhotoSessionGroupsCard from "../components/photo-session-groups/card"
import {tenantAtom} from "shared/atoms/tenant-atom"
import {UserContext} from "shared/user-context"
import {useRecoilValue} from "recoil"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoRoutesDownloads() {
  const [currentUser] = useContext(UserContext)
  const [photoSessionGroupContacts, setPhotoSessionGroupContacts] = useState()
  const tenant = useRecoilValue(tenantAtom)

  useTitle(I18n.t("js.merfoto.downloads.downloads"))

  useEffect(() => {
    async function loadDigitalPhotoSheetProductOrderLines() {
      const orderLines = await PhotoSheetProductOrderLine
        .ransack({
          order_customer_user_id_eq: currentUser.id(),
          order_state_eq_any: ["ordered", "processing", "processed"],
          photo_sheet_product_digital_product_true: true
        })
        .preload([
          "photo_session_group_contact.photo_session_group",
          "photo_session_group_contact.photo_session_group.photo_session",
          "photo_session_group_contact.contact"
        ])
        .select({
          PhotoSheetProductOrderLine: ["id"],
          PhotoSessionGroupContact: ["id"]
        })
        .toArray()

      const photoSessionGroupContacts = orderLines
        .map((photoSheetProductOrderLine) => photoSheetProductOrderLine.photoSessionGroupContact())

      setPhotoSessionGroupContacts(photoSessionGroupContacts)
    }

    loadDigitalPhotoSheetProductOrderLines()
  }, [currentUser])

  return (
    <div className="merfoto-routes-downloads">
      <h1 className="mb-4">{I18n.t("js.merfoto.downloads.downloads")}</h1>
      {photoSessionGroupContacts?.map((photoSessionGroupContact) =>
        <PhotoSessionGroupsCard
          allowDownloadOfPhotos
          key={photoSessionGroupContact.id()}
          photoSessionGroupContact={photoSessionGroupContact}
          tenant={tenant}
        />
      )}
    </div>
  )
}
