import "./style"

export default class AdminLayoutBottomBar extends React.Component {
  static defaultProps = {
    fixedPositionInLayout: true
  }

  static propTypes = PropTypesExact({
    children: PropTypes.node,
    className: PropTypes.string,
    fixedPositionInLayout: PropTypes.bool,
    rightContent: PropTypes.node
  })

  render() {
    const {children, className, fixedPositionInLayout, rightContent} = this.props

    return (
      <div
        className={classNames(
          "admin-components-layout-bottom-bar",
          "d-flex",
          "justify-content-end",
          className,
          {
            "fixed-bottom": fixedPositionInLayout
          }
        )}
        data-fixed-position-in-layout={fixedPositionInLayout}
      >
        {children}
        {rightContent}
      </div>
    )
  }
}
