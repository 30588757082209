import Card from "merfoto/components/card"
import DestroyOrderLineButton from "merfoto/components/basket/destroy-order-line-button"
import Quantity from "merfoto/components/basket/quantity"
import SalesPrice from "merfoto/components/basket/sales-price"
import SheetLayoutsPresentation from "components/sheet-layouts/presentation"
import UniquePhotosLabel from "merfoto/components/photo-sheet-product-order-lines/unique-photos-label"

export default class MerfotoBasketItemCardsOrderPhotoSheetProductOrderLine extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    orderLine: PropTypes.instanceOf(OrderLine).isRequired
  }

  render() {
    const {className, editable, orderLine, ...restProps} = this.props
    const photoSheetProductOrderLine = orderLine.photoSheetProductOrderLine()
    const sheetLayout = photoSheetProductOrderLine.sheetLayout()

    return (
      <Card.Outer className={classNames("merfoto-components-basket-item-cards-photo-sheet-product-order-line", className)} {...restProps}>
        <Card.Header>
          <div className="d-flex w-100">
            <div className="flex-grow-1 photo-product-name-label">
              {sheetLayout.webshopName()}
            </div>
            {editable && orderLine.can("update", orderLine) &&
              <div className="align-self-center me-2">
                <Link to={MerfotoRoutes.editOrderLinePath(orderLine.id())}>
                  <Icon icon="pencil-alt" />
                </Link>
              </div>
            }
            {editable && orderLine.can("destroy", orderLine) &&
              <div className="align-items-center">
                <DestroyOrderLineButton className="me-2" orderLine={orderLine} />
              </div>
            }
            <div>
              <Quantity editable={editable} orderLine={orderLine} />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex">
            <SheetLayoutsPresentation
              photoSheetProductOrderLine={photoSheetProductOrderLine}
              sheetLayout={sheetLayout}
              sheetSize={photoSheetProductOrderLine.sheetSize()}
              size={84}
            />
            <div className="ms-2">
              <UniquePhotosLabel photoSheetProductOrderLine={photoSheetProductOrderLine} />
            </div>
          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <SalesPrice orderLine={orderLine} />
        </Card.Footer>
      </Card.Outer>
    )
  }
}
