import PhotoSessionGroupsCard from "merfoto/components/photo-session-groups/card"
import {UserContext} from "shared/user-context"

function photosAvailable(photoSessionGroupContact) {
  const photoSession = photoSessionGroupContact.photoSessionGroup().photoSession()

  return !(photoSession.photosAvailableAt() && photoSession.photosAvailableAt().getTime() > new Date().getTime())
}

// TODO make function with useTitle
class MerfotoAlbums extends React.Component {
  state = {
    photoSessionGroupContacts: undefined,
    photoSessionGroupContactTokenContacts: undefined,
    tenant: CurrentTenant.current()
  }

  componentDidMount() {
    this.loadPhotoSessionGroupContacts()
    this.loadPhotoSessionGroupContactTokenContacts()
  }

  async loadPhotoSessionGroupContacts() {
    const [currentUser] = this.context
    const photoSessionGroupContacts = await PhotoSessionGroupContact
      .ransack({
        albums_for_user: currentUser.id(),
        s: "photo_session_start_date"
      })
      .preload(["contact", "photo_session_group.photo_session"])
      .select({
        Contact: ["id", "name"],
        PhotoSession: ["allowDownloadOfPhotos", "id", "photosAvailableAt", "startsAt"],
        PhotoSessionGroup: ["id", "name"],
        PhotoSessionGroupContact: ["id", "photoSessionGroupId"]
      })
      .toArray()
    const filtered = photoSessionGroupContacts.filter((photoSessionGroupContact) => photosAvailable(photoSessionGroupContact))

    this.setState({photoSessionGroupContacts: filtered})
  }

  async loadPhotoSessionGroupContactTokenContacts() {
    const photoSessionGroupContactTokenContacts = await PhotoSessionGroupContactTokenContact
      .ransack({contact_user_id_eq: Devise.currentUser().id()}).toArray()

    this.setState({photoSessionGroupContactTokenContacts})
  }

  onTokenContactDestroyed = ({model: tokenContact}) => {
    const {photoSessionGroupContacts, photoSessionGroupContactTokenContacts} = this.state
    const filteredPhotoSessionGroupContacts = photoSessionGroupContacts
      .filter((photoSessionGroupContact) => photoSessionGroupContact.id() != tokenContact.photoSessionGroupContactId())
    const filteredPhotoSessionGroupContactTokenContacts = photoSessionGroupContactTokenContacts
      .filter((photoSessionGroupContactTokenContact) => photoSessionGroupContactTokenContact.id() != tokenContact.id())

    this.setState({
      photoSessionGroupContacts: filteredPhotoSessionGroupContacts,
      photoSessionGroupContactTokenContacts: filteredPhotoSessionGroupContactTokenContacts
    })
  }

  render() {
    const {photoSessionGroupContacts, photoSessionGroupContactTokenContacts, tenant} = this.state

    return (
      <div className="merfoto-routes-albums">
        {photoSessionGroupContactTokenContacts?.map((tokenContact) =>
          <EventDestroyed key={tokenContact.id()} model={tokenContact} onDestroyed={this.onTokenContactDestroyed} />
        )}
        <h1 className="mb-4">{I18n.t("js.merfoto.routes.albums.albums")}</h1>
        {tenant && photoSessionGroupContactTokenContacts && photoSessionGroupContacts?.map((photoSessionGroupContact, index) =>
          <PhotoSessionGroupsCard
            allowDownloadOfPhotos={photoSessionGroupContact.photoSessionGroup().photoSession().allowDownloadOfPhotos()}
            className={this.cardClassNames(index)}
            key={photoSessionGroupContact.id()}
            photoSessionGroupContact={photoSessionGroupContact}
            photoSessionGroupContactTokenContact={this.findTokenForPhotoSessionGroupContact(photoSessionGroupContact)}
            tenant={tenant}
          />
        )}
        {photoSessionGroupContacts?.length === 0 &&
          <div className="no-albums-container">
            {I18n.t("js.merfoto.albums.index.no_accessible_albums_were_found")}
          </div>
        }
      </div>
    )
  }

  findTokenForPhotoSessionGroupContact = (photoSessionGroupContact) => {
    const {photoSessionGroupContactTokenContacts} = this.state
    const photoSessionGroupContactTokenContact = photoSessionGroupContactTokenContacts
      .find((tokenContact) => tokenContact.photoSessionGroupContactId() === photoSessionGroupContact.id())

    return photoSessionGroupContactTokenContact
  }

  cardClassNames = (index) => {
    const {photoSessionGroupContacts} = this.state

    if (index < photoSessionGroupContacts.length - 1) {
      return "mb-4"
    }
  }
}

MerfotoAlbums.contextType = UserContext

export default MerfotoAlbums
