export default class SharedModelsTableHeader extends React.Component {
  static propTypes = {
    args: PropTypes.object
  }

  render() {
    const {args, className, onDragLeave, onDragOver, onDragStart, onDrop, ...restProps} = this.props

    return (
      <th
        className={classNames("components-shared-models-table-header", className)}
        onDragLeave={this.onDragLeave}
        onDragOver={this.onDragOver}
        onDragStart={this.onDragStart}
        onDrop={this.onDrop}
        {...restProps}
      />
    )
  }

  onDragLeave = (e) => this.props.onDragLeave && this.props.onDragLeave(e, this.props.args)
  onDragOver = (e) => this.props.onDragOver && this.props.onDragOver(e, this.props.args)
  onDragStart = (e) => this.props.onDragStart && this.props.onDragStart(e, this.props.args)
  onDrop = (e) => this.props.onDrop && this.props.onDrop(e, this.props.args)
}
