import OrderLineCard from "merfoto/components/basket/order-line-card"

MerfotoOrdersOrderCard.defaultProps = {
  editable: true
}

MerfotoOrdersOrderCard.propTypes = {
  className: PropTypes.string,
  editable: PropTypes.bool,
  order: PropTypes.instanceOf(Order).isRequired
}

export default function MerfotoOrdersOrderCard({className, editable, order, ...restProps}) {
  const orderedOrderLines = order.orderLines().loaded().sort((orderLine1, orderLine2) => orderLine1.createdAt().getTime() - orderLine2.createdAt().getTime())

  return (
    <div
      className={classNames("merfoto-components-orders-order-card", className)}
      data-editable={editable}
      data-order-id={order.id()}
      {...restProps}
    >
      {orderedOrderLines.map((orderLine) =>
        <OrderLineCard
          editable={editable}
          key={orderLine.id()}
          orderLine={orderLine}
        />
      )}
    </div>
  )
}
