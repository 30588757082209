export default class AdminLogsNamesActivityParticipant extends React.Component {
  static propTypes = PropTypesExact({
    log: PropTypes.instanceOf(Log).isRequired
  })

  state = ({
    activity: undefined,
    activityParticipantNotFound: false,
    contact: undefined
  })

  componentDidMount() {
    this.loadActivity()
    this.loadParticipantContact()
  }

  async loadActivity() {
    const activityId = this.props.log.trackable()?.activityId()

    if (activityId) {
      const activity = await Activity
        .ransack({id_eq: activityId})
        .select({Activity: ["id", "name"]})
        .first()

      this.setState({activity})
    } else {
      this.setState({activityParticipantNotFound: true})
    }
  }

  async loadParticipantContact() {
    const contactId = this.props.log.trackable()?.contactId()

    if (contactId) {
      const contact = await Contact
        .ransack({id_eq: contactId})
        .select({Contact: ["id", "name"]})
        .first()

      this.setState({contact})
    }
  }

  render() {
    const {activity, activityParticipantNotFound, contact} = digs(this.state, "activity", "activityParticipantNotFound", "contact")

    return (
      <div className="admin-components-logs-names-activity-participant">
        {ActivityParticipant.modelName().human()}:
        {activityParticipantNotFound &&
          <span className="ms-1">
            {I18n.t("js.admin.logs.names.activity_participant.no_activity_participant")}
          </span>
        }
        {activity &&
          <>
            <Link className="ms-1" to={AdminRoutes.activityPath(activity.id())}>
              {activity.name()}
            </Link>
            <span className="mx-1">
              /
            </span>
          </>
        }
        {contact &&
          <ContactLink contact={contact} />
        }
      </div>
    )
  }
}
