import Stack from "react-bootstrap/Stack"

export default class SessionsNewOneTimePasswordPrompt extends React.Component {
  static propTypes = PropTypesExact({
    email: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired
  })

  oneTimePasswordInputRef = React.createRef()

  render() {
    return (
      <div className="components-sessions-new-one-time-password-prompt">
        <form onSubmit={this.onSubmit}>
          <Stack gap={3}>
            <BootstrapInput
              autoComplete="one-time-password"
              autoFocus
              className="one-time-password-input"
              inputRef={digg(this, "oneTimePasswordInputRef")}
              label={I18n.t("js.sessions.new.one_time_password_prompt.one_time_password")}
              name="_otp"
              placeholder="OTP"
            />
            <SubmitButton label={I18n.t("js.sessions.new.one_time_password_prompt.sign_in")} />
            <Button
              className="send-code-with-email-button"
              label={I18n.t("js.sessions.new.one_time_password_prompt.send_code_again_with_email")}
              onClick={this.onSendCodeWithEmailButtonClicked}
            />
            <Button
              className="send-code-with-sms-button"
              label={I18n.t("js.sessions.new.one_time_password_prompt.send_code_again_with_sms")}
              onClick={this.onSendCodeWithSmsButtonClicked}
            />
          </Stack>
        </form>
      </div>
    )
  }

  onSendCodeWithEmailButtonClicked = () => this.onSendOneTimePasswordClicked("email")
  onSendCodeWithSmsButtonClicked = () => this.onSendOneTimePasswordClicked("sms")

  onSendOneTimePasswordClicked = async (deliveryMethod) => {
    const {email} = digs(this.props, "email")

    try {
      await User.sendOtp({email, delivery_method: deliveryMethod})
      FlashMessage.success(I18n.t("js.sessions.new.one_time_password_prompt.the_code_was_sent_again"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onSubmit = (e) => {
    e.preventDefault()

    const oneTimePassword = digg(this, "oneTimePasswordInputRef", "current", "value")
    const {onSubmit} = digs(this.props, "onSubmit")

    onSubmit({oneTimePassword})
  }
}
