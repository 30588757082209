export default class OrderDeliveriesDeliveryMethodsDeliveryMethodContent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    currentDeliveryMethodOption: PropTypes.object,
    order: PropTypes.instanceOf(Order).isRequired,
    orderDelivery: PropTypes.instanceOf(OrderDelivery).isRequired
  }

  render() {
    const {className, currentDeliveryMethodOption, order, orderDelivery, ...restProps} = this.props
    const DeliveryMethodContent = this.componentOrNull()

    return (
      <div
        className={classNames(
          "d-flex",
          "flex-column",
          "merfoto-components-order-deliveries-delivery-methods-delivery-method-content",
          "overflow-hidden",
          className
        )}
        {...restProps}
      >
        {DeliveryMethodContent &&
          <DeliveryMethodContent
            currentDeliveryMethodOption={currentDeliveryMethodOption}
            order={order}
            orderDelivery={orderDelivery}
          />
        }
      </div>
    )
  }

  componentOrNull() {
    const {currentDeliveryMethodOption} = digs(this.props, "currentDeliveryMethodOption")
    const {deliveryMethod} = digs(currentDeliveryMethodOption, "deliveryMethod")
    const dashedDeliveryMethod = Inflection.dasherize(Inflection.underscore(deliveryMethod))

    try {
      const DeliveryMethodContent = require(`merfoto/components/order-deliveries/delivery-methods/${dashedDeliveryMethod}/content`).default

      return DeliveryMethodContent
    } catch (error) {
      if ((error instanceof Error) && error.code == "MODULE_NOT_FOUND") {
        return null
      }

      throw error
    }
  }
}
