import Timeout from "await-timeout"

export default class ButtonsSubmit extends React.Component {
  static defaultProps = {
    block: false,
    disabled: false,
    large: false,
    outlineSecondary: false,
    save: false,
    small: false,
    type: "submit"
  }

  static propTypes = PropTypesExact({
    block: PropTypes.bool.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    label: PropTypes.string,
    large: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
    outlineSecondary: PropTypes.bool.isRequired,
    save: PropTypes.bool.isRequired,
    small: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired
  })

  state = {
    disabled: false
  }

  componentWillUnmount() {
    // TODO seems like a hack
    this.unmounted = true
  }

  render() {
    const {save, type} = digs(this.props, "save", "type")

    return (
      <button
        className={this.className()}
        data-disable-submit={this.props.disabled || this.state.disabled}
        onClick={this.onSubmitClicked}
        type={type}
      >
        {save &&
          <>
            <Icon className="me-1" icon="save" />
            {I18n.t("js.global.save")}
          </>
        }
        {!save &&
          (this.props.children || this.props.label || I18n.t("js.global.submit"))
        }
      </button>
    )
  }

  className() {
    const {block, large, small, outlineSecondary} = this.props
    const {className} = this.props
    const classNames = ["components-buttons-submit", "btn"]

    if (block) {
      classNames.push("btn-block")
    }

    if (className) {
      classNames.push(className)
    }

    if (this.props.disabled || this.state.disabled) {
      classNames.push("disabled")
    }

    if (large) {
      classNames.push("btn-lg")
    }

    if (outlineSecondary) {
      classNames.push("btn-outline-secondary")
    } else {
      classNames.push("btn-primary")
    }

    if (small) {
      classNames.push("btn-sm")
    }

    return classNames.join(" ")
  }

  onSubmitClicked = (e) => {
    if (this.props.disabled || this.state.disabled) {
      return e.preventDefault()
    }

    Timeout.set(1).then(() => {
      if (!this.unmounted) {
        this.setState({disabled: true})
      }
    })

    Timeout.set(1500).then(() => {
      if (!this.unmounted) {
        this.setState({disabled: false})
      }
    })

    if (this.props.onClick) {
      this.props.onClick()
    }
  }
}
