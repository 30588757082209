import buildBoxesToPhotosMapping from "shared/sheet-layouts/build-boxes-to-photos-mapping"
import SheetLayoutsPresentation from "components/sheet-layouts/presentation"

MerfotoTenantPhotoProductsWithPhotosCardResourcesBundleTenantPhotoProductPresentation.propTypes = {
  bundleTenantPhotoProduct: PropTypes.instanceOf(BundleTenantPhotoProduct),
  className: PropTypes.string,
  photos: PropTypes.arrayOf(PropTypes.instanceOf(Photo)).isRequired
}

export default function MerfotoTenantPhotoProductsWithPhotosCardResourcesBundleTenantPhotoProductPresentation({
  className,
  photos,
  bundleTenantPhotoProduct,
  ...restProps
}) {
  const tenantPhotoProduct = bundleTenantPhotoProduct.tenantPhotoProduct()
  const photoProduct = tenantPhotoProduct.photoProduct()

  if (photoProduct.resourceType() === "PhotoSheetProduct") {
    const photoSheetProduct = photoProduct.photoSheetProduct()
    const sheetLayout = photoSheetProduct.sheetLayout()
    const boxesToPhotosMapping = buildBoxesToPhotosMapping(sheetLayout, photos)

    return (
      <SheetLayoutsPresentation
        boxesToPhotosMapping={boxesToPhotosMapping}
        className={
          classNames(
            "merfoto-components-tenant-photo-products-with-photos-card-resources-bundle-bundle-tenant-photo-product-presentation",
            className
          )
        }
        data-bundle-tenant-photo-product-id={bundleTenantPhotoProduct.id()}
        sheetLayout={sheetLayout}
        sheetSize={sheetLayout.sheetSize()}
        size={290}
        {...restProps}
      />
    )
  }

  console.error(`Unhandled type in bundle: ${photoProduct.resourceType()}`)

  return (
    <div
      className={classNames(
        "merfoto-components-tenant-photo-products-with-photos-card-resources-bundle-bundle-tenant-photo-product-presentation",
        className
      )}
    >
      Unhandled type in bundle: {photoProduct.resourceType()}
    </div>
  )
}
