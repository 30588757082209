import "./style"

export default class MerfotoLayoutNavItem extends React.Component {
  static defaultProps = {
    active: false
  }

  static propTypes = PropTypesExact({
    active: PropTypes.bool.isRequired,
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  })

  render() {
    const {className} = this.props
    const {active, icon, identifier, label, to} = digs(this.props, "active", "icon", "identifier", "label", "to")

    return (
      <Link
        className={classNames("align-content-center", "merfoto-components-layout-nav-item", className)}
        data-active={active}
        data-identifier={identifier}
        to={to}
      >
        <div className="align-content-center align-items-center d-md-flex d-none icon-container justify-content-center mx-auto">
          <Icon className="nav-item-icon" icon={icon} />
        </div>
        <div className="nav-item-label pt-1 text-center">
          {label}
        </div>
      </Link>
    )
  }
}
