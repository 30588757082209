import AttributePresenter from "components/attribute-presenter"
import photoLabel from "shared/photos/photo-label"

export default class MerfotoBasketUniquePhotosLabel extends BaseComponent {
  static propTypes = {
    className: PropTypes.string,
    photoSheetProductOrderLine: ModelPropType.ofModel(PhotoSheetProductOrderLine)
      .withLoadedAssociation("boxes")
        .withLoadedAssociation("photo")
          .withLoadedAttributes(["photoSessionGroupId", "photoSessionGroupContactId"])
          .previous()
        .previous()
      .isRequired
  }

  render() {
    const {className, photoSheetProductOrderLine, ...restProps} = this.props

    return (
      <AttributePresenter
        className={classNames("merfoto-components-photo-sheet-product-order-lines-unique-photos-label", className)}
        label={I18n.t("js.merfoto.basket.unique_photos_label.photo_s")}
        upperCaseLabel
        {...restProps}
      >
        {this.photosWithUniqueLabels().map((photo) =>
          <div key={photo.id()}>
            {photoLabel(photo)}
          </div>
        )}
      </AttributePresenter>
    )
  }

  photosWithUniqueLabels() {
    const {photoSheetProductOrderLine} = digs(this.props, "photoSheetProductOrderLine")
    const photos = photoSheetProductOrderLine.boxes().loaded().map((photoSheetProductOrderLineBox) => photoSheetProductOrderLineBox.photo())
    const photoEntriesObject = {}

    for (const photo of photos) {
      photoEntriesObject[`${photo.photoSessionGroupId()}-${photo.photoSessionGroupContactId()}`] = photo
    }

    return Object.values(photoEntriesObject)
  }
}
