export default class AdminLogsLogHeader extends React.Component {
  static propTypes = PropTypesExact({
    log: PropTypes.instanceOf(Log).isRequired
  })

  render() {
    const {log} = this.props
    const key = log.logKey().key()
    const subject = log.logSubject().subject()

    let Header

    Header = this.requireOrFail(`${subject}/${key}`)
    if (!Header) {
      Header = this.requireOrFail(`${key}`)
    }

    if (!Header) {
      return `${subject}.${key}`
    }

    return (
      <Header log={log} />
    )
  }

  requireOrFail = (path) => {
    try {
      return require(`admin/components/logs/headers/${path}`).default
    } catch (error) {
      if ((error instanceof Error) && error.code == "MODULE_NOT_FOUND") {
        return null
      }

      throw error
    }
  }
}
