import getCurrentLayout from "tenants/get-current-layout"

export default function currentRoutes() {
  const currentLayout = getCurrentLayout()

  if (currentLayout == "admin") {
    return AdminRoutes
  } else if (currentLayout == "merfoto") {
    return MerfotoRoutes
  } else if (currentLayout == "nemoa") {
    return NemoaRoutes
  } else if (currentLayout == "election") {
    return ElectionRoutes
  }

  throw new Error(`Unknown layout: ${currentLayout}`)
}
