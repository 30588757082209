import {useCallback, useContext} from "react"
import OrdersTable from "merfoto/components/orders/table"
import {UserContext} from "shared/user-context"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoRoutesOrders() {
  const [currentUser] = useContext(UserContext)
  const ordersCollection = useCallback(() => Order.ransack({customer_user_id_eq: currentUser.id(), state_not_eq: "basket"}), [currentUser])

  useTitle(I18n.t("js.merfoto.orders.order"))

  return (
    <div className="merfoto-routes-orders">
      <OrdersTable collection={ordersCollection} />
    </div>
  )
}
