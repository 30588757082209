import BundleTenantPhotoProductPresentation from "./bundle/bundle-tenant-photo-product-presentation"
import ProductActions from "merfoto/components/tenant-photo-products-with-photos/card/product-actions"
import ProductCard from "merfoto/components/product-card"
import Stack from "react-bootstrap/Stack"

TenantPhotoProductsWithPhotosCardResourcesPhotoSheetProduct.propTypes = {
  className: PropTypes.string.isRequired,
  photos: PropTypes.arrayOf(PropTypes.instanceOf(Photo)).isRequired,
  photoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact).isRequired,
  tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct)
}

export default function TenantPhotoProductsWithPhotosCardResourcesPhotoSheetProduct({
  className,
  photos,
  photoSessionGroupContact,
  tenantPhotoProduct,
  ...restProps
}) {
  return (
    <ProductCard
      className={classNames("merfoto-components-tenant-photo-products-with-photos-card-resources-bundle", className)}
      data-bundle-id={tenantPhotoProduct.photoProduct().bundle().id()}
      footerContent={
        <ProductActions
          photoSessionGroupContact={photoSessionGroupContact}
          photos={photos}
          tenantPhotoProduct={tenantPhotoProduct}
        />
      }
      header={tenantPhotoProduct.name()}
      tenantPhotoProduct={tenantPhotoProduct}
      {...restProps}
    >
      <div style={{overflowX: "auto"}}>
        <div className="px-4">
          <Stack direction="horizontal" gap={1}>
            {tenantPhotoProduct.photoProduct().bundle().bundleTenantPhotoProducts().loaded().map((bundleTenantPhotoProduct) =>
              <div key={bundleTenantPhotoProduct.id()}>
                <BundleTenantPhotoProductPresentation
                  bundleTenantPhotoProduct={bundleTenantPhotoProduct}
                  photos={photos}
                />
              </div>
            )}
          </Stack>
        </div>
      </div>
    </ProductCard>
  )
}
