import Carousel from "react-bootstrap/Carousel"
import Image from "react-bootstrap/Image"
import Modal from "react-bootstrap/Modal"
import {photosSelectorFamily} from "shared/selectors/photos-selector-family"
import Stack from "react-bootstrap/Stack"
import {useRecoilValue} from "recoil"
import {useState} from "react"

export default function MerfotoPhotoSessionGroupsCarousel({className, photoSessionGroupContact, ...restProps}) {
  const photos = useRecoilValue(photosSelectorFamily(photoSessionGroupContact.id()))
  const [activePhotoIndex, setActivePhotoIndex] = useState(-1)
  const show = activePhotoIndex > -1

  return (
    <div className={classNames("overflow-auto", "pb-2", className)} {...restProps}>
      <Modal backdrop centered onHide={() => setActivePhotoIndex(-1)} show={show} size="lg">
        <Modal.Header closeButton />
        <Modal.Body>
          <Carousel activeIndex={activePhotoIndex} interval={null} keyboard onSelect={(selectedIndex) => setActivePhotoIndex(selectedIndex)}>
            {photos?.map((photo) =>
              <Carousel.Item key={photo.id()}>
                <div className="d-flex justify-content-center">
                  <img loading="lazy" src={photo.fullSizeUrl()} style={{objectFit: "contain", maxWidth: "100%"}} />
                </div>
              </Carousel.Item>
            )}
          </Carousel>
        </Modal.Body>
      </Modal>
      <Stack direction="horizontal" gap={2}>
        {photos?.map((photo, index) =>
          <Image
            className="photo-in-carousel"
            data-photo-id={photo.id()}
            key={photo.id()}
            onClick={() => setActivePhotoIndex(index)}
            src={photo.thumbnailUrl()}
            style={{cursor: "zoom-in", height: 200}}
            thumbnail
          />
        )}
      </Stack>
    </div>
  )
}
