import SanitizedHtml from "../../../components/sanitized-html"
import SessionsNew from "components/sessions/new"
import {tenantShopSettingSelector} from "shared/selectors/tenant-shop-setting-selector"
import TenantShopSettingsVimeoVideo from "merfoto/components/tenant-shop-settings/vimeo-video"
import {useRecoilValue} from "recoil"

MerfotoSessionsNew.propTypes = {
  onSignedIn: PropTypes.func
}

export default function MerfotoSessionsNew({className, onSignedIn, ...restProps}) {
  const tenantShopSetting = useRecoilValue(tenantShopSettingSelector)

  return (
    <div className={classNames("merfoto-components-sessions-new-with-greeter", className)} {...restProps}>
      {tenantShopSetting && (tenantShopSetting.hasGreeterText() || tenantShopSetting.hasVimeoGreeterIdentifier()) &&
        <div className="greeter-content mb-4">
          {tenantShopSetting.hasGreeterText() &&
            <SanitizedHtml
              className={classNames("greeter-text", "p-2", {"mb-2": tenantShopSetting.hasVimeoGreeterIdentifier()})}
              dirty={tenantShopSetting.greeterText()}
            />
          }
          {tenantShopSetting.hasVimeoGreeterIdentifier() &&
            <TenantShopSettingsVimeoVideo tenantShopSetting={tenantShopSetting} />
          }
        </div>
      }
      <SessionsNew authenticateAnonymously={false} onSignedIn={onSignedIn} showLogo={false} />
    </div>
  )
}
