import Column from "./column"

export default class SharedModelsTableActionsColumn extends React.Component {
  static propTypes = PropTypesExact({
    columnsContentArgs: PropTypes.object.isRequired,
    model: PropTypes.object.isRequired,
    onDestroyModelClicked: PropTypes.func.isRequired,
    onEditClicked: PropTypes.func.isRequired,
    onSubmitEditableRowClicked: PropTypes.func.isRequired,
    onUnSoftDeleteClicked: PropTypes.func.isRequired,
    tableProps: PropTypes.object,
    tableState: PropTypes.object
  })

  render() {
    const {columnsContentArgs, model} = this.props
    const {actions, actionsContent, editModelPath, preActionsContent, unSoftDeleteAction, viewModelPath, viewTitle} = this.props.tableProps
    const {editableRows} = this.props.tableState
    const editable = editableRows.includes(model.id())

    return (
      <Column className="actions-column">
        {preActionsContent && preActionsContent({model})}

        {editable &&
          <a className="save-row-button" href="#" onClick={this.onSaveRowButtonClicked}>
            <Icon icon="save" size="small" />
          </a>
        }

        {this.shouldShowEditRowButton(model) &&
          <TableEditButton className={this.editRowButtonClassName(editable)} icon="pen" label={false} onClick={this.onTableEditButtonClicked} />
        }

        {viewModelPath && viewModelPath({model}) && model.can("show") &&
          <TableViewButton className="me-1" link title={viewTitle} to={viewModelPath({model})} />
        }

        {editModelPath && model.can("edit") &&
          <TableEditButton className="edit-with-path-button" label={false} to={editModelPath({model})} />
        }

        {actions?.(columnsContentArgs)?.map(({icon, identifier, onClick, tooltip}, index) =>
          <Icon
            className={classNames("action-button", {"ms-1": index > 0})}
            data-identifier={identifier}
            icon={icon}
            key={identifier}
            muted
            onClick={() => onClick(columnsContentArgs)}
            tooltip={tooltip}
          />
        )}
        {actionsContent && actionsContent(columnsContentArgs)}

        {this.shouldShowDestroyRowButton(model) &&
          <TableDestroyButton className="destroy-row-button" onClick={this.onTableDestroyButtonClicked} />
        }

        {unSoftDeleteAction && model.deletedAt() && model.can("unSoftDelete") &&
          <a
            className="btn btn-action un-soft-delete-button"
            href="#"
            onClick={this.onSoftDeleteButtonClicked}
            title={I18n.t("js.global.un_soft_delete")}
          >
            <Icon icon="share" size="small" />
          </a>
        }
      </Column>
    )
  }

  editRowButtonClassName = (editable) => {
    if (editable) {
      return "cancel-edit-row-button"
    }

    return "edit-row-button"
  }

  onSaveRowButtonClicked = (e) => this.props.onSubmitEditableRowClicked(e, this.props.model)
  onSoftDeleteButtonClicked = (e) => this.props.onUnSoftDeleteClicked(e, this.props.model)
  onTableDestroyButtonClicked = () => this.props.onDestroyModelClicked(this.props.model)
  onTableEditButtonClicked = (e) => this.props.onEditClicked(e, this.props.model)

  shouldShowDestroyRowButton = (model) => {
    const {showDestroyRowButton, softDeleteAction} = this.props.tableProps

    if (showDestroyRowButton) {
      return showDestroyRowButton({model})
    } else if (softDeleteAction && model.can("softDelete")) {
      return true
    }

    return model.can("destroy")
  }

  shouldShowEditRowButton = (model) => {
    const {editable, showEditRowButton} = this.props.tableProps

    if (showEditRowButton) {
      return showEditRowButton({model})
    }

    if (editable && !model.can("edit")) {
      return false
    }

    return editable
  }
}
