export default class DropDownButtonItem extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    label: PropTypes.node,
    to: PropTypes.string
  }

  render() {
    const {children, className, label, ...restProps} = this.props

    return (
      <Link {...restProps} className={classNames("components-shared-drop-down-button-item", className)} to={this.to()}>
        {children || label}
      </Link>
    )
  }

  to() {
    return this.props.to || "#"
  }
}
