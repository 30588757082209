export default class AdminLogsNamesUser extends React.Component {
  static propTypes = PropTypesExact({
    log: PropTypes.instanceOf(Log).isRequired
  })

  render() {
    const trackable = this.props.log.trackable()

    return (
      <UserLink className="user-link" user={trackable} />
    )
  }
}
