import UsersConfirmation from "components/users/confirmation"
import {useState} from "react"

export default function MerfotoUsersConfirmation() {
  const [confirmed, setConfirmed] = useState()

  return (
    <UsersConfirmation confirmed={confirmed} onEmailConfirmed={() => setConfirmed(true)} />
  )
}
