import {useContext, useEffect, useState} from "react"
import AddressForm from "merfoto/components/checkout/address-form"
import CheckoutNavbar from "merfoto/components/checkout/navbar"
import MerfotoCard from "merfoto/components/card"
import Stack from "react-bootstrap/Stack"
import {useNavigate} from "react-router-dom"
import {UserContext} from "shared/user-context"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoCheckoutCustomerInformation() {
  const [invoiceAddress, setInvoiceAddress] = useState()
  const [order, setOrder] = useState()
  const [currentUser] = useContext(UserContext)
  const navigate = useNavigate()
  const onSubmit = async (e) => {
    e.preventDefault()

    const form = e.target
    const formData = new FormData(form)

    try {
      await order.updateCustomerInformation(formData)
      FlashMessage.success(I18n.t("js.merfoto.checkout.customer_information.your_information_was_saved"))
      navigate(MerfotoRoutes.checkoutDeliveryPath())
    } catch (error) {
      order.parseValidationErrors(error, {form, throwValidationError: false})

      FlashMessage.errorResponse(error)
    }
  }

  useTitle(I18n.t("js.merfoto.checkout.customer_information.customer_information"))

  useEffect(() => {
    async function loadOrder() {
      const order = await Order
        .ransack({
          customer_user_id_eq: currentUser.id(),
          state_eq: "basket"
        })
        .select({
          Address: ["city", "country", "id", "placeName", "street", "zipCode"],
          Order: ["customerEmail", "customerFirstName", "customerLastName", "customerMobilePhoneNumber", "id", "invoiceAddressId", "state"],
          OrderDelivery: ["id"]
        })
        .preload(["invoice_address", "order_delivery"])
        .first()

      let invoiceAddress

      if (order?.invoiceAddress()) {
        invoiceAddress = order.invoiceAddress()
      } else {
        invoiceAddress = new Address({country: "DK"})
      }

      if (!order) {
        navigate(MerfotoRoutes.basketPath(), {replace: true})
      }

      setInvoiceAddress(invoiceAddress)
      setOrder(order)
    }

    if (currentUser) {
      loadOrder()
    }
  }, [currentUser, navigate])

  return (
    <div className="merfoto-routes-checkout-customer-information">
      {order &&
        <div className="content-container">
          <CheckoutNavbar activeIdentifier="customer-information" className="mb-4" order={order} />
          <MerfotoCard.Outer>
            <MerfotoCard.Header>
              {I18n.t("js.merfoto.checkout.customer_information.customer_information")}
            </MerfotoCard.Header>
            <MerfotoCard.Body>
              <form onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <BootstrapInput
                      attribute="customerFirstName"
                      className="form-control-sm"
                      label={I18n.t("js.merfoto.checkout.customer_information.first_name")}
                      model={order}
                    />
                  </div>
                  <div className="col-md-6">
                    <BootstrapInput
                      attribute="customerLastName"
                      className="form-control-sm"
                      label={I18n.t("js.merfoto.checkout.customer_information.last_name")}
                      model={order}
                    />
                  </div>
                </div>
                <BootstrapInput
                  attribute="customerEmail"
                  className="form-control-sm"
                  label={I18n.t("js.merfoto.checkout.customer_information.email")}
                  model={order}
                />
                <BootstrapInput
                  attribute="customerMobilePhoneNumber"
                  className="form-control-sm"
                  label={I18n.t("js.merfoto.checkout.customer_information.mobile_phone_number")}
                  model={order}
                />
                <AddressForm address={invoiceAddress} addressInputNamePrefix="order[invoice_address_attributes]" className="mb-3" />
                <Stack>
                  <SubmitButton label={I18n.t("js.global.continue")} />
                </Stack>
              </form>
            </MerfotoCard.Body>
          </MerfotoCard.Outer>
        </div>
      }
    </div>
  )
}
