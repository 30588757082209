import "./style"
import BottomBarContent from "admin/components/layout/bottom-bar/content"
import {useNavigate} from "react-router-dom"

AdminComponentsLayoutBottomBarAction.defaultProps = {
  disabled: false
}

AdminComponentsLayoutBottomBarAction.propTypes = {
  disabled: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string
}

export default function AdminComponentsLayoutBottomBarAction(props) {
  const {className, disabled, icon, label, onClick: onClickFromProps, to, ...restProps} = props
  const navigate = useNavigate()
  const onClick = (...args) => {
    if (onClickFromProps) onClickFromProps(...args)
    if (to) navigate(to)
  }

  return (
    <BottomBarContent className={classNames("admin-components-layout-bottom-bar-action", className)} onClick={onClick} {...restProps}>
      <Icon disabled={disabled} icon={icon} size="small" tooltip={label} />
      <div className="action-label">
        {label}
      </div>
    </BottomBarContent>
  )
}
