import {currentPhotoSessionGroupContactIdAtom} from "../atoms/current-photo-session-group-contact-id-atom"
import {selector} from "recoil"

export const currentPhotoSessionGroupContactSelector = selector({
  key: "currentPhotoSessionGroupContactSelector",
  get: async({get}) => {
    const id = get(currentPhotoSessionGroupContactIdAtom)

    // TODO when userContext gets replaced with recoil, add albums_for_user to the ransack
    return await PhotoSessionGroupContact.ransack({id_eq: id}).preload(["contact", "photo_session", "photo_session_group"]).first()
  }
})
