export default class AdminLogsLogDetails extends React.Component {
  static propTypes = PropTypesExact({
    log: PropTypes.instanceOf(Log).isRequired
  })

  state = ({
    log: undefined
  })

  componentDidMount() {
    this.loadLog()
  }

  async loadLog() {
    const log = await Log
      .ransack({id_eq: this.props.log.id()})
      .preload(["log_key", "log_subject"])
      .first()

    this.setState({log})
  }

  render() {
    const {log} = digs(this.state, "log")

    if (!log) {
      return null
    }

    const key = log.logKey().key()
    const subject = log.logSubject().subject()

    let Presenter

    Presenter = this.requireOrFail(`${subject}/${key}`)

    if (!Presenter) {
      Presenter = this.requireOrFail(`${key}`)
    }

    if (!Presenter) {
      return (
        <div className="mb-4 row">
          <div className="container">
            <code className="p-2">
              {JSON.stringify(log.parameters())}
            </code>
          </div>
        </div>
      )
    }

    return (
      <Presenter log={log} />
    )
  }

  requireOrFail = (path) => {
    try {
      return require(`admin/components/logs/details/${path}`).default
    } catch (error) {
      if ((error instanceof Error) && error.code == "MODULE_NOT_FOUND") {
        return null
      }

      throw error
    }
  }
}
