import "./style"
import CompanyImage from "./company-image"
import ElementImage from "./element-image"
import PersonImage from "./person-image"
import SchoolImage from "./school-image"
import {UserContext} from "shared/user-context"

export default class AdminWorkerPluginsModelCheckbox extends React.Component {
  static propTypes = PropTypesExact({
    checkbox: PropTypes.elementType,
    className: PropTypes.string,
    model: PropTypes.object.isRequired
  })

  state = {}

  componentDidMount() {
    this.loadCurrentLink()
  }

  async loadCurrentLink() {
    const {model} = this.props
    const response = await Workplace.linkFor({model_class: model.modelClassData().name, model_id: model.id()})
    const link = digg(response, "link")

    this.setState({
      checked: Boolean(link),
      linkLoaded: true
    })
  }

  render() {
    const {className, model} = this.props
    const {checked, linkLoaded} = this.state

    if (!linkLoaded) {
      return null
    }

    let style

    if (!checked) {
      style = {backgroundImage: `url('${this.imagePath()}')`}
    }

    return (
      <>
        <UserContext.Consumer>
          {([currentUser]) =>
            <>
              <EventConnection event="workplace_links_created" model={currentUser} onCall={this.onLinksCreated} />
              <EventConnection event="workplace_links_destroyed" model={currentUser} onCall={this.onLinksDestroyed} />
            </>
          }
        </UserContext.Consumer>
        <div
          className={classNames("admin-components-worker-plugins-model-checkbox", "rounded-circle", className)}
          data-checked={checked}
          data-model-id={model.id()}
          onClick={this.onCheckedChanged}
        >
          <div
            className={classNames("align-items-center", "contained-image", "d-flex", "inner-image", "rounded-circle", {
              "bg-primary": checked,
              "bg-white": !checked
            })}
            style={style}
          >
            {checked && <i className="la la-check mx-auto" />}
          </div>
        </div>
      </>
    )
  }

  imagePath() {
    const {model} = this.props

    if (model instanceof Contact) {
      const person = model.person()

      if (person && person.hasProfileImagePath()) {
        return person.profileImagePath()
      } else if (person) {
        return PersonImage
      } else if (model.contactType() == "school") {
        return SchoolImage
      }

      return CompanyImage
    }

    return ElementImage
  }

  onCheckedChanged = (e) => {
    e.preventDefault()

    const {model} = this.props
    const {checked} = this.state

    if (checked) {
      const modelClassName = model.modelClassData().name
      const params = {models: {}}

      params.models[modelClassName] = [model.id()]

      Workplace.destroyLinks(params)
    } else {
      Workplace.createLink({model_class: model.modelClassData().name, model_id: model.id()})
    }
  }

  onLinksCreated = (args) => {
    const {model} = this.props
    const id = model.id()
    const modelClassName = model.modelClassData().name

    if (args.args.created[modelClassName] && args.args.created[modelClassName].includes(id)) {
      this.setState({checked: true})
    }
  }

  onLinksDestroyed = (args) => {
    const {model} = this.props
    const id = model.id()
    const modelClassName = model.modelClassData().name

    if (args.args.destroyed[modelClassName]?.includes(id)) {
      this.setState({checked: false})
    }
  }
}
