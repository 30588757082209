import "./style"
import Money from "js-money"

export default class MerfotoOrderDeliveriesDeliveryMethodsDeliveryMethodOption extends React.Component {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    deliveryMethod: PropTypes.string.isRequired,
    identifier: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
    onChecked: PropTypes.func.isRequired,
    priceWithVat: PropTypes.instanceOf(Money).isRequired
  }

  render() {
    const {className, checked, deliveryMethod, identifier, label, logo, onChecked, order, priceWithVat, ...restProps} = this.props

    return (
      <div
        className={classNames("merfoto-components-order-deliveries-delivery-methods-delivery-method-option", className)}
        data-checked={checked}
        data-identifier={identifier}
        onClick={this.onClick}
        {...restProps}
      >
        <div className="d-flex option-content">
          <div className="d-flex flex-column p-2">
            <div className="mb-1">
              <img height="20" src={logo} />
            </div>
            {label}
          </div>
          <div className="align-self-center ms-auto me-2 price-with-vat">
            {MoneyFormatter.format(priceWithVat, {excludeCurrency: false})}
          </div>
        </div>
      </div>
    )
  }

  onClick = (e) => {
    e.preventDefault()

    this.props.onChecked()
  }
}
