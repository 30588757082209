import {useEffect, useState} from "react"
import PhotoSessionGroupsCard from "merfoto/components/photo-session-groups/card"
import {useParams} from "react-router-dom"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoAlbumShow() {
  const [photoSessionGroupContact, setPhotoSessionGroupContact] = useState()
  const {id} = useParams()

  useTitle(I18n.t("js.merfoto.routes.albums.albums"))

  useEffect(() => {
    async function loadPhotoSessionGroupContact() {
      const photoSessionGroupContact = await PhotoSessionGroupContact
        .ransack({id_eq: id})
        .preload(["contact", "photo_session_group.photo_session"])
        .select({
          Contact: ["id", "name"],
          PhotoSession: ["allowDownloadOfPhotos", "id", "startsAt"],
          PhotoSessionGroup: ["id", "name"]
        })
        .first()

      setPhotoSessionGroupContact(photoSessionGroupContact)
    }

    loadPhotoSessionGroupContact()
  }, [id])

  return (
    <div className="merfoto-routes-albums">
      <h1 className="mb-4">{I18n.t("js.merfoto.routes.albums.albums")}</h1>
      {photoSessionGroupContact &&
        <PhotoSessionGroupsCard
          photoSessionGroupContact={photoSessionGroupContact}
          tenant={tenant}
        />
      }
    </div>
  )
}
