export default class MerfotoBasketDestroyorderLineButton extends React.Component {
  static propTypes = {
    orderLine: PropTypes.instanceOf(OrderLine).isRequired
  }

  render() {
    const {className, orderLine, ...restProps} = this.props

    return (
      <Icon
        className={classNames("merfoto-components-basket-destroy-order-line-button", className)}
        data-order-line-id={orderLine.id()}
        icon="trash"
        onClick={this.onDestroyOrderLineClicked}
        {...restProps}
      />
    )
  }

  onDestroyOrderLineClicked = async (e) => {
    e.preventDefault()

    const {orderLine} = digs(this.props, "orderLine")

    if (!await CustomConfirm.confirm(I18n.t("js.merfoto.basket.destroy_order_line_button.do_you_want_to_remove_item_from_basket"))) {
      return
    }

    try {
      await orderLine.destroy()
      FlashMessage.success(I18n.t("js.merfoto.basket.destroy_order_line_button.the_item_was_removed_from_the_basket"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
