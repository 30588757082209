export default class FormDataBuilder {
  constructor() {
    this.formData = new FormData()
  }

  scanInputsFrom(element) {
    const inputs = element.querySelectorAll("input, select, textarea")

    for (const input of inputs) {
      const name = input.getAttribute("name")
      const value = input.value

      if (name != "") {
        if (input.type == "file") {
          for (const file of input.files) {
            this.formData.append(name, file)
          }
        } else if (input.type == "checkbox") {
          this.formData.append(name, input.checked)
        } else {
          this.formData.append(name, value)
        }
      }
    }
  }

  getFormData() {
    return this.formData
  }
}
