import "./style"

export default class LayoutBlockUi extends React.Component {
  static propTypes = PropTypesExact({
    blocking: PropTypes.bool.isRequired
  })

  render() {
    return (
      <div className="component-block-ui" data-blocking={this.props.blocking ? "true" : "false"}>
        {I18n.t("js.block_ui.please_wait")}
      </div>
    )
  }
}
