export default class PlaceholderLabel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    labelContent: PropTypes.node.isRequired,
    multiple: PropTypes.bool.isRequired,
    onUnselectOptionClicked: PropTypes.func.isRequired
  }

  render() {
    const {className, labelContent, multiple, onUnselectOptionClicked, ...restProps} = this.props

    return (
      <span className={classNames("components-nemoa-select-placeholder-label", className)} {...restProps}>
        {multiple &&
          <>
            {labelContent}
            <i className="la la-remove unselect-option-button" onClick={this.onUnselectOptionButtonClicked} />
          </>
        }
        {!multiple && labelContent}
      </span>
    )
  }

  onUnselectOptionButtonClicked = (e) => this.props.onUnselectOptionClicked(e)
}
