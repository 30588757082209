import "./style"
import BodyPortal from "components/body-portal"
import getAbsoluteOffsets from "shared/get-absolute-offsets"
import MenuModal from "./menu-modal"
import {UserContext} from "shared/user-context"

class MerfotoLayoutTopbarIndex extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string
  })

  state = {
    menuModalLeft: undefined,
    menuModalShown: false,
    menuModalTop: undefined
  }

  render() {
    const {className} = this.props
    const {menuModalLeft, menuModalShown, menuModalTop} = this.state
    const [currentUser] = this.context

    return (
      <div className={classNames("merfoto-components-layout-top-bar-user-button", className)}>
        {menuModalShown &&
          <BodyPortal>
            <MenuModal
              left={menuModalLeft}
              onRequestClose={this.onMenuModelRequestClose}
              ref="menuModal"
              top={menuModalTop}
            />
          </BodyPortal>
        }
        <a className="username-menu-link" href="#" onClick={this.onClick}>
          <span className={classNames("d-sm-inline", "d-none", "me-2", "username-label")}>
            {currentUser?.name()}
          </span>
          <Icon icon="user" size="medium" />
        </a>
      </div>
    )
  }

  onClick = (e) => {
    e.preventDefault()

    const userMenuLinkElement = digg(e, "target").closest(".username-menu-link")
    const offsets = getAbsoluteOffsets(userMenuLinkElement)

    this.setState({
      menuModalLeft: digg(offsets, "offsetLeft") - 20,
      menuModalShown: !this.state.menuModalShown,
      menuModalTop: digg(offsets, "offsetTop") - 10
    })
  }

  onMenuModelRequestClose = () => this.setState({menuModalShown: false})
}

MerfotoLayoutTopbarIndex.contextType = UserContext

export default MerfotoLayoutTopbarIndex
