import "./style"
import buildBoxesToPhotosMapping from "shared/sheet-layouts/build-boxes-to-photos-mapping"
import cloneDeep from "clone-deep"
import HeaderCard from "merfoto/components/header-card"
import MerfotoRadioButton from "merfoto/components/radio-button"
import MutedHeader from "merfoto/components/muted-header"
import photosQueryFromPhotoSessionGroupContact from "shared/photos/photos-query-from-photo-session-group-contact"
import SheetLayoutPreview from "./sheet-layout-preview"
import SheetLayoutsPresentation from "components/sheet-layouts/presentation"

export default class MerfotoComponentsOrderLinesEditResourcesPhotoSheetProductContent extends React.Component {
  static propTypes = {
    inputPre: PropTypes.string,
    photos: PropTypes.array.isRequired,
    photoSheetProductOrderLine: PropTypes.instanceOf(PhotoSheetProductOrderLine).isRequired,
    tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct).isRequired
  }

  state = {
    boxesToPhotosMapping: undefined,
    currentPhotoQuality: undefined,
    draggedOverBox: undefined,
    draggedPhoto: undefined,
    inputPre: this.props.inputPre || "order_line[orderable_attributes]",
    otherPhotosFromAlbums: undefined,
    otherPhotosFromAlbumsGroups: undefined,
    photoQualities: undefined,
    photoSessionGroupContactId: undefined,
    photoSheetProduct: undefined,
    sheetLayout: undefined,
    sheetSize: undefined,
    primarySheetLayoutProps: this.calculatePrimarySheetLayoutPresentationSize(),
    tenant: CurrentTenant.current()
  }

  calculatePrimarySheetLayoutPresentationSize() {
    const params = Params.parse()
    const windowWidth = digg(window, "innerWidth")

    if ("sheet_layout_presentation_size" in params) {
      return {
        size: parseInt(digg(params, "sheet_layout_presentation_size"))
      }
    }

    if (windowWidth < 576) {
      return {width: windowWidth - 50}
    } else if (windowWidth >= 576 && windowWidth < 768) {
      return {width: 490}
    } else if (windowWidth >= 768 && windowWidth < 992) {
      return {width: 320}
    } else if (windowWidth >= 992 && windowWidth < 1200) {
      return {width: 440}
    }

    return {width: 540}
  }

  componentDidMount() {
    const {photoSheetProductOrderLine} = this.props

    if (photoSheetProductOrderLine.isPersisted()) {
      this.loadDataFromExistingPhotoSheetProductOrderLine()
    } else {
      this.loadNewPhotoSheetProductOrderLine()
    }

    this.loadPhotoQualities().then(() => {
      if (photoSheetProductOrderLine.isNewRecord()) {
        this.setState((state) => ({
          currentPhotoQuality: state.photoQualities[0]
        }))
      }
    })
  }

  async loadDataFromExistingPhotoSheetProductOrderLine() {
    await this.loadExistingPhotoSheetProductOrderLine()

    const {photoSheetProductOrderLine} = this.props
    const photos = photoSheetProductOrderLine.boxes().loaded().map((box) => box.photo())

    this.loadOtherPhotosFromAlbums()

    this.setState({
      currentPhotoQuality: photoSheetProductOrderLine.photoQuality(),
      photos
    })
  }

  loadExistingPhotoSheetProductOrderLine() {
    const {photoSheetProductOrderLine} = this.props

    if (!photoSheetProductOrderLine.photoSheetProduct()) throw new Error("No photoSheetProduct on photoSheetProductOrderLine?")
    if (!photoSheetProductOrderLine.sheetLayout()) throw new Error("No sheetLayout on photoSheetProductOrderLine?")
    if (!photoSheetProductOrderLine.sheetSize()) throw new Error("No sheetSize on photoSheetProductOrderLine?")

    this.setState({
      photoSheetProduct: photoSheetProductOrderLine.photoSheetProduct(),
      sheetLayout: photoSheetProductOrderLine.sheetLayout(),
      sheetSize: photoSheetProductOrderLine.sheetSize()
    })
  }

  loadNewPhotoSheetProductOrderLine() {
    this.loadOtherPhotosFromAlbums()
    this.loadPhotoSheetProduct()
  }

  loadBoxesToPhotosMapping() {
    const {photos} = this.props
    const {otherPhotosFromAlbums, sheetLayout} = this.state

    if (!photos) {
      throw new Error("'photos' wasn't present")
    } else if (photos.length === 0) {
      throw new Error("The photos array was empty")
    } else if (!sheetLayout) {
      throw new Error("Expected sheet layout to have been loaded")
    }

    const boxesToPhotosMapping = buildBoxesToPhotosMapping(sheetLayout, photos, otherPhotosFromAlbums)

    this.setState({
      boxesToPhotosMapping
    })
  }

  async loadPhotoSheetProduct() {
    const {tenantPhotoProduct} = this.props
    const {tenant} = this.state

    const photoSheetProduct = await PhotoSheetProduct
      .ransack({
        tenant_photo_products_id_eq: tenantPhotoProduct.id()
      })
      .preload([
        "photo_sheet_product_sheet_layouts.sheet_layout.boxes",
        "photo_sheet_product_sheet_layouts.sheet_layout.sheet_size",
        "sheet_layout.boxes",
        "sheet_layout.sheet_size"
      ])
      .select({
        PhotoSheetProduct: ["id", "name"],
        PhotoSheetProductSheetLayout: ["id", "updatedAt"],
        SheetLayout: ["id", "webshopName"],
        SheetLayoutBox: ["heightPercent", "id", "leftPercent", "photoTypeId", "topPercent", "widthPercent"],
        SheetSize: ["heightMm", "id", "widthMm"]
      })
      .first()

    if (!photoSheetProduct) {
      if (tenantPhotoProductId) {
        throw new Error(`Photo sheet product couldn't be gotten from tenant photo product ID: ${tenantPhotoProductId}`)
      } else {
        throw new Error(`No default photo sheet product set on the tenant: ${tenant.name()}`)
      }
    }

    const sheetLayout = photoSheetProduct.sheetLayout()
    const sheetSize = sheetLayout?.sheetSize()

    if (!sheetLayout) throw new Error(`Couldn't read sheet layout for photo sheet product: ${photoSheetProduct.id()}`)
    if (!sheetSize) throw new Error(`Couldn't read sheet size for sheet layout: ${sheetLayout.id()}`)

    this.setState({photoSheetProduct, sheetLayout, sheetSize}, () => this.loadBoxesToPhotosMapping())
  }

  loadOtherPhotosFromAlbums = async () => {
    const {photoSheetProductOrderLine} = this.props

    let collection, photoSessionGroupContactId

    if (photoSheetProductOrderLine.isNewRecord()) {
      const params = Params.parse()

      photoSessionGroupContactId = params.photo_session_group_contact_id
    } else {
      photoSessionGroupContactId = photoSheetProductOrderLine.photoSessionGroupContactId()
    }

    if (photoSessionGroupContactId) {
      collection = photosQueryFromPhotoSessionGroupContact(photoSessionGroupContactId)
    } else {
      const {photos} = this.props
      const photoIds = photos.map((photo) => photo.id())

      collection = Photo.ransack({
        photo_session_group_photos_id_eq_any: photoIds
      })
    }

    const otherPhotosFromAlbums = await collection
      .preload([
        "photo_session_group_contact.contact",
        "photo_session_group_contact.photo_session_group",
        "photo_types"
      ])
      .select({
        Contact: ["name"],
        Photo: ["height", "id", "fullSizeUrl", "photoSessionGroupId", "photoSessionGroupContactId", "thumbnailUrl", "updatedAt", "width"],
        PhotoSessionGroup: ["id", "name"],
        PhotoSessionGroupContact: ["id"],
        PhotoType: ["id"]
      })
      .distinct()
      .toArray()

    const otherPhotosFromAlbumsGroupContacts = []

    for (const photo of otherPhotosFromAlbums) {
      if (!otherPhotosFromAlbumsGroupContacts.includes(photo.photoSessionGroupContact()) && photo.photoSessionGroupContact()) {
        otherPhotosFromAlbumsGroupContacts.push(photo.photoSessionGroupContact())
      }
    }

    this.setState({
      otherPhotosFromAlbums,
      otherPhotosFromAlbumsGroupContacts,
      photoSessionGroupContactId
    })
  }

  async loadPhotoQualities() {
    const photoQualities = await PhotoQuality
      .ransack({s: "current_translation_name asc", visible_in_shop_true: true})
      .toArray()

    if (photoQualities.length === 0) throw new Error(`No photo qualities could be read: ${photoQualities.length}`)

    this.setState({photoQualities})
  }

  render() {
    const {
      boxesToPhotosMapping,
      currentPhotoQuality,
      otherPhotosFromAlbums,
      photoQualities,
      photoSheetProduct,
      sheetLayout,
      sheetSize
    } = this.state
    const {className, inputPre, photos, photoSheetProductOrderLine, tenantPhotoProduct, ...restProps} = this.props

    return (
      <div
        className={classNames("merfoto-routes-order-lines-edit-resources-photo-sheet-product", className)}
        data-photo-sheet-product-id={photoSheetProduct?.id()}
        {...restProps}
      >
        {(boxesToPhotosMapping || photoSheetProductOrderLine) &&
          currentPhotoQuality &&
          otherPhotosFromAlbums &&
          photoQualities &&
          photos &&
          photoSheetProduct &&
          sheetLayout &&
          sheetSize &&
          tenantPhotoProduct &&
          this.content()
        }
      </div>
    )
  }

  content() {
    const {
      boxesToPhotosMapping,
      currentPhotoQuality,
      inputPre,
      otherPhotosFromAlbumsGroupContacts,
      photoQualities,
      photoSessionGroupContactId,
      photoSheetProduct,
      primarySheetLayoutProps,
      sheetLayout,
      sheetSize
    } = this.state
    const {photoSheetProductOrderLine, tenantPhotoProduct} = this.props

    return (
      <div className="content-container">
        <input name={`${inputPre}[photo_product_id]`} type="hidden" value={tenantPhotoProduct.photoProductId()} />
        <input name={`${inputPre}[photo_sheet_product_id]`} type="hidden" value={photoSheetProduct.id()} />
        <input name={`${inputPre}[sheet_layout_id]`} type="hidden" value={sheetLayout.id()} />
        <input name={`${inputPre}[sheet_size_id]`} type="hidden" value={sheetSize.id()} />
        <input name={`${inputPre}[tenant_photo_product_id]`} type="hidden" value={tenantPhotoProduct.id()} />
        <input name={`${inputPre}[photo_session_group_contact_id]`} type="hidden" value={photoSessionGroupContactId || ""} />
        {photoSheetProductOrderLine?.isPersisted() &&
          <input name={`${inputPre}[id]`} type="hidden" value={photoSheetProductOrderLine.id()} />
        }
        {photoSheetProductOrderLine?.isPersisted() && photoSheetProductOrderLine.boxes().loaded().map((photoSheetProductOrderLineBox) =>
          <React.Fragment key={photoSheetProductOrderLineBox.id()}>
            {!this.sheetLayoutBoxForPhotoSheetProductOrderLineBox(photoSheetProductOrderLineBox) &&
              <>
                <input
                  name={`${inputPre}[boxes_attributes][${photoSheetProductOrderLineBox.uniqueKey()}][id]`}
                  type="hidden"
                  value={photoSheetProductOrderLineBox.id()}
                />
                <input
                  name={`${inputPre}[boxes_attributes][${photoSheetProductOrderLineBox.uniqueKey()}][_destroy]`}
                  type="hidden"
                  value="1"
                />
              </>
            }
          </React.Fragment>
        )}
        <div className="row">
          <div className="col-md-6">
            <SheetLayoutsPresentation
              boxContent={this.sheetLayoutPresentationBoxContent}
              boxProps={this.chosenSheetLayoutBoxProps}
              boxesToPhotosMapping={boxesToPhotosMapping}
              className="chosen-sheet-layout"
              photoPosition
              photoRotation={this.photoRotationEnabled()}
              photoSheetProductOrderLine={photoSheetProductOrderLine}
              photoZoom
              sheetLayout={sheetLayout}
              sheetSize={sheetSize}
              {...primarySheetLayoutProps}
            />
          </div>
          <div className="col-md-6">
            <MutedHeader className="mb-1" style={{cursor: "default"}}>
              {I18n.t("js.merfoto.photo_sheet_product_order_lines.edit.chosen_sheet_layout")}
            </MutedHeader>
            <div className="sheet-layouts-container">
              <div className="d-flex">
                {photoSheetProduct.photoSheetProductSheetLayouts().loaded().map((otherPhotoSheetProductSheetLayout, index) =>
                  <div className={classNames({"ms-2": index > 0})} key={otherPhotoSheetProductSheetLayout.sheetLayout().id()}>
                    <SheetLayoutPreview
                      currentSheetLayout={sheetLayout}
                      onClick={this.onSheetLayoutPreviewClicked}
                      photoSheetProductSheetLayout={otherPhotoSheetProductSheetLayout}
                    />
                  </div>
                )}
              </div>
            </div>
            {sheetLayout.hasWebshopName() &&
              <HeaderCard className="mt-2 sheet-layout-webshop-name">
                {sheetLayout.webshopName()}
              </HeaderCard>
            }
            <MutedHeader className="mb-1 mt-4" style={{cursor: "default"}}>
              {I18n.t("js.merfoto.photo_sheet_product_order_lines.edit.choose_photos")}
            </MutedHeader>
            <div className="mb-2 fst-italic" style={{cursor: "default"}}>
              {I18n.t("js.merfoto.photo_sheet_product_order_lines.edit.drag_the_wanted_photos_onto_the_sheet_layout_to_the_left")}
            </div>
            <div className="mb-2 photos-from-other-albums-container" style={{cursor: "default"}}>
              <div className="d-flex">
                {this.otherPhotosFromAlbumsWithRelevantPhotoTypes().map((otherPhotoFromAlbums, index) =>
                  <div
                    className={classNames("other-photo-from-albums", {"ms-4": index > 0})}
                    data-photo-id={otherPhotoFromAlbums.id()}
                    key={otherPhotoFromAlbums.id()}
                  >
                    <img
                      className="other-photo-from-album-image"
                      onDragStart={() => this.onDragStart(otherPhotoFromAlbums)}
                      src={otherPhotoFromAlbums.thumbnailUrl()}
                      width="60px"
                    />
                  </div>
                )}
              </div>
            </div>
            <HeaderCard className="mb-4 photo-albums-containers">
              {otherPhotosFromAlbumsGroupContacts.map((otherPhotosFromAlbumsGroupContact) =>
                <div
                  data-session-group-contact-id={otherPhotosFromAlbumsGroupContact.id()}
                  data-session-group-id={otherPhotosFromAlbumsGroupContact.photoSessionGroup()?.id()}
                  key={otherPhotosFromAlbumsGroupContact.id()}
                >
                  {otherPhotosFromAlbumsGroupContact.photoSessionGroup().name()}; {otherPhotosFromAlbumsGroupContact.contact().name()}
                </div>
              )}
            </HeaderCard>
            {currentPhotoQuality &&
              <>
                <MutedHeader className="mb-2">
                  {PhotoQuality.modelName().human()}
                </MutedHeader>
                <div>
                  {photoQualities.map((photoQuality) =>
                    <MerfotoRadioButton
                      callbackArgs={{photoQuality}}
                      className="mb-1 photo-quality-container"
                      data-photo-quality-id={photoQuality.id()}
                      inputProps={{
                        defaultChecked: currentPhotoQuality.id() === photoQuality.id(),
                        name: `${inputPre}[photo_quality_id]`,
                        value: photoQuality.id()
                      }}
                      key={photoQuality.id()}
                      label={photoQuality.name()}
                      onChange={this.onPhotoQualityChanged}
                    />
                  )}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    )
  }

  otherPhotosFromAlbumsWithRelevantPhotoTypes() {
    const {otherPhotosFromAlbums, sheetLayout} = this.state
    const photoTypeIdsInBoxes = sheetLayout.boxes().loaded().filter((box) => box.photoTypeId()).map((box) => box.photoTypeId())

    if (photoTypeIdsInBoxes.length < sheetLayout.boxes().loaded().length) {
      return otherPhotosFromAlbums
    }

    return otherPhotosFromAlbums.filter((photo) => {
      const photoTypeIds = photo.photoTypes().loaded().map((photoType) => photoType.id())

      return photoTypeIds.find((photoTypeId) => photoTypeIdsInBoxes.includes(photoTypeId)) != null
    })
  }

  chosenSheetLayoutBoxProps = ({sheetLayoutBox}) => ({
    "data-box-active": this.state.draggedOverBox?.id() == sheetLayoutBox.id(),
    "onDragLeave": () => this.onDragLeaveBox(sheetLayoutBox),
    "onDragOver": (e) => this.onDragOverBox(e, sheetLayoutBox),
    "onDrop": (e) => this.onDropOnBox(e, sheetLayoutBox)
  })

  photoRotationEnabled() {
    if (window.RAILS_ENV != "production") return true
    if (Devise.currentUser()?.isAdmin()) return true

    return false
  }

  sheetLayoutPresentationBoxContent = ({
    photoHeightPercent,
    photo,
    photoLeftPercent,
    photoSheetProductOrderLineBox,
    photoTopPercent,
    photoWidthPercent,
    rotation,
    sheetLayoutBox
  }) => {
    const {inputPre} = this.state

    return (
      <>
        {photoSheetProductOrderLineBox?.isPersisted() &&
          <input
            name={`${inputPre}[boxes_attributes][${sheetLayoutBox.uniqueKey()}][id]`}
            type="hidden"
            value={photoSheetProductOrderLineBox.id()}
          />
        }
        <input
          name={`${inputPre}[boxes_attributes][${sheetLayoutBox.uniqueKey()}][photo_id]`}
          type="hidden"
          value={photo?.id() || ""}
        />
        <input
          name={`${inputPre}[boxes_attributes][${sheetLayoutBox.uniqueKey()}][sheet_layout_box_id]`}
          type="hidden"
          value={sheetLayoutBox.id()}
        />
        <input
          name={`${inputPre}[boxes_attributes][${sheetLayoutBox.uniqueKey()}][rotation]`}
          type="hidden"
          value={rotation !== undefined ? rotation : ""}
        />
        <input
          name={`${inputPre}[boxes_attributes][${sheetLayoutBox.uniqueKey()}][photo_width_percent]`}
          type="hidden"
          value={photoWidthPercent !== undefined ? photoWidthPercent : ""}
        />
        <input
          name={`${inputPre}[boxes_attributes][${sheetLayoutBox.uniqueKey()}][photo_height_percent]`}
          type="hidden"
          value={photoHeightPercent !== undefined ? photoHeightPercent : ""}
        />
        <input
          name={`${inputPre}[boxes_attributes][${sheetLayoutBox.uniqueKey()}][photo_top_percent]`}
          type="hidden"
          value={photoTopPercent !== undefined ? photoTopPercent : ""}
        />
        <input
          name={`${inputPre}[boxes_attributes][${sheetLayoutBox.uniqueKey()}][photo_left_percent]`}
          type="hidden"
          value={photoLeftPercent !== undefined ? photoLeftPercent : ""}
        />
      </>
    )
  }

  sheetLayoutBoxForPhotoSheetProductOrderLineBox(photoSheetProductOrderLineBox) {
    return this.state.sheetLayout.boxes().loaded().find((sheetLayoutBox) => sheetLayoutBox.id() == photoSheetProductOrderLineBox.sheetLayoutBoxId())
  }

  onDragLeaveBox = (_box) => {
    this.setState({draggedOverBox: undefined})
  }

  onDragOverBox(e, box) {
    const {draggedPhoto} = this.state

    // Allow drop photos on box
    if (draggedPhoto) {
      e.preventDefault()
      this.setState({draggedOverBox: box})
    }
  }

  onDropOnBox = (e, box) => {
    e.preventDefault()

    const {boxesToPhotosMapping, currentPhotoQuality, draggedPhoto, sheetLayout} = this.state
    const newBoxesToPhotosMapping = boxesToPhotosMapping ? cloneDeep(boxesToPhotosMapping) : {}

    if (currentPhotoQuality.supportsMultiplePhotosOnSheet()) {
      newBoxesToPhotosMapping[box.id()] = draggedPhoto
    } else {
      // No support for multiple photos on sheet - set all boxes to the dragged photo
      for (const sheetLayoutBox of sheetLayout.boxes().loaded()) {
        newBoxesToPhotosMapping[sheetLayoutBox.id()] = draggedPhoto
      }
    }

    this.setState({
      boxesToPhotosMapping: newBoxesToPhotosMapping,
      draggedPhoto: undefined,
      draggedOverBox: undefined
    })
  }

  onDragStart = (otherPhotoFromAlbums) => {
    this.setState({
      draggedPhoto: otherPhotoFromAlbums
    })
  }

  onPhotoQualityChanged = ({photoQuality}) => {
    this.setState({
      currentPhotoQuality: photoQuality
    })

    if (!photoQuality.supportsMultiplePhotosOnSheet()) {
      this.setAllBoxesToFirstPhoto()
    }
  }

  setAllBoxesToFirstPhoto = () => {
    const {boxesToPhotosMapping, sheetLayout} = digs(this.state, "boxesToPhotosMapping", "sheetLayout")
    const newBoxesToPhotosMapping = cloneDeep(boxesToPhotosMapping)
    const firstBox = sheetLayout.boxes().loaded()[0]
    const firstPhoto = digg(newBoxesToPhotosMapping, firstBox.id())

    for (const sheetLayoutBox of sheetLayout.boxes().loaded()) {
      newBoxesToPhotosMapping[sheetLayoutBox.id()] = firstPhoto
    }

    this.setState({
      boxesToPhotosMapping: newBoxesToPhotosMapping
    })
  }

  onSheetLayoutPreviewClicked = (e, newSheetLayout) => {
    e.preventDefault()

    this.setState({
      sheetLayout: newSheetLayout,
      sheetSize: newSheetLayout.sheetSize()
    }, () => this.loadBoxesToPhotosMapping())
  }
}
