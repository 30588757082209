import NavItem from "merfoto/components/layout/nav-item"
import {useMatch} from "react-router-dom"

const items = [
  {icon: "store", identifier: "welcome", label: I18n.t("js.merfoto.layout.index.webshop"), to: MerfotoRoutes.reactRootPath()},
  {icon: "images", identifier: "albums", label: I18n.t("js.merfoto.layout.index.albums"), to: MerfotoRoutes.albumsPath()},
  {icon: "download", identifier: "downloads", label: I18n.t("js.merfoto.layout.index.downloads"), to: MerfotoRoutes.downloadsPath()},
  {icon: "truck-moving", identifier: "orders", label: I18n.t("js.merfoto.layout.index.orders"), to: MerfotoRoutes.ordersPath()}
]

export default function MerfotoLayoutTopbarNavbar() {
  const path = useMatch("/:path")?.params?.path || "welcome"
  const navItemClassNames = (index) => {
    if (index < items.length) {
      return "me-4"
    }
  }

  return items.map(({icon, identifier, label, to}, index) =>
    <NavItem
      active={identifier === path}
      className={navItemClassNames(index)}
      icon={icon}
      identifier={identifier}
      key={identifier}
      label={label}
      to={to}
    />
  )
}
