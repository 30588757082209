// Returns the absolute offsets relative to the window for a given element by summing offsets of ancestors with those on the given element
export default function getAbsoluteOffsets(element) {
  let offsetLeft = 0

  let offsetTop = 0

  let currentElement = element

  while (currentElement) {
    offsetLeft += currentElement.offsetLeft
    offsetTop += currentElement.offsetTop

    currentElement = currentElement.offsetParent
  }

  return {offsetLeft, offsetTop}
}
