import ReactDOM from "react-dom"

export default class BodyPortal extends React.Component {
  static propTypes = PropTypesExact({
    children: PropTypes.node.isRequired
  })

  render() {
    const bodyPortal = document.querySelector(".react-body-portal")
    const {children} = this.props

    if (!bodyPortal) {
      throw new Error("bodyPortal couldn't be found")
    }

    return ReactDOM.createPortal(children, bodyPortal)
  }
}
