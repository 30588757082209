export default class TenantPhotoProductsCard extends React.Component {
  static defaultProps = {
    allowDownloadOfPhotos: false
  }

  static propTypes = PropTypesExact({
    allowDownloadOfPhotos: PropTypes.bool.isRequired,
    className: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.instanceOf(Photo)).isRequired,
    photoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact).isRequired,
    tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct).isRequired
  })

  render() {
    const {allowDownloadOfPhotos, className, ...restProps} = this.props
    const {photos, photoSessionGroupContact, tenantPhotoProduct} = digs(this.props, "photos", "photoSessionGroupContact", "tenantPhotoProduct")
    const dashedResourceType = Inflection.dasherize(Inflection.underscore(tenantPhotoProduct.photoProduct().resourceType()))
    const ResourceComponent = require(`merfoto/components/tenant-photo-products-with-photos/card/resources/${dashedResourceType}`).default

    return (
      <ResourceComponent
        className={classNames("merfoto-components-tenant-photo-products-with-photos-card", className)}
        data-tenant-photo-product-id={tenantPhotoProduct.id()}
        photoSessionGroupContact={photoSessionGroupContact}
        photos={photos}
        tenantPhotoProduct={tenantPhotoProduct}
        {...restProps}
      />
    )
  }
}
