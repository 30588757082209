import "./style"

export default class MerfotoCardFooter extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const {className, children} = this.props

    return (
      <div className={classNames("merfoto-components-card-footer", "p-2", className)}>
        {children}
      </div>
    )
  }
}
