import "./style"
import BellowHeader from "./bellow-header"

export default class Accordion extends React.Component {
  static propTypes = {
    bellows: PropTypes.arrayOf(PropTypes.shape({
      content: PropTypes.node,
      header: PropTypes.string.isRequired,
      headerIcon: PropTypes.string,
      identifier: PropTypes.string.isRequired
    }))
  }

  state = ({
    expandedBellow: this.props.bellows[0].identifier
  })

  render() {
    const {className, bellows, ...restProps} = this.props
    const {expandedBellow} = digs(this.state, "expandedBellow")

    return (
      <div className={classNames("components-accordion", className)} {...restProps}>
        {bellows.map(({content, identifier, header, headerIcon, ...restProps}) => (
          <div key={identifier} {...restProps}>
            <BellowHeader
              expanded={identifier == expandedBellow}
              header={header}
              headerIcon={headerIcon}
              identifier={identifier}
              onCollapseClicked={this.onCollapseClicked}
              onExpandClicked={this.onExpandClicked}
            />
            {expandedBellow == identifier &&
              <div className="bellow-content p-2">
                {content}
              </div>
            }
          </div>
        ))}
      </div>
    )
  }

  onExpandClicked = (identifier) => {
    this.setState({expandedBellow: identifier})
  }

  onCollapseClicked = () => {
    this.setState({expandedBellow: undefined})
  }
}
