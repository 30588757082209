import Button from "react-bootstrap/Button"
import Modal from "react-bootstrap/Modal"

export default class ConfirmDialog extends React.Component {
  static propTypes = PropTypesExact({
    message: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
  })

  render() {
    const {message} = this.props

    return (
      <Modal centered className="components-confirm-dialog" onHide={this.cancel} show>
        <EventListener event="keydown" onCalled={this.onKeyPressed} target={window} />
        <Modal.Header closeButton>
          {I18n.t("js.confirm_dialog.confirm")}
        </Modal.Header>
        <Modal.Body>
          <p className="confirmation-text">
            {message}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary confirm-dialog-accept" onClick={this.onConfirmClicked} variant="primary">
            {I18n.t("js.confirm_dialog.ok")}
          </Button>
          <a className="btn btn-outline-secondary ms-2 confirm-dialog-cancel" href="#" onClick={this.onCancelClicked}>
            {I18n.t("js.confirm_dialog.cancel")}
          </a>
        </Modal.Footer>
      </Modal>
    )
  }

  cancel = () => {
    this.props.onCancel()
  }

  confirm() {
    this.props.onConfirm()
  }

  onKeyPressed = (e) => {
    e.preventDefault()

    if (e.key === "Enter") {
      this.onEnterPressed()
    } else if (e.key === "Escape") {
      this.onEscapePressed()
    }
  }

  onConfirmClicked = (e) => {
    e.preventDefault()
    this.confirm()
  }

  onCancelClicked = (e) => {
    e.preventDefault()
    this.cancel()
  }

  onEnterPressed = () => {
    this.confirm()
  }

  onEscapePressed = () => {
    this.cancel()
  }
}
