import PropTypes from "prop-types"
import UniLogin from "./unilogin.png"

UniLoginImageButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default function UniLoginImageButton({className, onLoginSuccess, ...restProps}) {
  const token = document.querySelector("meta[name='csrf-token']").getAttribute("content")

  const onSubmit = (e) => {
    e.preventDefault()

    // hack because we cannot reliably determine whether the opened window is closed
    // Furthermore, we cannot get the current user before the session is updated
    window.onSuccessfulCallback = (user) => {
      onLoginSuccess(user)

      delete window.onSuccessfulCallback
    }

    e.currentTarget.submit()
  }

  return (
    <form action="/users/auth/openid_connect" method="post" onSubmit={onSubmit} target="uniC">
      <input name="authenticity_token" type="hidden" value={token} />
      <button
        className={classNames("sign-in-with-uni-c-button", "btn", "btn-link", className)}
        type="submit"
        {...restProps}
      >
        <img alt={I18n.t("js.sessions.new.uni_login_image_button.login_with_uni_login")} className="border p-1 rounded shadow" src={UniLogin} />
      </button>
    </form>
  )
}
