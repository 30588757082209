import Accordion from "components/accordion"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import UsersPasswordsNew from "components/users/passwords/new"
import {useTitle} from "shared/hooks/use-title"

const bellows = [
  {
    content: <UsersPasswordsNew />,
    identifier: "forgotten",
    header: I18n.t("js.merfoto.users.passwords.new.forgot_your_password"),
    headerIcon: "sign-in-alt"
  }
]

export default function MerfotoUsersPasswordsNew() {
  useTitle(I18n.t("js.merfoto.users.passwords.new.forgot_your_password"))

  return (
    <div className="merfoto-routes-users-passwords-new">
      <Row className="justify-content-center">
        <Col lg={6} md={8} xl={4}>
          <Accordion bellows={bellows} />
        </Col>
      </Row>
    </div>
  )
}
