import "./style"
import Icon from "components/icon"

export default class PreviewAction extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired
  }

  render() {
    const {className, href, icon, label, to, ...restProps} = this.props

    if (href) {
      return (
        <a className={classNames("components-preview-action", "mb-1", className)} href={href} {...restProps}>
          {this.content()}
        </a>
      )
    }

    return (
      <Link className={classNames("components-preview-action", "mb-1", className)} to={to || "#"} {...restProps}>
        {this.content()}
      </Link>
    )
  }

  content() {
    const {icon, label} = this.props

    return (
      <>
        {icon &&
          <Icon className="me-2" icon={icon} />
        }
        <span className="action-label">
          {label}
        </span>
      </>
    )
  }
}
