import {useLocation} from "react-router-dom"
import {useMemo} from "react"

const qs = require("qs")

SortLink.propTypes = {
  attribute: PropTypes.string.isRequired,
  className: PropTypes.string,
  linkComponent: PropTypes.object,
  onChanged: PropTypes.func,
  query: PropTypes.object.isRequired,
  title: PropTypes.node
}

// TODO should use useSearchParams - but we need to figure out how to handle objects, e.g. q[value]=h&q[value2]=b
export default function SortLink({attribute, className, linkComponent, onChanged, query, title: titleFromProps, ...restProps}) {
  const location = useLocation()
  const underscoredAttribute = Inflection.underscore(attribute)
  const currentParams = useMemo(() => qs.parse(location.search.substr(1)), [location])
  const searchKey = query.queryArgs.searchKey || "q"
  const sortMode = useMemo(() => {
    const params = currentParams[searchKey] || {}

    if (params.s === underscoredAttribute || params.s === `${underscoredAttribute} desc`) return "desc"
    if (params.s === `${underscoredAttribute} asc`) return "asc"
  }, [currentParams, searchKey, underscoredAttribute])
  const href = useMemo(() => {
    if (!currentParams[searchKey]) currentParams[searchKey] = {}

    const nextSortMode = sortMode === "asc" ? "desc" : "asc"

    currentParams[searchKey].s = `${underscoredAttribute} ${nextSortMode}` // eslint-disable-line id-length

    const newParams = qs.stringify(currentParams)

    return `${location.pathname}?${newParams}`
  }, [currentParams, location.pathname, searchKey, sortMode, underscoredAttribute])

  const title = useMemo(() => {
    if (titleFromProps) return titleFromProps

    return query.modelClass().humanAttributeName(attribute)
  }, [attribute, query, titleFromProps])

  return (
    <Link
      className={classNames("component-api-maker-bootstrap-sort-link", className)}
      data-attribute={attribute}
      data-sort-mode={sortMode}
      to={href}
      {...restProps}
    >
      {title}
    </Link>
  )
}
