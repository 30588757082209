import DropDownButton from "components/drop-down-button"
import DropDownButtonHeadline from "components/drop-down-button/headline"
import DropDownButtonItem from "components/drop-down-button/item"

export default class ModelsTableColumnsDropdown extends React.Component {
  static propTypes = PropTypesExact({
    columnsWithOrder: PropTypes.array.isRequired,
    modelClass: PropTypes.func.isRequired,
    onColumnClicked: PropTypes.func.isRequired
  })

  render() {
    const {columnsWithOrder, modelClass} = digs(this.props, "columnsWithOrder", "modelClass")

    return (
      <DropDownButton className="components-models-table-columns-drop-down" closeOnItemClick={false} icon="columns" right small>
        <DropDownButtonHeadline label={I18n.t("js.models_table.columns_drop_down.columns")} />
        {columnsWithOrder.map((column, index) =>
          <DropDownButtonItem
            className="column-item"
            data-identifier={digg(column, "identifier")}
            key={`column-${digg(column, "identifier")}`}
            label={
              <>
                <Icon className="me-1" icon={digg(column, "visible") ? "check" : "times"} size="small" />
                {column.label || modelClass.humanAttributeName(digg(column, "attribute"))}
              </>
            }
            onClick={(e) => this.onColumnClicked(e, column, index)}
          />
        )}
      </DropDownButton>
    )
  }

  onColumnClicked(e, column, index) {
    e.preventDefault()

    this.props.onColumnClicked(column, index)
  }
}
