import DeliveryMethodOption from "merfoto/components/order-deliveries/delivery-methods/delivery-method-option"

export default class MerfotoOrderDeliveriesDeliveryMethodsShipmondoDeliveryMethodOption extends React.Component {
  static propTypes = {
    currentDeliveryMethodOption: PropTypes.object.isRequired,
    onShipmentTemplateChanged: PropTypes.func.isRequired,
    shipmentTemplate: PropTypes.shape({
      carrier_code: PropTypes.string.isRequired,
      identifier: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  }

  render() {
    const {className, currentDeliveryMethodOption, onShipmentTemplateChanged, shipmentTemplate, ...restProps} = this.props
    const {deliveryMethod: currentDeliveryMethod, shipmentTemplate: currentShipmentTemplate} = currentDeliveryMethodOption
    const {identifier, name} = digs(shipmentTemplate, "identifier", "name")

    return (
      <DeliveryMethodOption
        checked={currentDeliveryMethod == "shipmondo" && currentShipmentTemplate?.identifier == identifier}
        className={classNames("merfoto-components-order-deliveries-delivery-methods-shipmondo-shipmondo-delivery-method-option", className)}
        deliveryMethod="shipmondo"
        identifier={`shipmondo-${identifier}`}
        label={name}
        logo={this.logo()}
        onChecked={this.onShipmentTemplateChanged}
        priceWithVat={digg(shipmentTemplate, "price_with_vat")}
        {...restProps}
      />
    )
  }

  onShipmentTemplateChanged = () => {
    const {onShipmentTemplateChanged, shipmentTemplate} = digs(this.props, "onShipmentTemplateChanged", "shipmentTemplate")

    onShipmentTemplateChanged(shipmentTemplate)
  }

  logo() {
    const carrierCode = digg(this, "props", "shipmentTemplate", "carrier_code")

    return require(`./logos/${carrierCode}`)
  }
}
