import Column from "./column"

export default class SharedModelsTableExpandableRow extends React.Component {
  static defaultProps = {
    expanded: false,
    showExpandRow: true
  }

  static propTypes = PropTypesExact({
    children: PropTypes.node.isRequired,
    colSpan: PropTypes.number,
    expanded: PropTypes.bool,
    label: PropTypes.node.isRequired,
    model: PropTypes.object.isRequired,
    onExpandClicked: PropTypes.func,
    rowClassName: PropTypes.string,
    showExpandRow: PropTypes.bool.isRequired
  })

  state = {
    expanded: Boolean(this.props.expanded)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.expanded !== this.props.expanded) {
      this.setState({expanded: this.props.expanded})
    }
  }

  render() {
    const {children, colSpan, label, model, rowClassName, showExpandRow} = this.props
    const {expanded} = this.state

    return (
      <>
        {showExpandRow &&
          <tr
            className={classNames("components-shared-models-table-expandable-row-expand-action-row", rowClassName)}
            data-model-class={digg(model.modelClassData(), "name")}
            data-model-id={model.id()}
          >
            <Column colSpan={colSpan - 1}>
              {label}
            </Column>
            <Column className="text-end">
              <TableExpandButton
                className="expandable-row-expand-button"
                expanded={expanded}
                onClick={this.onExpandClicked}
              />
            </Column>
          </tr>
        }
        {expanded &&
          <tr className={classNames("components-shared-models-table-expandable-row-expanded-content-row", rowClassName)} data-model-id={model.id()}>
            <Column className="p-0" colSpan={colSpan}>
              {children}
            </Column>
          </tr>
        }
      </>
    )
  }

  onExpandClicked = (e) => {
    e.preventDefault()
    const {model, onExpandClicked} = this.props
    const {expanded} = this.state

    this.setState({expanded: !expanded})

    if (onExpandClicked) {
      onExpandClicked({model})
    }
  }
}
