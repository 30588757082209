import zeroise from "zeroise"

export default class EditableContent extends React.Component {
  static defaultProps = {
    showLabel: false,
    small: true
  }

  static propTypes = {
    attribute: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    editable: PropTypes.bool,
    editableContent: PropTypes.node,
    id: PropTypes.string,
    label: PropTypes.string,
    min: PropTypes.number,
    model: PropTypes.object,
    name: PropTypes.string,
    options: PropTypes.object,
    placeholder: PropTypes.string,
    showLabel: PropTypes.bool.isRequired,
    small: PropTypes.bool,
    step: PropTypes.number,
    stringInputClassName: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.string
  }

  state = {}

  render() {
    const {attribute, editable, model} = digs(this.props, "attribute", "editable", "model")
    const {children} = this.props

    return (
      <span className="nemoa-components-editable-content" data-attribute={attribute} data-model-id={model.id()}>
        {editable && this.editableContent()}
        {!editable && children && this.children()}
        {!editable && !children && this.nonEditableContent()}
      </span>
    )
  }

  children() {
    const {children, label} = this.props
    const {attribute, model, showLabel} = digs(this.props, "attribute", "model", "showLabel")

    if (showLabel) {
      return <AttributePresenter attribute={attribute} label={label} model={model} value={children} />
    }

    return children
  }

  editableContent() {
    const {editableContent} = this.props

    if (editableContent) {
      return editableContent
    }

    const {attribute, model, showLabel} = digs(this.props, "attribute", "model", "showLabel")
    const {id, inputRef, min, name, options, placeholder, small, step, stringInputClassName, type} = this.props

    const stringInputProps = {
      attribute,
      inputRef,
      model,
      placeholder,
      type
    }

    if (id) {
      stringInputProps.id = id
    }

    if (min != undefined) {
      stringInputProps.min = min
    }

    if (name != undefined) {
      stringInputProps.name = name
    }

    if (step != undefined) {
      stringInputProps.step = step
    }

    if (type == "time") {
      const time = model[attribute]()

      if (time) {
        const hours = zeroise(time.getHours(), 2, 0)
        const minutes = zeroise(time.getMinutes(), 2, 0)

        stringInputProps.defaultValue = `${hours}:${minutes}`
      }
    }

    if (type == "checkbox") {
      stringInputProps.className = classNames("form-check-input", "ms-0", "position-static", stringInputClassName, {"form-check-input-sm": small})
      stringInputProps.defaultChecked = model[attribute]()
      stringInputProps.defaultValue = "true"
    } else {
      stringInputProps.className = classNames("form-control", stringInputClassName, {"form-control-sm": small})
    }

    if (type == "money") {
      const {type, ...moneyInputProps} = stringInputProps

      return (
        <MoneyInput {...moneyInputProps} />
      )
    }

    if (this.isSelect()) {
      if (!options) {
        return null
      }

      const {className, inputRef, type, ...restProps} = stringInputProps

      if (!showLabel) {
        restProps.label = false
      }

      return (
        <NemoaSelect
          className={this.props.className}
          options={HashToCollection.toCollectionArray(options)}
          size={small ? "small" : "medium"}
          {...restProps}
        />
      )
    }

    if (showLabel) {
      if (type == "checkbox") {
        delete stringInputProps.inputRef

        return <BootstrapCheckbox {...stringInputProps} />
      }

      return <BootstrapInput {...stringInputProps} />
    } else if (type == "checkbox") {
      delete stringInputProps.inputRef

      return <Checkbox {...stringInputProps} />
    }

    return <Input {...stringInputProps} />
  }

  isSelect() {
    if (this.props.options) {
      return true
    }
  }

  nonEditableContent() {
    const {to, type} = this.props
    const {attribute, model, showLabel} = digs(this.props, "attribute", "model", "showLabel")

    if (!(attribute in model)) {
      throw new Error(`Attribute ${attribute} was not found for model`)
    }

    const value = model[attribute]()

    if (to) {
      return (
        <Link to={to}>
          {value}
        </Link>
      )
    }

    if (type == "date") {
      return I18n.l("date.formats.short", value)
    } else if (type == "datetime-local") {
      return I18n.l("time.formats.short", value)
    } else if (type == "time") {
      return I18n.l("time.formats.time", value)
    } else if (type == "money") {
      if (value) {
        return MoneyFormatter.format(value, {decimals: 2, excludeCurrency: true})
      }

      return "-"
    } else if (type == "checkbox") {
      if (value) {
        return I18n.t("js.global.yes")
      }

      return I18n.t("js.global.no")
    }

    if (showLabel) {
      return (
        <AttributePresenter attribute={attribute} model={model} />
      )
    }

    return value
  }
}
