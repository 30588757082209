import "./style"
import Accordion from "components/accordion"
import currentRoutes from "shared/current-routes"
import NemoaLogo from "./nemoa-logo"
import OneTimePasswordPrompt from "./one-time-password-prompt"
import RegistrationsForm from "nemoa/components/registrations/form"
import UniLoginImageButton from "./uni-login-image-button"
import updateCurrentUser from "components/users/update-current-user"
import {UserContext} from "shared/user-context"
import UsersConfirmation from "components/users/confirmation"

class SessionsNew extends React.Component {
  static defaultProps = {
    authenticateAnonymously: true,
    authenticateWithUniC: true,
    authenticateWithEmail: true,
    showLogo: true
  }

  static propTypes = PropTypesExact({
    authenticateAnonymously: PropTypes.bool.isRequired,
    authenticateWithUniC: PropTypes.bool.isRequired,
    authenticateWithEmail: PropTypes.bool.isRequired,
    className: PropTypes.string,
    logoImagePath: PropTypes.string,
    onSignedIn: PropTypes.func,
    showLogo: PropTypes.bool.isRequired,
    style: PropTypes.object
  })

  signInEmailRef = React.createRef()
  signInPasswordRef = React.createRef()
  signInRememberMeRef = React.createRef()

  state = {
    confirmationRequired: false,
    email: undefined,
    oneTimePassword: undefined,
    oneTimePasswordPrompt: false,
    password: undefined,
    rememberMe: undefined,
    showAuthenticateWithUniC: true
  }

  render() {
    const {authenticateAnonymously, className, logoImagePath, showLogo, style} = this.props
    const {confirmationRequired, email, oneTimePasswordPrompt} = this.state

    return (
      <div className={classNames("components-sessions-new", className)} data-authenticate-anonymously={authenticateAnonymously} style={style}>
        {showLogo &&
          <>
            {logoImagePath &&
              <div className="sign-in-image-container contained-image" style={{backgroundImage: `url('${logoImagePath}`}} />
            }
            {!logoImagePath &&
              <div className="nemoa-sign-in-image-container contained-image my-4" style={{backgroundImage: `url('${NemoaLogo}`}} />
            }
          </>
        }
        <Choose>
          <When condition={this.shouldShowConfirmationRequiredOrOneTimePasswordPrompt() || this.shouldShowAlreadySignedInContent()}>
            <div className="d-flex justify-content-center">
              <div className="overall-container flex-grow-1">
                {confirmationRequired &&
                  <UsersConfirmation email={email} onEmailConfirmed={this.onEmailConfirmed} />
                }
                {oneTimePasswordPrompt &&
                  <OneTimePasswordPrompt
                    email={email}
                    onSubmit={this.onSubmitOneTimePassword}
                  />
                }
                {this.shouldShowAlreadySignedInContent() && this.alreadySignedInContent()}
              </div>
            </div>
          </When>
          <Otherwise>
            <Accordion bellows={this.bellows()} />
          </Otherwise>
        </Choose>
      </div>
    )
  }

  bellows() {
    const {authenticateWithEmail} = digs(this.props, "authenticateWithEmail")
    const {onSignedIn} = this.props
    const bellows = [
      {
        content: this.notSignedInContent(),
        identifier: "sign-in",
        header: I18n.t("js.sessions.new.index.sign_in"),
        headerIcon: "sign-in-alt"
      }
    ]

    if (authenticateWithEmail) {
      bellows.push({
        content: <RegistrationsForm onSignedIn={onSignedIn} />,
        identifier: "sign-up",
        header: I18n.t("js.sessions.new.index.create_user"),
        headerIcon: "plus"
      })
    }

    return bellows
  }

  shouldShowConfirmationRequiredOrOneTimePasswordPrompt() {
    const {confirmationRequired, oneTimePasswordPrompt} = this.state

    if (confirmationRequired || oneTimePasswordPrompt) {
      return true
    }
  }

  shouldShowAlreadySignedInContent() {
    const [currentUser] = this.context

    if (this.shouldShowConfirmationRequiredOrOneTimePasswordPrompt()) {
      return false
    }

    if (currentUser) {
      return true
    }
  }

  alreadySignedInContent() {
    const [currentUser] = this.context

    return I18n.t("js.sessions.new.you_are_signed_in_as", {name: currentUser.name()})
  }

  notSignedInContent = () => {
    const {authenticateAnonymously, authenticateWithUniC, authenticateWithEmail} = this.props
    const {showAuthenticateWithUniC} = this.state

    return (
      <div className="not-signed-in-content">
        {!authenticateAnonymously && !authenticateWithUniC && !authenticateWithEmail &&
          <div className="no-authentication-options-warning">
            {I18n.t("js.sessions.new.no_authentication_options_has_been_enabled")}
          </div>
        }
        {authenticateWithEmail &&
          <div>
            <form onSubmit={this.onSignInSubmit}>
              <BootstrapInput
                className="input-with-bottom-border mb-3"
                id="user_email"
                inputRef={digg(this, "signInEmailRef")}
                placeholder={Email.humanAttributeName("email")}
              />
              <BootstrapInput
                className="input-with-bottom-border mb-3"
                id="user_password"
                inputRef={digg(this, "signInPasswordRef")}
                placeholder={I18n.t("js.sessions.new.password")}
                type="password"
              />
              <BootstrapCheckbox inputRef={digg(this, "signInRememberMeRef")} label={I18n.t("js.sessions.new.remember_me")} />
              <div className="mt-2">
                <SubmitButton className="w-100" label={I18n.t("js.sessions.new.sign_in")} large />
              </div>
            </form>

            <div className="mt-2 text-center">
              <Link className="forgot-password-link" to={currentRoutes().newUserPasswordPath()}>
                {I18n.t("js.sessions.new.forgot_password")}
              </Link>
            </div>
          </div>
        }
        {(authenticateAnonymously || (authenticateWithUniC && showAuthenticateWithUniC)) &&
          <>
            {authenticateWithEmail &&
              <div className="my-4 sign-in-additional-options-splitter w-100" />
            }
            {authenticateWithUniC && showAuthenticateWithUniC &&
              <div className="mx-auto pb-2">
                <h5 className="mb-3">{I18n.t("js.sessions.new.index.login_with")}</h5>
                <div>
                  <UniLoginImageButton onLoginSuccess={this.onUniLoginSuccessful} />
                </div>
              </div>
            }
            {authenticateAnonymously &&
              <div className="mt-2 text-center">
                <a
                  className="btn btn-lg btn-outline-primary sign-in-anonymously-link w-100"
                  href="#"
                  onClick={this.onSignInAnonymouslyClicked}
                >
                  {I18n.t("js.sessions.new.sign_in_anonymously")}
                </a>
              </div>
            }
          </>
        }
      </div>
    )
  }

  onSubmitOneTimePassword = ({oneTimePassword}) => {
    this.setState({oneTimePassword}, () => this.signIn())
  }

  onUniLoginSuccessful = async (userFromOtherFrame) => {
    // If we use the given user, then it will act weird so we need to reload it
    const user = await User.find(userFromOtherFrame.id())

    await updateCurrentUser(user)
    this.onAfterSignIn({model: user})
  }

  onSignInAnonymouslyClicked = async (e) => {
    e.preventDefault()

    const response = await User.signInAnonymously()
    const user = response.user

    await updateCurrentUser(user)
    this.onAfterSignIn({model: user})
  }

  onSignInSubmit = (e) => {
    e.preventDefault()

    const email = digg(this, "signInEmailRef", "current", "value")
    const password = digg(this, "signInPasswordRef", "current", "value")
    const rememberMe = digg(this, "signInRememberMeRef", "current", "checked")

    this.setState({email, oneTimePassword: undefined, password, rememberMe}, () => this.signIn())
  }

  async promptForOneTimePassword() {
    const {email} = this.state

    await User.sendOtp({email})
    this.setState({oneTimePasswordPrompt: true})
  }

  async signIn() {
    const {email, oneTimePassword, password, rememberMe} = this.state

    try {
      const response = await User.signIn({email, password, one_time_password: oneTimePassword, remember_me: rememberMe})
      const user = digg(response, "model")

      await updateCurrentUser(user)
      this.onAfterSignIn(response)
    } catch (error) {
      if (error["errorTypes"] && error.errorTypes().includes("one_time_password_required")) {
        this.promptForOneTimePassword()

        return
      } else if (error["errorTypes"] && error.errorTypes().includes("unconfirmed")) {
        this.setState({confirmationRequired: true})
      }

      FlashMessage.errorResponse(error)
    }
  }

  onAfterSignIn = (response) => {
    const {onSignedIn} = this.props

    FlashMessage.success(I18n.t("js.sessions.new.you_were_signed_in"))

    if (onSignedIn) {
      onSignedIn(response)
    }
  }

  onEmailConfirmed = () => {
    this.setState({confirmationRequired: false})
  }
}

SessionsNew.contextType = UserContext

export default SessionsNew
