import "./style"

export default class MerfotoLayoutFooterPaymentMethod extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    paymentMethod: PropTypes.oneOf([
      "dankort",
      "mastercard",
      "mobilepay",
      "visa-card"
    ]).isRequired
  }

  render() {
    const {className, paymentMethod, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-layout-footer-payment-method", className)} data-payment-method={paymentMethod} {...restProps}>
        <div className="payment-method-icon" data-payment-method={paymentMethod} />
      </div>
    )
  }
}
