import "./style"
import Chooser from "./chooser"

export default class AttributeSelect extends React.Component {
  static defaultProps = {
    readFromResource: false
  }

  static propTypes = PropTypesExact({
    className: PropTypes.string,
    modelClass: PropTypes.func.isRequired,
    name: PropTypes.string,
    onChange: PropTypes.func,
    readFromResource: PropTypes.bool.isRequired,
    rule: PropTypes.instanceOf(ModelSearchRule)
  })

  state = {
    attribute: this.props.rule?.ruleAttributeData(),
    chooserOpen: false,
    collection: this.props.rule?.isCollection() || false,
    filter: undefined,
    path: this.initialPath(this.props.rule),
    value: this.props.rule?.searchValue()
  }

  initialPath(rule) {
    if (rule?.hasRelationshipPath()) {
      return rule.relationshipPathWithLabels()
    }

    return []
  }

  render() {
    const {modelClass, readFromResource} = digs(this.props, "modelClass", "readFromResource")
    const {className, name} = this.props
    const {attribute, chooserOpen, collection, filter, path} = this.state

    return (
      <div className={classNames("admin-components-layout-top-menu-extended-search-attribute-select", className)} data-chooser-open={chooserOpen}>
        <div className="current-value-container d-flex" onClick={this.onClicked}>
          {name && path.map((pathItem, index) =>
            <input
              key={index}
              name={`${name}[relationship_path][]`}
              type="hidden"
              value={digg(pathItem, "name")}
            />
          )}
          {!chooserOpen && path.map((pathItem, index) =>
            <div key={index}>
              {digg(pathItem, "label")}
              {(!collection || (index + 1) != path.length) &&
                <i className="la la-angle-right mx-1" />
              }
            </div>
          )}
          {attribute && name &&
            <input
              name={`${name}[rule_attribute]`}
              type="hidden"
              value={digg(attribute, "name")}
            />
          }
          {attribute && collection && name &&
            <input
              name={`${name}[collection]`}
              type="hidden"
              value="1"
            />
          }
          {attribute && !collection && !chooserOpen &&
            <div>
              {digg(attribute, "label")}
            </div>
          }
          {path.length == 0 && !attribute && !chooserOpen &&
            <div>
              {I18n.t("js.admin.layout.top_menu.extended_search.group.rule.attribute_select.choose_attribute")}
            </div>
          }
          <div className="flex-grow-1">
            {chooserOpen &&
              <input autoFocus className="filter-input transparent-input w-100" onChange={this.onFilterChanged} />
            }
          </div>
          <div>
            <i className="la la-angle-down ms-1" />
          </div>
        </div>
        {chooserOpen &&
          <Chooser
            defaultPath={path}
            filter={filter}
            modelClass={modelClass}
            onAttributeChosen={this.onAttributeChosen}
            onCancel={this.onChooserCancel}
            readFromResource={readFromResource}
          />
        }
      </div>
    )
  }

  onAttributeChosen = (args) => {
    const {onChange} = this.props
    const {attribute, path} = digs(args, "attribute", "path")
    const collection = Boolean(args.collection)

    this.setState({
      attribute,
      chooserOpen: false,
      collection,
      path
    })

    if (onChange) {
      onChange({attribute, collection, path})
    }
  }

  onChooserCancel = () => {
    this.setState({chooserOpen: false})
  }

  onClicked = (e) => {
    e.preventDefault()

    this.setState((prevState) => ({
      chooserOpen: !prevState.chooserOpen
    }))
  }

  onFilterChanged = (e) => {
    this.setState({
      filter: digg(e, "target", "value")
    })
  }
}
