import Card from "merfoto/components/card"
import Content from "./content"
import SubmitImageButton from "merfoto/components/order-lines/edit/submit-image-button"

export default class MerfotoComponentsOrderLinesEditResourcesPhotoSheetProduct extends BaseComponent {
  static propTypes = {
    photoSheetProductOrderLine: PropTypes.instanceOf(PhotoSheetProductOrderLine).isRequired,
    tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct).isRequired
  }

  render() {
    const {tenantPhotoProduct} = this.props
    const photoSheetProduct = tenantPhotoProduct.photoProduct().photoSheetProduct()

    return (
      <Card.Outer>
        <Card.Header actions={<SubmitImageButton />}>
          {photoSheetProduct.name()}
        </Card.Header>
        <Card.Body>
          <Content {...this.props} />
        </Card.Body>
      </Card.Outer>
    )
  }
}
