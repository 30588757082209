export default class MerfotoBasketItemCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    orderLine: PropTypes.instanceOf(OrderLine).isRequired
  }

  render() {
    const {className, orderLine, ...restProps} = this.props
    const dasherizedOrderableType = Inflection.dasherize(Inflection.underscore(orderLine.orderableType()))
    const ItemCardComponent = require(`merfoto/components/basket/item-cards/${dasherizedOrderableType}`).default

    return (
      <ItemCardComponent
        className={classNames("merfoto-components-basket-order-line-card", className)}
        data-order-line-id={orderLine.id()}
        orderLine={orderLine}
        {...restProps}
      />
    )
  }
}
