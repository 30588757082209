export default class AdminLogsDetailsUpdate extends React.Component {
  static propTypes = PropTypesExact({
    log: PropTypes.instanceOf(Log).isRequired
  })

  state = ({
    relationshipAttributes: {}
  })

  componentDidMount() {
    this.loadRelationshipsWithChanges()
  }

  loadRelationshipsWithChanges() {
    for (const relationship of this.relationships()) {
      const {name: relationshipName, resource_name: resourceName} = digs(relationship, "name", "resource_name")
      const modelClass = digg(require("@kaspernj/api-maker/src/models"), resourceName)

      this.loadChangedModels(relationshipName, modelClass)
    }
  }

  async loadChangedModels(relationshipName, modelClass) {
    const {log} = digs(this.props, "log")
    const changes = digg(log.parameters(), "changes")
    const nameAttribute = "name" in modelClass.modelClassData().attributes
    const attributeName = `${relationshipName}_id`

    if (attributeName in changes && nameAttribute) {
      const collectionKey = digg(modelClass.modelClassData(), "collectionKey")
      const camelizedModelName = digg(modelClass.modelClassData(), "name")
      const selectAttributesForModel = {}

      selectAttributesForModel[Inflection.camelize(collectionKey)] = ["id", "name"]

      const models = await modelClass.ransack({id_in: digg(changes, attributeName)}).select(selectAttributesForModel).toArray()
      const relationshipAttributes = {...digg(this.state, "relationshipAttributes")}

      relationshipAttributes[attributeName] = camelizedModelName

      const shapeUpdateData = {
        relationshipAttributes
      }

      shapeUpdateData[camelizedModelName] = models
      this.setState(shapeUpdateData)
    }
  }

  render() {
    const {log} = digs(this.props, "log")

    if (!log.parameters() || !log.parameters().changes) {
      return null
    }

    const changes = log.parameters().changes
    const {relationshipAttributes} = digs(this.state, "relationshipAttributes")

    return (
      <div className="admin-components-logs-details-update">
        {changes &&
          <ul className="update-changes-list">
            {Object.keys(changes).map((field) =>
              <li key={field}>
                <Choose>
                  <When condition={field in relationshipAttributes}>
                    {this.handleAttributeWithRelationshipChanges(field, digg(this, "state", relationshipAttributes[field]))}
                  </When>
                  <Otherwise>
                    {this.fieldChangedFromToText(changes, field)}
                  </Otherwise>
                </Choose>
              </li>
            )}
          </ul>
        }
        {!changes && I18n.t("js.admin.logs.details.update.no_changes")}
      </div>
    )
  }

  fieldChangedFromToText(changes, field) {
    const from = this.valueOrEmpty(changes[field][0])
    const to = this.valueOrEmpty(changes[field][1])

    return I18n.t("js.admin.logs.details.update.field_changed_from_to", {field, from, to})
  }

  valueOrEmpty = (value) => {
    if (!value) {
      return I18n.t("js.admin.logs.details.update.empty")
    }

    return value
  }

  modelLabel(model, value) {
    if (value && !model) {
      return value
    }

    if (!model) {
      return I18n.t("js.admin.logs.details.update.empty")
    }

    return `${model.name()} (${model.id()})`
  }

  handleAttributeWithRelationshipChanges(attribute, models) {
    const {log} = digs(this.props, "log")
    const parameters = log.parameters()
    const changes = digg(parameters, "changes")
    const modelClass = digg(require("@kaspernj/api-maker/src/models"), log.trackableType())
    const changedFrom = changes[attribute][0]
    const changedTo = changes[attribute][1]

    return I18n.t("js.admin.logs.details.reimbursement.update.changed_from_to", {
      attribute: modelClass.humanAttributeName(Inflection.camelize(attribute.replace("_id", ""))),
      from: this.modelLabel(models.find((model) => model.id() == changedFrom), changedFrom),
      to: this.modelLabel(models.find((model) => model.id() == changedTo), changedTo)
    })
  }

  relationships() {
    const {log} = digs(this.props, "log")
    const modelClass = digg(require("@kaspernj/api-maker/src/models"), log.trackableType())
    const relationships = digg(modelClass.modelClassData(), "relationships")

    return relationships.filter((relationship) => digg(relationship, "macro") == "belongs_to")
  }
}
