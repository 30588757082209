import MerfotoNavbar from "merfoto/components/navbar"

export default class MerfotoCheckout extends React.Component {
  static propTypes = {
    order: PropTypes.instanceOf(Order).isRequired
  }

  state = {
    validForPayment: undefined
  }

  componentDidMount() {
    this.loadValidForPayment()
  }

  async loadValidForPayment() {
    const {order} = digs(this.props, "order")

    if (order.state() !== "basket") {
      return
    }

    const response = await order.validForPayment()
    const validForPayment = digg(response, "valid_for_payment")

    this.setState({validForPayment})
  }

  render() {
    const {className, order, ...restProps} = this.props

    return (
      <MerfotoNavbar
        className={classNames("merfoto-components-checkout-navbar", className)}
        items={this.items()}
        {...restProps}
      />
    )
  }

  items() {
    const {order} = digs(this.props, "order")
    const {validForPayment} = digs(this.state, "validForPayment")

    return [
      {
        enabled: order.state() === "basket",
        identifier: "customer-information",
        label: I18n.t("js.merfoto.checkout.navbar.customer_information"),
        title: 1,
        to: MerfotoRoutes.checkoutCustomerInformationPath()
      },
      {
        enabled: order.state() === "basket" && order.hasInvoiceAddressId(),
        identifier: "delivery",
        label: I18n.t("js.merfoto.checkout.navbar.delivery"),
        title: 2,
        to: MerfotoRoutes.checkoutDeliveryPath()
      },
      {
        enabled: order.state() === "basket" && order.orderDelivery() != null,
        identifier: "confirm",
        label: I18n.t("js.merfoto.checkout.navbar.confirm"),
        title: 3,
        to: MerfotoRoutes.checkoutConfirmPath()
      },
      {
        enabled: order.state() === "basket" && validForPayment,
        identifier: "payment",
        label: I18n.t("js.merfoto.checkout.navbar.payment"),
        title: 4,
        to: MerfotoRoutes.checkoutPaymentPath()
      },
      {
        identifier: "completed",
        label: I18n.t("js.merfoto.checkout.navbar.completed"),
        title: 5,
        to: "#"
      }
    ]
  }
}
