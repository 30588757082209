export default class BootstrapBreakpoint extends React.Component {
  static BREAKPOINTS = [
    ["xxl", 1400],
    ["xl", 1200],
    ["lg", 992],
    ["md", 768],
    ["sm", 576],
    ["xs", 0]
  ]

  static propTypes = PropTypesExact({
    onBreakpointChanged: PropTypes.func
  })

  static current() {
    const windowWidth = window.innerWidth

    for (const breakpointData of BootstrapBreakpoint.BREAKPOINTS) {
      const breakpoint = breakpointData[0]
      const width = breakpointData[1]

      if (windowWidth >= width) {
        return breakpoint
      }
    }

    throw new Error(`Couldn't not find breakpoint from window width: ${windowWidth}`)
  }

  static isOrUp(givenBreakpoint) {
    const readBreakpoints = []
    const windowWidth = window.innerWidth

    for (const breakpointData of BootstrapBreakpoint.BREAKPOINTS) {
      const breakpoint = breakpointData[0]
      const width = breakpointData[1]

      readBreakpoints.push(breakpoint)

      if (givenBreakpoint == breakpoint) {
        if (windowWidth >= width && readBreakpoints.includes(breakpoint)) {
          return true
        }

        return false
      }
    }

    throw new Error(`Given breakpoint never reached: ${givenBreakpoint}`)
  }

  render() {
    return (
      <EventListener event="resize" onCalled={this.onWindowResized} target={window} />
    )
  }

  onWindowResized = () => {
    const {onBreakpointChanged} = this.props

    if (onBreakpointChanged) {
      onBreakpointChanged()
    }
  }
}
