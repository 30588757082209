import pathToModelClass from "./path-to-model-class"
import preloadCalculator from "./preload-calculator"

export default class ModelsTableDynamicColumns {
  constructor(component) {
    this.component = component
  }

  async initialize() {
    await this.loadTableSetting()

    if (this.tableSetting) {
      await this.addNewAndRemoveDeprecatedRegisteredColumns()
    } else {
      await this.createTableSetting()
      await this.loadTableSetting()
    }

    await this.loadStoredColumnsData()
  }

  async createTableSetting() {
    const {modelClass} = digs(this.component.props, "modelClass")
    const modelClassName = digg(modelClass.modelClassData(), "name")
    const {queryName} = digs(this.component, "queryName")
    const tableSetting = new TableSetting({
      user_id: Devise.currentUser().id(),
      model_class_name: modelClassName,
      query_name: queryName,
      table_setting_columns_attributes: this.newColumnsData()
    })

    await tableSetting.save()
  }

  async loadTableSetting() {
    const {modelClass} = digs(this.component.props, "modelClass")
    const modelClassName = digg(modelClass.modelClassData(), "name")
    const {queryName} = digs(this.component, "queryName")

    this.tableSetting = await TableSetting
      .ransack({
        model_class_name_eq: modelClassName,
        query_name_eq: queryName,
        user_id_eq: Devise.currentUser().id()
      })
      .preload("table_setting_columns")
      .select({
        TableSetting: ["id"],
        TableSettingColumn: ["attributeName", "id", "identifier", "label", "path", "position", "propsColumn", "sortKey", "visible"]
      })
      .first()

    this.tableSettingColumns = this.tableSetting?.tableSettingColumns()?.loaded()?.sort((column1, column2) =>
      column1.position() - column2.position()
    )
  }

  addColumn = async (args) => {
    const {attributeChosen, label} = digs(args, "attributeChosen", "label")
    const {attribute, path: givenPath} = digs(attributeChosen, "attribute", "path")
    const path = givenPath?.map(({label, name}) => ({label, name}))
    const attributeName = digg(attribute, "name")
    const {columnsWithOrder} = digs(this.component.state, "columnsWithOrder")
    const {tableSetting} = digs(this, "tableSetting")
    const position = digg(columnsWithOrder, "length") + 1
    const labelParts = []
    const pathPartsForSortKey = path.map((pathPart, index) => {
      labelParts.push(digg(pathPart, "label"))

      if (index == 0) {
        return Inflection.camelize(digg(pathPart, "name"), true)
      }

      return Inflection.camelize(digg(pathPart, "name"))
    })
    const {modelClass} = digs(this.component.props, "modelClass")
    const modelClassName = digg(modelClass.modelClassData(), "name")
    const relationshipScan = await ModelSearchRule.scanRelationship({
      model_class: modelClassName,
      path: path.map((pathPart) => digg(pathPart, "name"))
    })
    const attributeFromRelationshipScan = digg(relationshipScan, "attributes")
      .find((attributeInRelationshipScan) => digg(attributeInRelationshipScan, "name") == digg(attribute, "name"))

    labelParts.push(digg(attribute, "label"))

    const columnData = {
      attribute_name: attributeName,
      identifier: this.identifierFor(attributeChosen),
      label: label || labelParts.join(" "),
      path,
      position,
      props_column: false,
      table_setting_id: tableSetting.id(),
      visible: true
    }

    // Only make it sortable if a column could be found in the relationship scan - otherwise its probably a virtual attribute which we cannot sort by
    // TODO should also work for the columns that are added through code, but currently we'll have to send a request to the server for each column
    if (attributeFromRelationshipScan) {
      columnData.sort_key = `${pathPartsForSortKey.join("")}${Inflection.camelize(attributeName)}`
    } else if (relationshipScan.attributes.find((attributeInScan) => attributeInScan.name == `${attributeName}_cents`)) {
      columnData.sort_key = `${pathPartsForSortKey.join("")}${Inflection.camelize(attributeName)}_cents`
    }

    const tableSettingColumn = new TableSettingColumn(columnData)

    await tableSettingColumn.save()
  }

  removeColumn = async (args) => {
    const {tableSetting} = digs(this, "tableSetting")
    const {identifier} = digs(args, "identifier")
    const tableSettingColumn = tableSetting.tableSettingColumns().loaded().find((tableSettingColumn) => tableSettingColumn.identifier() == identifier)

    await tableSettingColumn.destroy()
  }

  identifierFor(args) {
    const {attribute, path} = digs(args, "attribute", "path")
    const attributeName = digg(attribute, "name")
    const {modelClass} = digs(this.component.props, "modelClass")
    const modelClassName = digg(modelClass.modelClassData(), "name")
    const identifierParts = [modelClassName]

    for (const pathPart of path) {
      identifierParts.push(Inflection.camelize(digg(pathPart, "name"), true))
    }

    identifierParts.push(Inflection.camelize(attributeName, true))

    return identifierParts.join("-")
  }

  columnsPreloads() {
    return digg(this, "preloads")
  }

  columnsSelectedAttributes() {
    return digg(this, "selectedAttributes")
  }

  newColumnsData() {
    const {propsColumns} = digs(this.component, "propsColumns")
    const newColumnsToSave = []

    for (let i = 0; i < propsColumns.length; i++) {
      const propsColumn = digg(propsColumns, i)
      const columnData = this.columnDataToSave(propsColumn, i + 1)

      newColumnsToSave.push(columnData)
    }

    return newColumnsToSave
  }

  columnDataToSave(propsColumn, position) {
    const {modelClass} = digs(this.component.props, "modelClass")
    const {
      attribute: attributeName,
      columnClassName,
      condition,
      content,
      defaultVisible,
      editable,
      headerProps,
      sortContent,
      sortKey,
      textCenter,
      textRight,
      type,
      ...restColumnData
    } = propsColumn
    const columnData = {
      attribute_name: attributeName,
      position,
      sort_key: sortKey,
      text_center: Boolean(textCenter),
      text_right: Boolean(textRight)
    }

    Object.assign(columnData, restColumnData)

    if (!("identifier" in columnData)) {
      columnData.identifier = this.propsColumnIdentifier(propsColumn)
    }

    if (!("label" in columnData) && attributeName) {
      columnData.label = modelClass.humanAttributeName(attributeName)
    }

    if (attributeName && !("path" in columnData)) {
      columnData.path = []
    }

    if (defaultVisible === undefined) {
      columnData.visible = true
    } else {
      columnData.visible = defaultVisible
    }

    return columnData
  }

  propsColumnIdentifier(propsColumn) {
    if ("identifier" in propsColumn) {
      return digg(propsColumn, "identifier")
    }

    const parts = ["attribute"]

    if (propsColumn.path) {
      for (const pathPart of digg(propsColumn, "path")) {
        parts.push(digg(pathPart, "name"))
      }
    }

    parts.push(propsColumn.attribute || digg(propsColumn, "sortKey"))

    return parts.join("-")
  }

  currentColumnsWithOrder() {
    const {propsColumns} = digs(this.component, "propsColumns")
    const {tableSettingColumns} = digs(this, "tableSettingColumns")
    const columnsWithOrder = []
    const pathsAndAttributes = []

    for (const tableSettingColumn of tableSettingColumns) {
      const {
        attribute_name: attribute,
        sort_key: sortKey,
        text_center: textCenter,
        text_right: textRight,
        ...newStoredColumnData
      } = tableSettingColumn.attributes()
      const propsColumn = propsColumns.find((propsColumn) => this.propsColumnIdentifier(propsColumn) == tableSettingColumn.identifier())

      // Some attributes are called differently in the backend
      Object.assign(newStoredColumnData, {attribute, sortKey, propsColumn: false, textCenter, textRight})

      if (tableSettingColumn.hasAttributeName()) {
        pathsAndAttributes.push({
          attribute: tableSettingColumn.attributeName(),
          path: tableSettingColumn.path() || []
        })
      }

      // Overwrite with any data given in props
      if (propsColumn) {
        newStoredColumnData.propsColumn = true

        Object.assign(newStoredColumnData, propsColumn)
      }

      columnsWithOrder.push(newStoredColumnData)
    }

    return {
      columnsWithOrder,
      pathsAndAttributes
    }
  }

  loadStoredColumnsData() {
    const {modelClass} = digs(this.component.props, "modelClass")
    const {columnsWithOrder, pathsAndAttributes} = digs(this.currentColumnsWithOrder(), "columnsWithOrder", "pathsAndAttributes")

    const preloadCalculatorResult = preloadCalculator({
      modelClass,
      pathsAndAttributes
    })

    // TODO wtf - should be passed through a callback
    this.component.setState({columnsWithOrder})
    this.preloads = digg(preloadCalculatorResult, "preloads")
    this.selectedAttributes = digg(preloadCalculatorResult, "selectedAttributes")

    // Might need to reload columns in order to show the changed information
    this.component.loadModelsLater()
  }

  async addNewAndRemoveDeprecatedRegisteredColumns() {
    const {propsColumns} = digs(this.component, "propsColumns")
    const {tableSettingColumns} = digs(this, "tableSettingColumns")
    const propsColumnsNotFoundInTableSetting = propsColumns.filter((propsColumn) =>
      !tableSettingColumns.find((tableSettingColumn) => tableSettingColumn.identifier() == this.propsColumnIdentifier(propsColumn))
    )
    const tableSettingPropsColumnsNoLongerFound = tableSettingColumns
      .filter((tableSettingColumn) =>
        tableSettingColumn.propsColumn() &&
          !propsColumns.find((propsColumn) => this.propsColumnIdentifier(propsColumn) == tableSettingColumn.identifier())
      )

    const tableSettingColumnsWithMissingAttribute = this.tableSettingColumnsNoLongerFoundInModelClassAttributes()
    const changedColumns = this.findChangedPropsColumns()

    if (
      propsColumnsNotFoundInTableSetting.length == 0 &&
      tableSettingPropsColumnsNoLongerFound.length == 0 &&
      tableSettingColumnsWithMissingAttribute.length == 0 &&
      changedColumns.length == 0
    ) {
      // There are no missing props-columns and no table-setting-columns no longer in props columns - so no reason to send a request to the backend
      return
    }

    let {columnsWithOrder} = this.currentColumnsWithOrder()

    // Filter out any columns that will be added as destroyed later which will otherwise mess up the position if given twice
    columnsWithOrder = columnsWithOrder.filter((column) =>
      !tableSettingPropsColumnsNoLongerFound.find((tableSettingColumn) => tableSettingColumn.identifier() == digg(column, "identifier"))
    )

    // Add columns from props that aren't found in settings
    for (const propsColumnNotFoundInTableSetting of propsColumnsNotFoundInTableSetting) {
      if (!("identifier" in propsColumnNotFoundInTableSetting)) {
        propsColumnNotFoundInTableSetting.identifier = this.propsColumnIdentifier(propsColumnNotFoundInTableSetting)
      }

      columnsWithOrder.push(propsColumnNotFoundInTableSetting)
    }

    // Destroy table setting columns created from props columns that are no longer found in props columns
    for (const tableSettingColumn of tableSettingPropsColumnsNoLongerFound) {
      columnsWithOrder.push({
        id: tableSettingColumn.id(),
        identifier: tableSettingColumn.identifier(),
        _destroy: true
      })
    }

    // Destroy table setting columns created from attributes where the attributes no longer exists
    for (const tableSettingColumn of tableSettingColumnsWithMissingAttribute) {
      columnsWithOrder.push({
        id: tableSettingColumn.id(),
        identifier: tableSettingColumn.identifier(),
        _destroy: true
      })
    }

    await this.saveColumnsData(columnsWithOrder)

    // Reload table setting in order to refresh columns with the newly created ones
    await this.loadTableSetting()
  }

  findChangedPropsColumns() {
    const {propsColumns} = digs(this.component, "propsColumns")
    const {tableSettingColumns} = digs(this, "tableSettingColumns")

    return propsColumns.filter((propsColumn) => {
      const identifier = this.propsColumnIdentifier(propsColumn)
      const foundTableSettingColumn = tableSettingColumns.find((tableSettingColumn) => tableSettingColumn.identifier() == identifier)

      if (!foundTableSettingColumn) return false

      let changed = false

      if ("attribute" in propsColumn && propsColumn.attribute != foundTableSettingColumn.attributeName()) changed = true

      return changed
    })
  }

  tableSettingColumnsNoLongerFoundInModelClassAttributes() {
    const {modelClass} = digs(this.component.props, "modelClass")
    const {tableSettingColumns} = digs(this, "tableSettingColumns")

    return tableSettingColumns
      .filter((tableSettingColumn) => tableSettingColumn.attributeName() && tableSettingColumn.path() && !tableSettingColumn.propsColumn())
      .filter((tableSettingColumn) => {
        const columnsModelClass = pathToModelClass(modelClass, tableSettingColumn.path())
        const attributes = digg(columnsModelClass.modelClassData(), "attributes")
        const underscoredAttribute = Inflection.underscore(tableSettingColumn.attributeName())
        const matchingAttribute = underscoredAttribute in attributes

        return !matchingAttribute
      })
  }

  saveColumnsData = async (newColumnsWithOrder) => {
    const {modelClass} = digs(this.component.props, "modelClass")
    const {propsColumns} = digs(this.component, "propsColumns")
    const {tableSetting} = digs(this, "tableSetting")
    const columnsData = []
    const tableSettingData = {
      table_setting_columns_attributes: columnsData
    }

    let positionCount = 1

    for (const column of newColumnsWithOrder) {
      const {
        attribute: attributeName,
        columnClassName,
        condition,
        content,
        defaultVisible,
        editable,
        headerProps,
        propsColumn,
        sortContent,
        sortKey,
        textCenter,
        textRight,
        type,
        ...columnData
      } = column
      const {identifier} = digs(column, "identifier")
      const existingTenantSettingColumn = tableSetting.tableSettingColumns().loaded().find((tableSettingColumn) =>
        tableSettingColumn.identifier() == identifier
      )

      columnData.attribute_name = attributeName
      columnData.sort_key = sortKey
      columnData.text_center = textCenter
      columnData.text_right = textRight

      if (columnData._destroy) { // eslint-disable-line no-underscore-dangle
        delete columnData.position
      } else {
        columnData.position = positionCount++
      }

      if (!("id" in columnData) && existingTenantSettingColumn) {
        columnData.id = existingTenantSettingColumn.id()
      }

      if (!("label" in columnData) && attributeName) {
        columnData.label = modelClass.humanAttributeName(attributeName)
      }

      if (!("props_column" in columnData)) {
        if (propsColumn !== undefined) {
          columnData.props_column = propsColumn
        } else {
          const propsColumnWithSameIdentifier = propsColumns.find((propsColumn) => this.propsColumnIdentifier(propsColumn) == identifier)

          columnData.props_column = Boolean(propsColumnWithSameIdentifier)
        }
      }

      columnsData.push(columnData)
    }

    await tableSetting.saveRaw({table_setting: tableSettingData})
  }

  async moveColumnTo({identifier}) {
    const {draggedHeaderIdentifier} = digs(this.component.state, "draggedHeaderIdentifier")
    const {tableSettingColumns} = digs(this, "tableSettingColumns")

    this.component.setState({
      draggedHeaderIdentifier: undefined,
      draggedHeaderOverIdentifier: undefined
    })

    if (!draggedHeaderIdentifier) {
      console.error("moveColumnTo: draggedHeaderIdentifier wasn't set", {draggedHeaderIdentifier})

      return
    }

    const draggedColumn = tableSettingColumns.find((tableSettingColumn) => tableSettingColumn.identifier() === draggedHeaderIdentifier)
    const droppedOnColumn = tableSettingColumns.find((tableSettingColumn) => tableSettingColumn.identifier() === identifier)

    let newPosition = droppedOnColumn.position()

    // If increasing order of a column it would come after the column it was dropped on.
    // This make sure the dropped column will consistently be placed before the colunmn it was dropped on.
    if (draggedColumn.position() < newPosition) {
      newPosition--
    }

    if (!droppedOnColumn) {
      throw new Error(`Couldn't find dropped-on-column from that identifier: ${identifier}`)
    }

    await draggedColumn.update({
      position: newPosition
    })
    await this.initialize()
  }

  showColumn(column) {
    if (!digg(column, "visible")) return false
    if ("condition" in column && !column.condition()) return false

    return true
  }

  async toggleColumnVisibility({column}) {
    const {columnsWithOrder} = digs(this.component.state, "columnsWithOrder")
    const newColumnsWithOrder = []
    const {identifier} = digs(column, "identifier")

    let found = false

    for (const columnWithOrder of columnsWithOrder) {
      const {identifier: columnWithOrderIdentifier} = digs(columnWithOrder, "identifier")

      if (columnWithOrderIdentifier === identifier) {
        // Found the column in question and toggles its visibility
        const newColumnWithOrder = {...columnWithOrder}

        newColumnWithOrder.visible = !newColumnWithOrder.visible
        newColumnsWithOrder.push(newColumnWithOrder)
        found = true
      } else {
        // Another column we just re-add to the array
        newColumnsWithOrder.push(columnWithOrder)
      }
    }

    if (found) {
      // Save new column information and re-load to refresh table
      await this.saveColumnsData(newColumnsWithOrder)
      await this.initialize()
    } else {
      // In case this is a new column added after the local database was saved
      throw new Error(`Couldnt find column by that identifier: ${identifier}`)
    }
  }
}
