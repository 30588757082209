export default class DropDownButtonHeadline extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    label: PropTypes.node
  })

  render() {
    const {className, label} = this.props

    return (
      <div className={classNames("components-shared-drop-down-button-headline", className)}>
        {label}
      </div>
    )
  }
}
