import "./style"
import BodyPortal from "components/body-portal"
import DynamicElement from "components/bootstrap/dynamic-element"

export default class AdminComponentsLayoutBottomBarActions extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired
  }

  state = {
    open: false
  }

  render() {
    const {children, className, ...restProps} = this.props
    const {open} = this.state

    return (
      <div
        className={classNames("admin-components-layout-bottom-bar-actions", className)}
        data-open={open}
        {...restProps}
      >
        <EventListener event="mouseup" onCalled={this.onWindowMouseUp} target={window} />
        <DynamicElement
          breakpoints={[
            ["isOrUp", "md", "div"],
            ["isOrUp", "xs", BodyPortal]
          ]}
        >
          <div className="admin-components-layout-bottom-bar-actions-container-outer" data-open={open}>
            <div className="actions-container" ref="actionsContainer">
              {children}
            </div>
          </div>
        </DynamicElement>
        <Icon className="actions-toggle" icon="ellipsis-h" onClick={this.onToggleClicked} size="small" />
      </div>
    )
  }

  onToggleClicked = (e) => {
    e.preventDefault()

    this.setState({open: !this.state.open})
  }

  onWindowMouseUp = (e) => {
    if (e.target.classList.contains("confirm-dialog-accept")) {
      return
    }

    if (e.target.classList.contains("confirm-dialog-cancel")) {
      return
    }

    if (!this.refs.actionsContainer.contains(e.target) && this.state.open) {
      this.setState({open: false})
    }
  }
}
