import "./style"
import {useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
DropDownButton.defaultProps = {
  closeOnItemClick: true
}

DropDownButton.propTypes = PropTypesExact({
  children: PropTypes.node,
  className: PropTypes.string,
  closeOnItemClick: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  label: PropTypes.node,
  onClick: PropTypes.func,
  right: PropTypes.bool,
  small: PropTypes.bool,
  to: PropTypes.string
})

export default function DropDownButton({className, closeOnItemClick, children, icon, label, onClick: onClickFromProps, right, small, to}) {
  const [toggled, setToggled] = useState(false)
  const navigate = useNavigate()
  const ref = useRef(null)

  const onClick = (e) => {
    e.preventDefault()

    if (onClickFromProps) {
      onClickFromProps(e)
    } else if (to) {
      navigate(to)
    } else {
      setToggled(!toggled)
    }
  }
  const onDropDownMenuClicked = () => {
    if (closeOnItemClick) {
      setToggled(false)
    }
  }
  const onToggleDropDown = (e) => {
    e.preventDefault()

    setToggled(!toggled)
  }
  const onWindowMouseUp = (e) => {
    if (!toggled || ref.current?.contains(e.target)) {
      return
    }

    setToggled(false)
  }

  return (
    <div className={classNames("components-shared-drop-down-button", className, {small})} data-toggled={toggled} ref={ref}>
      <EventListener event="mouseup" onCalled={onWindowMouseUp} target={window} />

      <div className="d-flex">
        <div className="primary-drop-down-option" onClick={onClick}>
          {icon &&
            <Icon icon={icon} />
          }
          {label}
        </div>
        <div className="angle-down-button toggle-drop-down-button" onClick={onToggleDropDown}>
          <i className="la la-angle-down" />
        </div>
      </div>
      {toggled &&
        <div className="drop-down-options-container" data-right={right} onClick={onDropDownMenuClicked}>
          {children}
        </div>
      }
    </div>
  )
}
