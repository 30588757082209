import Button from "react-bootstrap/Button"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"

export default class MerfotoPhotoSessionGroupContactTokensCreateFromTokenCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onPhotoSessionGroupContactTokenContactCreated: PropTypes.func.isRequired
  }

  render() {
    const {className, onPhotoSessionGroupContactTokenContactCreated, ...restProps} = this.props

    return (
      <form
        className={classNames("merfoto-components-photo-session-group-contact-tokens-create-from-token-form", className)}
        onSubmit={this.onSubmit}
        {...restProps}
      >
        <InputGroup className="mb-3">
          <FormControl
            name="token"
            placeholder={I18n.t("js.merfoto.photo_session_group_contact_tokens.create_from_token_form.enter_your_token_here")}
          />
          <Button className="create-from-token-form-button" type="submit">
            <Icon icon="angle-right" />
          </Button>
        </InputGroup>
      </form>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {onPhotoSessionGroupContactTokenContactCreated} = digs(this.props, "onPhotoSessionGroupContactTokenContactCreated")
    const form = digg(e, "target")
    const formData = new FormData(form)

    try {
      const response = await PhotoSessionGroupContactToken.createFromToken(formData)
      const photoSessionGroupContactTokenContact = digg(response, "photo_session_group_contact_token_contact")

      onPhotoSessionGroupContactTokenContactCreated(photoSessionGroupContactTokenContact)

      FlashMessage.success(I18n.t("js.merfoto.token.your_token_was_added_to_your_contact"))

      form.reset()
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
