import "./style"

export default class AttributeSelectChooser extends React.Component {
  static defaultProps = {
    readFromResource: false
  }

  static propTypes = PropTypesExact({
    defaultPath: PropTypes.array,
    filter: PropTypes.string,
    modelClass: PropTypes.func.isRequired,
    onAttributeChosen: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    readFromResource: PropTypes.bool.isRequired
  })

  state = ({
    attribute: undefined,
    attributes: [],
    collections: [],
    currentPath: this.props.defaultPath || [],
    filter: undefined,
    relationships: []
  })

  componentDidMount() {
    this.loadAttributesAndRelationshipsOnCurrentPath()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.filter != this.props.filter) {
      this.setState({
        filter: this.props.filter
      })
    }
  }

  async loadAttributesAndRelationshipsOnCurrentPath() {
    const {readFromResource} = digs(this.props, "readFromResource")

    if (readFromResource) {
      return await this.loadAttributesAndRelationshipsOnCurrentPathFromResources()
    }

    return await this.loadAttributesAndRelationshipsOnCurrentPathFromApi()
  }

  loadAttributesAndRelationshipsOnCurrentPathFromResources() {
    const currentResource = this.currentResource()
    const attributes = Object.keys(currentResource.modelClassData().attributes)
      .map((attribute) => ({label: currentResource.humanAttributeName(attribute), name: attribute}))
      .sort((attribute1, attribute2) => digg(attribute1, "label").toLowerCase().localeCompare(digg(attribute2, "label").toLowerCase()))

    const relationships = digg(currentResource.modelClassData(), "relationships")
      .map((relationship) => Object.assign({}, relationship, {label: currentResource.humanAttributeName(digg(relationship, "name"))}))
      .sort((relationship1, relationship2) => digg(relationship1, "label").toLowerCase().localeCompare(digg(relationship2, "label").toLowerCase()))

    this.setState({attributes, relationships})
  }

  async loadAttributesAndRelationshipsOnCurrentPathFromApi() {
    const {modelClass} = digs(this.props, "modelClass")
    const {currentPath} = digs(this.state, "currentPath")
    const path = currentPath.map((path) => digg(path, "name"))
    const result = await ModelSearchRule.scanRelationship({
      model_class: digg(modelClass.modelClassData(), "name"),
      path
    })
    const {attributes, collections, relationships} = digs(result, "attributes", "collections", "relationships")

    this.setState({attributes, collections, relationships})
  }

  render() {
    const {modelClass} = digs(this.props, "modelClass")
    const {currentPath} = digs(this.state, "currentPath")
    const filteredAttributes = this.filteredAttributes()
    const filteredCollections = this.filteredCollections()
    const filteredRelationships = this.filteredRelationships()

    return (
      <div
        className="admin-components-top-menu-extended-search-group-rule-attribute-select-chooser"
        data-current-path={JSON.stringify(currentPath)}
        ref="rootElement"
      >
        <EventListener event="mouseup" onCalled={this.onWindowMouseUp} target={window} />

        <div className="headline">
          {I18n.t("js.admin.layout.top_menu.extended_search.group.rule.attribute_select.chooser.relationships")}
          {currentPath.length > 0 &&
            <a className="go-to-previous-path-button ms-1" href="#" onClick={this.onGoToPreviousPathClicked}>
              <i className="la la-angle-double-left" />
            </a>
          }
        </div>
        <div className="d-inline-block">
          {modelClass.modelName().human()}
        </div>
        {currentPath.map((pathPart, index) =>
          <div className="d-inline-block ms-1" key={`path-part-${digg(pathPart, "name")}-${index}`}>
            - {digg(pathPart, "label")}
          </div>
        )}

        {filteredRelationships.length > 0 &&
          <div className="headline mt-3">
            {I18n.t("js.admin.layout.top_menu.extended_search.group.rule.attribute_select.chooser.relationships")}
          </div>
        }
        {filteredRelationships.map((relationship, index) =>
          <div key={`relation-${index}-${digg(relationship, "name")}`}>
            <a
              className="relationship-link"
              data-relationship-name={digg(relationship, "name")}
              href="#"
              onClick={(e) => this.onRelationshipClicked(e, relationship)}
            >
              {digg(relationship, "label")}
            </a>
          </div>
        )}
        {filteredCollections.length > 0 &&
          <div className="headline mt-3">
            {I18n.t("js.admin.layout.top_menu.extended_search.group.rule.attribute_select.chooser.collections")}
          </div>
        }
        {filteredCollections.map((collection, index) =>
          <div key={`collection-${index}-${digg(collection, "name")}`}>
            <a
              className="collection-link"
              data-collection-name={digg(collection, "name")}
              href="#"
              onClick={(e) => this.onCollectionClicked(e, collection)}
            >
              {digg(collection, "label")}
            </a>
          </div>
        )}
        {filteredAttributes.length > 0 &&
          <div className="headline mt-3">
            {I18n.t("js.admin.layout.top_menu.extended_search.group.rule.attribute_select.chooser.attributes")}
          </div>
        }
        {filteredAttributes.map((attribute) =>
          <div key={digg(attribute, "name")}>
            <a
              className="attribute-link"
              data-attribute-name={digg(attribute, "name")}
              href="#"
              onClick={(e) => this.onAttributeClicked(e, attribute)}
            >
              {digg(attribute, "label")}
            </a>
          </div>
        )}
      </div>
    )
  }

  // Traverses the current path to find the current resource class
  currentResource() {
    const {modelClass} = digs(this.props, "modelClass")
    const {currentPath} = digs(this.state, "currentPath")

    let currentModelClass = modelClass

    for (const pathPart of currentPath) {
      const relationships = digg(currentModelClass.modelClassData(), "relationships")
      const relationship = relationships.find((relationship) => digg(relationship, "name") == digg(pathPart, "name"))

      if (!relationship) {
        throw new Error(`Couldn't find a relationship called ${digg(pathPart, "name")} on ${digg(currentModelClass.modelClassData(), "name")}`)
      }

      const resourceName = digg(relationship, "resource_name")
      const resourceClass = digg(require("@kaspernj/api-maker/src/models"), resourceName)

      currentModelClass = resourceClass
    }

    return currentModelClass
  }

  filteredAttributes() {
    const {attributes, filter} = digs(this.state, "attributes", "filter")

    if (!filter || filter == "") {
      return attributes
    }

    const lowerCaseFilter = filter.toLowerCase()

    return attributes.filter((attribute) => digg(attribute, "label").toLowerCase().includes(lowerCaseFilter))
  }

  filteredCollections() {
    const {collections, filter} = digs(this.state, "collections", "filter")

    if (!filter || filter == "") {
      return collections
    }

    const lowerCaseFilter = filter.toLowerCase()

    return collections.filter((collection) => digg(collection, "label").toLowerCase().includes(lowerCaseFilter))
  }

  filteredRelationships() {
    const {filter, relationships} = digs(this.state, "filter", "relationships")

    if (!filter || filter == "") {
      return relationships
    }

    const lowerCaseFilter = filter.toLowerCase()

    return relationships.filter((relationship) => digg(relationship, "label").toLowerCase().includes(lowerCaseFilter))
  }

  onAttributeClicked(e, attribute) {
    e.preventDefault()

    this.props.onAttributeChosen({
      attribute,
      path: this.state.currentPath
    })
  }

  async onCollectionClicked(e, collection) {
    e.preventDefault()

    const {modelClass} = digs(this.props, "modelClass")
    const path = this.state.currentPath.concat([collection])
    const pathNames = path.map((pathItem) => digg(pathItem, "name"))
    const relationshipScan = await ModelSearchRule.scanRelationship({
      model_class: digg(modelClass.modelClassData(), "name"),
      path: pathNames
    })
    const idAttribute = digg(relationshipScan, "attributes").find((attributeData) => digg(attributeData, "name") == "id")
    const resourceName = digg(relationshipScan, "resource_name")

    if (!idAttribute) {
      throw new Error(`No ID attribute could be found on ${digg(collection, "name")}`)
    }

    this.props.onAttributeChosen({
      attribute: idAttribute,
      collection: {resourceName},
      path
    })
  }

  onGoToPreviousPathClicked = (e) => {
    e.preventDefault()

    // TODO should not depend on state update
    this.setState((prevState) => ({
      currentPath: prevState.currentPath.slice(0, -1)
    }), () => this.loadAttributesAndRelationshipsOnCurrentPath())
  }

  onRelationshipClicked(e, relationship) {
    e.preventDefault()

    // TODO should not depend on state update
    this.setState((prevState) => ({
      currentPath: prevState.currentPath.concat([relationship])
    }), () => this.loadAttributesAndRelationshipsOnCurrentPath())
  }

  onWindowMouseUp = (e) => {
    if (!this.refs.rootElement.contains(e.target)) {
      this.props.onCancel()
    }
  }
}
