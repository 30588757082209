import SheetLayoutsPresentation from "components/sheet-layouts/presentation"

export default class MerfotoOrderLinesEditResourcesPhotoSheetProductSheetLayoutPreview extends React.Component {
  static propTypes = {
    currentSheetLayout: PropTypes.instanceOf(SheetLayout).isRequired,
    onClick: PropTypes.func.isRequired,
    photoSheetProductSheetLayout: PropTypes.instanceOf(PhotoSheetProductSheetLayout).isRequired
  }

  render() {
    const {currentSheetLayout, photoSheetProductSheetLayout} = digs(this.props, "currentSheetLayout", "photoSheetProductSheetLayout")

    return (
      <a href="#" onClick={this.onClicked}>
        <SheetLayoutsPresentation
          active={photoSheetProductSheetLayout.sheetLayout().id() == currentSheetLayout.id()}
          className="preview-sheet-layout"
          sheetLayout={photoSheetProductSheetLayout.sheetLayout()}
          sheetSize={photoSheetProductSheetLayout.sheetLayout().sheetSize()}
          size={84}
        />
      </a>
    )
  }

  onClicked = (e) => {
    const {photoSheetProductSheetLayout} = digs(this.props, "photoSheetProductSheetLayout")

    this.props.onClick(e, photoSheetProductSheetLayout.sheetLayout())
  }
}
