import LogDetails from "admin/components/logs/log-details"
import LogHeader from "admin/components/logs/log-header"
import LogLabel from "admin/components/logs/log-label"
import {NemoaSelectV2} from "components/nemoa-select-v2"
import SharedModelsTable from "components/models-table"

export default class AdminLogsTable extends React.Component {
  static defaultProps = {
    filters: true,
    showHeader: true,
    trackableFilter: true
  }

  static propTypes = {
    className: PropTypes.string,
    filters: PropTypes.bool,
    showHeader: PropTypes.bool,
    trackableFilter: PropTypes.bool
  }

  state = ({
    logKeys: undefined,
    translatedTrackableTypes: undefined
  })

  componentDidMount() {
    const {trackableFilter} = this.props

    this.loadKeys()

    if (trackableFilter) {
      this.loadTrackableTypes()
    }
  }

  async loadKeys() {
    const logKeys = await LogKey.ransack({s: "key"}).toArray()

    this.setState({logKeys})
  }

  async loadTrackableTypes() {
    const response = await Log.translatedTrackableTypes()

    this.setState({translatedTrackableTypes: digg(response, "translated_trackable_types")})
  }

  render() {
    const {className, filters, showHeader, trackableFilter, ...restProps} = this.props

    return (
      <SharedModelsTable.Table
        cardHeader={this.cardHeader()}
        className={classNames("admin-components-logs-table", className)}
        columns={this.columns}
        defaultParams={{s: "created_at desc"}}
        expandableContent={this.expandableContent}
        filterContent={this.filterContentProxy()}
        modelClass={Log}
        preloads={["log_key", "log_subject", "user"]}
        responsiveTable={false}
        select={{
          Log: ["createdAt", "id", "trackable", "trackableId", "trackableType"],
          LogKey: ["key"],
          LogSubject: ["subject"],
          User: ["id", "name"]
        }}
        {...restProps}
      />
    )
  }

  filterContentProxy() {
    if (!this.props.filters) {
      return null
    }

    return (args) => this.filterContent(args)
  }

  filterContent = (args) => {
    const {onFilterChanged, onFilterChangedWithDelay, qParams} = args
    const {logKeys, translatedTrackableTypes} = digs(this.state, "logKeys", "translatedTrackableTypes")
    const {trackableFilter} = this.props

    return (
      <>
        <div className="row">
          {trackableFilter &&
            <>
              {translatedTrackableTypes &&
                <div className="col-md-6">
                  <NemoaSelectV2
                    defaultValue={qParams.trackable_type_eq_any}
                    id="trackable_type_filter"
                    label={I18n.t("js.admin.logs.table.trackable_type")}
                    multiple
                    name="trackable_type_eq_any"
                    onChange={() => this.setState({}, onFilterChanged)}
                    options={HashToCollection.toOptionsArray(translatedTrackableTypes)}
                    resetOption
                    size="small"
                  />
                </div>
              }
              <div className="col-md-6">
                <BootstrapInput
                  className="form-control-sm"
                  defaultValue={qParams.trackable_id_eq}
                  id="trackable_id_filter"
                  label={I18n.t("js.admin.logs.table.trackable_id")}
                  name="trackable_id_eq"
                  onChange={onFilterChangedWithDelay}
                />
              </div>
            </>
          }
          <div className="col-md-6">
            <BootstrapInput
              className="form-control-sm"
              defaultValue={qParams.user_person_first_name_or_user_person_last_name_cont_any_word}
              id="name_filter"
              label={I18n.t("js.admin.logs.table.name")}
              name="user_person_first_name_or_user_person_last_name_cont_any_word"
              onChange={onFilterChangedWithDelay}
            />
          </div>
          <div className="col-md-6">
            {logKeys &&
              <NemoaSelectV2
                defaultValue={qParams.log_key_id_eq_any}
                id="key_filter"
                isMulti
                label={I18n.t("js.admin.logs.table.key")}
                name="log_key_id_eq_any"
                onChange={() => this.setState({}, onFilterChanged)}
                options={this.keyOptions()}
                resetOption
                size="small"
              />
            }
          </div>
        </div>
      </>
    )
  }

  expandableContent = (args) => {
    const model = digg(args, "model")

    return (
      <div className="mb-4 row">
        <LogDetails log={model} />
      </div>
    )
  }

  keyContent = ({log}) =>
    <LogHeader log={log} />

  nameContent = ({log}) => {
    const NameComponent = this.nameComponent(log.trackableType())

    return (
      <>
        {NameComponent &&
          <NameComponent log={log} />
        }
        {!NameComponent &&
          `${log.trackableType()} ${log.trackableId()}`
        }
      </>
    )
  }

  userContent = ({log}) =>
    <>
      {log.user() &&
        <UserLink user={log.user()} />
      }
      {!log.user() && I18n.t("js.admin.logs.table.system")}
    </>

  columns = () => [
    {
      content: this.nameContent,
      columnClassName: "name-column",
      identifier: "name",
      label: I18n.t("js.admin.logs.table.name")
    },
    {
      content: this.keyContent,
      label: LogKey.humanAttributeName("key"),
      sortKey: "logKeyKey"
    },
    {
      content: this.userContent,
      columnClassName: "user-name-column",
      label: Log.humanAttributeName("user"),
      sortKey: "userId"
    },
    {
      attribute: "createdAt",
      sortKey: "createdAt"
    }
  ]

  nameComponent = (trackableType) => {
    if (!trackableType) {
      return null
    }

    const fileName = Inflection.dasherize(Inflection.underscore(trackableType))

    try {
      const component = require(`admin/components/logs/names/${fileName}`).default

      return component
    } catch (error) {
      if ((error instanceof Error) && error.code == "MODULE_NOT_FOUND") {
        return null
      }

      throw error
    }
  }

  keyOptions() {
    return this.state.logKeys
      .map((logKey) => {
        const logLabel = new LogLabel({key: logKey.key()})

        return {label: logLabel.label(), value: logKey.id()}
      })
      .sort((option1, option2) => option1.label.toLowerCase().localeCompare(option2.label.toLowerCase()))
  }

  cardHeader() {
    if (!this.props.showHeader) {
      return null
    }

    return I18n.t("js.admin.logs.card.log")
  }
}
