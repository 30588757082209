import "./style"

export default class MerfotoTenantPhotoProductsAddToBasketButton extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    photos: PropTypes.arrayOf(PropTypes.instanceOf(Photo)).isRequired,
    photoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact),
    tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct).isRequired
  }

  render() {
    const {className, photos, photoSessionGroupContact, tenantPhotoProduct, ...restProps} = this.props

    return (
      <Link
        className={classNames("merfoto-components-tenant-photo-products-add-to-basket-button", "fw-bold", className)}
        data-tenant-photo-product-id={tenantPhotoProduct.id()}
        to={MerfotoRoutes.newOrderLinePath({
          photo_ids: photos.map((photo) => photo.id()),
          photo_session_group_contact_id: photoSessionGroupContact?.id(),
          tenant_photo_product_id: tenantPhotoProduct.id()
        })}
        {...restProps}
      >
        <Icon className="pe-1" icon="cart-plus" />
        {I18n.t("js.merfoto.tenant_photo_products.add_to_basket_button.choose_photos_and_add_to_basket")}
      </Link>
    )
  }
}
