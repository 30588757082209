import "./style"
import {idForComponent, nameForComponent} from "@kaspernj/api-maker-inputs"
import PlaceholderLabel from "./placeholder-label"
import PositionRelativeTo from "components/position-relative-to"
import SameWidthAs from "components/same-width-as"
import SanitizedHtml from "../sanitized-html"
import {v4 as uuidv4} from "uuid"

export default class NemoaSelect extends React.Component {
  static defaultProps = {
    multiple: false,
    opens: "downwards"
  }

  static propTypes = {
    attribute: PropTypes.string,
    callbackArgs: PropTypes.object,
    className: PropTypes.string,
    currentOptions: PropTypes.arrayOf(PropTypes.object), // TODO could be PropTypes.shape
    defaultValue: PropTypes.oneOfType([
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string
        ])
      ),
      PropTypes.number,
      PropTypes.string
    ]),
    events: PropTypes.instanceOf(EventEmitter),
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    model: PropTypes.object,
    multiple: PropTypes.bool.isRequired,
    name: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    onChange: PropTypes.func,
    onOptionsOpened: PropTypes.func,
    onSearch: PropTypes.func,
    opens: PropTypes.string,
    options: PropTypes.array,
    optionsCallback: PropTypes.func,
    optionsContainerRef: PropTypes.object,
    optionsQuery: PropTypes.object,
    placeholder: PropTypes.node,
    placeholderMuted: PropTypes.bool,
    placeholderPrimary: PropTypes.bool,
    preLabel: PropTypes.node,
    resetOption: PropTypes.bool,
    search: PropTypes.bool,
    selectOptionsRef: PropTypes.object,
    size: PropTypes.oneOf(["medium", "small"])
  }

  optionsContainerRef = React.createRef()
  selectOptionsRef = this.props.selectOptionsRef || React.createRef()
  state = {
    appliedSearchValue: undefined,
    currentOptions: [],
    focusIndex: undefined,
    uniqueId: uuidv4(),
    opened: false,
    options: this.optionsFromProps(),
    searching: false
  }

  constructor(props) {
    super(props)

    if (props.defaultValue && !props.multiple && Array.isArray(props.defaultValue)) {
      throw new Error("An array was passed as defaultValue for a singular selector")
    }
  }

  callbackArgs() {
    const {callbackArgs} = this.props
    let newCallbackArgs = {}

    if (callbackArgs) {
      newCallbackArgs = Object.assign(newCallbackArgs, callbackArgs)
    }

    return newCallbackArgs
  }

  componentDidMount() {
    const {attribute, defaultValue, model, options, optionsCallback} = this.props

    if (!options && optionsCallback && (defaultValue || (attribute && model))) {
      this.setOptionsCallbackDefaultValue()
    } else {
      this.setState({currentOptions: this.findDefaultLabelForDefaultValue(this.state.options)})
    }
  }

  componentDidUpdate = (prevProps) => {
    if (("options" in this.props) && prevProps.options !== this.props.options) {
      const optionsFromProps1 = this.optionsFromProps()

      this.setState({options: optionsFromProps1})
    }

    if (prevProps.currentOptions !== this.props.currentOptions) {
      this.setState({currentOptions: this.props.currentOptions})
    }
  }

  defaultValue() {
    const {attribute, defaultValue, model} = this.props

    if (defaultValue) {
      return defaultValue
    } else if (attribute && model) {
      return model.readAttribute(attribute)
    }
  }

  async setOptionsCallbackDefaultValue() {
    const {optionsCallback} = this.props
    const options = await optionsCallback({id: this.defaultValue()})

    // findDefaultLabelForDefaultValue uses shape-options so we need to set that in an individual call first
    this.setState({
      currentOptions: this.findDefaultLabelForDefaultValue(options),
      options
    })
  }

  findDefaultLabelForDefaultValue(options) {
    const {attribute, currentOptions, model} = this.props

    if (currentOptions) {
      return currentOptions
    }

    let defaultValues

    if ("defaultValue" in this.props) {
      if (Array.isArray(this.props.defaultValue)) {
        defaultValues = digg(this, "props", "defaultValue")
      } else {
        defaultValues = [digg(this, "props", "defaultValue")]
      }
    } else if (attribute && model) {
      defaultValues = [model.readAttribute(attribute)]
    }

    if (!defaultValues || !options) {
      return []
    }

    const selectedDefaultOptions = options.filter((option) => {
      for (const defaultValue of defaultValues) {
        if (defaultValue == digg(option, "value")) {
          return true
        }
      }

      return false
    })

    return selectedDefaultOptions
  }

  optionsFromProps() {
    const {options} = this.props

    if (!options) {
      return undefined
    }

    return options.map((option) => {
      if (Array.isArray(option)) {
        return {
          label: option[0],
          value: option[1]
        }
      }

      return option
    })
  }

  render() {
    const {
      attribute,
      callbackArgs,
      className,
      currentOptions: currentOptionsFromProps,
      defaultValue,
      events,
      id,
      label: labelFromProps,
      model,
      multiple,
      name,
      onChange,
      onOptionsOpened,
      onSearch,
      opens,
      options,
      optionsCallback,
      optionsContainerRef,
      optionsQuery,
      placeholder,
      placeholderMuted,
      placeholderPrimary,
      preLabel,
      resetOption,
      search,
      selectOptionsRef,
      size,
      ...restProps
    } = this.props
    const {appliedSearchValue, currentOptions, opened, searching, searchValue, uniqueId} = this.state
    const label = this.label()

    return (
      <div
        className={this.className()}
        data-applied-search-value={appliedSearchValue}
        data-multiple={multiple}
        data-opened={opened}
        data-opens={opens}
        data-search-value={searchValue}
        data-select-id={idForComponent(this)}
        data-size={size}
        data-unique-id={uniqueId}
        ref="rootElement"
        {...restProps}
      >
        <EventListener event="mouseup" onCalled={this.onWindowMouseUp} target={window} />
        <EventListener event="keydown" onCalled={this.onDocumentKeydown} target={document} />
        {events &&
          <EventEmitterListener event="setValue" events={events} onCalled={this.onSetValueCalled} />
        }

        {label &&
          <label>
            {label}
          </label>
        }
        {this.hiddenInputs()}

        {opens === "upwards" && this.selectOptionsContainer()}
        <div className={this.selectContainerClassName()} onClick={this.onClicked}>
          <div className="align-items-center current-value-container d-flex flex-grow-1">
            {preLabel && !searching &&
              <div className="me-1 pre-label text-muted">
                {preLabel}:
              </div>
            }
            {!multiple && currentOptions.length > 0 && currentOptions[0].iconUrl && !searching &&
              <div className="current-icon">
                <div className="option-icon" style={{backgroundImage: `url("${digg(currentOptions[0], "iconUrl")}"`}} />
              </div>
            }
            {currentOptions.length == 0 && !searching &&
              <div className={this.placeholderLabelClass()}>
                {this.placeholderContent()}
              </div>
            }
            {searching &&
              <input
                className="search-input w-100"
                onChange={this.onSearchInputChanged}
                placeholder={I18n.t("js.nemoa_select.type_something_to_search")}
                ref="searchInput"
                type="text"
              />
            }
            {!searching &&
              <div className="current-label flex-grow-1 text-nowrap">
                {currentOptions.map((currentOption) =>
                  <PlaceholderLabel
                    className="w-100"
                    data-value={currentOption.value}
                    key={`current-option-${currentOption.id}-${currentOption.label}-${currentOption.value}`}
                    labelContent={this.labelContent(currentOption)}
                    multiple={multiple}
                    onUnselectOptionClicked={(e) => this.onUnselectOptionClicked(e, currentOption)}
                  />
                )}
              </div>
            }
            {resetOption && currentOptions.length > 0 &&
              <div>
                <a className="reset-select-button" href="#" onClick={this.onResetClicked} title={I18n.t("js.global.reset")}>
                  <i className="la la-remove" />
                </a>
              </div>
            }
          </div>
          <div className="angle-down-container">
            <i className="la la-angle-down" />
          </div>
        </div>
        {opens == "downwards" && this.selectOptionsContainer()}
      </div>
    )
  }

  hiddenInputs() {
    const {currentOptions} = this.state
    const {multiple} = this.props

    if (multiple) {
      return this.hiddenInputsForMultiple()
    }

    return (
      <input id={idForComponent(this)} name={nameForComponent(this)} type="hidden" value={(currentOptions[0]?.value) || ""} />
    )
  }

  hiddenInputsForMultiple() {
    const {currentOptions} = this.state
    const {name} = this.props

    if (name && typeof name == "function") {
      return currentOptions.map((currentOption, index) =>
        <input
          defaultValue={digg(currentOption, "value")}
          id={idForComponent(this)}
          key={digg(currentOption, "value")}
          name={name({currentOption, index})}
          type="hidden"
        />
      )
    }

    let inputName = nameForComponent(this)

    if (!inputName.endsWith("[]")) {
      inputName += "[]"
    }

    if (currentOptions.length == 0) {
      return <input id={idForComponent(this)} name={inputName} type="hidden" />
    }

    return currentOptions.map((currentOption) =>
      <input
        defaultValue={digg(currentOption, "value")}
        id={idForComponent(this)}
        key={digg(currentOption, "value")}
        name={inputName}
        type="hidden"
      />
    )
  }

  placeholderContent() {
    const {placeholder} = this.props

    if (placeholder) return placeholder

    // Return non-breaking-space in order to make the box forcefully contain characters to not make it jump because its empty when choosing an option
    return "\u00A0"
  }

  selectOptionsContainer() {
    const {optionsContainerRef, selectOptionsRef} = this
    const {multiple, opens, size} = this.props
    const {focusIndex, opened, options, uniqueId} = this.state

    return (
      <div className="select-options-container" data-size={size} ref={optionsContainerRef}>
        {opened && options &&
          <PositionRelativeTo element={optionsContainerRef.current}>
            <SameWidthAs element={this.refs.rootElement}>
              <div
                className="components-nemoa-select-select-options"
                data-opens={opens}
                data-select-id={idForComponent(this)}
                data-size={size}
                data-unique-id={uniqueId}
                ref={selectOptionsRef}
              >
                {options.map((option, optionIndex) =>
                  <React.Fragment key={option.value || option.label}>
                    {option.type == "group" &&
                      <div className={this.optionGroupClassName(option)}>
                        {this.labelContent(option)}
                      </div>
                    }
                    {option.type != "group" &&
                      <div
                        className={this.optionClassName(option)}
                        data-focus={optionIndex == focusIndex}
                        data-value={option.value}
                        onClick={(e) => this.onOptionClicked(e, option)}
                        onMouseEnter={() => this.onOptionMouseEnter(optionIndex)}
                        onMouseLeave={() => this.onOptionMouseLeave(optionIndex)}
                      >
                        {multiple &&
                          <input
                            checked={this.isCheckboxChecked(option)}
                            className="me-2 select-option-checkbox"
                            onChange={(e) => this.onCheckboxChanged(e, option)}
                            onClick={this.onCheckboxClicked}
                            type="checkbox"
                          />
                        }
                        {option.iconUrl &&
                          <div className="option-icon" style={{backgroundImage: `url("${option.iconUrl}"`}} />
                        }
                        {this.labelContent(option)}
                      </div>
                    }
                  </React.Fragment>
                )}
              </div>
            </SameWidthAs>
          </PositionRelativeTo>
        }
      </div>
    )
  }

  isCheckboxChecked = (option) => {
    const {currentOptions} = this.state

    if (currentOptions.find((currentOption) => digg(currentOption, "value") == digg(option, "value"))) {
      return true
    }

    return false
  }

  label() {
    const {attribute, label, model} = this.props

    if (label === false) {
      return null
    } else if (label) {
      return label
    } else if (attribute && model) {
      return model.constructor.humanAttributeName(attribute)
    }
  }

  className() {
    const {className} = this.props
    const classNames = ["components-nemoa-select"]

    if (className) {
      classNames.push(className)
    }

    return classNames.join(" ")
  }

  closeSelect() {
    this.setState({opened: false, searching: false})
  }

  labelContent = ({labelHTML, labelNode, label}) => {
    if (labelHTML) {
      return (<SanitizedHtml dirty={labelHTML} />)
    } else if (labelNode) {
      return labelNode
    }

    return label
  }

  onUnselectOptionClicked(e, option) {
    e.preventDefault()
    e.stopPropagation()

    this.unselectOption(option)
  }

  onCheckboxChanged(e, option) {
    this.selectOption(option)
  }

  onCheckboxClicked = (e) => {
    // Prevent onOptionClicked from being called
    e.stopPropagation()
  }

  onClicked = (e) => {
    e.preventDefault()

    const {opened} = this.state

    if (opened) {
      this.closeSelect()
    } else {
      this.openSelect()
    }
  }

  onDocumentKeydown = (e) => {
    const {opened} = this.state

    if (!opened) {
      return
    }

    if (e.key == "ArrowDown") {
      e.preventDefault()
      this.focusMoveDown()
    } else if (e.key == "ArrowUp") {
      e.preventDefault()
      this.focusMoveUp()
    } else if (e.key == "Enter") {
      e.preventDefault()

      const {focusIndex, options} = this.state

      if (focusIndex) {
        const focusOption = digg(options, focusIndex)

        this.selectOption(focusOption)
      }
    } else if (e.key == "Escape") {
      e.preventDefault()
      this.closeSelect()
    }
  }

  focusMoveDown() {
    const {focusIndex, options} = this.state

    if (focusIndex === undefined) {
      this.setState({focusIndex: 0})
    } else {
      let nextFocusIndex = this.state.focusIndex + 1

      if (nextFocusIndex > (options.length - 1)) {
        nextFocusIndex = options.length - 1
      }

      this.setState({focusIndex: nextFocusIndex})
    }
  }

  focusMoveUp() {
    const {focusIndex, options} = this.state

    if (focusIndex === undefined) {
      this.setState({focusIndex: options.length - 1})
    } else {
      let nextFocusIndex = this.state.focusIndex - 1

      if (nextFocusIndex <= 0) {
        nextFocusIndex = 0
      }

      this.setState({focusIndex: nextFocusIndex})
    }
  }

  onResetClicked = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.resetValue()
  }

  onOptionClicked(e, option) {
    e.preventDefault()

    this.selectOption(option)
  }

  onOptionMouseEnter = (optionIndex) => {
    this.setState({focusIndex: optionIndex})
  }

  onOptionMouseLeave = (optionIndex) => {
    if (this.state.focusIndex == optionIndex) {
      this.setState({focusIndex: undefined})
    }
  }

  selectOption = (option) => {
    const {multiple} = this.props

    this.setState((state) => {
      const {currentOptions} = state

      // Prevent changing anything if option is the current option
      if (!multiple && option.value == currentOptions[0]?.value) {
        return {
          opened: false, searching: false
        }
      }

      let newCurrentOptions

      if (multiple) {
        if (currentOptions.find((currentOption) => currentOption.value == option.value)) {
          newCurrentOptions = currentOptions.filter((currentOption) => currentOption.value != option.value)
        } else {
          newCurrentOptions = currentOptions.concat([option])
        }
      } else {
        newCurrentOptions = [option]
      }

      return {
        currentOptions: newCurrentOptions,
        opened: multiple,
        searching: false
      }
    }, () => this.notifyCurrentOptionsChanged(option))
  }

  unselectOption = (option) => {
    this.setState((state) => ({
      currentOptions: state.currentOptions.filter((currentOption) => digg(currentOption, "value") != digg(option, "value"))
    }), () => this.notifyCurrentOptionsChanged(option))
  }

  notifyCurrentOptionsChanged = () => {
    const {multiple} = digs(this.props, "multiple")
    const {currentOptions} = this.state

    if (this.props.onChange) {
      const callbackArgs = this.callbackArgs()

      if (multiple) {
        callbackArgs.option = currentOptions

        this.props.onChange(callbackArgs)
      } else {
        callbackArgs.option = currentOptions[0]

        this.props.onChange(callbackArgs)
      }
    }
  }

  onSearchInputChanged = (e) => {
    const {onSearch, optionsCallback} = this.props

    if (onSearch) {
      onSearch(e.target.value)
    }

    if (optionsCallback) {
      if (this.onSearchInputChangedTimeout) {
        clearTimeout(this.onSearchInputChangedTimeout)
      }

      this.onSearchInputChangedTimeout = setTimeout(this.searchForOptions, 250)
    }

    this.setState({searchValue: e.target.value})
  }

  // TODO deprecated - dont use events :-(
  onSetValueCalled = async (newOption) => {
    const {multiple} = digs(this.props, "multiple")
    let currentOptions

    if (Array.isArray(newOption)) {
      currentOptions = newOption
    } else {
      currentOptions = [newOption]
    }

    if (!newOption) {
      await this.resetValue()
    } else if ("id" in newOption) {
      const {optionsCallback} = digs(this.props, "optionsCallback")
      const options = await optionsCallback({id: digg(newOption, "id")})

      if (options.length > 1 && !multiple) {
        throw new Error(`${options.length} options were returned, but only a single currentOption is allowed.`)
      }

      this.setState({currentOptions: options, options}, () => this.notifyCurrentOptionsChanged())
    } else {
      await this.setState({currentOptions}, () => this.notifyCurrentOptionsChanged())
    }
  }

  onWindowMouseUp = (e) => {
    const {selectOptionsRef} = this

    if (!this.state.opened) {
      return
    }

    if (this.refs.rootElement.contains(e.target) || selectOptionsRef.current.contains(e.target)) {
      return
    }

    this.setState({opened: false, searching: false})
  }

  async openSelect() {
    const {optionsCallback, search} = this.props
    const newState = {}

    if (search) {
      newState.opened = true
      newState.searching = true

      if (optionsCallback) {
        await this.searchForOptions()
        this.setState({opened: true}, this.callOptionsOpened)
      }
    } else {
      newState.opened = true
      newState.searching = false
    }

    this.setState(newState, () => {
      if (newState.searching) {
        this.refs.searchInput.focus()

        if (optionsCallback) {
          this.searchForOptions()
        }
      }

      if (newState.opened) {
        this.callOptionsOpened()
      }
    })
  }

  callOptionsOpened = () => {
    if (this.props.onOptionsOpened) {
      this.props.onOptionsOpened()
    }
  }

  optionClassName = (option) => {
    const {currentOptions} = this.state
    const classNames = ["d-flex", "select-option"]

    if (currentOptions?.find((currentOption) => currentOption.value == option.value)) {
      classNames.push("selected-option")
    }

    if (option.class) {
      classNames.push(digg(option, "class"))
    }

    return classNames.join(" ")
  }

  optionGroupClassName = (option) => {
    const classNames = ["d-flex", "select-option-group", "text-muted"]

    if (option.class) {
      classNames.push(digg(option, "class"))
    }

    return classNames.join(" ")
  }

  placeholderLabelClass() {
    const classes = ["placeholder-label", "text-nowrap"]

    if (this.props.placeholderMuted) {
      classes.push("text-muted")
    }

    if (this.props.placeholderPrimary) {
      classes.push("text-primary")
    }

    return classes.join(" ")
  }

  resetValue() {
    const {multiple, onChange} = this.props

    this.setState({currentOptions: []}, () => {
      if (onChange) {
        const callbackArgs = this.callbackArgs()

        if (multiple) {
          callbackArgs.option = []

          onChange(callbackArgs)
        } else {
          callbackArgs.option = null

          onChange(callbackArgs)
        }
      }
    })
  }

  searchForOptions = async () => {
    const searchValue = this.state.searchValue || ""

    const args = {value: searchValue}
    const options = await this.props.optionsCallback(args)

    this.setState({appliedSearchValue: searchValue, options})
  }

  selectContainerClassName() {
    const {opened} = this.state
    const classNames = ["select-container", "d-flex"]

    if (opened) {
      classNames.push("opened")
    }

    return classNames.join(" ")
  }
}
