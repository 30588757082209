import useDebouncedCallback from "@restart/hooks/useDebouncedCallback"
import {useNavigate} from "react-router-dom"
import {useRef} from "react"
import useSessionReload from "shared/session"

SharedModelsTableFilter.propTypes = PropTypesExact({
  defaultExpanded: PropTypes.bool.isRequired,
  filterArgs: PropTypes.object,
  filterContent: PropTypes.func.isRequired,
  qParams: PropTypes.object,
  queryQName: PropTypes.string.isRequired
})

export default function SharedModelsTableFilter({defaultExpanded, filterArgs, filterContent, qParams, queryQName}) {
  const navigate = useNavigate()
  const filterFormRef = useRef(null)
  const filterResults = () => {
    const qParams = Params.serializeForm(filterFormRef.current)
    const changeParamsParams = {}

    changeParamsParams[queryQName] = qParams

    navigate(Params.updatedPathWithchangedParams(changeParamsParams))
  }

  const onFilterChangedWithDelay = useDebouncedCallback(filterResults, 250)
  const filterMethodArgs = {
    onFilterChanged: filterResults,
    onFilterChangedWithDelay,
    qParams
  }
  const onFilterFormSubmit = (e) => {
    e.preventDefault()
    filterResults()
  }
  const reloadSession = useSessionReload()

  const onResetFilterClicked = (e) => {
    e.preventDefault()

    // Get params and remove any query made there for this table
    const params = Params.parse()

    delete params[queryQName]

    // Redirect to URL without the query
    const newParams = Params.stringify(params)
    const newPath = `${location.pathname}?${newParams}`

    navigate(newPath)

    // TODO this should never be needed? - remove reload and see what specs are failing
    // Reload page to update any inputs that had filled in values
    reloadSession()
  }

  return (
    <Card className="filter-card mb-4" defaultExpanded={defaultExpanded} expandable header={I18n.t("js.global.search")} {...filterArgs}>
      <form onSubmit={onFilterFormSubmit} ref={filterFormRef}>
        {filterContent(filterMethodArgs)}
        <a className="btn btn-outline-secondary btn-sm ms-1 mt-5 reset-filter-button" href="#" onClick={onResetFilterClicked}>
          {I18n.t("js.shared.table.index.reset")}
        </a>
      </form>
    </Card>
  )
}
