import "merfoto/components/checkout/delivery/style"
import {useContext, useEffect, useState} from "react"
import CheckoutNavbar from "merfoto/components/checkout/navbar"
import Col from "react-bootstrap/Col"
import DeliveryMethodContent from "merfoto/components/order-deliveries/delivery-methods/delivery-method-content"
import MerfotoCard from "merfoto/components/card"
import OptionsContentForDeliveryMethod from "merfoto/components/order-deliveries/delivery-methods/options-content-for-delivery-method"
import Row from "react-bootstrap/Row"
import Stack from "react-bootstrap/Stack"
import {useNavigate} from "react-router-dom"
import {UserContext} from "shared/user-context"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoCheckoutDelivery() {
  const [currentDeliveryMethodOption, setCurrentDeliveryMethodOption] = useState()
  const [enabledDeliveryMethods, setEnabledDeliveryMethods] = useState()
  const [order, setOrder] = useState()
  const [orderDelivery, setOrderDelivery] = useState()
  const [currentUser] = useContext(UserContext)
  const navigate = useNavigate()
  const onSubmit = async (e) => {
    e.preventDefault()

    const form = e.target
    const formData = new FormData(form)

    try {
      await order.updateDelivery(formData)
      FlashMessage.success(I18n.t("js.merfoto.checkout.delivery.your_information_was_saved"))
      navigate(MerfotoRoutes.checkoutConfirmPath())
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
  const onDeliveryMethodChecked = (deliveryMethod, args) => {
    setCurrentDeliveryMethodOption({deliveryMethod, ...args})
  }

  useTitle(I18n.t("js.merfoto.checkout.delivery.delivery"))

  useEffect(() => {
    async function loadOrderOrRedirect() {
      const order = await Order
        .ransack({
          customer_user_id_eq: currentUser.id(),
          state_eq: "basket"
        })
        .preload(["invoice_address", "order_delivery.delivery_address", "order_delivery.order_delivery_shipmondo"])
        .select({
          Order: ["id", "invoiceAddressId", "state"],
          OrderDelivery: ["deliveryAddressId", "deliveryMethod", "id"],
          OrderDeliveryShipmondo: ["id", "pickupPointIdentifier", "shipmentTemplateIdentifier"]
        })
        .first()

      let orderDelivery

      if (order?.orderDelivery()) {
        orderDelivery = order.orderDelivery()
      } else {
        orderDelivery = new OrderDelivery()
      }

      if (order) {
        setCurrentDeliveryMethodOption({deliveryMethod: orderDelivery.deliveryMethod()})
        setOrder(order)
        setOrderDelivery(orderDelivery)
      } else {
        navigate(MerfotoRoutes.basketPath(), {replace: true})

        return
      }

      const response = await order.deliveryMethods()
      const deliveryMethods = response.delivery_methods

      setEnabledDeliveryMethods(deliveryMethods)
    }

    if (currentUser) {
      loadOrderOrRedirect()
    }
  }, [currentUser, navigate])

  return (
    <div className="merfoto-routes-checkout-delivery">
      {enabledDeliveryMethods && order &&
        <div className="content-container">
          <CheckoutNavbar activeIdentifier="delivery" className="mb-4" order={order} />
          <MerfotoCard.Outer>
            <MerfotoCard.Header>
              {I18n.t("js.merfoto.checkout.delivery.delivery")}
            </MerfotoCard.Header>
            <MerfotoCard.Body>
              <form onSubmit={onSubmit}>
                <input
                  name="order[order_delivery_attributes][delivery_method]"
                  type="hidden"
                  value={currentDeliveryMethodOption.deliveryMethod || ""}
                />
                {orderDelivery.isPersisted() &&
                  <input name="order[order_delivery_attributes][id]" type="hidden" value={digg(orderDelivery.id())}/>
                }
                <div className="checkout-delivery-container">
                  <Row>
                    <Col className="overflow-auto" md={6}>
                      {enabledDeliveryMethods.map((deliveryMethod, index) =>
                        <OptionsContentForDeliveryMethod
                          className={classNames({"mt-2": index > 0})}
                          currentDeliveryMethodOption={currentDeliveryMethodOption}
                          deliveryMethod={deliveryMethod}
                          key={deliveryMethod}
                          onChecked={onDeliveryMethodChecked}
                          order={order}
                          orderDelivery={orderDelivery}
                        />
                      )}
                    </Col>
                    <Col className="overflow-hidden" md={6}>
                      <Stack direction="vertical" gap={3}>
                        <SubmitButton block label={I18n.t("js.global.continue")} />
                        {currentDeliveryMethodOption.deliveryMethod &&
                          <DeliveryMethodContent
                            currentDeliveryMethodOption={currentDeliveryMethodOption}
                            order={order}
                            orderDelivery={orderDelivery}
                          />
                        }
                        {!currentDeliveryMethodOption.deliveryMethod &&
                          <div className="fst-italic no-delivery-method-content">
                            {I18n.t("js.merfoto.checkout.delivery.choose_delivery_method_to_the_left")}
                          </div>
                        }
                      </Stack>
                    </Col>
                  </Row>
                </div>
              </form>
            </MerfotoCard.Body>
          </MerfotoCard.Outer>
        </div>
      }
    </div>
  )
}
