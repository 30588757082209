import "./style"

export default class MerfotoOrderDeliveriesDeliveryMethodsShipmondoPickupPoints extends React.Component {
  static propTypes = {
    checked: PropTypes.bool.isRequired,
    grey: PropTypes.bool.isRequired,
    onChecked: PropTypes.func.isRequired,
    pickupPoint: PropTypes.shape({
      address: PropTypes.string.isRequired,
      identifier: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      zip_code: PropTypes.string.isRequired
    })
  }

  render() {
    const {checked, className, grey, onChecked, pickupPoint, ...restProps} = this.props
    const {address, city, identifier, name, zip_code: zipCode} = digs(pickupPoint, "address", "city", "identifier", "name", "zip_code")

    return (
      <div
        className={classNames("form-check", "merfoto-components-order-deliveries-delivery-methods-shipmondo-pickup-point", className)}
        data-grey={grey}
        data-identifier={identifier}
        {...restProps}
      >
        <div className="p-2">
          <input
            checked={checked}
            className="form-check-input"
            id={`pickup-point-${identifier}`}
            onChange={this.onChecked}
            type="radio"
          />
          <label className="form-check-label" htmlFor={`pickup-point-${identifier}`}>
            <div className="pickup-point-name fw-bold">
              {name}
            </div>
            <div className="pickup-point-zip-code-and-city">
              {zipCode} {city}
            </div>
            <div className="pickup-point-address">
              {address}
            </div>
          </label>
        </div>
      </div>
    )
  }

  onChecked = () => {
    const {onChecked, pickupPoint} = digs(this.props, "onChecked", "pickupPoint")

    onChecked(pickupPoint)
  }
}
