import Preview from "components/preview"

function ActiveStorageAttachmentsPreview(props) {
  ActiveStorageAttachmentsPreview.propTypes = {
    attachment: PropTypes.instanceOf(ActiveStorageAttachment).isRequired
  }
  const {attachment, ...restProps} = props
  const blob = attachment.blob()

  if (!blob) throw new Error(`Couldn't read blob for ActiveStorage attachment ${attachment.id()}`)

  return (
    <Preview type={blob.contentType()} url={attachment.url()} {...restProps} />
  )
}

export default ActiveStorageAttachmentsPreview
