export default class HashToCollection {
  static toCollectionArray(hash) {
    const converter = new HashToCollection(hash)

    return converter.toCollectionArray()
  }

  static modelsToCollectionArray(models) {
    const collection = []

    for (const key in models) {
      const model = models[key]

      collection.push({label: model.name(), value: model.id()})
    }

    return collection
  }

  constructor(hash) {
    this.hash = hash
  }

  toCollectionArray() {
    const collection = []

    for (const key in this.hash) {
      const value = this.hash[key]

      collection.push([key, value])
    }

    return collection
  }

  static toOptionsArray(hash) {
    const collection = []

    for (const label in hash) {
      const value = hash[label]

      collection.push({label, value})
    }

    return collection
  }
}
