import "./style"

export default class SuggestionsInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    component: PropTypes.func,
    onSuggest: PropTypes.func.isRequired
  }

  inputRef = React.createRef()

  state = ({
    focusSuggestionIndex: undefined,
    showSuggestions: false,
    suggestions: undefined
  })

  render() {
    const {className, component, onSuggest, ...restProps} = this.props
    const {focusSuggestionIndex, showSuggestions, suggestions} = digs(this.state, "focusSuggestionIndex", "showSuggestions", "suggestions")
    const InputComponent = component || Input

    return (
      <div className="nemoa-components-suggestions-input-container" ref="rootElement">
        <EventListener event="mouseup" onCalled={this.onWindowMouseUp} target={window} />
        <InputComponent
          autoComplete="off"
          className={classNames("nemoa-components-suggestions-input", className)}
          inputRef={digg(this, "inputRef")}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          {...restProps}
        />
        {showSuggestions && suggestions &&
          <div className="suggestions-outer-container">
            <div className="suggestions-absolute-container">
              {suggestions.map((suggestion, index) =>
                <div
                  className="suggestion-container"
                  data-focus={index == focusSuggestionIndex}
                  data-suggestion={suggestion}
                  key={`suggestion-${suggestion}`}
                  onClick={() => this.onSuggestionClicked(suggestion)}
                >
                  {suggestion}
                </div>
              )}
            </div>
          </div>
        }
      </div>
    )
  }

  hideSuggestions() {
    this.setState({
      focusSuggestionIndex: undefined,
      showSuggestions: false
    })
  }

  moveFocusSuggestionDown() {
    const {focusSuggestionIndex, suggestions} = digs(this.state, "focusSuggestionIndex", "suggestions")

    if (!suggestions) {
      // Suggestions haven't been loaded - abort
      return
    }

    let newFocusSuggestionIndex

    if (focusSuggestionIndex === undefined) {
      newFocusSuggestionIndex = 0
    } else {
      newFocusSuggestionIndex = focusSuggestionIndex + 1
    }

    if (newFocusSuggestionIndex >= suggestions.length) {
      newFocusSuggestionIndex = suggestions.length - 1
    }

    this.setState({focusSuggestionIndex: newFocusSuggestionIndex})
  }

  moveFocusSuggestionUp() {
    const {focusSuggestionIndex, suggestions} = digs(this.state, "focusSuggestionIndex", "suggestions")

    if (!suggestions) {
      // Suggestions haven't been loaded - abort
      return
    }

    let newFocusSuggestionIndex

    if (focusSuggestionIndex === undefined) {
      newFocusSuggestionIndex = suggestions.length - 1
    } else {
      newFocusSuggestionIndex = focusSuggestionIndex - 1
    }

    if (newFocusSuggestionIndex < 0) {
      newFocusSuggestionIndex = 0
    }

    this.setState({focusSuggestionIndex: newFocusSuggestionIndex})
  }

  onClick = () => {
    const {showSuggestions, suggestions} = digs(this.state, "showSuggestions", "suggestions")

    if (suggestions && !showSuggestions) {
      this.setState({showSuggestions: true})
    }
  }

  onKeyDown = (e) => {
    const input = digg(e, "target")
    const value = digg(input, "value")
    const key = digg(e, "key")

    if (key == "ArrowDown") {
      this.moveFocusSuggestionDown()
    } else if (key == "ArrowUp") {
      this.moveFocusSuggestionUp()
    } else if (key == "Enter") {
      this.onEnterPressed(input)
    } else if (key == "Escape") {
      this.hideSuggestions()
    } else {
      this.updateSuggestionsDebounce(value)
    }
  }

  onEnterPressed = (input) => {
    const {focusSuggestionIndex, suggestions} = digs(this.state, "focusSuggestionIndex", "suggestions")
    const focussedSuggestion = digg(suggestions, focusSuggestionIndex)

    input.value = focussedSuggestion
    this.hideSuggestions()
    this.setState({
      suggestions: undefined
    })
  }

  onSuggestionClicked = (suggestion) => {
    digg(this, "inputRef", "current").value = suggestion
    this.hideSuggestions()
  }

  onWindowMouseUp = (e) => {
    if (!this.state.showSuggestions) {
      return
    }

    if (this.refs.rootElement.contains(e.target)) {
      return
    }

    this.hideSuggestions()
  }

  updateSuggestions = async (value) => {
    const {onSuggest} = digs(this.props, "onSuggest")
    const suggestions = await onSuggest({value})

    this.setState({
      showSuggestions: true,
      suggestions
    })
  }

  updateSuggestionsDebounce = debounce(this.updateSuggestions, 250)
}
