AdminLayoutBottomBarSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default function AdminLayoutBottomBarSection(props) {
  const {children, className, ...restProps} = props

  return (
    <>
      <div className="admin-components-layout-bottom-bar-section section-divider" />
      <div className={classNames("admin-components-layout-bottom-bar-section section-content align-self-center", className)} {...restProps}>
        {children}
      </div>
    </>
  )
}
