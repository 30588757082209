import {selector} from "recoil"
import {tenantAtom} from "../atoms/tenant-atom"

const tenantContactSettingSelector = selector({
  key: "tenantContactSettingSelector",
  get: async ({get}) => {
    const tenant = get(tenantAtom)

    return await TenantContactSetting.ransack({tenant_id_eq: tenant.id()}).preload("address").first()
  }
})

export {tenantContactSettingSelector}
