import SuggestionsInput from "nemoa/components/suggestions-input"

export default class AdminTaggingsTaggableForm extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    model: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onSaved: PropTypes.func
  }

  state = ({
    tag: new Tag()
  })

  render() {
    const {className, model, onSave, onSaved, ...restProps} = this.props
    const {tag} = digs(this.state, "tag")

    return (
      <div className={classNames("admin-components-taggings-taggable-form", className)} {...restProps}>
        <form onSubmit={this.onSubmit}>
          <SuggestionsInput
            attribute="name"
            component={BootstrapInput}
            model={tag}
            onSuggest={this.onSuggest}
            placeholder={I18n.t("js.admin.taggings.taggable.form.type_to_search_or_create_a_new_tag")}
          />
          <SubmitButton className="save-tagging-button" label={I18n.t("js.global.save")} />
        </form>
      </div>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const tagName = formData.get("tag[name]")
    const tagNameInputElement = digg(form, "tag[name]")
    const {model} = digs(this.props, "model")
    const {onSave, onSaved} = this.props

    if (onSave) {
      onSave({tagName})
    } else {
      const resourceName = digg(model.modelClassData(), "name")
      const tags = {}

      tags[tagName] = {}
      tags[tagName][resourceName] = [model.id()]

      try {
        await Tagging.tagModels({tags})
        FlashMessage.success(I18n.t("js.admin.taggings.taggable.form.the_model_was_tagged"))
        tagNameInputElement.value = ""

        if (onSaved) {
          onSaved()
        }
      } catch (error) {
        FlashMessage.errorResponse(error)
      }
    }
  }

  async onSuggest({value}) {
    const tags = await Tag
      .ransack({
        name_cont_any_word: value,
        s: "name"
      })
      .select({Tag: ["id", "name"]})
      .selectColumns({Tag: ["id", "name"]})
      .toArray()

    return tags.map((tag) => tag.name())
  }
}
