import ActiveStorageAttachmentPreview from "nemoa/components/active-storage-attachments/preview"
import AttributePresenter from "components/attribute-presenter"
import Card from "merfoto/components/card"
import DestroyOrderLineButton from "merfoto/components/basket/destroy-order-line-button"
import photoLabel from "shared/photos/photo-label"
import Quantity from "merfoto/components/basket/quantity"
import SalesPrice from "merfoto/components/basket/sales-price"

export default class MerfotoBasketItemCardsOrderLinePhoto extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    orderLine: PropTypes.instanceOf(OrderLine).isRequired
  }

  render() {
    const {className, editable, orderLine, ...restProps} = this.props
    const orderLinePhoto = orderLine.orderLinePhoto()

    if (!orderLinePhoto) {
      throw new Error("No matching order line photo")
    }

    return (
      <Card.Outer className={classNames("merfoto-components-basket-item-cards-order-line-photo", className)} {...restProps}>
        <Card.Header>
          <div className="d-flex">
            <div className="flex-grow-1 photo-product-name-label">
              {orderLinePhoto.tenantPhotoProduct().photoProduct().name()}
            </div>
            <div className="align-items-center d-flex m-2 quantity-container">
              {editable && orderLine.can("destroy", orderLine) &&
                <DestroyOrderLineButton className="me-2" orderLine={orderLine} />
              }
              <Quantity editable={editable} orderLine={orderLine} />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="d-flex">
            {orderLinePhoto.photo() &&
              <ActiveStorageAttachmentPreview
                attachment={orderLinePhoto.photo().attachmentAttachment()}
                className="photo-preview"
                data-photo-id={orderLinePhoto.photo().id()}
                key={orderLinePhoto.photo().id()}
                previewOnHover={false}
                showOpenLink={false}
                thumbnailUrl={orderLinePhoto.photo().thumbnailUrl()}
                url={orderLinePhoto.photo().fullSizeUrl()}
              />
            }
            <div className="ms-2">
              {orderLinePhoto.photo() &&
                <AttributePresenter
                  className="photo-label"
                  label={I18n.t("js.merfoto.basket.basket_item_card.index.photo")}
                  upperCaseLabel
                  value={photoLabel(orderLinePhoto.photo())}
                />
              }
            </div>
            {!orderLinePhoto.photo() &&
              "placeholder for no photo"
            }
          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <SalesPrice orderLine={orderLine} />
        </Card.Footer>
      </Card.Outer>
    )
  }
}
