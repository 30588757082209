export default class AddressesCountrySelect extends React.Component {
  static propTypes = {
    className: PropTypes.string
  }

  render() {
    const {className, ...restProps} = this.props

    return (
      <NemoaSelect
        className={classNames("components-addresses/country-select", className)}
        options={HashToCollection.toCollectionArray(translatedCollection("Address", "countries"))}
        {...restProps}
      />
    )
  }
}
