import {useContext, useEffect, useState} from "react"
import CheckoutNavbar from "merfoto/components/checkout/navbar"
import MerfotoCard from "merfoto/components/card"
import {useParams} from "react-router-dom"
import {UserContext} from "shared/user-context"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoCheckoutOrderCompleted() {
  const {id: orderId} = useParams()
  const [order, setOrder] = useState()
  const [currentUser] = useContext(UserContext)

  useTitle(I18n.t("js.merfoto.checkout.completed.checkout_completed"))

  useEffect(() => {
    async function loadOrder() {
      const order = await Order
        .ransack({
          id_eq: orderId
        })
        .preload("order_delivery")
        .select({
          Order: ["anyDigitalProducts", "expectedDeliveryAt", "id", "orderNumber", "state"],
          OrderDelivery: ["deliveryMethod", "id"]
        })
        .first()

      setOrder(order)
    }

    if (currentUser) {
      loadOrder()
    }
  }, [orderId, currentUser])

  return (
    <div className="merfoto-routes-checkout-completed">
      {order &&
        <div className="content-container">
          <CheckoutNavbar activeIdentifier="completed" className="mb-4" order={order} />
          <MerfotoCard.Outer>
            <MerfotoCard.Header>
              {I18n.t("js.merfoto.checkout.completed.thank_you_your_checkout_was_completed")}
            </MerfotoCard.Header>
            <MerfotoCard.Body className="order-confirmation-content">
              <Choose>
                <When condition={order.orderDelivery().deliveryMethod() === "digital_download"}>
                  {I18n.t("js.merfoto.checkout.completed.your_digital_order_was_completed_with_order_number", {order_number: order.orderNumber()})}
                </When>
                <Otherwise>
                  {I18n.t("js.merfoto.checkout.completed.your_order_was_completed_with_order_number", {
                    expected_delivery_at: I18n.l("date.formats.default", order.expectedDeliveryAt()),
                    order_number: order.orderNumber()
                  })}
                </Otherwise>
              </Choose>
              {order.anyDigitalProducts() &&
                <div>
                  {I18n.t("js.merfoto.checkout.completed.you_can_download_the_photos_under", {order_number: order.orderNumber()})}
                  <Link className="download-link ms-1" to={MerfotoRoutes.downloadsPath()}>
                    {I18n.t("js.merfoto.checkout.completed.downloads")}
                  </Link>.
                </div>
              }
            </MerfotoCard.Body>
          </MerfotoCard.Outer>
        </div>
      }
    </div>
  )
}
