export default class ButtonsTableView extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    link: PropTypes.bool,
    title: PropTypes.string,
    to: PropTypes.string.isRequired
  })

  render() {
    const {link, title, to} = this.props

    if (link) {
      return (
        <Link className={this.className()} title={title || I18n.t("js.global.view")} to={to}>
          <Icon icon="eye" size="small" />
        </Link>
      )
    }

    return (
      <a className={this.className()} href={to} title={title || I18n.t("js.global.view")}>
        <Icon icon="eye" size="small" />
      </a>
    )
  }

  className() {
    const classNames = ["components-buttons-table-view", "btn", "btn-action"]

    if (this.props.className) {
      classNames.push(this.props.className)
    }

    return classNames.join(" ")
  }
}
