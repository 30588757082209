import "./style"
import Card from "merfoto/components/card"
import Col from "react-bootstrap/Col"
import PhotoCard from "./photo-card"
import photosQueryFromPhotoSessionGroupContact from "shared/photos/photos-query-from-photo-session-group-contact"
import Row from "react-bootstrap/Row"
import SubHeaderLabel from "merfoto/components/photo-session-groups/sub-header-label"

export default class MerfotoPhotoSessionGroupsCard extends React.Component {
  state = {
    photos: undefined
  }

  static propTypes = {
    allowDownloadOfPhotos: PropTypes.bool,
    className: PropTypes.string,
    photoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact).isRequired,
    photoSessionGroupContactTokenContact: PropTypes.instanceOf(PhotoSessionGroupContactTokenContact),
    tenant: PropTypes.instanceOf(Tenant).isRequired
  }

  componentDidMount() {
    this.loadPhotos()
  }

  async loadPhotos() {
    const {photoSessionGroupContact, tenant} = this.props
    const photos = await photosQueryFromPhotoSessionGroupContact(photoSessionGroupContact.id())
      .ransack({
        tenant_id_eq: tenant.id()
      })
      .select({
        ActiveStorageAttachment: ["id", "url"],
        ActiveStorageBlob: ["contentType"],
        Photo: ["contentType", "fullSizeUrl", "id", "thumbnailUrl", "url"]
      })
      .preload(["attachment_attachment.blob"])
      .toArray()

    this.setState({
      photos
    })
  }

  render() {
    const {allowDownloadOfPhotos, className, photoSessionGroupContact, photoSessionGroupContactTokenContact, tenant, ...restProps} = this.props
    const {photos} = this.state

    return (
      <Card.Outer
        className={classNames("merfoto-components-photo-session-groups-card", className)}
        data-photo-session-group-contact-id={photoSessionGroupContact.id()}
        {...restProps}
      >
        <Card.Header>
          {photoSessionGroupContact.photoSessionGroup().name()}; {photoSessionGroupContact.contact().name()}
        </Card.Header>
        <Card.SubHeader className="d-flex">
          <SubHeaderLabel
            className="starts-at-label me-4"
            icon="calendar-plus"
            label={I18n.l("date.formats.default", photoSessionGroupContact.photoSessionGroup().photoSession().startsAt())}
          />
          <SubHeaderLabel
            icon="images"
            label={photos?.length}
          />
          {allowDownloadOfPhotos &&
            <SubHeaderLabel
              className="download-zip-file-link ms-4"
              icon="file-archive"
              label={I18n.t("js.global.download")}
              onClick={this.onDownloadZipFileClicked}
            />
          }
          {photoSessionGroupContactTokenContact &&
            <SubHeaderLabel
              actions={[
                {
                  className: "destroy-photo-session-group-contact-token-contact-button",
                  key: "destroy-action",
                  label: I18n.t("js.merfoto.photo_session_groups.card.index.remove_access"),
                  onClick: (e) => this.onDestroyTokenClicked(e, photoSessionGroupContactTokenContact)
                }
              ]}
              className="ms-4"
              icon="key"
              label={I18n.t("js.merfoto.photo_session_groups.card.index.added_through_token")}
            />
          }
        </Card.SubHeader>
        <Card.Body>
          <Row className="g-2">
            {photos?.map((photo) =>
              <Col key={photo.id()} lg={4} md={6} sm={12}>
                <PhotoCard allowDownloadOfPhotos={allowDownloadOfPhotos} photo={photo} photoSessionGroupContact={photoSessionGroupContact} />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card.Outer>
    )
  }

  async onDestroyTokenClicked(e, photoSessionGroupContactTokenContact) {
    if (!await CustomConfirm.confirm()) {
      return
    }

    try {
      await photoSessionGroupContactTokenContact.destroy()
      FlashMessage.success(I18n.t("js.merfoto.photo_session_groups.card.index.the_access_through_the_token_was_destroyed"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onDownloadZipFileClicked = async (e) => {
    e.preventDefault()

    const {photoSessionGroupContact} = this.props
    const response = await photoSessionGroupContact.generateZipFile()
    const job = digg(response, "job")

    FlashMessage.component({
      component: <JoblerJobStatus job={job} />,
      timeout: false
    })
  }
}
