import "./style"

export default class MerfotoComponentsProductCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    footerContent: PropTypes.node,
    header: PropTypes.string,
    tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct).isRequired
  }

  render() {
    const {children, className, footerContent, header, tenantPhotoProduct, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-product-card", className)} {...restProps}>
        {header &&
          <div className="product-card-header">
            {header}
          </div>
        }
        {children}
        <div className="align-items-center d-flex mt-4 product-card-footer">
          {footerContent &&
            <div className="product-card-footer-footer-content">
              {footerContent}
            </div>
          }
          <div className="ms-auto">
            <span className="product-card-footer-price">
              {MoneyFormatter.format(tenantPhotoProduct.salesPriceWithVat())}
            </span>
            <span className="product-card-footer-currency">
              kr.
            </span>
          </div>
        </div>
      </div>
    )
  }
}
