import "./style"

SharedModal.defaultProps = {
  size: "small"
}

SharedModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  footer: PropTypes.node,
  onRequestClose: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["medium", "small", "fill"]),
  title: PropTypes.string
}

export default function SharedModal({children, className, footer, onRequestClose, size, title, ...restProps}) {
  const onBackDropMouseDown = (e) => {
    // Only react to clicks directly on the back-drop and not on elements above the back-drop
    if (!e.target.classList.contains("components-shared-modal")) {
      return
    }

    onRequestClose()
  }
  const onHeaderCloseButtonClicked = (e) => {
    e.preventDefault()

    onRequestClose()
  }

  return (
    <div
      className={classNames("align-items-center", "components-shared-modal", "d-flex", className)}
      data-size={size}
      onMouseDown={onBackDropMouseDown}
      {...restProps}
    >
      <div className="content">
        {title &&
          <div className="content-header">
            <div className="header-title">
              {title}
            </div>
            <a className="header-close-button" href="#" onClick={onHeaderCloseButtonClicked}>
              <i className="la la-close" />
            </a>
          </div>
        }
        <div className="content-body">
          {children}
        </div>
        {footer &&
          <div className="content-footer">
            {footer}
          </div>
        }
      </div>
    </div>
  )
}
