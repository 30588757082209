import "./style"

export default class MerfotoCardHeader extends React.Component {
  static propTypes = {
    actions: PropTypes.node,
    className: PropTypes.string,
    children: PropTypes.node.isRequired
  }

  render() {
    const {actions, children, className, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-card-header", "p-2", className)} {...restProps}>
        <div className="d-flex">
          <div className="header-content flex-grow-1">
            {children}
          </div>
          {actions &&
            <div>
              {actions}
            </div>
          }
        </div>
      </div>
    )
  }
}
