import consumer from "channels/consumer"
import currentRoutes from "shared/current-routes"
import getCurrentLayout from "tenants/get-current-layout"
import updateCurrentUser from "components/users/update-current-user"
import {useLayoutRedirector} from "shared/hooks/use-layout-redirector"

class UsersConfirmation extends React.Component {
  confirmationTokenRef = React.createRef()

  static defaultProps = {
    confirmed: false
  }

  static propTypes = {
    className: PropTypes.string,
    confirmed: PropTypes.bool.isRequired,
    email: PropTypes.string,
    onEmailConfirmed: PropTypes.func
  }

  state = {
    confirmationToken: undefined
  }

  componentDidMount() {
    const {confirmed} = this.props

    if (confirmed) {
      return
    }

    this.checkForConfirmationTokenInParams()

    if (this.props.email) {
      this.connectChannel()
    }
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  connectChannel() {
    const {email, onEmailConfirmed} = this.props

    this.subscription = consumer.subscriptions.create({
      channel: "EmailConfirmChannel",
      email
    }, {
      received(data) {
        if (digg(data, "confirmed")) {
          if (onEmailConfirmed) {
            onEmailConfirmed()
          }
        }
      }
    })
  }

  checkForConfirmationTokenInParams() {
    const params = Params.parse()
    const confirmationToken = params.confirmation_token

    this.setState({confirmationToken})

    if (confirmationToken) {
      this.confirmEmail(confirmationToken)
    }
  }

  confirmEmail = async (confirmationToken) => {
    const {onEmailConfirmed} = this.props

    try {
      const response = await Email.confirm({confirmation_token: confirmationToken})
      const user = digg(response, "user")

      FlashMessage.success(I18n.t("js.users.confirmation.your_email_was_confirmed"))
      await updateCurrentUser(user)
      if (onEmailConfirmed) {
        onEmailConfirmed()
      }
    } catch (error) {
      FlashMessage.errorResponse(error)

      if (error["errorTypes"] && error.errorTypes().includes("expired")) {
        this.setState({confirmationToken: undefined})
      }
    }
  }

  render() {
    const {confirmed} = this.props
    const {confirmationToken} = this.state
    const {confirmationTokenRef} = digs(this, "confirmationTokenRef")

    return (
      <div className="components-users-confirmation">
        <div className="content-container">
          <h1>{I18n.t("js.users.confirmation.email_confirmation")}</h1>
          {confirmed &&
            <>
              <div className="email-confirmed-text">
                {I18n.t("js.users.confirmation.your_email_was_confirmed")}
              </div>
              <Button className="continue-button" large onClick={this.onContinueClicked} primary>
                {I18n.t("js.users.confirmation.click_to_continue")}
              </Button>
            </>
          }
          {!confirmed && confirmationToken && I18n.t("js.users.confirmation.please_wait_while_your_email_is_confirmed")}
          {!confirmationToken &&
            <form onSubmit={this.onSubmit}>
              <div className="mb-2">
                {I18n.t("js.users.confirmation.index.enter_the_token_that_you_received_per_email_below_or_request_a_new_by_clicking_the_link_below_the_form")}
              </div>
              <BootstrapInput
                id="email_confirmation_token"
                inputRef={confirmationTokenRef}
                name="confirmation_token"
                placeholder={I18n.t("js.users.confirmation.index.enter_confirmation_token")}
              />
              <div className="align-items-center d-flex flex-column">
                <SubmitButton block className="mb-2 submit-confirmation-token-button" label={I18n.t("js.users.confirmation.index.confirm_email")} />
                <Link className="didnt-receive-confirmation-email-link" to={currentRoutes().newUserConfirmationPath()}>
                  {I18n.t("js.users.confirmation.index.didnt_receive_confirmation_email")}
                </Link>
              </div>
            </form>
          }
        </div>
      </div>
    )
  }

  onSubmit = (e) => {
    e.preventDefault()

    const {confirmationTokenRef} = digs(this, "confirmationTokenRef")

    this.confirmEmail(digg(confirmationTokenRef, "current", "value"))
  }

  onContinueClicked = () => {
    this.props.router.layoutRedirector(getCurrentLayout(), "reactRoot")
  }
}

export default function WrappedUsersConfirmation(props) {
  const layoutRedirector = useLayoutRedirector()

  return (
    <UsersConfirmation router={{layoutRedirector}} {...props} />
  )
}
