import {SessionStatusUpdater} from "@kaspernj/api-maker"

export default async function UsersSignOut() {
  const args = {scope: "user"}

  await User.signOut()
  Devise.setSignedOut(args)
  SessionStatusUpdater.current().updateSessionStatus()
  Devise.callSignOutEvent(args)
  await CanCan.current().resetAbilities()
}
