import {useCallback, useContext} from "react"
import Button from "react-bootstrap/Button"
import OrderCard from "merfoto/components/orders/order-card"
import Spinner from "react-bootstrap/Spinner"
import Stack from "react-bootstrap/Stack"
import TotalWithDelivery from "merfoto/components/orders/total-with-delivery"
import {useNavigate} from "react-router-dom"
import {useOrder} from "shared/hooks/queries/use-order"
import {UserContext} from "shared/user-context"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoBasket() {
  const [currentUser] = useContext(UserContext)
  const {isLoading, data: order, refetch} = useOrder({currentUserId: currentUser.id()})
  const navigate = useNavigate()
  const onOrderUpdated = refetch

  useTitle(I18n.t("js.merfoto.basket.basket"))

  const onResetBasketButtonClicked = useCallback(async () => {
    if (!(await CustomConfirm.confirm(I18n.t("js.global.confirmation")))) {
      return
    }

    try {
      await order.reset()
      FlashMessage.success(I18n.t("js.merfoto.basket.your_order_was_reset"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }, [order])

  if (isLoading) {
    return (
      <Spinner animation="border" variant="primary" />
    )
  }

  if (!order || order.orderLinesCount() === 0) {
    return (
      <div className="merfoto-routes-basket">
        <div className="your-basket-is-empty-content">
          {I18n.t("js.merfoto.basket.your_basket_is_empty")}
        </div>
      </div>
    )
  }

  return (
    <div className="merfoto-routes-basket">
      <div className="content-container">
        <EventUpdated model={order} onUpdated={onOrderUpdated} />
        {order.orderLines().loaded().map((orderLine) =>
          <React.Fragment key={orderLine.id()}>
            <EventDestroyed model={orderLine} onDestroyed={onOrderUpdated} />
            <EventUpdated model={orderLine} onUpdated={onOrderUpdated} />
          </React.Fragment>
        )}
        <div className="row">
          <div className="col-md-6">
            <OrderCard data-cache-key={order.id()} order={order}/>
            <TotalWithDelivery className="mb-3" order={order} />
          </div>
          {order.orderLines().loaded().length > 0 &&
            <div className="col-md-6">
              <Stack gap={3}>
                <Button className="checkout-button" onClick={() => navigate(MerfotoRoutes.checkoutCustomerInformationPath())}>
                  {I18n.t("js.merfoto.basket.checkout")}
                </Button>
                <Button className="reset-basket-button" onClick={onResetBasketButtonClicked} variant="outline-primary">
                  {I18n.t("js.merfoto.basket.reset_basket")}
                </Button>
              </Stack>
            </div>
          }
        </div>
      </div>
    </div>
  )
}
