import "./style"
import {useContext, useEffect, useState} from "react"
import Navbar from "./navbar"
import SignedInUserButton from "./signed-in-user-button"
import useDebouncedCallback from "@restart/hooks/useDebouncedCallback"
import {useOrder} from "shared/hooks/queries/use-order"
import {UserContext} from "shared/user-context"

function BasketContainer() {
  const [currentUser] = useContext(UserContext)
  const {data: order, refetch} = useOrder({currentUserId: currentUser.id()})
  const loadOrderLater = useDebouncedCallback(refetch, 250)
  const onOrderCreated = ({model: order}) => {
    if (order.customerId() === currentUser.contact()?.id() && order.state() === "basket") {
      loadOrderLater()
    }
  }
  const [currentContact, setCurrentContact] = useState(false)
  const basketCount = order?.orderLinesCount() || 0
  const basketLabel = `${I18n.t("js.merfoto.layout.topbar.index.basket")} (${basketCount})`
  const onOrderLineCreated = ({model: orderLine}) => {
    if (orderLine.orderId() === order.id()) {
      loadOrderLater()
    }
  }

  useEffect(() => {
    async function loadCurrentUserContact() {
      await currentUser.loadContact()

      setCurrentContact(true)
    }

    if (currentUser) {
      loadCurrentUserContact()
    } else {
      setCurrentContact(undefined)
    }
  }, [currentUser])

  return (
    <div className="basket-container">
      {!order && currentContact &&
        <EventCreated modelClass={Order} onCreated={onOrderCreated} />
      }
      {order &&
        <>
          <EventCreated modelClass={OrderLine} onCreated={onOrderLineCreated} />
          <EventUpdated model={order} onUpdated={loadOrderLater} />
        </>
      }
      {order?.orderLines()?.loaded()?.map((orderLine) =>
        <EventDestroyed key={orderLine.id()} model={orderLine} onDestroyed={loadOrderLater} />
      )}
      {currentUser &&
        <Link className="basket-link ms-2 ms-md-0 top-bar-link" to={MerfotoRoutes.basketPath()}>
          <span className="basket-label d-sm-inline d-none me-2">
            {basketLabel}
          </span>
          <Icon icon="shopping-cart" size="medium" />
        </Link>
      }
    </div>
  )
}

export default function LayoutTopBar({className}) {
  const [currentUser] = useContext(UserContext)
  const [showLeftMenu, setShowLeftMenu] = useState(false)
  const onToggleLeftMenuClicked = (e) => {
    e.preventDefault()

    setShowLeftMenu(!showLeftMenu)
  }

  return (
    <div
      className={classNames(
        "align-items-center",
        "merfoto-components-layout-top-bar",
        "d-flex",
        "flex-md-nowrap",
        "flex-wrap",
        "justify-content-between",
        "mb-4",
        className
      )}
    >
      <Icon
        className="d-md-none left-menu-toggle ms-2 order-1 p-2"
        data-show-left-menu={showLeftMenu}
        icon="bars"
        onClick={onToggleLeftMenuClicked}
        size="medium"
      />
      <div className="flex-shrink my-merfoto-logo ms-3 order-2" />
      <div
        className="flex-grow-1 flex-nowrap flex-md-row flex-column justify-content-center menu-container nav order-md-3 order-4"
        data-show-left-menu={showLeftMenu}
      >
        {currentUser &&
          <Navbar />
        }
      </div>
      <div className="d-flex flex-sm-column flex-row me-2 order-md-4 order-3 right-container text-end">
        {currentUser &&
          <>
            <SignedInUserButton />
            <BasketContainer/>
          </>
        }
      </div>
    </div>
  )
}
