import "./style"

export default class AccordionBellowHeader extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    expanded: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    headerIcon: PropTypes.string,
    identifier: PropTypes.string.isRequired,
    onCollapseClicked: PropTypes.func.isRequired,
    onExpandClicked: PropTypes.func.isRequired
  }

  render() {
    const {className, expanded, header, headerIcon, identifier, onCollapseClicked, onExpandClicked, ...restProps} = this.props

    return (
      <div
        className={classNames("align-items-center", "components-accordion-bellow-header", "d-flex", "p-2")}
        data-identifier={identifier}
        onClick={this.onHeaderClicked}
        {...restProps}
      >
        {headerIcon &&
          <Icon className="me-2" icon={headerIcon} size="small" />
        }
        <div>{header}</div>
        {expanded &&
          <Icon className="ms-auto" icon="angle-up" size="small" />
        }
        {!expanded &&
          <Icon className="ms-auto" icon="angle-down" size="small" />
        }
      </div>
    )
  }

  onHeaderClicked = () => {
    const {expanded, identifier, onCollapseClicked, onExpandClicked} = digs(this.props, "expanded", "identifier", "onCollapseClicked", "onExpandClicked")

    if (expanded) {
      onCollapseClicked()
    } else {
      onExpandClicked(identifier)
    }
  }
}
