import "./style"

export default class MerfotoCardBody extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
  }

  render() {
    const {children, className, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-card-body", "p-2", className)} {...restProps}>
        {children}
      </div>
    )
  }
}
