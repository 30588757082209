import "./style"
import BlockUiLayoutComponent from "components/block-ui/layout-component"
import Footer from "./footer"
import {Outlet} from "react-router-dom"
import Spinner from "react-bootstrap/Spinner"
import {Suspense} from "react"
import TopBar from "./top-bar"

MerfotoLayout.defaultProps = {
  contentCentered: false,
  enforceSignedIn: false
}

MerfotoLayout.propTypes = PropTypesExact({
  children: PropTypes.node,
  className: PropTypes.string,
  contentCentered: PropTypes.bool.isRequired,
  enforceSignedIn: PropTypes.bool.isRequired,
  navbarActive: PropTypes.string
})

export default function MerfotoLayout({className, contentCentered}) {
  return (
    <div className={classNames("merfoto-components-layout", "d-flex", "flex-column", className)}>
      <BlockUiLayoutComponent />
      <TopBar className="mb-4"/>
      <main
        className={classNames("container", "flex-fill", "main-container", {
          "align-items-center": contentCentered,
          "d-flex": contentCentered,
          "flex-column": contentCentered
        })}
      >
        <Suspense fallback={<Spinner animation="border" variant="primary" />}>
          <Outlet />
        </Suspense>
      </main>
      <Suspense fallback={<Spinner animation="border" variant="primary" />}>
        <Footer className="mt-4" />
      </Suspense>
    </div>
  )
}
