import "./style"

export default class MerfotoCardOuter extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
  }

  render() {
    const {children, className, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-card-outer", className)} {...restProps}>
        {children}
      </div>
    )
  }
}
