import "./style"
import currentWorkplace from "shared/workplaces/current"
import Modal from "components/modal"

export default class AdminWorkplacesBottomBarAddRelationshipToWorkplaceModal extends React.Component {
  static propTypes = PropTypesExact({
    modelClass: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired
  })

  state = {
    currentPath: [],
    relationships: []
  }

  componentDidMount() {
    this.loadAttributesAndRelationshipsOnCurrentPath()
  }

  async loadAttributesAndRelationshipsOnCurrentPath() {
    const {modelClass} = this.props
    const {currentPath} = this.state
    const path = currentPath.map((path) => digg(path, "name"))
    const result = await ModelSearchRule.scanRelationship({
      model_class: digg(modelClass.modelClassData(), "name"),
      path
    })
    const {relationships} = digs(result, "relationships")

    this.setState({relationships})
  }

  render() {
    const {modelClass, onRequestClose} = digs(this.props, "modelClass", "onRequestClose")
    const {currentPath, relationships} = this.state

    return (
      <Modal className="admin-components-workplaces-bottom-bar-add-relationship-to-workplace-modal" onRequestClose={onRequestClose}>
        <div className="current-path-container">
          <div className="d-inline-block">
            {modelClass.modelName().human()}
          </div>
          {currentPath.map((pathPart, index) =>
            <div className="d-inline-block ms-1" key={`path-part-${digg(pathPart, "name")}-${index}`}>
              - {digg(pathPart, "label")}
            </div>
          )}
        </div>
        <div className="headline mt-3">
          {I18n.t("js.admin.workplaces.bottom_bar.add_relationship_to_workplace_modal.relationships")}
        </div>
        <div className="relationships-container">
          {relationships.map((relationship, index) =>
            <div key={`relation-${index}-${digg(relationship, "name")}`}>
              <a
                className="relationship-link"
                data-relationship-name={digg(relationship, "name")}
                href="#"
                onClick={(e) => this.onRelationshipClicked(e, relationship)}
              >
                {digg(relationship, "label")}
              </a>
            </div>
          )}
        </div>
        <SubmitButton
          className="add-to-workplace-button mt-3"
          label={I18n.t("js.admin.workplaces.bottom_bar.add_relationship_to_workplace_modal.add_to_workplace")}
          onClick={this.onSubmitClicked}
        />
      </Modal>
    )
  }

  onRelationshipClicked(e, relationship) {
    e.preventDefault()

    this.setState((prevState) => ({
      currentPath: prevState.currentPath.concat([relationship])
    }), () => this.loadAttributesAndRelationshipsOnCurrentPath())
  }

  onSubmitClicked = async () => {
    const {modelClass} = this.props
    const {currentPath} = this.state
    const workplace = await currentWorkplace()

    try {
      await workplace.addRelationshipToWorkplace({
        model_class: digg(modelClass.modelClassData(), "name"),
        path: currentPath
      })
      FlashMessage.success(I18n.t("js.admin.workplaces.bottom_bar.add_relationship_to_workplace_modal.the_models_were_added"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
