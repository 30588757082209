import {AttributeNotLoadedError, Collection, NotLoadedError} from "@kaspernj/api-maker"

const resolveCurrentModel = (currentModel, pathPart) => {
  if (Array.isArray(currentModel)) {
    const values = currentModel
      .map((currentModelPart) => digg(resolveCurrentModel(currentModelPart, pathPart), "value"))
      .flat()
      .filter((value) => Boolean(value))

    return {
      isAttributeLoaded: true,
      isModelLoaded: true,
      value: values
    }
  }

  if (!(pathPart in currentModel)) {
    throw new Error(`Path part ${pathPart} not found on ${digg(currentModel.modelClassData(), "name")}`)
  }

  let newCurrentModel

  try {
    newCurrentModel = currentModel[pathPart]()
  } catch (error) {
    // This is expected since we need to refresh the models after adding a column
    if (error instanceof NotLoadedError) {
      console.log(`${pathPart} wasn't loaded on ${digg(currentModel.modelClassData(), "name")}`)

      return {
        isAttributeLoaded: false,
        isModelLoaded: false,
        value: null
      }
    } else if (error instanceof AttributeNotLoadedError) {
      console.log(`${pathPart} wasn't selected on ${digg(currentModel.modelClassData(), "name")}`)

      return {
        isAttributeLoaded: false,
        isModelLoaded: true,
        value: null
      }
    }

    throw error
  }

  if (newCurrentModel instanceof Collection) {
    if (!newCurrentModel.isLoaded()) {
      return {
        isAttributeLoaded: false,
        isModelLoaded: false,
        value: null
      }
    }

    newCurrentModel = newCurrentModel.loaded()
  }

  return {
    isAttributeLoaded: true,
    isModelLoaded: true,
    value: newCurrentModel
  }
}

export default function pathAndAttributeResolver({attribute, model, path}) {
  let currentModel = model

  for (const pathPart of path) {
    const camelizedPathPart = Inflection.camelize(digg(pathPart, "name"), true)

    const {value} = resolveCurrentModel(currentModel, camelizedPathPart)

    if (!value) {
      // The current models relationship was empty - return empty string
      return {
        isAttributeLoaded: true,
        isModelLoaded: true,
        value: null
      }
    }

    currentModel = value
  }

  const camelizedAttributeName = Inflection.camelize(attribute, true)
  const value = resolveCurrentModel(currentModel, camelizedAttributeName)

  return value
}
