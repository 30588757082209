import Card from "merfoto/components/card"
import DestroyOrderLineButton from "merfoto/components/basket/destroy-order-line-button"
import Element from "./element"
import Quantity from "merfoto/components/basket/quantity"
import SalesPrice from "merfoto/components/basket/sales-price"

export default function MerfotoBasketItemCardsOrderPhotoSheetBundleOrderLine(props) {
  MerfotoBasketItemCardsOrderPhotoSheetBundleOrderLine.propTypes = {
    className: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    orderLine: PropTypes.instanceOf(OrderLine).isRequired,
    tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct).isRequired
  }

  const {className, editable, orderLine, ...restProps} = props
  const bundleOrderLine = orderLine.bundleOrderLine()

  return (
    <Card.Outer
      className={classNames("merfoto-components-basket-item-cards-bundle-order-line", className)}
      data-bundle-order-line-id={bundleOrderLine.id()}
      {...restProps}
    >
      <Card.Header>
        <div className="d-flex w-100">
          <div className="flex-grow-1 photo-product-name-label">
            {bundleOrderLine.tenantPhotoProduct().name()}
          </div>
          {editable && orderLine.can("update") &&
            <div className="align-self-center me-2">
              <Link className="edit-order-line-button" to={MerfotoRoutes.editOrderLinePath(orderLine.id())}>
                <Icon icon="pencil-alt"/>
              </Link>
            </div>
          }
          {editable && orderLine.can("destroy") &&
            <div className="align-items-center me-2">
              <DestroyOrderLineButton orderLine={orderLine}/>
            </div>
          }
          <div>
            <Quantity editable={editable} orderLine={orderLine} />
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        {bundleOrderLine.bundleOrderLineElements().loaded().map((bundleOrderLineElement, index) =>
          <Element bundleOrderLineElement={bundleOrderLineElement} className={classNames({"mt-4": index > 0})} key={bundleOrderLineElement.id()} />
        )}
      </Card.Body>
      <Card.Footer className="d-flex justify-content-end">
        <SalesPrice orderLine={orderLine} />
      </Card.Footer>
    </Card.Outer>
  )
}
