export default class AdminUsersLink extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    user: PropTypes.instanceOf(User).isRequired
  }

  render() {
    const {children, className, user, ...restProps} = this.props

    return (
      <Link className={classNames("admin-components-users-link", className)} data-user-id={user.id()} to={AdminRoutes.userPath(user.id())} {...restProps}>
        {children || user.name()}
      </Link>
    )
  }
}
