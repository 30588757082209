import photosQueryFromPhotoSessionGroupContact from "../photos/photos-query-from-photo-session-group-contact"
import {selectorFamily} from "recoil"

export const photosSelectorFamily = selectorFamily({
  key: "photosSelectorFamily",
  get: (photoSessionGroupContactId) => async () => {
    const photos = await photosQueryFromPhotoSessionGroupContact(photoSessionGroupContactId)
      .select({
        ActiveStorageAttachment: ["id", "url"],
        ActiveStorageBlob: ["contentType"],
        Photo: ["contentType", "fullSizeUrl", "height", "id", "photoIdentifier", "photoSessionGroupContactId", "thumbnailUrl", "width"],
        PhotoType: ["id"]
      })
      .preload(["attachment_attachment.blob", "photo_types"])
      .toArray()

    return photos
  }
})
