import buildBoxesToPhotosMapping from "shared/sheet-layouts/build-boxes-to-photos-mapping"
import ProductActions from "merfoto/components/tenant-photo-products-with-photos/card/product-actions"
import ProductCard from "merfoto/components/product-card"
import SheetLayoutsPresentation from "components/sheet-layouts/presentation"
import {useMemo} from "react"

TenantPhotoProductsWithPhotosCardResourcesPhotoSheetProduct.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.instanceOf(Photo)).isRequired,
  photoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact).isRequired,
  tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct)
}

export default function TenantPhotoProductsWithPhotosCardResourcesPhotoSheetProduct({
  photos,
  photoSessionGroupContact,
  tenantPhotoProduct,
  ...restProps
}) {
  const sheetLayout = tenantPhotoProduct.photoProduct().photoSheetProduct().sheetLayout()
  const boxesToPhotosMapping = useMemo(() => buildBoxesToPhotosMapping(sheetLayout, photos), [photos, sheetLayout])
  const relevantPhotos = Object.values(boxesToPhotosMapping).filter((photo) => photo !== null)

  return (
    <ProductCard
      className={classNames("merfoto-components-tenant-photo-products-with-photos-card-resources-photo-sheet-product")}
      footerContent={
        <ProductActions
          photoSessionGroupContact={photoSessionGroupContact}
          photos={relevantPhotos}
          tenantPhotoProduct={tenantPhotoProduct}
        />
      }
      header={tenantPhotoProduct.name()}
      tenantPhotoProduct={tenantPhotoProduct}
      {...restProps}
    >
      <SheetLayoutsPresentation
        boxesToPhotosMapping={boxesToPhotosMapping}
        className="mx-auto mb-2"
        sheetLayout={sheetLayout}
        sheetSize={sheetLayout.sheetSize()}
        size={290}
      />
    </ProductCard>
  )
}
