import UsersConfirmationNew from "components/users/confirmation/new"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoRoutesUsersConfirmationNew() {
  useTitle(I18n.t("js.users.confirmations.new.send_confirmation_email_again"))

  return (
    <div className="merfoto-routes-users-confirmation-new">
      <h1>{I18n.t("js.users.confirmations.new.send_confirmation_email_again")}</h1>
      <UsersConfirmationNew />
    </div>
  )
}
