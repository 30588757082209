import {useCallback, useContext, useEffect, useState} from "react"
import CheckoutNavbar from "merfoto/components/checkout/navbar"
import Col from "react-bootstrap/Col"
import MerfotoCard from "merfoto/components/card"
import OrderCard from "merfoto/components/orders/order-card"
import Row from "react-bootstrap/Row"
import Stack from "react-bootstrap/Stack"
import TotalWithDelivery from "merfoto/components/orders/total-with-delivery"
import {useNavigate} from "react-router-dom"
import {UserContext} from "shared/user-context"
import {useTitle} from "shared/hooks/use-title"

function AddressPresenter({address, header}) {
  return (
    <div style={{cursor: "default"}}>
      <b>{header}</b>
      <div>{address.street()}</div>
      {address.placeName() &&
        <div>{address.placeName()}</div>
      }
      <div>{address.zipCode()} {address.city()}</div>
      <div>{address.translatedCountry()}</div>
    </div>
  )
}

export default function MerfotoCheckoutConfirm() {
  const [order, setOrder] = useState()
  const [currentUser] = useContext(UserContext)
  const navigate = useNavigate()
  const onSubmit = useCallback(async (e) => {
    e.preventDefault()

    const form = e.target
    const formData = new FormData(form)

    try {
      await order.updateAcceptedTerms(formData)
      FlashMessage.success(I18n.t("js.merfoto.checkout.confirm.your_information_was_saved"))
      navigate(MerfotoRoutes.checkoutPaymentPath())
    } catch (error) {
      order.parseValidationErrors(error, {form, throwValidationError: false})

      FlashMessage.errorResponse(error)
    }
  }, [navigate, order])

  useTitle(I18n.t("js.merfoto.checkout.confirm.confirm"))

  useEffect(() => {
    async function loadOrder() {
      const order = await Order
      .ransack({
        customer_user_id_eq: currentUser.id(),
        state_eq: "basket"
      })
      .preload([
        "invoice_address",
        "order_delivery.delivery_address",

        "order_lines.bundle_order_line.bundle",
        "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.photo.photo_session_group",
        "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.photo.photo_session_group_contact.contact",
        "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.sheet_layout_box",
        "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.sheet_layout.boxes",
        "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.sheet_size",
        "order_lines.bundle_order_line.tenant_photo_product",

        "order_lines.order_line_photo.photo.attachment_attachment.blob",
        "order_lines.order_line_photo.photo.photo_session_group",
        "order_lines.order_line_photo.photo.photo_session_group_contact.contact",
        "order_lines.order_line_photo.tenant_photo_product.photo_product",

        "order_lines.photo_sheet_product_order_line.boxes.photo.photo_session_group",
        "order_lines.photo_sheet_product_order_line.boxes.photo.photo_session_group_contact.contact",
        "order_lines.photo_sheet_product_order_line.boxes.sheet_layout_box",
        "order_lines.photo_sheet_product_order_line.sheet_layout.boxes",
        "order_lines.photo_sheet_product_order_line.sheet_size"
      ])
      .select({
        Address: ["city", "translatedCountry", "id", "placeName", "street", "zipCode"],
        Bundle: ["name"],
        BundleOrderLine: ["id"],
        BundleOrderLineElement: ["id", "resourceType"],
        Contact: ["name"],
        Order: [
          "acceptOfPrivacyPolicy",
          "acceptOfTermsAndConditions",
          "customerEmail",
          "customerFirstName",
          "customerLastName",
          "id",
          "invoiceAddressId",
          "priceWithVat",
          "state"
        ],
        OrderDelivery: ["priceWithVat", "productId"],
        OrderLine: ["createdAt", "id", "orderableId", "orderableType", "quantity", "totalPriceWithVat"],
        Photo: ["height", "fullSizeUrl", "id", "photoIdentifier", "photoSessionGroupId", "photoSessionGroupContactId", "thumbnailUrl", "width"],
        PhotoSessionGroup: ["id", "name"],
        PhotoSessionGroupContact: ["id"],
        PhotoSheetProductOrderLine: ["id"],
        PhotoSheetProductOrderLineBox: [
          "id",
          "photoHeightPercent",
          "photoLeftPercent",
          "photoTopPercent",
          "photoWidthPercent",
          "rotation",
          "sheetLayoutBoxId",
          "updatedAt"
        ],
        SheetLayout: ["id", "webshopName"],
        SheetLayoutBox: ["heightPercent", "id", "leftPercent", "topPercent", "widthPercent"],
        SheetSize: ["heightMm", "id", "widthMm"],
        TenantPhotoProduct: ["id", "name"]
      })
      .first()

      if (!order) {
        navigate(MerfotoRoutes.basketPath())
      }

      setOrder(order)
    }

    if (currentUser) {
      loadOrder()
    }
  }, [currentUser, navigate])

  return (
    <div className="merfoto-routes-checkout-confirm">
      {order &&
        <div className="content-container">
          <CheckoutNavbar activeIdentifier="confirm" className="mb-4" order={order} />
          <MerfotoCard.Outer>
            <MerfotoCard.Header>
              {I18n.t("js.merfoto.checkout.confirm.confirm")}
            </MerfotoCard.Header>
            <MerfotoCard.Body>
              <Row>
                <Col md={6}>
                  <Stack gap={3}>
                    <OrderCard editable={false} order={order} />
                    <TotalWithDelivery className="mb-3" order={order} />
                  </Stack>
                </Col>
                <Col md={6}>
                  <div className="mb-3" style={{cursor: "default"}}>
                    <div>{`${order.customerFirstName()} ${order.customerLastName()}`}</div>
                    <div>{order.customerEmail()}</div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <AddressPresenter address={order.invoiceAddress()} header={Order.humanAttributeName("invoiceAddress")} />
                    </div>
                    {order.orderDelivery()?.deliveryAddress() &&
                      <div className="col-6">
                        <AddressPresenter address={order.orderDelivery().deliveryAddress()} header={OrderDelivery.humanAttributeName("deliveryAddress")} />
                      </div>
                    }
                  </div>
                  <form onSubmit={onSubmit}>
                    <Stack direction="vertical" gap={3}>
                      <BootstrapCheckbox
                        attribute="acceptOfTermsAndConditions"
                        className="mb-2"
                        label={
                          <>
                            {I18n.t("js.merfoto.checkout.confirm.i_have_read_and_agree_to")}
                            <Link className="ms-1" to={MerfotoRoutes.termsAndConditionsPath()}>
                              {TenantShopSetting.humanAttributeName("termsAndConditions")}
                            </Link>
                          </>
                        }
                        model={order}
                      />
                      <BootstrapCheckbox
                        attribute="acceptOfPrivacyPolicy"
                        className="mb-2"
                        label={
                          <>
                            {I18n.t("js.merfoto.checkout.confirm.i_have_read_and_agree_to")}
                            <Link className="ms-1" to={MerfotoRoutes.cookiesAndPrivacyPolicyPath()}>
                              {I18n.t("js.merfoto.checkout.confirm.privacy_policy")}
                            </Link>
                          </>
                        }
                        model={order}
                      />
                      <SubmitButton label={I18n.t("js.merfoto.checkout.confirm.approve_and_continue")} />
                    </Stack>
                  </form>
                </Col>
              </Row>
            </MerfotoCard.Body>
          </MerfotoCard.Outer>
        </div>
      }
    </div>
  )
}
