export default class MerfotoBasketSalesPrice extends React.Component {
  static propTypes = {
    orderLine: PropTypes.instanceOf(OrderLine).isRequired
  }

  render() {
    const {className, orderLine, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-basket-sales-price", className)} {...restProps}>
        {MoneyFormatter.format(orderLine.totalPriceWithVat(), {decimals: 2, excludeCurrency: false})}
      </div>
    )
  }
}
