export default class ButtonsTableDestroy extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired
  }

  render() {
    const {className, onClick, ...restProps} = this.props

    return (
      <a
        className={classNames("components-buttons-table-destroy", "btn", "btn-action", className)}
        href="#"
        onClick={this.onClick}
        title={I18n.t("js.global.delete")}
        {...restProps}
      >
        <Icon icon="trash" size="small" />
      </a>
    )
  }

  onClick = async (e) => {
    e.preventDefault()

    const {onClick} = digs(this.props, "onClick")

    if (!await CustomConfirm.confirm(I18n.t("js.global.confirmation"))) {
      return
    }

    onClick()
  }
}
