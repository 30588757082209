import ShipmentTemplateDeliveryMethodOption from "./shipmondo-delivery-method-option"

export default class MerfotoOrderDeliveriesDeliveryMethodsShipmondo extends BaseComponent {
  static propTypes = {
    currentDeliveryMethodOption: PropTypes.object.isRequired,
    onChecked: PropTypes.func.isRequired,
    order: PropTypes.instanceOf(Order).isRequired,
    orderDelivery: PropTypes.instanceOf(OrderDelivery).isRequired
  }

  state = {
    shipmentTemplates: undefined,
    shipmentTemplatesLoaded: false
  }

  componentDidMount() {
    const {onChecked} = this.props

    this.blockUiAndLoadShipmentTemplates().then(() => {
      const {orderDelivery} = this.props

      if (orderDelivery.orderDeliveryShipmondo()) {
        const {shipmentTemplates} = this.state
        const currentShipmentTemplate = shipmentTemplates
          .find((shipmentTemplate) => digg(shipmentTemplate, "identifier") == orderDelivery.orderDeliveryShipmondo().shipmentTemplateIdentifier())

        onChecked({shipmentTemplate: currentShipmentTemplate})
      }
    })
  }

  async loadShipmentTemplates() {
    const response = await OrderDeliveryShipmondo.shipmentTemplates()
    const shipmentTemplates = digg(response, "shipment_templates")

    this.setState({shipmentTemplates, shipmentTemplatesLoaded: true})
  }

  async blockUiAndLoadShipmentTemplates() {
    await this.blockUI(async () => {
      await this.loadShipmentTemplates()
    })
  }

  render() {
    const {className, currentDeliveryMethodOption, onChecked, order, orderDelivery, ...restProps} = this.props
    const {shipmentTemplates, shipmentTemplatesLoaded} = this.state

    return (
      <div
        className={classNames("merfoto-components-order-deliveries-methods-shipmondo", "d-flex", "flex-column", "overflow-hidden", className)}
        data-shipment-templates-loaded={shipmentTemplatesLoaded}
        {...restProps}
      >
        {currentDeliveryMethodOption.deliveryMethod == "shipmondo" && currentDeliveryMethodOption.shipmentTemplate &&
          <>
            <input
              name="order[order_delivery_attributes][order_delivery_shipmondo_attributes][carrier_code]"
              type="hidden"
              value={digg(currentDeliveryMethodOption, "shipmentTemplate", "carrier_code")}
            />
            <input
              name="order[order_delivery_attributes][order_delivery_shipmondo_attributes][product_code]"
              type="hidden"
              value={digg(currentDeliveryMethodOption, "shipmentTemplate", "product_code")}
            />
            <input
              name="order[order_delivery_attributes][order_delivery_shipmondo_attributes][shipment_template_identifier]"
              type="hidden"
              value={digg(currentDeliveryMethodOption, "shipmentTemplate", "identifier")}
            />
          </>
        }
        {shipmentTemplates?.map((shipmentTemplate, index) => (
          <ShipmentTemplateDeliveryMethodOption
            className={classNames({"mt-2": index > 0})}
            currentDeliveryMethodOption={currentDeliveryMethodOption}
            key={digg(shipmentTemplate, "identifier")}
            onShipmentTemplateChanged={this.onShipmentTemplateChanged}
            shipmentTemplate={shipmentTemplate}
          />
        ))}
      </div>
    )
  }

  onShipmentTemplateChanged = (shipmentTemplate) => {
    const {onChecked} = this.props

    onChecked({shipmentTemplate})
  }
}
