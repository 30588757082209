import * as DOMPurify from "dompurify"
import {useMemo} from "react"

export function useSanitize(dirty) {
  const clean = useMemo(() => DOMPurify.sanitize(dirty), [dirty])

  return clean
}

export default function SanitizedHtml({dirty, tagName, ...restProps}) {
  const cleanHtml = useSanitize(dirty)
  const CustomTag = tagName || "div"

  return <CustomTag {...restProps} dangerouslySetInnerHTML={{__html: cleanHtml}} />
}
