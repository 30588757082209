import Form from "./form"

export default class AdminTaggingsTaggable extends React.Component {
  static propTypes = {
    model: PropTypes.object.isRequired
  }

  state = ({
    taggings: undefined
  })

  componentDidMount() {
    this.loadTaggings()
  }

  async loadTaggings() {
    const {model} = digs(this.props, "model")
    const taggings = await Tagging
      .ransack({
        resource_type_eq: digg(model.modelClassData(), "name"),
        resource_id_eq: model.id(),
        s: "tag_name"
      })
      .preload("tag")
      .select({
        Tag: ["name"],
        Tagging: ["id"]
      })
      .toArray()

    this.setState({taggings})
  }

  render() {
    const {className, model, ...restProps} = this.props
    const {taggings} = digs(this.state, "taggings")

    return (
      <Card className={classNames("admin-components-taggings-taggable", className)} header={Tag.modelName().human({count: 2})} {...restProps}>
        <Form className="mb-4" model={model} onSaved={this.onTaggingSaved} />

        {taggings?.map((tagging) =>
          <EventDestroyed key={`tagging-destroyed-event-${tagging.id()}`} model={tagging} onDestroyed={this.onTaggingDestroyed} />
        )}
        {taggings?.map((tagging) =>
          <div className="badge badge-primary tagging-container me-2" data-tagging-id={tagging.id()} key={tagging.id()}>
            {tagging.tag()?.name()}
            <Icon className="destroy-tagging-icon ms-1" icon="times" onClick={() => this.onRemoveTaggingClicked(tagging)} size="extra-small" />
          </div>
        )}
        {taggings?.length === 0 &&
          I18n.t("js.admin.taggings.taggable.this_model_havent_been_tagged")
        }
      </Card>
    )
  }

  onRemoveTaggingClicked = async (tagging) => {
    if (!(await CustomConfirm.confirm(I18n.t("js.global.confirmation")))) {
      return
    }

    try {
      await tagging.destroy()
      FlashMessage.success(I18n.t("js.admin.taggings.taggable.the_tagging_was_removed"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onTaggingDestroyed = (args) => {
    const destroyedTagging = digg(args, "model")

    this.setState((state) => ({
      taggings: state.taggings.filter((tagging) => tagging.id() != destroyedTagging.id())
    }))
  }

  onTaggingSaved = () => {
    this.loadTaggings()
  }
}
