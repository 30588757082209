import getCurrentLayout from "tenants/get-current-layout"
import {useLayoutRedirector} from "shared/hooks/use-layout-redirector"
import {useRef} from "react"

export default function UsersPasswordsEdit({className, ...restProps}) {
  const passwordConfirmationInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const layoutRedirector = useLayoutRedirector()
  const onSubmit = async (e) => {
    e.preventDefault()

    // TODO remove refs and use state
    const password = passwordInputRef.current.value
    const passwordConfirmation = passwordConfirmationInputRef.current.value
    const params = Params.parse()
    const resetPasswordToken = digg(params, "reset_password_token")
    const layout = getCurrentLayout()

    try {
      await User.applyForgottenPasswordRequest({
        password,
        password_confirmation: passwordConfirmation,
        reset_password_token: resetPasswordToken
      })
      FlashMessage.success(I18n.t("js.users.passwords.edit.index.your_password_was_changed"))
      layoutRedirector(layout, "newUserSession")
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  return (
    <div className={classNames("components-users-passwords-edit", className)} {...restProps}>
      <form onSubmit={onSubmit}>
        <Card>
          <BootstrapInput
            id="user_password"
            inputRef={passwordInputRef}
            label={I18n.t("js.users.passwords.edit.new_password")}
            type="password"
          />
          <BootstrapInput
            id="user_password_confirmation"
            inputRef={passwordConfirmationInputRef}
            label={I18n.t("js.users.passwords.edit.confirm_password")}
            type="password"
          />
          <SubmitButton label={I18n.t("js.users.passwords.edit.change_my_password")} />
        </Card>
      </form>
    </div>
  )
}
