export default class AdminLogsNamesActivity extends React.Component {
  static propTypes = PropTypesExact({
    log: PropTypes.instanceOf(Log).isRequired
  })

  render() {
    const trackable = this.props.log.trackable()

    return (
      <Link className="admin-components-logs-names-activity" to={AdminRoutes.activityPath(trackable.id())}>
        {trackable.name()}
      </Link>
    )
  }
}
