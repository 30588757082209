import BootstrapBreakpoint from "components/bootstrap/breakpoint"

export default class BootstrapDynamicElement extends React.PureComponent {
  static propTypes = {
    breakpoints: PropTypes.array.isRequired,
    elementRef: PropTypes.object
  }

  state = {
    component: this.component()
  }

  render() {
    const {breakpoints, elementRef, ...restProps} = this.props
    const ElementComponent = digg(this, "state", "component")

    return (
      <>
        <BootstrapBreakpoint onBreakpointChanged={this.onBootstrapBreakpointChanged} />
        <ElementComponent ref={elementRef} {...restProps} />
      </>
    )
  }

  component() {
    const {breakpoints} = digs(this.props, "breakpoints")

    for (const breakpointData of breakpoints) {
      const breakpointMethod = breakpointData[0]
      const breakpoint = breakpointData[1]
      const element = breakpointData[2]
      const result = BootstrapBreakpoint[breakpointMethod].call(null, [breakpoint])

      if (result) {
        return element
      }
    }

    throw new Error("Could not figure out element")
  }

  onBootstrapBreakpointChanged = () => {
    const newComponent = this.component()
    const {component} = this.state

    if (newComponent != component) {
      this.setState({component: newComponent})
    }
  }
}
