export default class Button extends React.Component {
  static defaultProps = {
    block: false,
    danger: false,
    disabled: false,
    large: false,
    primary: false,
    small: false,
    to: "#"
  }

  static propTypes = {
    block: PropTypes.bool.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    confirm: PropTypes.bool,
    danger: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    label: PropTypes.string,
    large: PropTypes.bool.isRequired,
    link: PropTypes.bool,
    onClick: PropTypes.func,
    primary: PropTypes.bool.isRequired,
    small: PropTypes.bool.isRequired,
    target: PropTypes.string,
    to: PropTypes.string
  }

  render() {
    const {block, children, className, confirm, danger, icon, label, large, onClick, primary, link, small, ...restProps} = this.props

    if (link) {
      return (
        <Link className={this.className()} onClick={this.onClick} to={this.to()}>
          {this.icon()}
          {children}
          {label}
        </Link>
      )
    }

    return (
      <a className={this.className()} href={this.to()} onClick={this.onClick} {...restProps}>
        {this.icon()}
        {children}
        {label}
      </a>
    )
  }

  className() {
    const classNames = ["components-buttons-button", "btn"]
    const {className} = this.props
    const {block, danger, disabled, large, primary, small} = digs(this.props, "block", "danger", "disabled", "large", "primary", "small")

    if (danger && primary) {
      throw new Error("Cannot have props 'danger' and 'primary' at the same time")
    }

    if (className) {
      classNames.push(className)
    }

    if (block) {
      classNames.push("btn-block")
    }

    if (disabled) {
      classNames.push("disabled")
    }

    if (danger) {
      classNames.push("btn-danger")
    } else if (primary) {
      classNames.push("btn-primary")
    } else {
      classNames.push("btn-outline-secondary")
    }

    if (large) {
      classNames.push("btn-lg")
    } else if (small) {
      classNames.push("btn-sm")
    }

    return classNames.join(" ")
  }

  icon() {
    const {icon, label} = this.props

    if (!icon) {
      return
    }

    const iconClassNames = ["la", `la-${icon}`]

    if (label) {
      iconClassNames.push("me-1")
    }

    return (
      <i className={classNames(iconClassNames)} />
    )
  }

  onClick = async (e) => {
    if (!this.props.onClick) {
      return
    }

    e.preventDefault()

    if (this.props.confirm && !await CustomConfirm.confirm(I18n.t("js.global.confirmation"))) {
      return
    }

    this.props.onClick()
  }

  to() {
    return this.props.to || "#"
  }
}
