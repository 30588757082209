export default class MerfotoPhotoSessionGroupsSubHeaderlabel extends React.Component {
  static propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired
    })),
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    label: PropTypes.node
  }

  render() {
    const {actions, className, icon, label, ...restProps} = this.props

    return (
      <div className={classNames("align-items-center", "merfoto-components-photo-session-groups-sub-header-label", "d-flex", className)} {...restProps}>
        <Icon className="me-1" icon={icon} />
        <span className={classNames("subheader-label-label", {"me-1": actions})}>
          {label}
        </span>
        {actions &&
          this.actionsContent()
        }
      </div>
    )
  }

  actionsContent() {
    const {actions} = digs(this.props, "actions")

    return actions.map(({key, label, ...restProps}, index) =>
      <React.Fragment key={key}>
        {index > 0 &&
          <span className="me-1">
            ,
          </span>
        }
        <a href="#" {...restProps}>
          {label}
        </a>
      </React.Fragment>
    )
  }
}
