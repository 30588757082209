import AttributeSelect from "components/attribute-select"
import FormDataObjectizer from "form-data-objectizer"
import {Modal} from "react-bootstrap"
import Stack from "react-bootstrap/Stack"

export default class ModelsTableAddColumnButton extends React.Component {
  static propTypes = {
    modelClass: PropTypes.func.isRequired,
    onAttributeChosen: PropTypes.func.isRequired
  }

  labelInputRef = React.createRef()

  state = {
    attributeChosen: undefined,
    attributeSelect: false
  }

  render() {
    const {className, modelClass, onAttributeChosen, ...restProps} = this.props
    const {attributeSelect} = this.state

    return (
      <>
        {attributeSelect &&
          <Modal centered onHide={this.onModelRequestClose} show>
            <form onSubmit={this.onSubmit}>
              <Modal.Body>
                <Stack gap={3}>
                  <BootstrapInput
                    inputRef={digg(this, "labelInputRef")}
                    label={I18n.t("js.models_table.add_column_button.label")}
                    name="label"
                  />
                  <AttributeSelect
                    modelClass={modelClass}
                    name="attribute"
                    onChange={this.onAttributeChanged}
                    readFromResource
                  />
                </Stack>
                <Modal.Footer>
                  <SubmitButton className="submit-dynamic-column-button">
                    {I18n.t("js.global.save")}
                  </SubmitButton>
                </Modal.Footer>
              </Modal.Body>
            </form>
          </Modal>
        }
        <a className={classNames("components-models-table-add-column-button", className)} href="#" onClick={this.onAddColumnClicked} {...restProps}>
          <Icon icon="plus-circle" size="small" />
        </a>
      </>
    )
  }

  onAddColumnClicked = (e) => {
    e.preventDefault()

    this.setState({attributeSelect: true})
  }

  onAttributeChanged = (args) => {
    this.setState({
      attributeChosen: args
    })
  }

  onModelRequestClose = () => this.setState({attributeSelect: false})

  onSubmit = (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const data = FormDataObjectizer.toObject(formData)
    const {onAttributeChosen} = digs(this.props, "onAttributeChosen")
    const {attributeChosen} = digs(this.state, "attributeChosen")

    if (!attributeChosen) {
      FlashMessage.error(I18n.t("js.models_table.add_column_button.please_choose_an_attribute"))

      return
    }

    this.setState({attributeSelect: false})

    onAttributeChosen({
      attributeChosen,
      label: digg(data, "label")
    })
  }
}
