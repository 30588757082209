export default class AdminLogsNamesContact extends React.Component {
  static propTypes = PropTypesExact({
    log: PropTypes.instanceOf(Log).isRequired
  })

  render() {
    const trackable = this.props.log.trackable()

    return (
      <ContactLink className="contact-link" contact={trackable} fallbackMessage />
    )
  }
}
