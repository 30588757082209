import "./style"
import PaymentMethod from "./payment-method"
import SanitizedHtml from "../../../../components/sanitized-html"
import {tenantAtom} from "shared/atoms/tenant-atom"
import {tenantContactSettingSelector} from "shared/selectors/tenant-contact-setting-selector"
import {tenantShopSettingSelector} from "shared/selectors/tenant-shop-setting-selector"
import {useRecoilValue} from "recoil"

export default function MerfotoLayoutFooter({className, ...restProps}) {
  const tenant = useRecoilValue(tenantAtom)
  const tenantShopSetting = useRecoilValue(tenantShopSettingSelector)
  const tenantContactSetting = useRecoilValue(tenantContactSettingSelector)

  return (
    <footer className={classNames("merfoto-components-layout-footer", className)} {...restProps}>
      <>
        <div className="contact-footer py-4 w-100">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-12">
                <div className="merfoto-footer-logo" />
              </div>
              <div className="col-md-4 col-sm-12">
                <div>{I18n.t("js.merfoto.layout.footer.write_us")}</div>
                <h3>
                  <a className="footer-link" href={`mailto:${tenantContactSetting?.email()}`}>
                    {tenantContactSetting?.email()}
                  </a>
                </h3>
                <div><b>{tenant.name()}</b></div>
                {tenantContactSetting?.address() &&
                  <>
                    <div className="address-street">
                      {tenantContactSetting.address().street()}
                    </div>
                    <div className="address-zip-code-and-city">
                      {tenantContactSetting.address().zipCode()} {tenantContactSetting.address().city()}
                    </div>
                    {tenantContactSetting.hasVatNumber() &&
                      <div className="mt-1 tenant-contact-setting-vat-number">
                        {`${TenantContactSetting.humanAttributeName("vatNumber")} ${tenantContactSetting.vatNumber()}`}
                      </div>
                    }
                  </>
                }
              </div>
              <div className="col-md-4 col-sm-12">
                <div>{I18n.t("js.merfoto.layout.footer.call_us")}</div>
                <h3>{tenantContactSetting?.phoneNumber()}</h3>
                <div><b>{I18n.t("js.merfoto.layout.footer.opening_hours")}</b></div>
                <SanitizedHtml className="opening-hours" dirty={tenantShopSetting?.openingHours()} />
              </div>
            </div>
            <div className="mt-2 row">
              <div className="col-md-4" />
              <div className="col-md-4" />
              <div className="col-md-4">
                <div className="mb-1">
                  <b>{I18n.t("js.merfoto.layout.footer.index.pay_with")}</b>
                </div>
                <div className="d-flex">
                  {["dankort", "visa-card", "mastercard", "mobilepay"].map((paymentMethod, index) =>
                    <PaymentMethod className={classNames({"ms-1": index > 0})} key={paymentMethod} paymentMethod={paymentMethod} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container copyright-footer d-flex">
          <div>
            {I18n.t("js.merfoto.layout.footer.copyright")} {new Date().getFullYear()} - {tenant?.name()}
          </div>
          <div className="ms-auto">
            {tenantShopSetting?.hasTermsAndConditions() &&
              <Link className="copyright-footer-link terms-and-conditions-link" to={MerfotoRoutes.termsAndConditionsPath()}>
                {TenantShopSetting.humanAttributeName("termsAndConditions")}
              </Link>
            }
            {tenantShopSetting?.hasCookiesAndPrivacyPolicy() &&
              <Link
                className={classNames("cookies-and-privacy-policy-link copyright-footer-link", {"ms-4": tenantShopSetting.hasTermsAndConditions()})}
                to={MerfotoRoutes.cookiesAndPrivacyPolicyPath()}
              >
                {TenantShopSetting.humanAttributeName("cookiesAndPrivacyPolicy")}
              </Link>
            }
          </div>
        </div>
      </>
    </footer>
  )
}
