import {useNavigate, useParams, useSearchParams} from "react-router-dom"
import MerfotoComponentsOrderLinesIndex from "merfoto/components/order-lines/edit"

export default function MerfotoRoutesOrderLinesEditWrapper() {
  const {id: orderLineId} = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const saveAction = (formData) => OrderLine.addToBasket(formData)
  const onSaveSucceeded = () => {
    navigate("/basket")
  }
  const photoIds = searchParams.getAll("photo_ids[]")
  const tenantPhotoProductId = searchParams.get("tenant_photo_product_id")

  return (
    <div className="merfoto-routes-photo-sheet-product-order-lines-edit">
      <h1>{I18n.t("js.merfoto.photo_sheet_product_order_lines.edit.add_sheet_to_basket")}</h1>
      <MerfotoComponentsOrderLinesIndex
        onSaveSucceeded={onSaveSucceeded}
        orderLineId={orderLineId}
        photoIds={photoIds}
        saveAction={saveAction}
        tenantPhotoProductId={tenantPhotoProductId}
      />
    </div>
  )
}
