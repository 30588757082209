import NemoaModelSelectV2 from "components/nemoa-model-select-v2"
import {useMemo} from "react"

export function modelToOption(photoSessionGroupContact) {
  return {
    label: `${photoSessionGroupContact.photoSessionGroup().name()}; ${photoSessionGroupContact.contact()?.name()}`, // Contact can be inaccessible
    value: photoSessionGroupContact.id()
  }
}

const collectionParams = {s: ["photo_session_group_name asc", "contact_name asc"]}

export default function MerfotoPhotoSessionGroupContactsSelect({className, collection: collectionFromProps, ...restProps}) {
  const collection = useMemo(() => {
    const collection = collectionFromProps?.clone() || PhotoSessionGroupContact.ransack()
    const query = collection
      .preload(["contact", "photo_session", "photo_session_group"])
      .select({
        Contact: ["id", "name"],
        PhotoSessionGroupContact: ["id", "photoSessionGroupId"]
      })

    return query
  }, [collectionFromProps])

  return (
    <NemoaModelSelectV2
      className={classNames(className, "merfoto-components-photo-session-group-contacts-select")}
      collection={collection}
      collectionParams={collectionParams}
      modelToOption={modelToOption}
      searchParams="contact_name_or_photo_session_group_name_cont_any_word"
      {...restProps}
    />
  )
}
