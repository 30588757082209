export default class ButtonsIcon extends React.Component {
  static defaultProps = {
    disabled: false,
    primary: false,
    small: false
  }

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string.isRequired,
    primary: PropTypes.bool.isRequired,
    small: PropTypes.bool.isRequired
  }

  render() {
    const {className, disabled, icon, primary, small, to, ...restProps} = this.props

    return (
      <Link className={this.classNames()} to={to || "#"} {...restProps}>
        <i className={`la la-${icon}`} />
      </Link>
    )
  }

  classNames() {
    const {className} = this.props
    const {disabled, primary, small} = digs(this.props, "disabled", "primary", "small")

    return classNames(
      "btn",
      "components-buttons-icon",
      className,
      {
        "btn-secondary": !primary,
        "btn-primary": primary,
        "btn-sm": small,
        disabled
      }
    )
  }
}
