import "./style"

export default class MerfotoComponentsRadioButton extends React.Component {
  static propTypes = {
    callbackArgs: PropTypes.object,
    className: PropTypes.string,
    inputProps: PropTypes.object.isRequired,
    label: PropTypes.node.isRequired,
    onChange: PropTypes.func
  }

  render() {
    const {callbackArgs, className, defaultChecked, inputProps, label, name, onChange, value, ...restProps} = this.props
    const {className: inputClassName, ...restInputProps} = inputProps

    return (
      <div className={classNames("merfoto-components-radio-button", className)} {...restProps}>
        <label>
          <input
            className={classNames("radio-button-input", inputClassName)}
            onChange={onChange && this.onChanged}
            type="radio"
            {...restInputProps}
          />
          <div className="visual-outer">
            <div className="visual-inner" />
          </div>
          <div className="radio-button-label">
            {label}
          </div>
        </label>
      </div>
    )
  }

  onChanged = (e) => {
    const {callbackArgs, onChange} = this.props
    const args = Object.assign({e}, callbackArgs)

    onChange(args)
  }
}
