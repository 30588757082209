import "./style"

export default function MerfotoComponentsOrderLinesEditSubmitImageButton({className, ...restProps}) {
  return (
    <button
      className={classNames("align-items-center", "d-flex", "merfoto-components-order-lines-edit-submit-image-button", className)}
      type="submit"
      {...restProps}
    >
      <div>{I18n.t("js.merfoto.order_lines.edit.submit_image_button.save_in_basket")}</div>
      <i className="la la-cart-plus ms-2 submit-image-button-icon" />
    </button>
  )
}
