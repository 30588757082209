import events from "./events"

export default class BlockUiCounter {
  blockCount = 0

  constructor() {
    this.connectEvents()
  }

  connectEvents() {
    events.addListener("blockUI", () => {
      this.blockCount++

      if (this.blockCount == 1) events.emit("blocked")
    })
    events.addListener("unblockUI", () => {
      this.blockCount--

      if (this.blockCount == 0) events.emit("unblocked")
    })
  }

  blocked() {
    return this.blockCount > 0
  }
}
