export default class ButtonsEdit extends React.Component {
  static defaultProps = {
    icon: "edit"
  }

  static propTypes = PropTypesExact({
    className: PropTypes.string,
    icon: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string
    ]),
    large: PropTypes.bool,
    onClick: PropTypes.func,
    outline: PropTypes.bool,
    primary: PropTypes.bool,
    small: PropTypes.bool,
    to: PropTypes.string
  })

  render() {
    if (this.props.link) {
      return this.link()
    }

    const {icon} = this.props

    return (
      <Link className={this.className()} onClick={this.props.onClick} title={I18n.t("js.global.edit")} to={this.to()}>
        <Icon className={this.iconClassName()} icon={icon} size="small" />
        {this.showLabel() && this.label()}
      </Link>
    )
  }

  className() {
    const {className, outline, primary, small, large} = this.props
    const classNames = ["components-buttons-edit", "btn"]

    if (className) {
      classNames.push(className)
    }

    if (primary) {
      classNames.push("btn-primary")
    } else if (outline) {
      classNames.push("btn-outline-secondary")
    } else {
      classNames.push("btn-action")
    }

    if (small) {
      classNames.push("btn-sm")
    }
    if (large) {
      classNames.push("btn-lg")
    }

    return classNames.join(" ")
  }

  label() {
    if ("label" in this.props) {
      return this.props.label
    }

    return I18n.t("js.global.edit")
  }

  showLabel() {
    return Boolean(this.label())
  }

  iconClassName() {
    if (this.showLabel()) {
      return "me-1"
    }
  }

  to() {
    return this.props.to || "#"
  }
}
