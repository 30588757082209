import "./style"

export default class MerfotoBasketQuantityContainer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    editable: PropTypes.bool.isRequired,
    orderLine: PropTypes.instanceOf(OrderLine).isRequired
  }

  render() {
    const {className, editable, orderLine, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-basket-quantity", className)} data-editable={editable} {...restProps}>
        {editable &&
          <Icon className="decrease-quantity-button mx-1" icon="minus" onClick={this.onDecreaseQuantityClicked} />
        }
        <span className="quantity-label">
          {orderLine.quantity()}
        </span>
        {editable &&
          <Icon className="increase-quantity-button mx-1" icon="plus" onClick={this.onIncreaseQuantityClicked} />
        }
      </div>
    )
  }

  onIncreaseQuantityClicked = async (e) => {
    e.preventDefault()

    const {orderLine} = this.props

    try {
      await orderLine.clone().update({quantity: orderLine.quantity() + 1})
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onDecreaseQuantityClicked = async (e) => {
    e.preventDefault()

    const {orderLine} = this.props

    if (orderLine.quantity() <= 1) {
      this.destroyOrderLine()
    } else {
      try {
        await orderLine.clone().update({quantity: orderLine.quantity() - 1})
      } catch (error) {
        FlashMessage.errorResponse(error)
      }
    }
  }

  async destroyOrderLine() {
    const {orderLine} = this.props

    if (!await CustomConfirm.confirm(I18n.t("js.merfoto.basket.quantity.remove_item_from_basket"))) {
      return
    }

    try {
      await orderLine.destroy()
      FlashMessage.success(I18n.t("js.merfoto.basket.quantity.the_item_was_removed_from_the_basket"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
