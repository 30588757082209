import pathToModelClass from "./path-to-model-class"

export default function preloadCalculator({modelClass, pathsAndAttributes}) {
  const preloads = []
  const selectedAttributes = {}

  for (const pathAndAttribute of pathsAndAttributes) {
    const {attribute, path} = digs(pathAndAttribute, "attribute", "path")

    // Generate preload path so the correct relationships can be included from the backend
    const preloadPath = digg(pathAndAttribute, "path").map((pathPart) => Inflection.underscore(digg(pathPart, "name")))

    // Generate select so the correct attributes can be included from the backend
    const currentModelClass = pathToModelClass(modelClass, path)
    const currentModelClassName = digg(currentModelClass.modelClassData(), "name")

    if (!(currentModelClassName in selectedAttributes)) selectedAttributes[currentModelClassName] = []

    selectedAttributes[currentModelClassName].push(attribute)

    if (preloadPath.length > 0) preloads.push(preloadPath.join("."))
  }

  return {preloads, selectedAttributes}
}
