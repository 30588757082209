import SanitizedHtml from "../../components/sanitized-html"
import {tenantShopSettingSelector} from "shared/selectors/tenant-shop-setting-selector"
import {useRecoilValue} from "recoil"
import {useTitle} from "shared/hooks/use-title"

export default function MerfotoRoutesCookiesAndPrivacyPolicy() {
  const tenantShopSetting = useRecoilValue(tenantShopSettingSelector)

  useTitle(TenantShopSetting.humanAttributeName("cookiesAndPrivacyPolicy"))

  return (
    <div className="merfoto-routes-cookies-and-privacy-policy">
      <h1>{TenantShopSetting.humanAttributeName("cookiesAndPrivacyPolicy")}</h1>
      <SanitizedHtml className="cookies-and-privacy-policy-text" dirty={tenantShopSetting.cookiesAndPrivacyPolicy()} />
      {window.COOKIEBOT_CBID &&
        <script
          async
          id="CookieDeclaration"
          src={`https://consent.cookiebot.com/${window.COOKIEBOT_CBID}/cd.js`}
          type="text/javascript"
        />
      }
    </div>
  )
}
