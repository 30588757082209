export default class MerfotoTenantShopSettingsVimeoVideo extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    tenantShopSetting: PropTypes.instanceOf(TenantShopSetting).isRequired
  })

  state = ({
    playerHtml: undefined
  })

  componentDidMount() {
    this.loadVimeoPlayerHtml()
  }

  async loadVimeoPlayerHtml() {
    const {tenantShopSetting} = digs(this.props, "tenantShopSetting")
    const response = await tenantShopSetting.vimeoGreeterEmbeddedHtml({autoplay: false})

    this.setState({
      playerHtml: digg(response, "html")
    })
  }

  render() {
    const {className, tenantShopSetting, ...restProps} = this.props
    const {playerHtml} = digs(this.state, "playerHtml")

    return (
      <div
        className={classNames("merfoto-components-tenant-shop-settings-vimeo-video", className)}
        dangerouslySetInnerHTML={{__html: playerHtml}}
        {...restProps}
      />
    )
  }
}
