export default function photosQueryFromPhotoSessionGroupContact(photoSessionGroupContactId) {
  return Photo
    .ransack({photo_session_group_photo_session_group_contacts_id_eq: photoSessionGroupContactId})
    .ransack({
      g: {
        0: {
          m: "or",
          c: {
            0: {
              a: {
                "0s": {
                  name: "photo_session_group_contact_id"
                }
              },
              p: "eq",
              v: {
                "0s": {
                  value: photoSessionGroupContactId
                }
              }
            },
            1: {
              a: {
                "0s": {
                  name: "photo_session_group_contact_id"
                }
              },
              p: "null",
              v: {
                "0s": {
                  value: true
                }
              }
            }
          }
        }
      }
    })
}
