import ConfirmDialog from "components/confirm-dialog"
import ReactDOM from "react-dom"

// Custom confirm class that works using promises (it can be shorthanded with await)
export default class CustomConfirm {
  static async confirm(message = I18n.t("js.global.confirmation")) {
    const customConfirm = new CustomConfirm(message)

    return await customConfirm.confirm()
  }

  constructor(message) {
    this.message = message
  }

  confirm() {
    return new Promise((resolve) => {
      this.resolve = resolve
      this.showDialog()
    })
  }

  showDialog() {
    const dialog = <ConfirmDialog message={this.message} onCancel={this.onCancelClicked} onConfirm={this.onConfirmClicked} />

    this.element = document.createElement("div")
    ReactDOM.render(dialog, this.element)
    document.body.prepend(this.element)
  }

  onCancelClicked = () => {
    this.removeDialog()
    this.resolve(false)
  }

  onConfirmClicked = () => {
    this.removeDialog()
    this.resolve(true)
  }

  removeDialog() {
    ReactDOM.unmountComponentAtNode(this.element)
    document.body.removeChild(this.element)
  }
}
