import {useNavigate, useSearchParams} from "react-router-dom"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import SessionsNewWithGreeter from "merfoto/components/sessions/new-with-greeter"
import {tenantAtom} from "shared/atoms/tenant-atom"
import {useRecoilValue} from "recoil"

export default function MerfotoUsersSessionsNew() {
  const tenant = useRecoilValue(tenantAtom)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const returnUrl = searchParams.get("returnUrl") || "/"

  return (
    <div className="merfoto-routes-users-sessions-new">
      <Row className="justify-content-center">
        <Col lg={6} md={8} xl={4}>
          <SessionsNewWithGreeter onSignedIn={() => navigate(returnUrl)} tenant={tenant} />
        </Col>
      </Row>
    </div>
  )
}
