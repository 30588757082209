import Stack from "react-bootstrap/Stack"

export default class MerfotoOrdersTotalWithDelivery extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    order: PropTypes.instanceOf(Order).isRequired
  }

  render() {
    const {className, order, ...restProps} = this.props

    return (
      <Stack className={classNames("merfoto-components-orders-total-with-delivery", className)} direction="vertical" gap={3} {...restProps}>
        {order.orderDelivery()?.hasProductId() &&
          <div className="border-bottom d-flex delivery-price-content pb-2">
            <div className="me-auto">
              {I18n.t("js.merfoto.orders.total_with_delivery.delivery")}
            </div>
            {MoneyFormatter.format(order.orderDelivery().priceWithVat(), {excludeCurrency: false})}
          </div>
        }
        <div className="d-flex justify-content-end total-price">
          <span className="me-auto">
            {I18n.t("js.merfoto.orders.total_with_delivery.total_price_including_vat")}
          </span>
          {MoneyFormatter.format(order.priceWithVat(), {decimals: 2, excludeCurrency: false})}
        </div>
      </Stack>
    )
  }
}
