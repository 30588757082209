import "./style"
import Col from "react-bootstrap/Col"
import {photosSelectorFamily} from "shared/selectors/photos-selector-family"
import Row from "react-bootstrap/Row"
import TenantPhotoProductsCard from "merfoto/components/tenant-photo-products-with-photos/card"
import {tenantPhotoProductsForPhotoSessionTypeSelectorFamily} from "shared/selectors/tenant-photo-products-selector"
import {useRecoilValue} from "recoil"

MerfotoTenantPhotoProductsWithPhotos.propTypes = PropTypesExact({
  className: PropTypes.string,
  photoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact).isRequired
})

export default function MerfotoTenantPhotoProductsWithPhotos({className, photoSessionGroupContact}) {
  const photos = useRecoilValue(photosSelectorFamily(photoSessionGroupContact.id()))
  const tenantPhotoProducts = useRecoilValue(tenantPhotoProductsForPhotoSessionTypeSelectorFamily(photoSessionGroupContact.photoSession().photoSessionType()))

  return (
    <Row className={classNames("merfoto-components-tenant-photo-products-with-photos", "g-3", className)}>
      {photos?.length > 0 && tenantPhotoProducts?.map((tenantPhotoProduct) =>
        <Col
          className="tenant-photo-product"
          key={tenantPhotoProduct.id()}
          lg={4}
          md={6}
          sm={12}
        >
          <TenantPhotoProductsCard
            photoSessionGroupContact={photoSessionGroupContact}
            photos={photos}
            tenantPhotoProduct={tenantPhotoProduct}
          />
        </Col>
      )}
      {photos?.length === 0 &&
        <div className="no-photos-content">
          <h1>{I18n.t("js.merfoto.tenant_photo_products_with_photos.index.album_without_photos")}</h1>
          {I18n.t("js.merfoto.tenant_photo_products_with_photos.index.you_have_chosen_an_album_without_photos_dont_worry")}
        </div>
      }
    </Row>
  )
}
