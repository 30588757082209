import {canCanAtom} from "shared/atoms/can-can-atom"
import {useMemo} from "react"
import {useRecoilValue} from "recoil"

AdminContactsLink.propTypes = PropTypesExact({
  children: PropTypes.node,
  className: PropTypes.string,
  contact: ModelPropType.ofModel(Contact).withLoadedAttributes(["id", "name"]).isNotRequired,
  fallbackMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
})

export default function AdminContactsLink({children, className, contact, fallbackMessage, ...restProps}) {
  const canCan = useRecoilValue(canCanAtom)
  const childrenContent = useMemo(() => {
    if (children) {
      return children
    }

    if (contact?.name()) {
      return contact.name()
    }

    return contact?.id()
  }, [children, contact])

  if (!contact) {
    if (fallbackMessage === true) {
      return I18n.t("js.admin.contacts.link.no_contact")
    } else if (fallbackMessage) {
      return fallbackMessage
    }

    throw new Error("No contact or fallbackMessage given")
  }

  return (
    <>
      <Choose>
        <When condition={canCan?.can("access", "admin")}>
          <Link
            className={classNames("admin-components-contacts-link", className)}
            data-admin-access={Boolean(canCan?.can("access", "admin"))}
            data-can-can-loaded={Boolean(canCan)}
            data-contact-id={contact.id()}
            to={AdminRoutes.contactPath(contact.id())}
            {...restProps}
          >
            {childrenContent}
          </Link>
        </When>
        <Otherwise>
          <span
            className={classNames("admin-components-contacts-link", className)}
            data-admin-access={Boolean(canCan?.can("access", "admin"))}
            data-can-can-loaded={Boolean(canCan)}
          >
            {childrenContent}
          </span>
        </Otherwise>
      </Choose>
    </>
  )
}
