import {Suspense, useContext, useEffect, useMemo} from "react"
import {useRecoilState, useRecoilValue} from "recoil"
import {currentPhotoSessionGroupContactIdAtom} from "shared/atoms/current-photo-session-group-contact-id-atom"
import {currentPhotoSessionGroupContactSelector} from "shared/selectors/current-photo-session-group-contact-selector"
import Spinner from "react-bootstrap/Spinner"
import TenantPhotoProductsWithPhotos from "merfoto/components/tenant-photo-products-with-photos"
import {UserContext} from "shared/user-context"
import {useTitle} from "shared/hooks/use-title"
import WelcomeHeader from "merfoto/components/welcome-header"
import WelcomeTopContainer from "merfoto/components/welcome-top-container"

function ProductsContainer() {
  const [currentUser] = useContext(UserContext)
  const [currentPhotoSessionGroupContactId, setCurrentPhotoSessionGroupContactId] = useRecoilState(currentPhotoSessionGroupContactIdAtom)
  const currentPhotoSessionGroupContact = useRecoilValue(currentPhotoSessionGroupContactSelector)
  const onPhotoSessionGroupContactChanged = ({value: photoSessionGroupContactId}) => {
    if (photoSessionGroupContactId !== currentPhotoSessionGroupContact?.id()) {
      setCurrentPhotoSessionGroupContactId(photoSessionGroupContactId)
    }
  }
  const onPhotoSessionGroupContactTokenContactCreated = (photoSessionGroupContactTokenContact) =>
    setCurrentPhotoSessionGroupContactId(photoSessionGroupContactTokenContact.photoSessionGroupContactId())
  const photosAvailable = useMemo(() => currentPhotoSessionGroupContact && !(currentPhotoSessionGroupContact.photoSession().photosAvailableAt() &&
    currentPhotoSessionGroupContact.photoSession().photosAvailableAt().getTime() > new Date().getTime()), [currentPhotoSessionGroupContact])

  useEffect(() => {
    async function loadFirstAvailablePhotoSessionGroupContact() {
      const photoSessionGroupContact = await PhotoSessionGroupContact.ransack({albums_for_user: currentUser.id()}).first()

      setCurrentPhotoSessionGroupContactId(photoSessionGroupContact?.id())
    }

    if (!currentPhotoSessionGroupContactId) {
      loadFirstAvailablePhotoSessionGroupContact()
    } else if (!currentPhotoSessionGroupContact) {
      setCurrentPhotoSessionGroupContactId(null)
    }
  }, [currentPhotoSessionGroupContact, currentPhotoSessionGroupContactId, currentUser, setCurrentPhotoSessionGroupContactId])

  return (
    <>
      <Suspense fallback={<Spinner animation="border" variant="primary" />}>
        <WelcomeTopContainer
          currentPhotoSessionGroupContact={currentPhotoSessionGroupContact}
          onPhotoSessionGroupContactChanged={onPhotoSessionGroupContactChanged}
          onPhotoSessionGroupContactTokenContactCreated={onPhotoSessionGroupContactTokenContactCreated}
          showPhotos={currentPhotoSessionGroupContact && photosAvailable}
        />
      </Suspense>
      {currentPhotoSessionGroupContact && !photosAvailable &&
        <div className="alert alert-warning photos-not-available-at-content mt-3">
          <h1>
            {I18n.t("js.merfoto.welcome.photos_available_at", {
              photosAvailableAt: I18n.l("time.formats.short", currentPhotoSessionGroupContact.photoSession().photosAvailableAt())
            })}
          </h1>
        </div>
      }
      {currentPhotoSessionGroupContact && photosAvailable &&
        <>
          <WelcomeHeader className="my-2">
            {I18n.t("js.merfoto.welcome.products")}
          </WelcomeHeader>
          <Suspense fallback={<Spinner animation="border" variant="primary" />}>
            <TenantPhotoProductsWithPhotos photoSessionGroupContact={currentPhotoSessionGroupContact} />
          </Suspense>
        </>
      }
    </>
  )
}

export default function MerfotoWelcome() {
  useTitle(I18n.t("js.merfoto.routes.welcome.welcome"))

  return (
    <div className="merfoto-routes-welcome">
      <WelcomeHeader className="mb-2">
        {I18n.t("js.merfoto.welcome.your_photos")}
      </WelcomeHeader>

      <Suspense fallback={<Spinner animation="border" variant="primary" />}>
        <ProductsContainer />
      </Suspense>
    </div>
  )
}
