import OrderCard from "merfoto/components/orders/order-card"

export default class MerfotoOrdersTable extends React.Component {
  render() {
    const {className, ...restProps} = this.props

    return (
      <SharedModelsTable.Table
        abilities={{OrderLine: ["destroy", "update"]}}
        additionalTablesEnabled={false}
        className={classNames("merfoto-components-orders-table", className)}
        columnsContent={this.columnsContent}
        expandableContent={this.expandableContent}
        filterBySetTenant
        headersContent={this.headersContent}
        modelClass={Order}
        preloads={[
          "order_delivery",

          "order_lines.bundle_order_line.bundle",
          "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.photo.photo_session_group",
          "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.photo.photo_session_group_contact.contact",
          "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.sheet_layout_box",
          "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.sheet_layout.boxes",
          "order_lines.bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.sheet_size",
          "order_lines.bundle_order_line.tenant_photo_product",

          "order_lines.order_line_photo",
          "order_lines.order_line_photo.photo.attachment_attachment.blob",
          "order_lines.order_line_photo.photo.photo_session_group",
          "order_lines.order_line_photo.photo.photo_session_group_contact.contact",
          "order_lines.order_line_photo.tenant_photo_product.photo_product",

          "order_lines.photo_sheet_product_order_line.boxes.photo.photo_session_group",
          "order_lines.photo_sheet_product_order_line.boxes.photo.photo_session_group_contact.contact",
          "order_lines.photo_sheet_product_order_line.boxes.sheet_layout_box",
          "order_lines.photo_sheet_product_order_line.sheet_layout.boxes",
          "order_lines.photo_sheet_product_order_line.sheet_size"
        ]}
        select={{
          Order: ["id", "orderNumber", "priceWithVat", "quantitySum", "translatedState", "updatedAt"],
          Bundle: ["name"],
          BundleOrderLine: ["id"],
          BundleOrderLineElement: ["id", "resourceType"],
          Contact: ["name"],
          OrderDelivery: ["id", "priceWithVat", "productId"],
          OrderLine: ["createdAt", "id", "orderableId", "orderableType", "quantity", "totalPriceWithVat"],
          Photo: ["height", "fullSizeUrl", "id", "photoIdentifier", "photoSessionGroupId", "photoSessionGroupContactId", "thumbnailUrl", "width"],
          PhotoSessionGroup: ["id", "name"],
          PhotoSessionGroupContact: ["id"],
          PhotoSheetProductOrderLine: ["id"],
          PhotoSheetProductOrderLineBox: [
            "id",
            "photoHeightPercent",
            "photoLeftPercent",
            "photoTopPercent",
            "photoWidthPercent",
            "rotation",
            "sheetLayoutBoxId",
            "updatedAt"
          ],
          SheetLayout: ["id", "webshopName"],
          SheetLayoutBox: ["heightPercent", "id", "leftPercent", "topPercent", "widthPercent"],
          SheetSize: ["heightMm", "id", "widthMm"],
          TenantPhotoProduct: ["id", "name"]
        }}
        {...restProps}
      />
    )
  }

  columnsContent = ({model: order}) => (
    <>
      <SharedModelsTable.Column>
        {order.orderNumber()}
      </SharedModelsTable.Column>
      <SharedModelsTable.Column>
        {order.quantitySum()}
      </SharedModelsTable.Column>
      <SharedModelsTable.Column>
        {MoneyFormatter.format(order.priceWithVat())}
      </SharedModelsTable.Column>
      <SharedModelsTable.Column>
        {order.translatedState()}
      </SharedModelsTable.Column>
    </>
  )

  expandableContent = ({order}) => (
    <OrderCard editable={false} order={order} />
  )

  headersContent = (args) => {
    const query = digg(args, "query")

    return (
      <>
        <SharedModelsTable.Header><SortLink attribute="orderNumber" label={Order.humanAttributeName("orderNumber")} query={query} /></SharedModelsTable.Header>
        <SharedModelsTable.Header>
          <SortLink attribute="quantitySum" query={query} title={I18n.t("js.merfoto.orders.table.quantity_sum")} />
        </SharedModelsTable.Header>
        <SharedModelsTable.Header>
          <SortLink attribute="priceWithVatCents" label={Order.humanAttributeName("priceWitVvat")} query={query} />
        </SharedModelsTable.Header>
        <SharedModelsTable.Header><SortLink attribute="state" label={Order.humanAttributeName("state")} query={query} /></SharedModelsTable.Header>
      </>
    )
  }
}
