import Stack from "react-bootstrap/Stack"

export default class UsersConfirmationNew extends React.Component {
  render() {
    return (
      <div className="components-users-confirmation-new">
        <form onSubmit={this.onSubmit}>
          <Stack gap={3}>
            <BootstrapInput
              id="email"
              label={Email.humanAttributeName("email")}
              name="email"
            />
            <SubmitButton />
          </Stack>
        </form>
      </div>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const email = formData.get("email")

    try {
      await User.resendConfirmationEmail({email})
      FlashMessage.success(I18n.t("js.users.confirmation.new.the_confirmation_email_was_sent_again"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
