import "./style"
import BundleTenantPhotoProductPartial from "./bundle-tenant-photo-product-partial"
import Card from "merfoto/components/card"
import Header from "./header"
import SubmitImageButton from "merfoto/components/order-lines/edit/submit-image-button"

export default class MerfotoComponentsOrderLinesBundle extends BaseComponent {
  static propTypes = {
    bundleOrderLine: PropTypes.instanceOf(BundleOrderLine).isRequired,
    className: PropTypes.string,
    photos: PropTypes.array.isRequired,
    tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct).isRequired
  }

  state = {
    activeBundleTenantPhotoProduct: this.props.tenantPhotoProduct.photoProduct().bundle().bundleTenantPhotoProducts().loaded()[0]
  }

  render() {
    const {bundleOrderLine, className, photos, tenantPhotoProduct, ...restProps} = this.props
    const bundle = tenantPhotoProduct.photoProduct().bundle()
    const {activeBundleTenantPhotoProduct} = this.state

    return (
      <div className={classNames("merfoto-components-order-lines-edit-resources-bundle", className)} {...restProps}>
        <input name="order_line[orderable_attributes][bundle_id]" type="hidden" value={bundle.id()} />
        <input name="order_line[orderable_attributes][touchy]" type="hidden" value="true" />
        <input name="order_line[orderable_attributes][tenant_photo_product_id]" type="hidden" value={tenantPhotoProduct.id()} />

        <Card.Outer>
          <Card.Header actions={<SubmitImageButton />}>
            {bundle.name()}
          </Card.Header>
          <Card.Body>
            <div className="row">
              <div className="col-lg-6">
                <Card.Outer>
                  <Card.Header>
                    {I18n.t("js.merfoto.components.order_lines.edit.resources.bundle.the_bundle_contains")}
                  </Card.Header>
                  <Card.Body>
                    {bundle.bundleTenantPhotoProducts().loaded().map((bundleTenantPhotoProduct, index) =>
                      <div
                        className={classNames("bundle-tenant-photo-product-contains-container", "d-flex", {"mt-1": index > 0})}
                        data-bundle-tenant-photo-product-id={bundleTenantPhotoProduct.id()}
                        key={bundleTenantPhotoProduct.id()}
                      >
                        {bundleTenantPhotoProduct.tenantPhotoProduct().photoProduct().resourceType() == "PhotoSheetProduct" &&
                          <>
                            <div className="sheet-with-index width-sm-100px">
                              {I18n.t("js.merfoto.components.order_lines.edit.resources.bundle.sheet_with_index", {index: index + 1})}
                            </div>
                            <div className="flex-grow-1">
                              {bundleTenantPhotoProduct.tenantPhotoProduct().photoProduct().photoSheetProduct().sheetLayout().boxes().loaded().map((box) =>
                                <div className="number-of-boxes-with-dimensions-in-cm" data-box-id={box.id()} key={box.id()}>
                                  {I18n.t("js.merfoto.components.order_lines.edit.resources.bundle.number_of_pcs", {number: 1})}
                                  {this.boxWidthCm(box, bundleTenantPhotoProduct)}x
                                  {this.boxHeightCm(box, bundleTenantPhotoProduct)}cm
                                </div>
                              )}
                            </div>
                          </>
                        }
                      </div>
                    )}
                  </Card.Body>
                </Card.Outer>
              </div>
              <div className="col-lg-6">
                <div className="you-can-edit-the-setup-of-the-sheets-below">
                  {I18n.t("js.merfoto.components.order_lines.edit.resources.bundle.you_can_edit_the_setup_of_the_sheets_below")}
                </div>
                <div className="bundle-sales-price">
                  {MoneyFormatter.format(tenantPhotoProduct.salesPriceWithVat())}
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Header>
            {I18n.t("js.merfoto.components.order_lines.edit.resources.bundle.edit_setup")}
          </Card.Header>
          <Card.Body>
            <div className="d-flex">
              {bundle.bundleTenantPhotoProducts().loaded().map((bundleTenantPhotoProduct, index) =>
                <Header
                  active={bundleTenantPhotoProduct.id() == activeBundleTenantPhotoProduct.id()}
                  bundleOrderLineElement={this.bundleOrderLineElementForBundleTenantPhotoProduct(bundleTenantPhotoProduct)}
                  bundleTenantPhotoProduct={bundleTenantPhotoProduct}
                  className={classNames({"ms-2": index > 0})}
                  key={bundleTenantPhotoProduct.id()}
                  onClick={this.onHeaderClicked}
                />
              )}
            </div>
          </Card.Body>
          {bundle.bundleTenantPhotoProducts().loaded().map((bundleTenantPhotoProduct) =>
            <div
              className={classNames({"d-none": bundleTenantPhotoProduct.id() != activeBundleTenantPhotoProduct.id()})}
              key={bundleTenantPhotoProduct.id()}
            >
              <Card.Body>
                <BundleTenantPhotoProductPartial
                  bundleOrderLine={bundleOrderLine}
                  bundleTenantPhotoProduct={bundleTenantPhotoProduct}
                  cacheKey={bundleTenantPhotoProduct.id()}
                  data-bundle-tenant-photo-product-id={bundleTenantPhotoProduct.id()}
                  data-visible={bundleTenantPhotoProduct.id() == activeBundleTenantPhotoProduct.id()}
                  photos={photos}
                />
              </Card.Body>
            </div>
          )}
        </Card.Outer>
      </div>
    )
  }

  boxWidthCm(box, bundleTenantPhotoProduct) {
    const boxWidthInPercentAsFraction = box.widthPercent() / 100
    const sheetWidthMm = bundleTenantPhotoProduct.tenantPhotoProduct().photoProduct().photoSheetProduct().sheetLayout().sheetSize().widthMm()

    return MoneyFormatter.floatToString(parseFloat(((boxWidthInPercentAsFraction * sheetWidthMm) / 10).toFixed(1)))
  }

  boxHeightCm(box, bundleTenantPhotoProduct) {
    const boxHeightInPercentAsFraction = box.heightPercent() / 100
    const sheetHeightMm = bundleTenantPhotoProduct.tenantPhotoProduct().photoProduct().photoSheetProduct().sheetLayout().sheetSize().heightMm()

    return MoneyFormatter.floatToString(parseFloat(((boxHeightInPercentAsFraction * sheetHeightMm) / 10).toFixed(1)))
  }

  bundleOrderLineElementForBundleTenantPhotoProduct(bundleTenantPhotoProduct) {
    const {bundleOrderLine} = digs(this.props, "bundleOrderLine")

    // An element cant be found for new bundle order lines, and it would other cause a not-loaded-error
    if (bundleOrderLine.isNewRecord()) return null

    return bundleOrderLine.bundleOrderLineElements().loaded().find((bundleOrderLineElement) =>
      bundleOrderLineElement.bundleTenantPhotoProductId() == bundleTenantPhotoProduct.id()
    )
  }

  onHeaderClicked = (args) => {
    const {bundleTenantPhotoProduct} = digs(args, "bundleTenantPhotoProduct")

    this.setState({
      activeBundleTenantPhotoProduct: bundleTenantPhotoProduct
    })
  }
}
