import PickupPoints from "./pickup-points"

export default class MerfotoOrderDeliveriesDeliveryMethodsShipmondoContent extends React.Component {
  static propTypes = {
    currentDeliveryMethodOption: PropTypes.object.isRequired,
    order: PropTypes.instanceOf(Order).isRequired,
    orderDelivery: PropTypes.instanceOf(OrderDelivery).isRequired
  }

  render() {
    const {checked, className, currentDeliveryMethodOption, order, orderDelivery, ...restProps} = this.props
    const {shipmentTemplate} = currentDeliveryMethodOption

    return (
      <div
        className={classNames(
          "d-flex",
          "flex-column",
          "form-check",
          "merfoto-components-order-deliveries-delivery-methods-shipmondo-content",
          "overflow-hidden",
          className
        )}
        {...restProps}
      >
        {shipmentTemplate && digg(shipmentTemplate, "pickup_point_required") &&
          <PickupPoints
            carrierCode={digg(shipmentTemplate, "carrier_code")}
            order={order}
            orderDelivery={orderDelivery}
            productCode={digg(shipmentTemplate, "product_code")}
          />
        }
      </div>
    )
  }
}
