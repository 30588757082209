import {useEffect, useState} from "react"
import {AsyncPaginate} from "react-select-async-paginate"
import {Collection} from "@kaspernj/api-maker"
import {defaultSelectProps} from "./nemoa-select-v2"
import Form from "react-bootstrap/Form"
import {tenantAtom} from "shared/atoms/tenant-atom"
import {useRecoilValue} from "recoil"

NemoaModelSelectV2.defaultProps = {
  currentTenantFilter: true
}

NemoaModelSelectV2.propTypes = {
  collection: PropTypes.instanceOf(Collection).isRequired,
  collectionParams: PropTypes.object, // params that are applied to all but the initial query. That is so e.g. soft deleted models can be included
  currentTenantFilter: PropTypes.bool,
  modelToOption: PropTypes.func.isRequired,
  searchParams: PropTypes.string.isRequired
}

const additional = {page: 1}

export default function NemoaModelSelectV2(props) {
  const {
    attribute,
    collection,
    collectionParams,
    currentTenantFilter,
    defaultValue: defaultValueFromProps,
    id,
    label,
    model,
    modelToOption,
    onChange: onChangeFromProps,
    value: valueFromProps,
    search,
    searchParams,
    ...restProps
  } = defaultSelectProps(props)
  const tenant = useRecoilValue(tenantAtom)
  const tenantId = tenant.id()
  const [value, setValue] = useState()
  const [isLoading, setIsLoading] = useState(defaultValueFromProps)

  const onChange = (option) => {
    if (onChangeFromProps) {
      onChangeFromProps(option)
    } else {
      setValue(option)
    }
  }

  useEffect(() => setValue(valueFromProps), [valueFromProps])

  useEffect(() => {
    async function initialOption() {
      if (!defaultValueFromProps) {
        return
      }

      const initialModels = await collection.clone().ransack({id_in: defaultValueFromProps}).toArray()

      setValue(initialModels.map((model) => modelToOption(model))[0])
      setIsLoading(false)
    }

    if (defaultValueFromProps) {
      initialOption()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const loadOptions = async (search, prevOptions, {page}) => {
    const query = collection.clone().page(page)

    if (collectionParams) {
      query.ransack(collectionParams)
    }

    if (currentTenantFilter) {
      query.ransack({tenant_id_eq: tenantId})
    }

    if (search) {
      query.ransack({[searchParams]: search})
    }

    const result = await query.result()
    const models = result.models()
    const options = models.map(modelToOption)
    const hasMore = result.totalPages() > result.currentPage()

    return {
      options: prevOptions.concat(options),
      hasMore,
      additional: {page: result.currentPage() + 1}
    }
  }

  const select = (
    <AsyncPaginate
      additional={additional}
      id={id}
      isDisabled={isLoading}
      isLoading={isLoading}
      loadOptions={loadOptions}
      onChange={onChange}
      value={value}
      {...restProps}
    />
  )

  if (label) {
    return (
      <Form.Group>
        {label &&
          <Form.Label htmlFor={id}>
            {label}
          </Form.Label>
        }
        {select}
      </Form.Group>
    )
  }

  return select
}
