import Inflection from "inflection"

export default class MerfotoComponentsOrderLinesIndex extends React.Component {
  static BundleSelectedAttributes = ["id", "name"]
  static PhotoProductSelectedAttributes = ["id", "resourceType"]
  static TenantPhotoProductSelectedAttributes = ["id", "photoProductId", "salesPriceWithVat"]

  static propTypes = {
    onSaveSucceeded: PropTypes.func.isRequired,
    orderLineId: PropTypes.string,
    photoIds: PropTypes.array,
    tenantPhotoProductId: PropTypes.string
  }

  state = {
    orderLine: undefined,
    photos: undefined,
    resourceOrderLine: undefined,
    resourceTypeMethodName: undefined,
    tenant: CurrentTenant.current(),
    tenantPhotoProduct: undefined
  }

  componentDidMount() {
    this.loadExistingOrInitializeOrderLine()
  }

  async loadExistingOrderLine() {
    const {orderLineId} = this.props
    const orderLine = await OrderLine
      .ransack({id_eq: orderLineId})
      .preload([
        "bundle_order_line.tenant_photo_product.photo_product.bundle.bundle_tenant_photo_products." +
        "tenant_photo_product.photo_product.photo_sheet_product.sheet_layout.sheet_size",

        "bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.photo.photo_types",
        "bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.boxes.sheet_layout_box",
        "bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.photo_quality",
        "bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.photo_sheet_product.photo_sheet_product_sheet_layouts.sheet_layout.boxes",
        "bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.photo_sheet_product.photo_sheet_product_sheet_layouts.sheet_layout" +
        ".sheet_size",
        "bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.sheet_layout.boxes",
        "bundle_order_line.bundle_order_line_elements.photo_sheet_product_order_line.sheet_size",

        "photo_sheet_product_order_line.boxes.photo.photo_types",
        "photo_sheet_product_order_line.boxes.sheet_layout_box",
        "photo_sheet_product_order_line.photo_quality",
        "photo_sheet_product_order_line.photo_sheet_product.photo_sheet_product_sheet_layouts.sheet_layout.boxes",
        "photo_sheet_product_order_line.photo_sheet_product.photo_sheet_product_sheet_layouts.sheet_layout.sheet_size",
        "photo_sheet_product_order_line.sheet_layout.boxes",
        "photo_sheet_product_order_line.sheet_layout.sheet_size",
        "photo_sheet_product_order_line.sheet_size",
        "photo_sheet_product_order_line.tenant_photo_product.photo_product.photo_sheet_product"
      ])
      .select({
        Bundle: MerfotoComponentsOrderLinesIndex.BundleSelectedAttributes,
        BundleOrderLine: ["id", "tenantPhotoProductId"],
        BundleOrderLineElement: ["bundleTenantPhotoProductId", "id"],
        BundleTenantPhotoProduct: ["id", "tenantPhotoProductId"],
        OrderLine: ["id"],
        Photo: ["height", "fullSizeUrl", "id", "photoSessionGroupId", "photoSessionGroupContactId", "width"],
        PhotoProduct: MerfotoComponentsOrderLinesIndex.PhotoProductSelectedAttributes,
        PhotoQuality: ["id", "name", "supportsMultiplePhotosOnSheet"],
        PhotoSheetProduct: ["id", "name"],
        PhotoSheetProductOrderLine: ["id", "photoSessionGroupContactId", "tenantPhotoProductId"],
        PhotoSheetProductOrderLineBox: ["id", "rotation", "photoHeightPercent", "photoLeftPercent", "photoTopPercent", "photoWidthPercent", "sheetLayoutBoxId"],
        PhotoSheetProductSheetLayout: ["id"],
        PhotoType: ["id"],
        SheetLayout: ["id", "name", "webshopName"],
        SheetLayoutBox: ["heightPercent", "id", "leftPercent", "photoTypeId", "topPercent", "widthPercent"],
        SheetSize: ["heightMm", "id", "widthMm"],
        TenantPhotoProduct: MerfotoComponentsOrderLinesIndex.TenantPhotoProductSelectedAttributes
      })
      .first()

    let tenantPhotoProduct

    if (orderLine.photoSheetProductOrderLine()) {
      tenantPhotoProduct = orderLine.photoSheetProductOrderLine().tenantPhotoProduct()

      if (!tenantPhotoProduct) {
        throw new Error(
          `No tenantPhotoProduct with ID ${orderLine.photoSheetProductOrderLine().tenantPhotoProductId()} ` +
          `on photoSheetProductOrderLine ${orderLine.photoSheetProductOrderLine().id()}`
        )
      }
    } else if (orderLine.bundleOrderLine()) {
      tenantPhotoProduct = orderLine.bundleOrderLine().tenantPhotoProduct()

      if (!tenantPhotoProduct) {
        throw new Error(
          `No tenantPhotoProduct with ID ${orderLine.bundleOrderLine().tenantPhotoProductId()} ` +
          `on bundleOrderLine ${orderLine.bundleOrderLine().id()}`
        )
      }
    } else {
      throw new Error(`No photoSheetProductOrderLine or bundleOrderLine on orderLine ${orderLine.id()}`)
    }

    const resourceTypeClassName = `${Inflection.camelize(tenantPhotoProduct.photoProduct().resourceType())}OrderLine`
    const resourceTypeMethodName = `${Inflection.camelize(tenantPhotoProduct.photoProduct().resourceType(), true)}OrderLine`
    const resourceOrderLine = orderLine[resourceTypeMethodName]()

    if (!resourceOrderLine) throw new Error(`Could not get ${resourceTypeMethodName} from the order line`)

    // FIXME: Needs to remember whatever photos was available when the order line was saved to show the exact same photos
    let photos

    if (orderLine.bundleOrderLine()) {
      photos = this.photosFromBundleOrderLine(orderLine.bundleOrderLine())
    } else if (orderLine.photoSheetProductOrderLine()) {
      photos = orderLine.photoSheetProductOrderLine().boxes().loaded().map((box) => box.photo())
    } else {
      throw new Error("Dont know where to load photos from")
    }

    this.setState({
      orderLine,
      photos,
      resourceOrderLine,
      resourceTypeClassName,
      resourceTypeMethodName,
      tenantPhotoProduct
    })
  }

  loadExistingOrInitializeOrderLine() {
    const {orderLineId} = this.props

    if (orderLineId) {
      this.loadExistingOrderLine()
    } else {
      this.loadNewOrderLine()
    }
  }

  photosFromBundleOrderLine(bundleOrderLine) {
    const photos = []

    for (const bundleOrderLineElement of bundleOrderLine.bundleOrderLineElements().loaded()) {
      if (bundleOrderLineElement.photoSheetProductOrderLine()) {
        for (const box of bundleOrderLineElement.photoSheetProductOrderLine().boxes().loaded()) {
          const existingPhoto = photos.find((photoInPhotos) => photoInPhotos.id() == box.photo().id())

          if (!existingPhoto) photos.push(box.photo())
        }
      }
    }

    return photos
  }

  loadNewOrderLine() {
    this.loadTenantPhotoProduct()
    this.loadPhotos()
    this.setState({
      orderLine: new OrderLine()
    })
  }

  async loadTenantPhotoProduct() {
    const {tenantPhotoProductId} = this.props
    const {tenant} = this.state

    let ransackParams

    if (tenantPhotoProductId) {
      ransackParams = {
        id_eq: tenantPhotoProductId
      }
    } else {
      ransackParams = {
        default_tenant_photo_sheet_product_on_tenant_shop_settings_tenant_id_eq: tenant.id()
      }
    }

    const tenantPhotoProduct = await TenantPhotoProduct
      .ransack(ransackParams)
      .preload([
        "photo_product.bundle.bundle_tenant_photo_products.tenant_photo_product.photo_product.photo_sheet_product.sheet_layout.boxes",
        "photo_product.bundle.bundle_tenant_photo_products.tenant_photo_product.photo_product.photo_sheet_product.sheet_layout.sheet_size",
        "photo_product.photo_sheet_product"
      ])
      .select({
        Bundle: MerfotoComponentsOrderLinesIndex.BundleSelectedAttributes,
        BundleTenantPhotoProduct: ["id"],
        PhotoProduct: MerfotoComponentsOrderLinesIndex.PhotoProductSelectedAttributes,
        PhotoSheetProduct: ["id", "name"],
        SheetLayout: ["id"],
        SheetLayoutBox: ["heightPercent", "id", "leftPercent", "topPercent", "widthPercent"],
        SheetSize: ["heightMm", "id", "widthMm"],
        TenantPhotoProduct: MerfotoComponentsOrderLinesIndex.TenantPhotoProductSelectedAttributes
      })
      .first()

    if (!tenantPhotoProduct) throw new Error(`Couldn't read tenant photo product: ${JSON.stringify(ransackParams)}`)

    const resourceTypeMethodName = `${Inflection.camelize(tenantPhotoProduct.photoProduct().resourceType(), true)}OrderLine`
    const resourceTypeClassName = `${Inflection.camelize(tenantPhotoProduct.photoProduct().resourceType())}OrderLine`
    const modelClass = digg(require("@kaspernj/api-maker/src/models"), resourceTypeClassName)
    const resourceOrderLine = new modelClass()

    this.setState({
      resourceOrderLine,
      resourceTypeClassName,
      resourceTypeMethodName,
      tenantPhotoProduct
    })
  }

  async loadPhotos() {
    const {photoIds} = this.props
    const photos = await Photo
      .ransack({id_eq_any: photoIds})
      .preload("photo_types")
      .select({
        Photo: ["height", "id", "fullSizeUrl", "width"],
        PhotoType: ["id"]
      })
      .toArray()

    if (photos.length === 0) throw new Error(`No photos could be found: ${JSON.stringify(photoIds)}`)

    this.setState({photos})
  }

  render() {
    const {orderLine, photos, resourceOrderLine, tenantPhotoProduct} = this.state

    if (orderLine && photos && resourceOrderLine && tenantPhotoProduct) {
      return this.content()
    }

    return null
  }

  content() {
    const {orderLine, photos, resourceOrderLine, resourceTypeClassName, resourceTypeMethodName, tenantPhotoProduct} = this.state
    const photoProduct = tenantPhotoProduct.photoProduct()
    const requireFileName = Inflection.dasherize(Inflection.underscore(photoProduct.resourceType()))
    const ResourceComponent = require(`merfoto/components/order-lines/edit/resources/${requireFileName}`).default
    const resourceComponentProps = {photos, tenantPhotoProduct}

    resourceComponentProps[resourceTypeMethodName] = resourceOrderLine

    return (
      <div className="content-container">
        <form onSubmit={digg(this, "onSubmit")}>
          {orderLine.isPersisted() &&
            <input name="order_line_id" type="hidden" value={orderLine.id()} />
          }
          {resourceOrderLine.isPersisted() &&
            <input name="order_line[orderable_id]" type="hidden" value={resourceOrderLine.id()} />
          }
          <input name="order_line[orderable_type]" type="hidden" value={resourceTypeClassName} />
          <ResourceComponent {...resourceComponentProps} />
        </form>
      </div>
    )
  }

  onSubmit = async (e) => {
    e.preventDefault()

    const {resourceOrderLine} = this.state
    const {saveAction} = this.props
    const {target: form} = e
    const formData = new FormData(form)

    try {
      await saveAction(formData)

      if (resourceOrderLine?.isPersisted()) {
        FlashMessage.success(I18n.t("js.merfoto.photo_sheet_product_order_lines.edit.the_sheet_was_updated"))
      } else {
        FlashMessage.success(I18n.t("js.merfoto.photo_sheet_product_order_lines.edit.the_sheet_was_added_to_the_basket"))
      }

      this.props.onSaveSucceeded()
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }
}
