export default class ButtonsTableExpand extends React.Component {
  static propTypes = PropTypesExact({
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    expanded: PropTypes.bool
  })

  render() {
    const {expanded, onClick} = this.props

    return (
      <a className={this.className()} href="#" onClick={onClick} title={I18n.t("js.buttons.table_expand.expand")}>
        <Icon icon={expanded ? "angle-up" : "angle-down"} size="small" />
      </a>
    )
  }

  className() {
    const {className, expanded} = this.props
    const classNames = ["components-buttons-table-expand", "btn", "btn-action"]

    if (className) {
      classNames.push(className)
    }

    if (expanded) {
      classNames.push("expanded")
    } else {
      classNames.push("collapsed")
    }

    return classNames.join(" ")
  }
}
