import "./style"
import {ModelsResponseReader, Result} from "@kaspernj/api-maker"
import AddRelationshipToWorkplaceModal from "admin/components/workplaces/bottom-bar/add-relationship-to-workplace-modal"
import BodyPortal from "components/body-portal"
import BottomBarAction from "admin/components/layout/bottom-bar/action"
import BottomBarActions from "admin/components/layout/bottom-bar/actions"
import BottomBarContent from "admin/components/layout/bottom-bar/content"
import BottomBarSection from "admin/components/layout/bottom-bar/section"
import {UserContext} from "shared/user-context"

export default class AdminWorkplacesBottomBar extends React.Component {
  static defaultProps = {
    showExpandableContentButtons: true
  }

  static propTypes = PropTypesExact({
    actionContent: PropTypes.node,
    children: PropTypes.node,
    destroyAction: PropTypes.func,
    enableDeletion: PropTypes.bool,
    modelClass: PropTypes.func.isRequired,
    result: PropTypes.instanceOf(Result),
    showExpandableContentButtons: PropTypes.bool
  })

  state = {
    currentWorkplace: undefined,
    linksCount: undefined,
    showAddRelationshipToWorkplaceModal: false
  }

  componentDidMount() {
    if (Devise.isUserSignedIn()) {
      this.loadLinks()
    }
  }

  async loadLinks() {
    const {modelClass} = digs(this.props, "modelClass")
    const workplaceQuery = Workplace
      .ransack()
      .abilities({Workplace: ["csvExport", "deleteAllLinks", "softDelete", "unSoftDelete"]})
    const currentWorkplaceResponse = await Workplace.current({
      links_count: {
        ransack: {
          resource_type_eq: modelClass.modelClassData().name
        }
      },
      params: workplaceQuery.params()
    })
    const currentWorkplace = ModelsResponseReader.first(digg(currentWorkplaceResponse, "current"))
    const linksCount = digg(currentWorkplaceResponse, "links_count")

    this.setState({currentWorkplace, linksCount, workplace: currentWorkplace})
  }

  render() {
    const {modelClass} = this.props
    const {linksCount, showAddRelationshipToWorkplaceModal} = this.state

    return (
      <div className="admin-components-workplaces-bottom-bar d-flex flex-fill">
        {showAddRelationshipToWorkplaceModal &&
          <BodyPortal>
            <AddRelationshipToWorkplaceModal modelClass={modelClass} onRequestClose={this.onAddRelationshipToWorkplaceModelRequestClose} />
          </BodyPortal>
        }
        {linksCount !== undefined && this.content()}
      </div>
    )
  }

  onAddRelationshipToWorkplaceModelRequestClose = () => this.setState({showAddRelationshipToWorkplaceModal: false})

  content() {
    const {actionContent, children, enableDeletion} = this.props
    const deletionActionsContent = this.deletionActionsContent()
    const generalBulkActions = this.generalBulkActionsContent()

    return (
      <>
        <BottomBarSection>
          <BottomBarActions>
            {generalBulkActions}
          </BottomBarActions>
        </BottomBarSection>
        {enableDeletion &&
          <BottomBarSection>
            <BottomBarActions>
              {deletionActionsContent}
            </BottomBarActions>
          </BottomBarSection>
        }
        <BottomBarSection>
          <BottomBarActions>
            {actionContent &&
              <>
                {actionContent}
              </>
            }
            {children}
          </BottomBarActions>
        </BottomBarSection>
      </>
    )
  }

  deletionActionsContent() {
    const {workplace} = this.state
    const {destroyAction} = this.props

    return (
      <>
        <BottomBarAction
          className={classNames({"soft-delete-link": !destroyAction, "delete-link": destroyAction})}
          disabled={!workplace.can("softDelete")}
          icon="trash"
          label={destroyAction ? I18n.t("js.global.delete") : I18n.t("js.admin.workplaces.bottom_bar.soft_delete")}
          onClick={this.onDeleteClicked}
        />
        <BottomBarAction
          className="un-soft-delete-link"
          disabled={!workplace.can("unSoftDelete")}
          icon="share"
          label={I18n.t("js.admin.workplaces.bottom_bar.un_soft_delete")}
          onClick={this.onUnSoftDeleteClicked}
        />
      </>
    )
  }

  generalBulkActionsContent() {
    const {linksCount, workplace} = this.state
    const {result, showExpandableContentButtons} = this.props

    return (
      <>
        <UserContext.Consumer>
          {([currentUser]) =>
            <>
              <EventConnection event="workplace_links_created" model={currentUser} onCall={this.onLinksCreated} />
              <EventConnection event="workplace_links_destroyed" model={currentUser} onCall={this.onLinksDestroyed} />
            </>
          }
        </UserContext.Consumer>
        <BottomBarContent className="select-count">
          {I18n.t("js.admin.workplaces.bottom_bar.chosen", {count: linksCount})}
        </BottomBarContent>
        {showExpandableContentButtons &&
          <>
            <BottomBarAction
              className="collapse-rows"
              icon="angle-up"
              label={I18n.t("js.admin.workplaces.bottom_bar.collapse_rows")}
              onClick={this.onCollapseRowsClicked}
            />
            <BottomBarAction
              className="expand-rows"
              icon="angle-down"
              label={I18n.t("js.admin.workplaces.bottom_bar.expand_rows")}
              onClick={this.onExpandRowsClicked}
            />
          </>
        }
        <BottomBarAction
          className="csv-export-link"
          disabled={!workplace.can("csvExport")}
          icon="file-alt"
          label={I18n.t("js.admin.workplaces.bottom_bar.csv_export")}
          onClick={this.onExportCsvClicked}
        />
        <BottomBarAction
          className="delete-all-links-link"
          disabled={!workplace.can("deleteAllLinks")}
          icon="recycle"
          label={I18n.t("js.admin.workplaces.bottom_bar.remove_all_marks")}
          onClick={this.onDeleteAllLinksClicked}
        />
        {result &&
          <BottomBarAction
            className="switch-all-on-workplace-link"
            icon="check-double"
            label={I18n.t("js.admin.workplaces.bottom_bar.select_all")}
            onClick={this.onWorkplaceSwitchQuery}
          />
        }
        <BottomBarAction
          className="add-relationship-to-workplace"
          icon="share-alt-square"
          label={I18n.t("js.admin.workplaces.bottom_bar.add_relationship_to_workplace")}
          onClick={this.onAddRelationshipToWorkplace}
        />
      </>
    )
  }

  getChosenRowIDs() {
    // TODO should be controlled component??
    const chosenRows = document.querySelectorAll(".admin-components-worker-plugins-model-checkbox[data-checked='true']")
    const ids = []

    for (const chosenRow of chosenRows) {
      const id = digg(chosenRow, "dataset", "modelId")

      ids.push(id)
    }

    return ids
  }

  modelName() {
    return digg(this.props.modelClass.modelClassData(), "name")
  }

  onAddRelationshipToWorkplace = (e) => {
    e.preventDefault()

    this.setState({
      showAddRelationshipToWorkplaceModal: true
    })
  }

  onCollapseRowsClicked = (e) => {
    e.preventDefault()

    const sharedTable = document.querySelector(".components-shared-models-table")
    const event = new CustomEvent("collapseRows", {detail: {ids: this.getChosenRowIDs()}})

    sharedTable.dispatchEvent(event)
  }

  onExpandRowsClicked = (e) => {
    e.preventDefault()

    const sharedTable = document.querySelector(".components-shared-models-table")
    const event = new CustomEvent("expandRows", {detail: {ids: this.getChosenRowIDs()}})

    sharedTable.dispatchEvent(event)
  }

  onLinksCreated = (args) => {
    const {modelClass} = digs(this.props, "modelClass")

    if (args.args.created[modelClass.modelClassData().name]) {
      const plusCount = args.args.created[modelClass.modelClassData().name].length

      this.setState((prevState) => ({
        linksCount: prevState.linksCount + plusCount
      }))
    }
  }

  onLinksDestroyed = (args) => {
    if (args.args.destroyed[this.modelName()]) {
      const minusCount = args.args.destroyed[this.modelName()].length

      this.setState((prevState) => ({
        linksCount: prevState.linksCount - minusCount
      }))
    }
  }

  onExportCsvClicked = async (e) => {
    e.preventDefault()

    const {currentWorkplace} = this.state
    const response = await currentWorkplace.csvExport({model_type: this.modelName()})
    const job = digg(response, "job")

    FlashMessage.component({
      component: <JoblerJobStatus job={job} />,
      timeout: false,
      title: I18n.t("js.admin.workplaces.bottom_bar.csv_export")
    })
  }

  onDeleteAllLinksClicked = async (e) => {
    e.preventDefault()

    if (!await CustomConfirm.confirm(I18n.t("js.admin.workplaces.bottom_bar.index.are_you_sure_you_want_to_remove_all_selections"))) {
      return
    }

    try {
      await this.state.currentWorkplace.deleteAllLinks({model_type: this.modelName()})
      FlashMessage.success(I18n.t("js.admin.workplaces.bottom_bar.all_link_were_removed"))
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onDeleteClicked = async (e) => {
    e.preventDefault()

    const {destroyAction} = this.props

    if (destroyAction) {
      destroyAction()

      return
    }

    if (!await CustomConfirm.confirm(I18n.t("js.admin.workplaces.bottom_bar.index.are_you_sure_you_want_to_soft_delete_selected_models"))) {
      return
    }

    const response = await this.state.currentWorkplace.softDelete({model_type: this.modelName()})
    const job = digg(response, "job")

    FlashMessage.component({
      component: <JoblerJobStatus job={job} />,
      timeout: false
    })
  }

  onWorkplaceSwitchQuery = async (e) => {
    e.preventDefault()

    const qParams = this.ransackQueryParams()

    let response

    try {
      response = await Workplace.switchQueryOnWorkplace({
        model_class: this.props.modelClass.modelClassData().name,
        ransack_query: qParams
      })
    } catch (error) {
      return FlashMessage.errorResponse(error)
    }

    const mode = digg(response, "mode")

    if (mode == "created") {
      FlashMessage.success(I18n.t("js.shared.table.index.all_found_resources_was_added_to_the_workspace"))
    } else if (mode == "destroyed") {
      FlashMessage.success(I18n.t("js.shared.table.index.all_found_resources_was_removed_from_the_workplace"))
    } else {
      throw new Error(`Unknown mode: ${mode}`)
    }
  }

  onUnSoftDeleteClicked = async (e) => {
    e.preventDefault()

    if (!await CustomConfirm.confirm(I18n.t("js.global.confirmation"))) {
      return
    }

    const response = await this.state.currentWorkplace.unSoftDelete({model_type: this.modelName()})
    const job = digg(response, "job")

    FlashMessage.component({
      component: <JoblerJobStatus job={job} />,
      timeout: false
    })
  }

  ransackQueryParams() {
    const {result} = this.props
    const ransackQueryParams = dig(result, "data", "collection", "queryArgs", "ransack")

    return ransackQueryParams
  }
}
