import getCurrentLayout from "tenants/get-current-layout"
import getPathToLayout from "shared/get-path-to-layout"
import {useNavigate} from "react-router-dom"

// TODO this should newer be used :-(
export default function useSessionReload() {
  const navigate = useNavigate()

  return async() => {
    const currentPath = `${location.pathname}${location.search}`
    const currentLayout = getCurrentLayout()
    const blankPath = await getPathToLayout(currentLayout, "blank")

    navigate(blankPath, {replace: true})
    await Timeout.set(100)
    navigate(currentPath, {replace: true})
  }
}
