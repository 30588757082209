export default class Cycler {
  constructor(items) {
    this.items = items
  }

  forCount(count) {
    const reduceBy = Math.floor(count / this.items.length) * this.items.length
    const index = count - reduceBy

    return this.items[index]
  }
}
