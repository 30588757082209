import "./style"
import PhotoSessionGroupContactsSelect, {modelToOption} from "merfoto/components/photo-session-group-contacts/select"
import {useContext, useMemo} from "react"
import Carousel from "merfoto/components/photos/carousel"
import CreateFromTokenForm from "merfoto/components/photo-session-group-contact-tokens/create-from-token-form.jsx"
import {UserContext} from "shared/user-context"
import WelcomeTopContainerCard from "./card"

MerfotoWelcomeTopCard.propTypes = {
  currentPhotoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact),
  onPhotoSessionGroupContactChanged: PropTypes.func,
  onPhotoSessionGroupContactTokenContactCreated: PropTypes.func,
  showPhotos: PropTypes.bool
}

export default function MerfotoWelcomeTopCard(props) {
  const [currentUser] = useContext(UserContext)
  const {
    className,
    currentPhotoSessionGroupContact,
    onPhotoSessionGroupContactChanged,
    onPhotoSessionGroupContactTokenContactCreated,
    showPhotos,
    ...restProps
  } = props
  const currentUserId = currentUser.id()
  const usersAlbumsCollection = useMemo(() => PhotoSessionGroupContact.ransack({
    albums_for_user: currentUserId
  }), [currentUserId])

  return (
    <div className={classNames("merfoto-components-welcome-top-container", className)} {...restProps}>
      <div className="container">
        <div className="row gx-5">
          <WelcomeTopContainerCard
            className="col-lg-4 my-3 hello-user-card"
            header={I18n.t("js.merfoto.welcome_top_container.index.hello_name", {name: currentUser.name()})}
          >
            <div className="mb-2">
              {I18n.t("js.merfoto.welcome_top_container.index.here_you_can_see_your_photos_and_our_products")}
            </div>
            <div className="fst-italic">
              {I18n.t("js.merfoto.welcome_top_container.index.all_our_products_are_build_so_you_can_build_your_own_sheet")}
            </div>
          </WelcomeTopContainerCard>
          <WelcomeTopContainerCard
            className="col-lg-4 my-3"
            header={I18n.t("js.merfoto.welcome_top_container.index.get_more_albums")}
          >
            <div className="mb-3 fst-italic">
              {I18n.t("js.merfoto.welcome_top_container.index.enter_the_provided_token_below")}
            </div>
            <CreateFromTokenForm onPhotoSessionGroupContactTokenContactCreated={onPhotoSessionGroupContactTokenContactCreated} />
          </WelcomeTopContainerCard>
          <WelcomeTopContainerCard
            className="col-lg-4 my-3"
            header={I18n.t("js.merfoto.welcome_top_container.index.choose_in_albums")}
          >
            <div className="mb-3">
              {I18n.t("js.merfoto.welcome_top_container.index.choose_in_existing_albums")}
            </div>
            {currentPhotoSessionGroupContact &&
              <div className="form-group">
                <PhotoSessionGroupContactsSelect
                  className="merfoto-components-photo-session-group-contacts-album-select"
                  collection={usersAlbumsCollection}
                  id="current_photo_session_group_contact_id"
                  onChange={onPhotoSessionGroupContactChanged}
                  value={currentPhotoSessionGroupContact ? modelToOption(currentPhotoSessionGroupContact) : null}
                  {...restProps}
                />
              </div>
            }
          </WelcomeTopContainerCard>
        </div>
        {currentPhotoSessionGroupContact && showPhotos &&
          <Carousel className="border-top m-2 pt-2" photoSessionGroupContact={currentPhotoSessionGroupContact} />
        }
      </div>
    </div>
  )
}
