export default function SharedModelsTableColumn(props) {
  const {className, ...restProps} = props

  return (
    <td
      className={classNames("components-shared-models-table-column", className)}
      {...restProps}
    />
  )
}
