import BlockUi from "./index"
import BlockUiCounter from "./counter"
import events from "./events"

const blockUiCounter = new BlockUiCounter()

export default class BlockUiLayoutComponent extends React.Component {
  state = ({
    blocking: blockUiCounter.blocked()
  })

  render() {
    const {blocking} = digs(this.state, "blocking")

    return (
      <>
        <EventEmitterListener event="blocked" events={events} onCalled={this.onBlocked} />
        <EventEmitterListener event="unblocked" events={events} onCalled={this.onUnblocked} />
        <BlockUi blocking={blocking} />
      </>
    )
  }

  onBlocked = () => {
    this.refreshBlocking()
  }

  onUnblocked = () => {
    this.refreshBlocking()
  }

  refreshBlocking() {
    this.setState({
      blocking: blockUiCounter.blocked()
    })
  }
}
