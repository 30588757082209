import "./style"

export default class MerfotoWelcomeHeader extends React.Component {
  render() {
    const {children, className, ...restProps} = this.props

    return (
      <div className={classNames("d-flex", "merfoto-components-welcome-header", className)} {...restProps}>
        <span className="fst-italic fw-bold me-2 text-uppercase welcome-header-content">
          {children}
        </span>
        <div className="flex-fill my-auto welcome-header-content-border" />
      </div>
    )
  }
}
