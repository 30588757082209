import getCurrentLayout from "tenants/get-current-layout"
import Stack from "react-bootstrap/Stack"
import {useLayoutRedirector} from "shared/hooks/use-layout-redirector"
import {useState} from "react"

export default function UsersPasswordsNew({className, ...restProps}) {
  const [submitting, setSubmitting] = useState(false)
  const layoutRedirector = useLayoutRedirector()
  const onSubmit = async (e) => {
    e.preventDefault()

    const form = digg(e, "target")
    const formData = new FormData(form)
    const layout = getCurrentLayout()

    setSubmitting(true)

    try {
      await User.newForgottenPasswordRequest(formData)
      FlashMessage.success(I18n.t("js.users.passwords.new.reset_password_instructions_were_sent_check_your_inbox_and_spam_filter"))
      await layoutRedirector(layout, "newUserSession")
    } catch (error) {
      FlashMessage.errorResponse(error)
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <div className={classNames("align-items-center", "components-users-passwords-new", "d-flex", "flex-column", className)} {...restProps}>
      <form onSubmit={onSubmit}>
        <Card>
          <Stack gap={3}>
            <BootstrapInput
              id="user_email"
              label={Email.humanAttributeName("email")}
              name="email"
            />
            <SubmitButton disabled={submitting} label={I18n.t("js.users.passwords.new.send_me_reset_password_instructions")} />
          </Stack>
        </Card>
      </form>
    </div>
  )
}
