import Select, {components} from "react-select"
import Form from "react-bootstrap/Form"
import inflection from "inflection"

const smallStyles = {
  container: (provided) => ({
    ...provided,
    fontSize: "0.765625rem",
    paddingTop: 0,
    paddingBottom: 0
  }),
  control: (provided) => ({
    ...provided,
    minHeight: "calc(1.5em + 0.5rem + 2px)"
  }),
  input: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 2
  })
}

const Option = (props) => {
  const optionProps = {
    ...props,
    innerProps: {
      ...props.innerProps,
      "data-value": props.data.value
    }
  }

  return <components.Option {...optionProps} />
}

export function defaultSelectProps(props) {
  const {
    attribute,
    defaultValue: defaultValueFromProps,
    id: idFromProps,
    isMulti,
    label: labelFromProps,
    model,
    name: nameFromProps,
    size,
    ...restProps
  } = props
  const label = (!("label" in props) && attribute && model && model.modelClass().humanAttributeName(attribute)) || labelFromProps
  const name = (!("name" in props) && attribute && model && `${model.modelClassData().paramKey}[${inflection.underscore(attribute)}]`) || nameFromProps
  const id = (!("id" in props) && attribute && model && `${model.modelClassData().paramKey}_${inflection.underscore(attribute)}`) || idFromProps
  const defaultValue = (!("defaultValue" in props) && attribute && model && model[attribute]()) || defaultValueFromProps

  return {
    "classNamePrefix": "nemoa-select-v2",
    "closeMenuOnSelect": !isMulti,
    "data-multiple": isMulti,
    "components": {Option},
    defaultValue,
    id,
    isMulti,
    label,
    name,
    "styles": size === "small" ? smallStyles : undefined,
    ...restProps
  }
}

export function NemoaSelectV2(props) {
  const {defaultValue: defaultValueFromProps, id, label, options, ...restProps} = defaultSelectProps(props)
  let defaultValue = defaultValueFromProps

  if (typeof defaultValue === "string") {
    defaultValue = options?.find((option) => option.value === defaultValueFromProps)
  }

  const select = (<Select id={id} {...restProps} defaultValue={defaultValue} options={options} />)

  if (label) {
    return (
      <Form.Group>
        {label && <Form.Label htmlFor={id}>{label}</Form.Label>}
        {select}
      </Form.Group>
    )
  }

  return select
}
