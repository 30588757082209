import Money from "js-money"

export default function presentValue(valueToUse) {
  if (valueToUse instanceof Date) {
    return I18n.l("time.formats.default", valueToUse)
  } else if (valueToUse instanceof Money) {
    return MoneyFormatter.format(valueToUse)
  } else if (typeof valueToUse == "boolean") {
    if (valueToUse) {
      return I18n.t("js.global.yes")
    }

    return I18n.t("js.global.no")
  } else if (Array.isArray(valueToUse)) {
    return valueToUse
      .map((valuePart) => presentValue(valuePart))
      .join(", ")
  }

  return valueToUse
}
