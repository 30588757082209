import CountrySelect from "components/addresses/country-select"
import ZipCodeInput from "components/zip-code-input"

export default class MerfotoCheckoutContactAddress extends React.Component {
  static propTypes = {
    addressInputNamePrefix: PropTypes.string.isRequired,
    address: PropTypes.instanceOf(Address).isRequired
  }

  addressCityRef = React.createRef()
  state = ({
    cityInputDisabled: false
  })

  render() {
    const {address, addressInputNamePrefix, className, ...restProps} = this.props
    const {cityInputDisabled} = digs(this.state, "cityInputDisabled")

    return (
      <div className={classNames("merfoto-components-checkout-address-form", className)} data-address-id={address.id()} {...restProps}>
        <div>
          <BootstrapInput attribute="street" className="form-control-sm" model={address} name={this.inputName("street")} />
        </div>
        <div>
          <BootstrapInput attribute="placeName" className="form-control-sm" model={address} name={this.inputName("placeName")} />
        </div>
        <div className="d-flex">
          <div className="me-2">
            <ZipCodeInput
              attribute="zipCode"
              className="form-control-sm"
              model={address}
              name={this.inputName("zipCode")}
              onNewZipCode={this.onZipCodeChanged}
              onRequestFinished={this.onRequestFinished}
              onRequestScheduled={this.onRequestScheduled}
            />
          </div>
          <div>
            <BootstrapInput
              attribute="city"
              className="form-control-sm"
              disabled={cityInputDisabled}
              inputRef={digg(this, "addressCityRef")}
              model={address}
              name={this.inputName("city")}
            />
          </div>
        </div>
        <div>
          <CountrySelect attribute="country" className="mb-2" model={address} name={this.inputName("country")} size="small" />
        </div>
      </div>
    )
  }

  inputName = (attribute) => {
    const {addressInputNamePrefix} = digs(this.props, "addressInputNamePrefix")
    const underscoreAttribute = Inflection.underscore(attribute)

    return `${addressInputNamePrefix}[${underscoreAttribute}]`
  }

  onZipCodeChanged = (result) => {
    digg(this, "addressCityRef", "current").value = digg(result, "cityName")
  }

  onRequestFinished = () => {
    this.setState({cityInputDisabled: false})
  }

  onRequestScheduled = () => {
    this.setState({cityInputDisabled: true})
  }
}
