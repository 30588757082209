import NavItem from "./nav-item"

export default class MerfotoNavbar extends React.Component {
  static propTypes = {
    activeIdentifier: PropTypes.string.isRequired,
    className: PropTypes.string,
    items: PropTypes.array.isRequired
  }

  render() {
    const {activeIdentifier, className, items, ...restProps} = this.props

    return (
      <div className={classNames("align-items-center", "d-flex", "justify-content-center", "merfoto-components-navbar", className)} {...restProps}>
        {items.map((item, index) =>
          <NavItem
            active={this.isNavItemActive(digg(item, "identifier"))}
            className={this.navItemClassName(index)}
            key={digg(item, "identifier")}
            {...item}
          />
        )}
      </div>
    )
  }

  isNavItemActive = (identifier) => {
    const {activeIdentifier} = digs(this.props, "activeIdentifier")

    return identifier == activeIdentifier
  }

  navItemClassName = (index) => {
    const {items} = digs(this.props, "items")

    if (index < items.length) {
      return "me-4"
    }
  }
}
