import {currentPhotoSessionGroupContactIdAtom} from "shared/atoms/current-photo-session-group-contact-id-atom"
import Preview from "components/preview"
import {useLayoutRedirector} from "shared/hooks/use-layout-redirector"
import {useMemo} from "react"
import {useSetRecoilState} from "recoil"

MerfotoPhotoSessionGroupsCardPhotoCard.defaultProps = {
  showBuyAction: true
}

MerfotoPhotoSessionGroupsCardPhotoCard.propTypes = {
  allowDownloadOfPhotos: PropTypes.bool.isRequired,
  photo: PropTypes.instanceOf(Photo).isRequired,
  photoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact).isRequired,
  showBuyAction: PropTypes.bool
}

export default function MerfotoPhotoSessionGroupsCardPhotoCard({
  allowDownloadOfPhotos,
  className,
  photo,
  photoSessionGroupContact,
  showBuyAction,
  ...restProps
}) {
  const layoutRedirector = useLayoutRedirector()
  const setCurrentPhotoSessionGroupContact = useSetRecoilState(currentPhotoSessionGroupContactIdAtom)
  const previewActions = useMemo(() => {
    const onBuyPhotoClicked = (e) => {
      e.preventDefault()

      setCurrentPhotoSessionGroupContact(photoSessionGroupContact.id())

      layoutRedirector("merfoto", "reactRoot")
    }
    const actions = []

    if (showBuyAction) {
      actions.push({
        "className": "buy-photo-link",
        "label": I18n.t("js.merfoto.photo_session_groups.card.photo_card.buy"),
        "onClick": onBuyPhotoClicked
      })
    }

    if (allowDownloadOfPhotos) {
      actions.push({
        "className": "download-photo-link",
        "data-photo-id": photo.id(),
        "href": photo.url(),
        "label": I18n.t("js.merfoto.photo_session_groups.card.photo_card.download"),
        "target": "_blank"
      })
    }

    return actions
  }, [allowDownloadOfPhotos, layoutRedirector, photo, photoSessionGroupContact, setCurrentPhotoSessionGroupContact, showBuyAction])

  return (
    <Preview
      actions={previewActions}
      className={classNames("merfoto-components-photo-session-groups-card-photo-card", className)}
      data-photo-id={photo.id()}
      previewOnHover={false}
      showOpenLink={false}
      thumbnailUrl={photo.thumbnailUrl()}
      type={photo.contentType()}
      url={photo.fullSizeUrl()}
      {...restProps}
    />
  )
}
