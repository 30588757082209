import "./style"

export default class MutedHeader extends React.Component {
  render() {
    const {className, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-muted-header", className)} {...restProps} />
    )
  }
}
