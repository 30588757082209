import DeliveryMethodOption from "merfoto/components/order-deliveries/delivery-methods/delivery-method-option"
import logo from "./logo"
import Money from "js-money"

export default class MerfotoOrderDeliveriesDeliveryMethodsPickUp extends React.Component {
  static propTypes = {
    currentDeliveryMethodOption: PropTypes.object.isRequired
  }

  render() {
    const {className, currentDeliveryMethodOption, order, orderDelivery, ...restProps} = this.props
    const {deliveryMethod} = currentDeliveryMethodOption

    return (
      <DeliveryMethodOption
        checked={deliveryMethod == "pick_up"}
        className={classNames("merfoto-components-order-deliveries-delivery-methods-pick-up", className)}
        deliveryMethod="pick_up"
        identifier="pick-up"
        label={I18n.t("js.merfoto.order_deliveries.delivery_methods.pick_up.index.pick_up")}
        logo={logo}
        priceWithVat={new Money(0, Money.DKK)}
        {...restProps}
      />
    )
  }
}
