import "./style"
import cloneDeep from "clone-deep"
import PhotoAction from "./photo-action"
import sizeCalculator from "./size-calculator"

export default class SheetLayoutsPresentation extends React.Component {
  static defaultProps = {
    active: false,
    boxContent: ({photo}) => (
      <>
        {!photo &&
          <div className="d-flex justify-content-center align-items-center">
            <Icon
              className="photo-type-mismatch"
              icon="camera"
              style={{color: "red"}}
              tooltip={I18n.t("js.merfoto.components.sheet-layouts.presentation.photo_types_mismatch")}
            />
          </div>
        }
      </>
    ),
    photoPosition: false,
    photoRotation: false,
    photoZoom: false
  }

  static propTypes = {
    active: PropTypes.bool.isRequired,
    boxContent: PropTypes.func,
    boxesToPhotosMapping: PropTypes.object,
    boxProps: PropTypes.func,
    boxes: PropTypes.array,
    className: PropTypes.string,
    height: PropTypes.number,
    photoPosition: PropTypes.bool.isRequired,
    photoRotation: PropTypes.bool.isRequired,
    photoSheetProductOrderLine: PropTypes.instanceOf(PhotoSheetProductOrderLine),
    photoZoom: PropTypes.bool.isRequired,
    sheetLayout: PropTypes.instanceOf(SheetLayout).isRequired,
    sheetSize: PropTypes.instanceOf(SheetSize).isRequired,
    size: PropTypes.number,
    width: PropTypes.number
  }

  photoPosition = undefined
  photoZoom = undefined
  state = {
    boxesToPhotosMapping: undefined,
    height: this.calculateHeight(),
    photoPositionEnabled: digg(this, "props", "photoPosition") && (digg(window, "RAILS_ENV") != "production" || Devise.currentUser()?.isSuperAdmin()),
    photoZoomEnabled: digg(this, "props", "photoZoom") && (digg(window, "RAILS_ENV") != "production" || Devise.currentUser()?.isSuperAdmin()),
    width: this.calculateWidth()
  }

  photoRotateLeftLinkProps = ({sheetLayoutBox}) => ({
    onClick: this.eventPreventDefault,
    onMouseDown: (e) => this.onPhotoRotateLeft(e, sheetLayoutBox)
  })

  photoRotateRightLinkProps = ({sheetLayoutBox}) => ({
    onClick: this.eventPreventDefault,
    onMouseDown: (e) => this.onPhotoRotateRight(e, sheetLayoutBox)
  })

  photoZoomLinkProps = ({sheetLayoutBox}) => ({
    onClick: this.eventPreventDefault,
    onMouseDown: (e) => this.onPhotoZoomMouseDown(e, sheetLayoutBox)
  })

  photoActions = this.calculatePhotoActions()

  calculateHeight() {
    const {height, width} = this.props
    const {sheetSize} = digs(this.props, "sheetSize")

    if (height) return height
    if (width) {
      return (sheetSize.heightMm() / sheetSize.widthMm()) * width
    }

    return digg(this.sizeCalculation(), "height")
  }

  calculateWidth() {
    const {height, width} = this.props
    const {sheetSize} = digs(this.props, "sheetSize")

    if (width) return width
    if (height) {
      return (sheetSize.widthMm() / sheetSize.heightMm()) * height
    }

    return digg(this.sizeCalculation(), "width")
  }

  sizeCalculation() {
    if (!this.sizeCalculationResult) {
      const size = this.props.size || 640

      this.sizeCalculationResult = sizeCalculator(digg(this, "props", "sheetSize"), size)
    }

    return this.sizeCalculationResult
  }

  componentDidMount() {
    this.setState({
      boxesToPhotosMapping: this.guessBoxesToPhotosMapping()
    })
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.boxesToPhotosMapping && this.props.boxesToPhotosMapping !== prevProps.boxesToPhotosMapping) {
      this.updateBoxesToPhotosMapping()
    }

    if (this.props.sheetSize && prevProps.sheetSize && this.props.sheetSize.id() != prevProps.sheetSize.id()) {
      this.sizeCalculationResult = undefined
      this.setState({
        height: this.calculateHeight(),
        width: this.calculateWidth()
      })
    }
  }

  render() {
    const {
      active,
      boxContent,
      boxesToPhotosMapping: boxesToPhotosMappingFromProps,
      boxProps,
      boxes,
      className,
      photoPosition,
      photoRotation,
      photoSheetProductOrderLine,
      photoZoom,
      sheetLayout,
      sheetSize,
      size,
      ...restProps
    } = this.props
    const {boxesToPhotosMapping, height, photoPositionEnabled, width} = this.state
    const boxesToUse = this.boxesToUse()

    return (
      <div
        className={classNames("components-sheet-layouts-presentation", className)}
        data-active={active}
        data-sheet-layout-id={sheetLayout.id()}
        data-sheet-size-id={sheetSize.id()}
        style={{height: `${height}px`, width: `${width}px`}}
        {...restProps}
      >
        <EventListener event="mousemove" onCalled={this.onWindowMouseMove} target={window} />
        <EventListener event="mouseup" onCalled={this.onWindowMouseUp} target={window} />

        {boxesToPhotosMapping && boxesToUse.map((sheetLayoutBox) =>
          <div
            className="box-preview"
            data-box-id={sheetLayoutBox.id()}
            data-photo-id={boxesToPhotosMapping && boxesToPhotosMapping[sheetLayoutBox.id()]?.photo?.id()}
            key={sheetLayoutBox.id()}
            onMouseDown={(e) => photoPositionEnabled && this.onBoxMouseDown(e, sheetLayoutBox)}
            style={this.boxStyle(sheetLayoutBox)}
            {...boxProps && boxProps({sheetLayoutBox})}
          >
            <div className="position-relative">
              <div className="box-image-container" style={this.boxImageContainerStyle({sheetLayoutBox})} />
            </div>
            <div className="box-content">
              {boxContent && boxContent(this.boxContentArgs(sheetLayoutBox))}
              {this.photoActions.length > 0 &&
                <div className="photo-actions">
                  {this.photoActions.map((photoActionProps, index) =>
                    <PhotoAction
                      key={index}
                      sheetLayoutBox={sheetLayoutBox}
                      {...photoActionProps}
                    />
                  )}
                </div>
              }
            </div>
          </div>
        )}
      </div>
    )
  }

  boxContentArgs = (sheetLayoutBox) => {
    const {boxesToPhotosMapping, height, width} = this.state
    const boxMapping = boxesToPhotosMapping[sheetLayoutBox.id()]

    let photoHeightPercent, photoLeftPercent, photoTopPercent, photoWidthPercent
    let rotation = 0

    if (boxMapping) {
      const {imageHeight, imageLeft, imageTop, imageWidth} = boxMapping

      photoWidthPercent = (imageWidth / width) * 100
      photoHeightPercent = (imageHeight / height) * 100
      photoTopPercent = (imageTop / height) * 100
      photoLeftPercent = (imageLeft / width) * 100
      rotation = boxMapping["rotation"]
    }

    return {
      photo: boxMapping?.photo,
      photoHeightPercent,
      photoLeftPercent,
      photoTopPercent,
      photoWidthPercent,
      photoSheetProductOrderLineBox: boxMapping?.photoSheetProductOrderLineBox,
      rotation,
      sheetLayoutBox
    }
  }

  boxesToUse() {
    const {boxes, sheetLayout} = this.props

    return boxes || sheetLayout.boxes().loaded()
  }

  defaultPhotoRotationForBoxAndPhoto(box, photo) {
    const {sheetSize} = digs(this.props, "sheetSize")
    const boxWidthMm = sheetSize.widthMm() * box.widthPercent()
    const boxHeightMm = sheetSize.heightMm() * box.heightPercent()

    if (photo.width() > photo.height() && boxHeightMm > boxWidthMm) {
      return 90
    } else if (photo.height() > photo.width() && boxWidthMm > boxHeightMm) {
      return 90
    }

    return 0
  }

  guessBoxesToPhotosMapping() {
    const {boxesToPhotosMapping, photoSheetProductOrderLine} = this.props

    if (boxesToPhotosMapping) {
      return this.boxesToPhotosMappingFromGivenMapping()
    } else if (photoSheetProductOrderLine) {
      return this.boxesToPhotosMappingFromPhotoSheetProductOrderLine()
    }

    return {}
  }

  boxesToPhotosMappingFromGivenMapping() {
    const {height, width} = this.state
    const {boxesToPhotosMapping, sheetLayout} = digs(this.props, "boxesToPhotosMapping", "sheetLayout")
    const mappings = {}

    for (const sheetLayoutBoxId in boxesToPhotosMapping) {
      const sheetLayoutBox = sheetLayout.boxes().loaded().find((sheetLayoutBox) => sheetLayoutBox.id() == sheetLayoutBoxId)
      const photo = digg(boxesToPhotosMapping, sheetLayoutBoxId)

      if (photo === null) {
        mappings[sheetLayoutBoxId] = null
        continue
      }

      if (!photo) {
        throw new Error(`Photo couldn't be found for sheet layout box ${sheetLayoutBoxId}`)
      }

      if (!sheetLayoutBox) {
        throw new Error(
          `Could not find sheet layout box with that ID: ${sheetLayoutBoxId} in: ${sheetLayout.boxes().loaded().map((box) => box.id()).join(", ")}`
        )
      }

      const rotation = this.defaultPhotoRotationForBoxAndPhoto(sheetLayoutBox, photo)
      const imageSize = this.coverSizeForPhotoAndSheetLayoutBox(photo, sheetLayoutBox, rotation)
      const {height: imageHeight, width: imageWidth} = digs(imageSize, "height", "width")
      const boxWidth = width * (sheetLayoutBox.widthPercent() / 100.0)
      const boxHeight = height * (sheetLayoutBox.heightPercent() / 100.0)

      let imageLeft

      if (rotation == 0 || rotation == 180) {
        const boxImageWidthDifference = boxWidth - imageWidth

        imageLeft = boxImageWidthDifference / 2.0
      } else if (rotation == 90 || rotation == 270) {
        const boxImageHeightDifference = boxHeight - imageWidth

        imageLeft = boxImageHeightDifference / 2.0
      } else {
        throw new Error(`Unhandled rotation: ${rotation}`)
      }

      mappings[sheetLayoutBoxId] = {
        imageHeight,
        imageLeft,
        imageTop: 0,
        imageWidth,
        photo: digg(boxesToPhotosMapping, sheetLayoutBoxId),
        rotation
      }
    }

    return mappings
  }

  boxesToPhotosMappingFromPhotoSheetProductOrderLine() {
    const {photoSheetProductOrderLine} = digs(this.props, "photoSheetProductOrderLine")
    const mappings = {}
    const {height, width} = digs(this.state, "height", "width")

    for (const photoSheetProductOrderLineBox of photoSheetProductOrderLine.boxes().loaded()) {
      const sheetLayoutBox = photoSheetProductOrderLineBox.sheetLayoutBox()
      const photo = photoSheetProductOrderLineBox.photo()

      if (!photo) {
        throw new Error(`Photo couldn't be found for photo sheet product order line box ${photoSheetProductOrderLineBox.id()}`)
      }

      if (!sheetLayoutBox) {
        throw new Error(
          `Sheet layout box ${photoSheetProductOrderLineBox.sheetLayoutBoxId()} not found ` +
          `on photo sheet product order line box ${photoSheetProductOrderLineBox.id()}`
        )
      }

      const imageWidth = (photoSheetProductOrderLineBox.photoWidthPercent() / 100) * width
      const imageHeight = (photoSheetProductOrderLineBox.photoHeightPercent() / 100) * height
      const imageLeft = (photoSheetProductOrderLineBox.photoLeftPercent() / 100) * width
      const imageTop = (photoSheetProductOrderLineBox.photoTopPercent() / 100) * height

      mappings[photoSheetProductOrderLineBox.sheetLayoutBoxId()] = {
        imageHeight,
        imageLeft,
        imageTop,
        imageWidth,
        photo,
        photoSheetProductOrderLineBox,
        rotation: photoSheetProductOrderLineBox.rotation()
      }
    }

    return mappings
  }

  boxDimensions = (box) => {
    const {height, width} = digs(this.state, "height", "width")

    return {
      height: Math.round((box.heightPercent() / 100) * height),
      left: Math.round((box.leftPercent() / 100) * width),
      top: Math.round((box.topPercent() / 100) * height),
      width: Math.round((box.widthPercent() / 100) * width)
    }
  }

  boxStyle = (box) => {
    const boxDimensions = this.boxDimensions(box)
    const style = {
      height: `${digg(boxDimensions, "height")}px`,
      left: `${digg(boxDimensions, "left")}px`,
      top: `${digg(boxDimensions, "top")}px`,
      width: `${digg(boxDimensions, "width")}px`
    }

    return style
  }

  boxImageContainerStyle({sheetLayoutBox}) {
    const {boxesToPhotosMapping} = this.state
    const boxDimensions = this.boxDimensions(sheetLayoutBox)
    const {height: boxHeight, width: boxWidth} = boxDimensions
    const photoRotation = boxesToPhotosMapping[sheetLayoutBox.id()]?.["rotation"] || 0
    const style = {}

    if (photoRotation === 0) {
      style.width = `${boxWidth - 2}px`
      style.height = `${boxHeight - 2}px`

      style.left = "0px"
      style.top = "0px"
    } else if (photoRotation == 90) {
      style.width = `${boxHeight - 2}px`
      style.height = `${boxWidth - 2}px`

      style.left = `${boxWidth - 2}px`
      style.top = "0px"
    } else if (photoRotation == 180) {
      style.width = `${boxWidth - 2}px`
      style.height = `${boxHeight - 2}px`

      style.left = `${boxWidth - 2}px`
      style.top = `${boxHeight - 2}px`
    } else if (photoRotation == 270) {
      style.width = `${boxHeight - 2}px`
      style.height = `${boxWidth - 2}px`

      style.left = "0px"
      style.top = `${boxHeight - 2}px`
    } else {
      throw new Error(`Unhandled photo rotation: ${photoRotation}`)
    }

    if (boxesToPhotosMapping && sheetLayoutBox.id() in boxesToPhotosMapping && boxesToPhotosMapping[sheetLayoutBox.id()]?.photo) {
      const {imageLeft, imageTop, imageWidth, photo} = digs(boxesToPhotosMapping[sheetLayoutBox.id()], "imageLeft", "imageTop", "imageWidth", "photo")
      const {imageHeight} = boxesToPhotosMapping[sheetLayoutBox.id()]

      style.backgroundImage = `url('${photo.fullSizeUrl()}')`
      style.backgroundPosition = `${imageLeft}px ${imageTop}px`

      if (imageHeight) {
        style.backgroundSize = `${imageWidth}px ${imageHeight}px`
      } else {
        style.backgroundSize = `${imageWidth}px`
      }

      if (photoRotation) {
        style.transform = `rotate(${photoRotation}deg)`
        style.transformOrigin = "0% 0%"
      }
    }

    return style
  }

  calculatePhotoActions() {
    const {photoRotation} = this.props
    const {photoZoomEnabled} = this.state
    const photoActions = []

    if (photoRotation) {
      photoActions.push({
        className: "photo-rotate-left",
        icon: "undo-alt",
        linkProps: this.photoRotateLeftLinkProps,
        title: I18n.t("js.sheet_layouts.presentation.index.rotate_left")
      })
    }

    if (photoZoomEnabled) {
      photoActions.push({
        className: "photo-zoom-action",
        icon: "expand",
        label: I18n.t("js.sheet_layouts.presentation.index.zoom"),
        linkProps: this.photoZoomLinkProps
      })
    }

    if (photoRotation) {
      photoActions.push({
        className: "photo-rotate-right",
        icon: "redo-alt",
        linkProps: this.photoRotateRightLinkProps,
        title: I18n.t("js.sheet_layouts.presentation.index.rotate_right")
      })
    }

    return photoActions
  }

  eventPreventDefault = (e) => e.preventDefault()

  onBoxMouseDown(e, sheetLayoutBox) {
    // Dont trigger if clicking a sub element like an action
    if (e.target.classList.contains("box-preview")) {
      e.preventDefault()

      const {boxesToPhotosMapping} = digs(this.state, "boxesToPhotosMapping")
      const boxMapping = digg(boxesToPhotosMapping, sheetLayoutBox.id())
      const {imageLeft, imageTop} = digs(boxMapping, "imageLeft", "imageTop")

      this.photoPosition = {
        clientX: digg(e, "clientX"),
        clientY: digg(e, "clientY"),
        originalLeft: imageLeft,
        originalTop: imageTop,
        sheetLayoutBox
      }
    }
  }

  restrictionsForPhotoInBox = (args) => {
    const {boxHeight, boxWidth, imageHeight, imageWidth, rotation} = digs(args, "boxHeight", "boxWidth", "imageHeight", "imageWidth", "rotation")

    let minX, minY

    if (rotation == 0) {
      minX = -(imageWidth - boxWidth)
      minY = -(imageHeight - boxHeight)
    } else if (rotation == 90) {
      minX = -(imageWidth - boxHeight)
      minY = -(imageHeight - boxWidth)
    } else if (rotation == 180) {
      minX = -(imageWidth - boxWidth)
      minY = -(imageHeight - boxHeight)
    } else if (rotation == 270) {
      minX = -(imageWidth - boxHeight)
      minY = -(imageHeight - boxWidth)
    } else {
      throw new Error(`Unhandled rotation: ${rotation}`)
    }

    return {minX, minY}
  }

  onPhotoPositionMouseMove = (e) => {
    const {
      clientX: originalClientX,
      clientY: originalClientY,
      originalLeft,
      originalTop,
      sheetLayoutBox
    } = digs(
      this.photoPosition,
      "clientX",
      "clientY",
      "originalLeft",
      "originalTop",
      "sheetLayoutBox"
    )
    const boxDimensions = this.boxDimensions(sheetLayoutBox)
    const {height: boxHeight, width: boxWidth} = digs(boxDimensions, "height", "width")
    const {boxesToPhotosMapping} = digs(this.state, "boxesToPhotosMapping")
    const boxMapping = digg(boxesToPhotosMapping, sheetLayoutBox.id())
    const {imageWidth, photo, rotation} = digs(boxMapping, "imageWidth", "photo", "rotation")
    const imageHeight = (photo.height() / photo.width()) * imageWidth
    const {clientX: newClientX, clientY: newClientY} = digs(e, "clientX", "clientY")
    const xChange = originalClientX - newClientX
    const yChange = originalClientY - newClientY

    if (photo.width() === null) {
      throw new Error(`Photo ${photo.id()} didn't have a width`)
    } else if (photo.height() === null) {
      throw new Error(`Photo ${photo.id()} didn't have a height`)
    }

    const {minX, minY} = this.restrictionsForPhotoInBox({boxHeight, boxWidth, imageHeight, imageWidth, rotation})
    let newX, newY

    if (rotation == 0) {
      newX = originalLeft - xChange
      newY = originalTop - yChange
    } else if (rotation == 90) {
      newX = originalLeft - yChange
      newY = originalTop + xChange
    } else if (rotation == 180) {
      newX = originalLeft + xChange
      newY = originalTop + yChange
    } else if (rotation == 270) {
      newX = originalLeft + yChange
      newY = originalTop - xChange
    } else {
      throw new Error(`Unhandled rotation: ${rotation}`)
    }

    if (newX > 0) {
      newX = 0
    } else if (newX < minX) {
      newX = minX
    }

    if (newY > 0) {
      newY = 0
    } else if (newY < minY) {
      newY = minY
    }

    boxMapping.imageLeft = newX
    boxMapping.imageTop = newY

    this.setState({
      boxesToPhotosMapping,
      lastUpdate: new Date()
    })
  }

  onPhotoPositionMouseUp = (e) => {
    e.preventDefault()
    this.photoPosition = undefined
  }

  onPhotoRotateLeft(e, sheetLayoutBox) {
    e.preventDefault()

    const {boxesToPhotosMapping} = digs(this.state, "boxesToPhotosMapping")
    const boxMapping = digg(boxesToPhotosMapping, sheetLayoutBox.id())
    const {rotation} = digs(boxMapping, "rotation")

    let newRotation

    if (rotation == 0) {
      newRotation = 270
    } else {
      newRotation = rotation - 90
    }

    const newBoxMappings = Object.assign({}, boxesToPhotosMapping)
    const newBoxMapping = Object.assign({}, boxMapping)

    newBoxMapping.rotation = newRotation
    newBoxMappings[sheetLayoutBox.id()] = newBoxMapping

    this.setState({
      boxesToPhotosMapping: newBoxMappings
    })
  }

  onPhotoRotateRight(e, sheetLayoutBox) {
    e.preventDefault()

    const {boxesToPhotosMapping} = digs(this.state, "boxesToPhotosMapping")
    const boxMapping = digg(boxesToPhotosMapping, sheetLayoutBox.id())
    const {rotation} = digs(boxMapping, "rotation")

    let newRotation

    if (rotation == 270) {
      newRotation = 0
    } else {
      newRotation = rotation + 90
    }

    const newBoxMappings = Object.assign({}, boxesToPhotosMapping)
    const newBoxMapping = Object.assign({}, boxMapping)

    newBoxMapping.rotation = newRotation
    newBoxMappings[sheetLayoutBox.id()] = newBoxMapping

    this.setState({
      boxesToPhotosMapping: newBoxMappings
    })
  }

  onPhotoZoomMouseDown(e, sheetLayoutBox) {
    e.preventDefault()

    const {boxesToPhotosMapping} = digs(this.state, "boxesToPhotosMapping")
    const boxMapping = digg(boxesToPhotosMapping, sheetLayoutBox.id())

    this.photoZoom = {
      clientY: digg(e, "clientY"),
      previousImageLeft: digg(boxMapping, "imageLeft"),
      previousImageWidth: digg(boxMapping, "imageWidth"),
      previousImageTop: digg(boxMapping, "imageTop"),
      sheetLayoutBox
    }
  }

  onPhotoZoomMouseMove = (e) => {
    this.updatePhotoZoomValue(e)
  }

  onPhotoZoomMouseUp = (e) => {
    this.updatePhotoZoomValue(e)
    this.photoZoom = undefined
  }

  onWindowMouseMove = (e) => {
    const {photoPosition, photoZoom} = digs(this, "photoPosition", "photoZoom")

    if (photoPosition) {
      e.preventDefault()
      this.onPhotoPositionMouseMove(e)
    }

    if (photoZoom) {
      e.preventDefault()
      this.onPhotoZoomMouseMove(e)
    }
  }

  onWindowMouseUp = (e) => {
    const {photoPosition, photoZoom} = digs(this, "photoPosition", "photoZoom")

    if (photoPosition) {
      e.preventDefault()
      this.onPhotoPositionMouseUp(e)
    }

    if (photoZoom) {
      e.preventDefault()
      this.onPhotoZoomMouseUp(e)
    }
  }

  // Updates the photos in state if a box-photo-mapping was changed in props
  updateBoxesToPhotosMapping() {
    const {boxesToPhotosMapping: boxesToPhotosMappingFromProps, sheetLayout} = digs(this.props, "boxesToPhotosMapping", "sheetLayout")
    const {boxesToPhotosMapping: boxesToPhotosMappingFromShape} = digs(this.state, "boxesToPhotosMapping")
    const newBoxesToPhotosMapping = cloneDeep(boxesToPhotosMappingFromShape)

    // Used to check if changed to avoid endless updates
    let changed = false

    for (const sheetLayoutBoxId in boxesToPhotosMappingFromProps) {
      const sheetLayoutBox = sheetLayout.boxes().loaded().find((sheetLayoutBox) => sheetLayoutBox.id() == sheetLayoutBoxId)
      const photo = digg(boxesToPhotosMappingFromProps, sheetLayoutBoxId)

      if (!photo) {
        newBoxesToPhotosMapping[sheetLayoutBoxId] = null
        continue
      }

      const rotation = this.defaultPhotoRotationForBoxAndPhoto(sheetLayoutBox, photo)
      const imageSize = this.coverSizeForPhotoAndSheetLayoutBox(photo, sheetLayoutBox, rotation)

      if (!(sheetLayoutBoxId in newBoxesToPhotosMapping)) {
        newBoxesToPhotosMapping[sheetLayoutBoxId] = {
          imageHeight: digg(imageSize, "height"),
          imageLeft: 0,
          imageTop: 0,
          imageWidth: digg(imageSize, "width"),
          photo: digg(boxesToPhotosMappingFromProps, sheetLayoutBoxId),
          rotation
        }
        changed = true
      } else if (newBoxesToPhotosMapping[sheetLayoutBoxId] &&
        newBoxesToPhotosMapping[sheetLayoutBoxId].photo?.id() != digg(boxesToPhotosMappingFromProps, sheetLayoutBoxId)?.id()) {
        newBoxesToPhotosMapping[sheetLayoutBoxId].imageHeight = digg(imageSize, "height")
        newBoxesToPhotosMapping[sheetLayoutBoxId].imageLeft = 0
        newBoxesToPhotosMapping[sheetLayoutBoxId].imageTop = 0
        newBoxesToPhotosMapping[sheetLayoutBoxId].imageWidth = digg(imageSize, "width")
        newBoxesToPhotosMapping[sheetLayoutBoxId].photo = digg(boxesToPhotosMappingFromProps, sheetLayoutBoxId)
        newBoxesToPhotosMapping[sheetLayoutBoxId].rotation = rotation
        changed = true
      }
    }

    if (changed) {
      this.setState({
        boxesToPhotosMapping: newBoxesToPhotosMapping,
        lastUpdate: new Date()
      })
    }
  }

  // Calculates initial size of a photo for using in a box in order to cover the whole box
  coverSizeForPhotoAndSheetLayoutBox(photo, sheetLayoutBox, rotation) {
    if (photo.width() === null || photo.height() === null) {
      throw new Error(`Photo ${photo.id()} didnt have a width or a height: ${photo.width()} ${photo.height()}`)
    }

    const boxDimensions = this.boxDimensions(sheetLayoutBox)
    const {height: boxHeight, width: boxWidth} = digs(boxDimensions, "height", "width")

    let boxHeightWithRotation, boxWidthWithRotation

    if (rotation == 0 || rotation == 180) {
      boxHeightWithRotation = boxHeight
      boxWidthWithRotation = boxWidth
    } else if (rotation == 90 || rotation == 270) {
      boxHeightWithRotation = boxWidth
      boxWidthWithRotation = boxHeight
    } else {
      throw new Error(`Unhandled rotation: ${rotation}`)
    }

    const photoWidthByBoxWidth = boxWidthWithRotation
    const photoHeightByBoxWidth = (photo.height() / photo.width()) * boxWidthWithRotation

    const photoWidthByBoxHeight = (photo.width() / photo.height()) * boxHeightWithRotation
    const photoHeightByBoxHeight = boxHeightWithRotation

    if (photoWidthByBoxWidth >= boxWidthWithRotation && photoHeightByBoxWidth >= boxHeightWithRotation) {
      return {
        width: photoWidthByBoxWidth,
        height: photoHeightByBoxWidth
      }
    }

    return {
      width: photoWidthByBoxHeight,
      height: photoHeightByBoxHeight
    }
  }

  updatePhotoZoomValue = (e) => {
    const {boxesToPhotosMapping} = digs(this.state, "boxesToPhotosMapping")
    const {photoZoom} = digs(this, "photoZoom")
    const {
      clientY: originalClientY,
      previousImageLeft,
      previousImageTop,
      previousImageWidth,
      sheetLayoutBox
    } = digs(
      photoZoom,
      "clientY",
      "previousImageLeft",
      "previousImageTop",
      "previousImageWidth",
      "sheetLayoutBox"
    )
    const {clientY: newClientY} = digs(e, "clientY")
    const sizeDifference = newClientY - originalClientY
    const boxMapping = digg(boxesToPhotosMapping, sheetLayoutBox.id())
    const {photo, rotation} = digs(boxMapping, "photo", "rotation")
    const boxDimensions = this.boxDimensions(sheetLayoutBox)
    const {width: boxWidth, height: boxHeight} = digs(boxDimensions, "height", "width")

    let boxHeightWithRotation, boxWidthWithRotation

    if (rotation == 0 || rotation == 180) {
      boxHeightWithRotation = boxHeight
      boxWidthWithRotation = boxWidth
    } else if (rotation == 90 || rotation == 270) {
      boxHeightWithRotation = boxWidth
      boxWidthWithRotation = boxHeight
    } else {
      throw new Error(`Unhandled rotation: ${rotation}`)
    }

    const minWidth = boxWidthWithRotation - previousImageLeft
    const minHeight = boxHeightWithRotation - previousImageTop

    let newWidth = previousImageWidth - (sizeDifference * 3)
    let newLeft, newTop

    // The absolute minimum width is the box width
    if (newWidth < boxWidthWithRotation) {
      newWidth = boxWidthWithRotation
    }

    // Try to adjust left position to accommodate new width
    if (newWidth < minWidth && previousImageLeft < 0) {
      const reduceLeftBy = minWidth - newWidth

      newLeft = previousImageLeft + reduceLeftBy

      if (newLeft > 0) {
        newWidth += 0 - newLeft
        newLeft = 0
      }
    }

    let newHeight = (photo.height() / photo.width()) * newWidth

    // The absolute minimum height is the box height
    if (newHeight < boxHeightWithRotation) {
      newHeight = boxHeightWithRotation
      newWidth = (photo.width() / photo.height()) * newHeight
    }

    // Try to adjust top position to accommodate new height
    if (newHeight < minHeight) {
      if (previousImageLeft < 0) {
        const reduceTopBy = minHeight - newHeight

        newTop = previousImageTop + reduceTopBy

        if (newTop > 0) {
          newHeight += 0 - newTop
          newTop = 0
        }
      } else {
        newHeight = minHeight
      }

      // Adjust the width to the new found height
      newWidth = (photo.width() / photo.height()) * newHeight
    }

    boxMapping["imageWidth"] = newWidth
    boxMapping["imageHeight"] = newHeight

    // Set new left and top and handle min restrictions
    const leftToSet = newLeft !== undefined ? newLeft : previousImageLeft
    const topToSet = newTop !== undefined ? newTop : previousImageTop
    const {minX, minY} = this.restrictionsForPhotoInBox({boxHeight, boxWidth, imageHeight: newHeight, imageWidth: newWidth, rotation})
    const {leftToSet: newLeftToSet, topToSet: newTopToSet} = this.calculateLeftToSetAndTopToSet(minX, minY, leftToSet, topToSet, rotation)

    boxMapping["imageLeft"] = newLeftToSet
    boxMapping["imageTop"] = newTopToSet

    this.setState({
      boxesToPhotosMapping,
      lastUpdate: new Date()
    })
  }

  calculateLeftToSetAndTopToSet(minX, minY, leftToSet, topToSet, rotation) {
    if (rotation == 0 || rotation == 180) {
      if (leftToSet < minX) {
        leftToSet = minX
      }

      if (topToSet < minY) {
        topToSet = minY
      }
    } else if (rotation == 90 || rotation == 270) {
      if (leftToSet < minY) {
        leftToSet = minY
      }

      if (topToSet < minX) {
        topToSet = minX
      }
    } else {
      throw new Error(`Unhandled rotation: ${rotation}`)
    }

    return {
      leftToSet,
      topToSet
    }
  }
}
