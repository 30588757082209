export default class MerfotoOrderDeliveriesDeliveryMethodsDeliveryMethodOptionContent extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    currentDeliveryMethodOption: PropTypes.object.isRequired,
    deliveryMethod: PropTypes.string.isRequired,
    onChecked: PropTypes.func.isRequired,
    order: PropTypes.instanceOf(Order).isRequired,
    orderDelivery: PropTypes.instanceOf(OrderDelivery).isRequired
  }

  render() {
    const {className, currentDeliveryMethodOption, deliveryMethod, onChecked, order, orderDelivery, ...restProps} = this.props
    const dashedDeliveryMethod = Inflection.dasherize(Inflection.underscore(deliveryMethod))
    const Options = require(`merfoto/components/order-deliveries/delivery-methods/${dashedDeliveryMethod}/options`).default

    return (
      <Options
        className={classNames("merfoto-components-order-deliveries-delivery-methods-options-content-for-delivery-method", className)}
        currentDeliveryMethodOption={currentDeliveryMethodOption}
        onChecked={this.onOrderDeliveryMethodChecked}
        order={order}
        orderDelivery={orderDelivery}
        {...restProps}
      />
    )
  }

  onOrderDeliveryMethodChecked = (args) => {
    const {deliveryMethod, onChecked} = digs(this.props, "deliveryMethod", "onChecked")

    onChecked(deliveryMethod, args)
  }
}
