import Money from "../components/money-input"

export default class MoneyInput extends React.Component {
  state = {
    currencies: undefined
  }

  componentDidMount() {
    this.loadCurrencies()
  }

  async loadCurrencies() {
    const response = await User.currenciesCollection()
    const currencies = digg(response, "currencies")

    this.setState({currencies})
  }

  render() {
    const {currencies} = this.state

    if (!currencies) {
      return ""
    }

    const args = {showCurrencyOptions: false, ...this.props}

    return (
      <Money currenciesCollection={currencies} {...args} />
    )
  }
}
