import "./style"

export default class MerfotoWelcomeTopContainerCard extends React.Component {
  render() {
    const {children, className, header, ...restProps} = this.props

    return (
      <div className={classNames("merfoto-components-welcome-top-container-card", "d-flex", className)} {...restProps}>
        <div className="align-self-center">
          <div className="mb-2 top-container-card-header">
            {header}
          </div>
          <div>
            {children}
          </div>
        </div>
      </div>
    )
  }
}
