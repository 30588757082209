export default function photoLabel(photo) {
  if (photo.photoSessionGroupContact()) {
    return `${photo.photoSessionGroup()?.name()}; ${photo.photoSessionGroupContact().contact()?.name()}`
  }

  if (photo.photoSessionGroup()) {
    return photo.photoSessionGroup().name()
  }

  return photo.id()
}
