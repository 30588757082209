export default class MerfotoComponentsBasketItemCardsBundleOrderLineElement extends React.Component {
  static propTypes = {
    bundleOrderLineElement: PropTypes.instanceOf(BundleOrderLineElement).isRequired
  }

  render() {
    const {bundleOrderLineElement, className, ...restProps} = this.props
    const requireName = Inflection.dasherize(Inflection.underscore(bundleOrderLineElement.resourceType()))
    const ElementComponent = require(`merfoto/components/basket/item-cards/bundle-order-line/elements/${requireName}`).default

    return (
      <div className={classNames("merfoto-components-basket-item-cards-bundle-order-line-element", className)} {...restProps}>
        <ElementComponent bundleOrderLineElement={bundleOrderLineElement} />
      </div>
    )
  }
}
