export default function dynamicColumnsPathToModelClass(modelClass, path) {
  let currentModelClass = modelClass

  for (const pathPart of path) {
    const relationships = digg(currentModelClass.modelClassData(), "relationships")
    const relationship = relationships.find((relationship) => digg(relationship, "name") == digg(pathPart, "name"))

    if (!relationship) {
      const relationshipsList = relationships.map((relationship) => digg(relationship, "name")).join(", ")
      const modelClassName = digg(currentModelClass.modelClassData(), "name")

      throw new Error(`Couldn't find relationship ${digg(pathPart, "name")} in ${modelClassName} relationships: ${relationshipsList}`)
    }

    const resourceName = digg(relationship, "resource_name")

    currentModelClass = digg(require("@kaspernj/api-maker/src/models"), resourceName)
  }

  return currentModelClass
}
