import {useEffect} from "react"

export function useTitle(title) {
  useEffect(() => {
    document.title = `${title} | ${I18n.t("js.merfoto.layout.index.merfoto")}`

    return () => {
      document.title = I18n.t("js.merfoto.layout.index.merfoto")
    }
  }, [title])
}
