import "./style"
import BottomBarSection from "admin/components/layout/bottom-bar/section"
import {NemoaSelectV2} from "components/nemoa-select-v2"
import {Result} from "@kaspernj/api-maker"
import {useNavigate} from "react-router-dom"

const perPageOptions = [
  {label: 30, value: 30},
  {label: 60, value: 60},
  {label: 100, value: 100}
]

export default function BottomBarPagination(props) {
  BottomBarPagination.propTypes = PropTypesExact({
    result: PropTypes.instanceOf(Result).isRequired
  })
  const {result} = props
  const currentPage = result.currentPage() || 1
  const totalCount = result.totalCount()
  const totalPages = result.totalPages()
  const perPage = result.perPage()
  const from = ((currentPage - 1) * perPage) + 1
  const to = Math.min(currentPage * perPage, totalCount)
  const previousPageNumber = Math.max(currentPage - 1, 1)
  const nextPageNumber = Math.min(currentPage + 1, totalPages)
  const onFirstPageButtonClicked = () => changeCurrentPage(1)
  const navigate = useNavigate()

  const changeCurrentPage = (page) => {
    navigate(pagePath(page))
  }

  const onPerPageChanged = ({value}) => {
    const perKey = props.result.data.collection.queryArgs.perKey || "per"
    const currentParams = Params.parse()

    currentParams[perKey] = value

    const newParams = Params.stringify(currentParams)
    const newPath = `${location.pathname}?${newParams}` // TODO this should obviously use useSearchParams

    navigate(newPath)
  }

  const pagePath = (pageNumber) => {
    const pageKey = props.result.data.collection.queryArgs.pageKey || "page"
    const currentParams = Params.parse()

    currentParams[pageKey] = pageNumber
    const newParams = Params.stringify(currentParams)
    const newPath = `${location.pathname}?${newParams}`

    return newPath
  }

  return (
    <>
      <BottomBarSection className="admin-components-layout-bottom-bar-pagination per-page-section">
        <div className="per-page-container">
          <NemoaSelectV2
            id="per_page_select"
            menuPlacement="top"
            onChange={onPerPageChanged}
            options={perPageOptions}
            size="small"
            value={perPageOptions.find((option) => option.value === perPage)}
          />
        </div>
        <span className="showing-from-to-text">
          {I18n.t("js.admin.layout.bottom_bar.pagination.showing_from_to_out_of_total", {from, to, total_count: totalCount})}
        </span>
      </BottomBarSection>
      <BottomBarSection className="admin-components-layout-bottom-bar-pagination pagination-section">
        <Link
          className="item-button first-page-button"
          onClick={onFirstPageButtonClicked}
          to={pagePath(1)}
        >
          <i className="la la-angle-double-left" />
        </Link>
        <Link
          className="item-button previous-page-button"
          onClick={() => changeCurrentPage(previousPageNumber)}
          to={pagePath(previousPageNumber)}
        >
          <i className="la la-angle-left" />
        </Link>
        <input
          className="current-page-input d-inline-block form-control input-small"
          onChange={(e) => changeCurrentPage(e.target.value)}
          type="number"
          value={currentPage}
        />
        <span className="pagination-of-text">
          {I18n.t("js.admin.layout_bottom_bar.pagination.of_total", {total: totalPages})}
        </span>
        <Link
          className="item-button next-page-button"
          onClick={() => changeCurrentPage(nextPageNumber)}
          to={pagePath(nextPageNumber)}
        >
          <i className="la la-angle-right" />
        </Link>
        <Link
          className="item-button last-page-button"
          onClick={() => changeCurrentPage(totalPages)}
          to={pagePath(totalPages)}
        >
          <i className="la la-angle-double-right" />
        </Link>
      </BottomBarSection>
    </>
  )
}
