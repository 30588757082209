import AttributePresenter from "components/attribute-presenter"
import SheetLayoutsPresentation from "components/sheet-layouts/presentation"
import UniquePhotosLabel from "merfoto/components/photo-sheet-product-order-lines/unique-photos-label"

export default class MerfotoComponentsBasketItemCardsBundleOrderLineElementsPhotoSheetProductOrderLine extends BaseComponent {
  static propTypes = {
    bundleOrderLineElement: PropTypes.instanceOf(BundleOrderLineElement).isRequired
  }

  render() {
    const {bundleOrderLineElement, className, ...restProps} = this.props
    const photoSheetProductOrderLine = bundleOrderLineElement.photoSheetProductOrderLine()
    const sheetLayout = photoSheetProductOrderLine.sheetLayout()

    return (
      <div
        className={classNames("merfoto-components-basket-item-cards-bundle-order-line-elements-photo-sheet-product-order-line", "d-flex", className)}
        {...restProps}
      >
        <SheetLayoutsPresentation
          photoSheetProductOrderLine={photoSheetProductOrderLine}
          sheetLayout={sheetLayout}
          sheetSize={photoSheetProductOrderLine.sheetSize()}
          size={84}
        />
        <div className="ms-2">
          <AttributePresenter className="mb-3 sheet-layout-label" label={SheetLayout.modelName().human()} upperCaseLabel>
            {sheetLayout.webshopName()}
          </AttributePresenter>
          <UniquePhotosLabel photoSheetProductOrderLine={photoSheetProductOrderLine} />
        </div>
      </div>
    )
  }
}
