import "./style"

export default class MerfotoNavbarNavItem extends React.Component {
  static defaultProps = {
    active: false,
    enabled: true
  }

  static propTypes = PropTypesExact({
    active: PropTypes.bool.isRequired,
    className: PropTypes.string,
    enabled: PropTypes.bool.isRequired,
    identifier: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired
  })

  render() {
    const {active, className, enabled, identifier, label, title, to, ...restProps} = this.props

    return (
      <Link
        className={classNames("align-content-center", "merfoto-components-navbar-nav-item", className)}
        data-active={active}
        data-enabled={enabled}
        data-identifier={identifier}
        to={enabled ? to : "#"}
        {...restProps}
      >
        <div className="align-content-center align-items-center d-md-flex d-none nav-item-title-container justify-content-center mx-auto">
          {title}
        </div>
        <div className="nav-item-label pt-2 text-center">
          {label}
        </div>
      </Link>
    )
  }
}
