import "./style"
import Action from "./action"
import BodyPortal from "components/body-portal"
import Modal from "components/modal"
import PositionRelativeTo from "components/position-relative-to"

export default class Preview extends React.Component {
  static defaultProps = {
    height: "auto",
    previewOnHover: true,
    showOpenLink: true,
    width: "100%"
  }

  static propTypes = {
    actions: PropTypes.array,
    className: PropTypes.string,
    content: PropTypes.string,
    height: PropTypes.string,
    previewOnHover: PropTypes.bool.isRequired,
    showOpenLink: PropTypes.bool.isRequired,
    thumbnailUrl: PropTypes.string,
    type: PropTypes.string.isRequired,
    url: PropTypes.string,
    width: PropTypes.string
  }

  state = {
    hover: false,
    opened: false
  }

  render() {
    const {actions, actionsContent, className, content, height, previewOnHover, showOpenLink, thumbnailUrl, type, url, width, ...restProps} = this.props
    const {hover, opened} = digs(this.state, "hover", "opened")
    const actionsToUse = actions ? [...actions] : []

    actionsToUse.unshift({
      className: "preview-button",
      icon: "expand",
      label: I18n.t("js.preview.preview"),
      onClick: this.onPreviewClicked
    })

    if (url && showOpenLink) {
      actionsToUse.push({
        icon: "external-link-alt",
        label: I18n.t("js.preview.open"),
        href: content || url,
        target: "_blank"
      })
    }

    return (
      <div
        className={classNames("components-preview", className)}
        data-hover={hover}
        data-preview-on-hover={previewOnHover}
        data-show-open-link={showOpenLink}
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
        ref="rootNode"
        {...restProps}
      >
        {previewOnHover && hover && !opened &&
          <PositionRelativeTo element={this.refs.rootNode}>
            <div className="components-preview-container">
              {this.previewContent()}
            </div>
          </PositionRelativeTo>
        }
        {opened &&
          <BodyPortal>
            <div className="components-preview-opened">
              <Modal
                onRequestClose={this.onModelRequestClose}
                size="fill"
                style={{zIndex: 1300}}
                title={I18n.t("js.shared.preview.preview")}
              >
                <div className="text-center">
                  {this.previewContent()}
                </div>
              </Modal>
            </div>
          </BodyPortal>
        }
        {type == "application/pdf" &&
          <embed className="preview-pdf" src={thumbnailUrl || content || url} style={{height, width}} type="application/pdf" />
        }
        {type.match("image/*") &&
          <img className="preview-image" src={thumbnailUrl || content || url} style={{height, width}} />
        }
        {actionsToUse.length > 0 &&
          <div className="actions-container">
            {actionsToUse.map(({className, ...restActionProps}, index) =>
              <Action
                className={classNames(className, {"ms-2": index > 0})}
                key={`action-${index}`}
                {...restActionProps}
              />
            )}
          </div>
        }
      </div>
    )
  }

  onModelRequestClose = () => this.setState({hover: false, opened: false})

  onMouseOver = (e) => {
    e.preventDefault()
    this.setState({hover: true})
  }

  onMouseOut = (e) => {
    e.preventDefault()
    this.setState({hover: false})
  }

  onPreviewClicked = (e) => {
    e.preventDefault()

    const {opened} = digs(this.state, "opened")

    this.setState({opened: !opened})
  }

  onWindowMouseUp = (e) => {
    if (e.target == this.refs.imageLink || this.refs.imageLink.contains(e.target)) {
      // Let 'onDocumentClicked' handle this event
    } else if (this.state.opened) {
      this.setState({opened: false})
    }
  }

  previewContent() {
    const {type} = digs(this.props, "type")
    const {content, url} = this.props

    return (
      <div className="preview-content">
        {type == "application/pdf" &&
          <embed className="preview-pdf" src={content || url} type="application/pdf" />
        }
        {type.match("image/*") &&
          <img className="preview-image" loading="lazy" src={content || url} />
        }
        {(type != "application/pdf" && !type.match("image/*")) &&
          I18n.t("js.shared.preview.index.unknown_type")
        }
      </div>
    )
  }
}
