import {Routes as jsRoutes} from "shared/js-routes"

const enqueuedJobs = {}

export default class JoblerJobStatus extends React.Component {
  static propTypes = PropTypesExact({
    job: PropTypes.instanceOf(JoblerJob).isRequired
  })

  state = {
    job: this.props.job,
    notificationState: "initial"
  }

  render() {
    const {job} = this.state
    const result = job.result()
    const resultType = result?.type

    return (
      <div className="components-jobler-job-status" ref="rootNode">
        <EventUpdated model={job} onConnected={this.onJobUpdatedConnected} onUpdated={this.onJobUpdated} />

        <div className="mb-3">
          {job.state() === "new" &&
            I18n.t("js.jobler.job_status.waiting")
          }
          {job.state() === "started" &&
            I18n.t("js.jobler.job_status.running")
          }
          {job.state() === "error" &&
            I18n.t("js.jobler.job_status.error")
          }
          {job.state() === "completed" &&
            I18n.t("js.jobler.job_status.completed")
          }
        </div>
        {job.hasProgress() &&
          <progress className="d-block w-100" max="100" value={parseInt(job.progress() * 100.0, 10)} />
        }
        {job.state() === "error" &&
          <a className="btn btn-success jobler-job-link mt-3" href={jsRoutes.joblerJobPath(job.slug())} target="_blank">
            {I18n.t("js.jobler.job_status.view_details")}
          </a>
        }
        {job.state() === "completed" && resultType === "file_download" &&
          <a className="btn btn-success download-file-button mt-3" href={digg(result, "url")} onClick={this.onDownloadClicked} target="_blank">
            {I18n.t("js.global.download")}
          </a>
        }
        {job.state() === "completed" && resultType === "message" &&
          digg(result, "message")
        }
        {job.state() === "completed" && resultType === "redirect_to" &&
          <a className="btn btn-success continue-button mt-3" href={digg(result, "url")}>
            {I18n.t("js.global.continue")}
          </a>
        }
      </div>
    )
  }

  checkItNotificationShouldClose() {
    const {job, notificationState} = this.state
    const result = job.result()
    const resultType = result?.type

    // The notification should close automatically 4 seconds after completing
    if (notificationState !== "completed" && job.state() === "completed" && resultType !== "file_download" && resultType !== "redirect_to") {
      setTimeout(() => this.closeNotification(), 4000)
    }

    this.setState({
      notificationState: job.state()
    })
  }

  closeNotification() {
    const {rootNode} = this.refs

    if (!rootNode) {
      // Component might be un-mounting
      return
    }

    const notificationElement = rootNode.closest(".nemoa-components-flash-messages-flash-message")
    const closeNotificationButton = notificationElement.querySelector(".close-flash-message-button")

    setTimeout(() => closeNotificationButton.click(), 15)
  }

  onDownloadClicked = () => {
    this.closeNotification()
  }

  // Called when the ActionCable subscription is connected. We have to wait for this to not get out of sync and get all events.
  onJobUpdatedConnected = async () => {
    const {job} = this.state

    if (!(job.id() in enqueuedJobs)) {
      await job.enqueue()
      enqueuedJobs[job.id()] = true
    }
  }

  onJobUpdated = (args) => {
    const job = digg(args, "model")

    this.setState({job})
    this.checkItNotificationShouldClose()
  }
}
