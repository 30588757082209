import AddColumnButton from "./add-column-button"
import {Collection} from "@kaspernj/api-maker"
import Column from "./column"
import ColumnsDropDown from "./columns-drop-down"
import Cycler from "shared/cycler"
import DynamicColumns from "./dynamic-columns"
import Filter from "./filter"
import FormDataBuilder from "shared/form-data-builder"
import Header from "./header"
import ModelRow from "./model-row"
import softDeleteModelDetect from "shared/soft-delete-model-detect"
import SoftDeletion from "shared/soft-deletion"
import SortByScopeLink from "components/sort-by-scope-link"
import Table from "react-bootstrap/Table"
import {useEffect} from "react"
import {useLocation} from "react-router-dom"
import {UserContext} from "shared/user-context"
import WorkplacesBottomBar from "admin/components/workplaces/bottom-bar"

// TODO this should use the location hook in the shared models table
function LocationChanged({onChanged}) {
  const location = useLocation()

  useEffect(() => {
    onChanged(location)
  }, [location, onChanged])

  return null
}

class SharedModelsTable extends React.Component {
  static defaultProps = {
    additionalTablesEnabled: true,
    card: true,
    destroyMessage: I18n.t("js.shared.table.index.the_model_was_deleted"),
    expandableContentPadding: true,
    filterContentDefaultExpanded: true,
    filterBySetTenant: true,
    orderByDragging: false,
    pagination: true,
    preloads: [],
    responsiveTable: true,
    showFilter: true,
    showBottomBar: true,
    showPagination: true,
    softDeleteAction: false,
    workplace: false
  }

  static propTypes = PropTypesExact({
    abilities: PropTypes.object,
    actions: PropTypes.func,
    actionsContent: PropTypes.func,
    additionalTablesEnabled: PropTypes.bool.isRequired,
    afterRow: PropTypes.func,
    afterRowBeforeExpandableContent: PropTypes.func,
    bottomBar: PropTypes.func,
    card: PropTypes.bool.isRequired,
    cardClassName: PropTypes.string,
    cardControls: PropTypes.node,
    cardHeader: PropTypes.string,
    className: PropTypes.string,
    collection: PropTypes.oneOfType([
      PropTypes.instanceOf(Collection),
      PropTypes.func
    ]),
    columns: PropTypes.func,
    columnsContent: PropTypes.func,
    cyclerIndex: PropTypes.number,
    defaultParams: PropTypes.object,
    destroyAction: PropTypes.func,
    destroyMessage: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    editModelPath: PropTypes.func,
    events: PropTypes.instanceOf(EventEmitter),
    expandableContent: PropTypes.func,
    expandableContentPadding: PropTypes.bool,
    filterArgs: PropTypes.object,
    filterBySetTenant: PropTypes.bool,
    filterContent: PropTypes.func,
    filterContentDefaultExpanded: PropTypes.bool,
    headersContent: PropTypes.func,
    logsCollection: PropTypes.func,
    modelClass: PropTypes.func.isRequired,
    newPath: PropTypes.string,
    onModelDoubleClick: PropTypes.func,
    onEditCancelled: PropTypes.func,
    onModelsLoaded: PropTypes.func,
    orderByDragging: PropTypes.bool.isRequired,
    onSelectModel: PropTypes.func,
    pagination: PropTypes.bool.isRequired,
    preloads: PropTypes.array,
    preActionsContent: PropTypes.func,
    queryName: PropTypes.string,
    responsiveTable: PropTypes.bool,
    select: PropTypes.object,
    selectColumns: PropTypes.object,
    selectedModelId: PropTypes.string,
    showDestroyRowButton: PropTypes.func,
    showEditRowButton: PropTypes.func,
    showFilter: PropTypes.bool,
    showBottomBar: PropTypes.bool.isRequired,
    showPagination: PropTypes.bool.isRequired,
    softDeleteAction: PropTypes.bool.isRequired,
    unSoftDeleteAction: PropTypes.bool,
    viewModelPath: PropTypes.func,
    viewTitle: PropTypes.string,
    workplace: PropTypes.bool.isRequired
  })

  headersRowRef = React.createRef()
  queryName = this.props.queryName || digg(digg(this, "props", "modelClass").modelClassData(), "collectionKey")
  queryQName = `${this.queryName}_q`
  queryPageName = `${this.queryName}_page`
  queryPerName = `${this.queryName}_per`
  params = Params.parse()
  tableRef = React.createRef()

  getQParams = () => {
    const {queryQName} = digs(this, "queryQName")
    const params = digg(this, "params")
    const qParams = params[queryQName] || this.filterParams() || {}

    return qParams
  }

  propsColumns = this.props.columns && this.props.columns()

  state = {
    baseQuery: undefined,
    colSpan: undefined,
    columns: undefined,
    columnsWithOrder: undefined,
    currentlyDraggedModel: undefined,
    draggedHeaderIdentifier: undefined,
    draggedHeaderOverIdentifier: undefined,
    editableRows: [],
    expandableContentModelIds: [],
    models: undefined,
    orderByDraggingEnabled: undefined,
    page: this.params[this.queryPageName] || 1,
    pagePer: this.params[this.queryPerName] || 30,
    qParams: this.getQParams(),
    result: undefined,
    softDeleteEnabledForModel: softDeleteModelDetect(digg(this, "props", "modelClass")),
    tenant: CurrentTenant.current()
  }

  componentDidMount() {
    if (this.props.columns && Devise.isUserSignedIn()) {
      this.initializeDynamicColumns()
    } else {
      this.loadModels()
    }
  }

  async initializeDynamicColumns() {
    const dynamicColumns = new DynamicColumns(this)

    await dynamicColumns.initialize()
    this.dynamicColumns = dynamicColumns
  }

  // TODO use hook
  locationChanged = (_location, _action) => {
    this.params = Params.parse()

    const newParams = this.getQParams()
    const {page, pagePer, qParams} = this.state
    const {queryPageName, queryPerName} = this
    const stateChanges = {}
    const newPage = this.params[queryPageName] || 1
    const newPagePer = this.params[queryPerName] || 30

    if (JSON.stringify(newParams) !== JSON.stringify(qParams)) stateChanges.qParams = newParams
    if (newPage !== page) stateChanges.page = newPage
    if (newPagePer !== pagePer) stateChanges.pagePer = newPagePer

    if (Object.keys(stateChanges).length > 0) {
      this.setState(stateChanges, () => this.loadModels())
    }
  }

  componentDidUpdate() {
    const headersRow = digg(this, "headersRowRef", "current")
    const headerElements = Array.from(headersRow.querySelectorAll(":scope > *"))
    const colSpan = headerElements.reduce((count, columnElement) => count + (columnElement.colSpan || 1), 0)

    if (colSpan !== this.state.colSpan) {
      this.setState({colSpan})
    }
  }

  filterParams() {
    const {defaultParams} = this.props

    return Params.setUndefined(defaultParams || {})
  }

  loadModels = async () => {
    const abilitiesToLoad = {}
    const params = Params.parse()
    const {filterBySetTenant, modelClass} = digs(this.props, "filterBySetTenant", "modelClass")
    const {abilities, onModelsLoaded, pagination, preloads, select, selectColumns} = this.props
    const {columnsWithOrder, qParams, tenant} = this.state
    const {queryPageName, queryPerName, queryQName} = digs(this, "queryPageName", "queryPerName", "queryQName")
    const baseQuery = await this.baseQuery()
    const modelClassName = digg(modelClass.modelClassData(), "name")

    let query = baseQuery

    query = query
      .ransack(qParams)
      .searchKey(queryQName)
      .preload(preloads)
      .select(select)
      .groupBy("id")

    if (filterBySetTenant && tenant) {
      query = query.ransack({
        tenant_id_eq: tenant.id()
      })
    }

    if (this.dynamicColumns) {
      const columnsPreloads = this.dynamicColumns.columnsPreloads()
      const columnsSelectedAttributes = this.dynamicColumns.columnsSelectedAttributes()

      if (columnsPreloads.length > 0) {
        query = query.preload(columnsPreloads)
      }

      if (Object.keys(columnsSelectedAttributes).length > 0) {
        query = query.select(columnsSelectedAttributes)
      }
    }

    const ownAbilities = this.ownAbilities()

    if (ownAbilities.length > 0) {
      abilitiesToLoad[modelClassName] = ownAbilities
    }

    if (abilities) {
      for (const modelName in abilities) {
        if (!(modelName in abilitiesToLoad)) {
          abilitiesToLoad[modelName] = []
        }

        for (const ability of abilities[modelName]) {
          abilitiesToLoad[modelName].push(ability)
        }
      }
    }

    if (Object.keys(abilitiesToLoad).length > 0) {
      query = query.abilities(abilitiesToLoad)
    }

    if (pagination) {
      query = query
        .page(params[queryPageName])
        .pageKey(queryPageName)
        .per(params[queryPerName])
        .perKey(queryPerName)
    }

    if (selectColumns) {
      query = query.selectColumns(selectColumns)
    }

    if (columnsWithOrder) {
      query = this.selectAttributesFromGivenColumns(query)
    }

    const result = await query.result()
    const orderByDraggingEnabled = query.queryArgs.ransack.s == "position"

    this.setState({
      baseQuery,
      models: result.models(),
      orderByDraggingEnabled,
      query,
      result
    })

    if (onModelsLoaded) {
      onModelsLoaded(result)
    }
  }

  loadModelsLater = debounce(this.loadModels, 50)

  ownAbilities() {
    const ownAbilities = ["destroy"]

    if (this.props.editable || this.props.editModelPath) {
      ownAbilities.push("edit")
    }

    if (this.props.softDeleteAction) {
      ownAbilities.push("softDelete")
    }

    if (this.props.unSoftDeleteAction) {
      ownAbilities.push("unSoftDelete")
    }

    if (this.props.viewModelPath) {
      ownAbilities.push("show")
    }

    return ownAbilities
  }

  selectAttributesFromGivenColumns = (query) => {
    const {modelClass} = digs(this.props, "modelClass")
    const {columnsWithOrder} = this.state
    const attributes = []
    const modelClassName = digg(modelClass.modelClassData(), "name")

    for (const columnWithOrder of columnsWithOrder) {
      const {visible} = digs(columnWithOrder, "visible")
      const attribute = columnWithOrder.column?.attribute

      if (attribute && visible) {
        attributes.push(attribute)
      }
    }

    const selectAttributes = {}

    selectAttributes[modelClassName] = attributes
    query = query.select(selectAttributes)

    return query
  }

  async baseQuery() {
    const {modelClass, orderByDragging} = digs(this.props, "modelClass", "orderByDragging")
    const {qParams, softDeleteEnabledForModel} = this.state

    let {collection} = this.props

    if (collection) {
      if (typeof collection == "function") {
        collection = await collection({qParams})
      } else {
        collection = collection.clone()
      }
    } else {
      collection = modelClass.ransack()
    }

    if (softDeleteEnabledForModel) {
      collection = SoftDeletion.defaultSoftDeletedHidden(collection, qParams)
    }

    if (orderByDragging) {
      collection = collection.ransack({s: "position"})
    }

    return collection
  }

  render() {
    const {card, events} = this.props

    return (
      <React.Fragment>
        <LocationChanged onChanged={this.locationChanged} />
        {events &&
          <>
            <EventEmitterListener event="reload" events={events} onCalled={this.loadModelsLater} />
            <EventEmitterListener
              event="submitEditableRow"
              events={events}
              onCalled={this.onSubmitEditableRowCalled}
            />
          </>
        }
        {!card && this.tableContent()}
        {card && this.cardContent()}
      </React.Fragment>
    )
  }

  cardContent() {
    const {
      cardClassName,
      cardControls,
      cardHeader,
      filterArgs,
      filterContent,
      filterContentDefaultExpanded,
      newPath,
      showFilter
    } = this.props
    const {qParams} = this.state
    const {queryQName} = digs(this, "queryQName")

    return (
      <div className={classNames("components-shared-models-table-card", cardClassName)}>
        {showFilter && filterContent && qParams &&
          <Filter
            defaultExpanded={filterContentDefaultExpanded}
            filterArgs={filterArgs}
            filterContent={filterContent}
            qParams={qParams}
            queryQName={queryQName}
          />
        }
        <div className="card card-default">
          {(cardHeader || cardControls || newPath) &&
            <div className="card-header">
              {cardHeader}
              {(cardControls || newPath) &&
                <div className="float-end">
                  {this.cardControls()}
                </div>
              }
            </div>
          }
          <div className={classNames("card-body", "p-0")}>
            {this.tableContent()}
          </div>
        </div>
      </div>
    )
  }

  tableContent() {
    const {
      additionalTablesEnabled,
      afterRow,
      afterRowBeforeExpandableContent,
      bottomBar,
      className,
      columns,
      cyclerIndex,
      modelClass,
      onModelDoubleClick,
      onSelectModel,
      orderByDragging,
      pagination,
      responsiveTable,
      showBottomBar,
      showPagination,
      workplace
    } = this.props
    const {colSpan, columnsWithOrder, models, orderByDraggingEnabled, query, result} = this.state
    const cycler = new Cycler(["even-row", "odd-row"])

    return (
      <>
        <EventCreated modelClass={modelClass} onCreated={this.onModelCreated} />
        {this.tableRef.current &&
          <EventListener event="collapseRows" onCalled={this.onCollapseRows} target={this.tableRef.current} />
        }
        {this.tableRef.current &&
          <EventListener event="expandRows" onCalled={this.onExpandRows} target={this.tableRef.current} />
        }
        {models?.map((model) =>
          <React.Fragment key={model.id()}>
            <EventDestroyed model={model} onDestroyed={this.onModelDestroyed} />
            <EventUpdated model={model} onUpdated={this.onModelUpdated} />
          </React.Fragment>
        )}
        <Table
          bordered
          className={classNames("components-shared-models-table", "w-100", className)}
          data-additional-tables-enabled={additionalTablesEnabled}
          data-model-ids={models?.map((model) => model.id())}
          hover
          ref={this.tableRef}
          responsive={responsiveTable}
          striped
        >
          <thead>
            <tr ref={this.headersRowRef}>
              {orderByDragging && <Header />}
              {workplace && this.selectHeaderColumn()}
              {onSelectModel && <Header />}
              {query && this.headersContent()}
              <Header className="text-end">
                {columns &&
                  <AddColumnButton className="me-2" modelClass={modelClass} onAttributeChosen={this.onAttributeChosen} />
                }
                {columns && columnsWithOrder &&
                  <ColumnsDropDown
                    columnsWithOrder={columnsWithOrder}
                    modelClass={modelClass}
                    onColumnClicked={this.onColumnInDropDownClicked}
                  />
                }
              </Header>
              <Header className="expand-header text-end px-0">
                {this.expandAndCollapseButton()}
              </Header>
            </tr>
          </thead>
          <tbody>
            {models?.map((model, index) =>
              <ModelRow
                additionalTablesEnabled={additionalTablesEnabled}
                afterRow={afterRow}
                afterRowBeforeExpandableContent={afterRowBeforeExpandableContent}
                className={this.rowClassName(model)}
                cycler={cycler}
                draggable={orderByDragging}
                index={cyclerIndex !== undefined ? cyclerIndex : index}
                key={model.id()}
                model={model}
                onDestroyModelClicked={this.onDestroyModelClicked}
                onDragOver={this.onDragOver}
                onDragStart={this.onDragStart}
                onDrop={this.onDrop}
                onEditClicked={this.onEditClicked}
                onExpandRowClicked={this.onExpandRowClicked}
                onModelDoubleClick={onModelDoubleClick}
                onModelSelected={this.onModelSelected}
                onSubmitEditableRowClicked={this.onSubmitEditableRowClicked}
                onUnSoftDeleteClicked={this.onUnSoftDeleteClicked}
                orderByDraggingEnabled={orderByDraggingEnabled}
                query={query}
                table={this}
              />
            )}
            {colSpan !== undefined && showBottomBar && (bottomBar || workplace || (pagination && showPagination)) &&
              <tr>
                <Column className="shared-table-bottom-bar p-0" colSpan={colSpan}>
                  {bottomBar && bottomBar({result})}
                  {!bottomBar && this.defaultBottomBar()}
                </Column>
              </tr>
            }
          </tbody>
        </Table>
      </>
    )
  }

  defaultBottomBar() {
    const {modelClass, pagination, showPagination, workplace} = digs(this.props, "modelClass", "pagination", "showPagination", "workplace")
    const {result} = this.state

    return (
      <BottomBar fixedPositionInLayout={false} rightContent={result && pagination && showPagination && <BottomBarPagination result={result} />}>
        {result && workplace &&
          <WorkplacesBottomBar modelClass={modelClass} result={result} />
        }
      </BottomBar>
    )
  }

  headersContent = () => {
    const {columns} = this.props
    const {columnsWithOrder, models, query} = this.state

    if (columns && columnsWithOrder) {
      return this.headersFromGivenColumns()
    } else if (this.props.headersContent) {
      return this.props.headersContent({models, query})
    }
  }

  headersFromGivenColumns() {
    const {columnsWithOrder, draggedHeaderOverIdentifier, query} = this.state

    return columnsWithOrder.filter((column) => this.dynamicColumns.showColumn(column)).map((args) =>
      <Header
        args={args}
        className={classNames("column-header", args?.headerProps?.className, {"text-center": args.textCenter, "text-end": args.textRight})}
        data-dragged-over={draggedHeaderOverIdentifier === digg(args, "identifier")}
        data-identifier={digg(args, "identifier")}
        draggable
        key={`header-${digg(args, "identifier")}`}
        onDragLeave={this.onHeaderDragLeave}
        onDragOver={this.onHeaderDragOver}
        onDragStart={this.onHeaderDragStart}
        onDrop={this.onHeaderDrop}
      >
        {args.sortContent &&
          args.sortContent({query})
        }
        {args.sortKey &&
          <SortLink
            attribute={args.sortKey}
            query={query}
            title={args.label}
          />
        }
        {!args.sortContent && !args.sortKey && args.label}
        {!digg(args, "propsColumn") &&
          <Icon className="ms-1 remove-column-button" icon="times" onClick={() => this.onRemoveColumnClicked(args)} size="small" />
        }
      </Header>
    )
  }

  onAttributeChosen = async (args) => {
    await this.dynamicColumns.addColumn(args)
    await this.dynamicColumns.initialize()
  }

  onColumnInDropDownClicked = (column, index) => {
    this.dynamicColumns.toggleColumnVisibility({column, index})
  }

  onHeaderDragLeave = (_e, {identifier}) => {
    const {draggedHeaderOverIdentifier} = this.state

    if (draggedHeaderOverIdentifier == identifier) {
      this.setState({
        draggedHeaderOverIdentifier: undefined
      })
    }
  }

  onHeaderDragStart = (_e, {identifier}) => {
    this.setState({
      draggedHeaderIdentifier: identifier
    })
  }

  onHeaderDragOver = (e, {identifier}) => {
    const {draggedHeaderIdentifier, draggedHeaderOverIdentifier} = this.state

    if (draggedHeaderIdentifier !== undefined && draggedHeaderIdentifier !== identifier) {
      e.preventDefault()

      if (draggedHeaderOverIdentifier != identifier) {
        this.setState({
          draggedHeaderOverIdentifier: identifier
        })
      }
    }
  }

  onHeaderDrop = (e, args) => {
    e.preventDefault()

    this.dynamicColumns.moveColumnTo(args)
  }

  onDragOver = (e, model) => {
    const {currentlyDraggedModel} = this.state

    // Allow drop on other models
    if (currentlyDraggedModel && currentlyDraggedModel.id() != model.id()) {
      e.preventDefault()
    }
  }

  onDrop = async (e, model) => {
    e.preventDefault()

    const {currentlyDraggedModel} = this.state

    try {
      await currentlyDraggedModel.update({position: model.position()})
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  onDragStart = (e, model) => {
    const {orderByDraggingEnabled} = this.state

    if (!orderByDraggingEnabled) {
      e.preventDefault()
      FlashMessage.error(I18n.t("js.shared.table.index.order_by_dragging_is_disabled_click_to_enable"))

      return
    }

    this.setState({currentlyDraggedModel: model})
  }

  onRemoveColumnClicked = async (args) => {
    if (!(await CustomConfirm.confirm(I18n.t("js.global.confirmation")))) {
      return
    }

    await this.dynamicColumns.removeColumn(args)
    await this.dynamicColumns.initialize()
  }

  cardControls() {
    const {cardControls, newPath} = this.props

    if (!cardControls && !newPath) {
      return
    }

    return (
      <>
        {cardControls}
        {newPath &&
          <IconButton className="new-model-button" icon="plus" primary size="small" to={newPath} />
        }
      </>
    )
  }

  expandAndCollapseButton() {
    const {expandableContentModelIds} = this.state

    if (expandableContentModelIds.length > 0) {
      return (
        <button
          className="btn collapse-all"
          onClick={this.onCollapseAllClicked}
          title={I18n.t("js.shared.table.index.collapse_all")}
        >
          <Icon icon="angle-up" size="small" />
        </button>
      )
    }

    return (
      <a
        className="btn expand-all"
        onClick={this.onExpandAllClicked}
        title={I18n.t("js.shared.table.index.expand_all")}
      >
        <Icon icon="angle-down" size="small" />
      </a>
    )
  }

  onExpandRowClicked = (model) => {
    const {expandableContentModelIds} = this.state

    if (expandableContentModelIds.includes(model.id())) {
      this.collapseModelIds([model.id()])
    } else {
      this.expandModelsIds([model.id()])
    }
  }

  expandModelsIds = (modelIds) => {
    this.setState((prevState) => ({
      expandableContentModelIds: prevState.expandableContentModelIds.concat(modelIds)
    }))
  }

  collapseModelIds = (modelIds) => {
    this.setState((prevState) => ({
      expandableContentModelIds: prevState.expandableContentModelIds.filter((element) => !modelIds.includes(element))
    }))
  }

  onModelSelected = (e, model) => {
    e.preventDefault()
    this.props.onSelectModel({model})
  }

  onSubmitEditableRowClicked = (e, model) => {
    e.preventDefault()
    this.submitEditableRow(model)
  }

  onSubmitEditableRowCalled = (model, additionalPayload) => this.submitEditableRow(model, additionalPayload)

  async submitEditableRow(model, additionalPayload) {
    const rowClassName = this.rowModelClassName()
    const rowSelector = `.${rowClassName}[data-model-id='${model.id()}']`
    const rowElement = this.tableRef.current.querySelector(rowSelector)
    const expandableRowSelector = `.expandable-content-row[data-expandable-model-id='${model.id()}']`
    const expandableRow = this.tableRef.current.querySelector(expandableRowSelector)
    const formDataBuilder = new FormDataBuilder()

    formDataBuilder.scanInputsFrom(rowElement)

    if (expandableRow) {
      formDataBuilder.scanInputsFrom(expandableRow)
    }

    const formData = formDataBuilder.getFormData()

    if (additionalPayload) {
      for (const payload of additionalPayload) {
        const name = digg(payload, "name")
        const value = digg(payload, "value")

        formData.append(name, value)
      }
    }

    try {
      await model.saveRaw(formData)
    } catch (error) {
      return FlashMessage.errorResponse(error)
    }

    FlashMessage.success(I18n.t("js.shared.table.index.the_model_was_saved"))

    this.collapseModelIds([model.id()])

    this.setState((prevState) => ({
      editableRows: prevState.editableRows.filter((id) => id != model.id())
    }))
  }

  onModelCreated = () => {
    this.loadModelsLater()
  }

  onModelUpdated = (args) => {
    const model = digg(args, "model")
    const {models} = this.state
    const modelFoundInModels = models.find((modelInModels) => modelInModels.id() == model.id())

    // Only reload if the found model was one of the shown models to avoid unnecessary and excessive reloading
    if (modelFoundInModels) {
      this.loadModelsLater()
    }
  }

  onModelDestroyed = (args) => {
    this.setState((prevState) => ({
      models: prevState.models.filter((model) => model.id() != args.model.id())
    }))
  }

  onDestroyModelClicked = (model) => {
    const {destroyAction, softDeleteAction} = this.props

    if (destroyAction) {
      destroyAction({model})
    } else if (softDeleteAction) {
      this.softDeleteModel(model)
    } else {
      this.destroyModel(model)
    }
  }

  softDeleteModel = async (model) => {
    const {destroyMessage} = this.props
    const modelClassName = digg(model.modelClassData(), "name")

    try {
      await User.softDeleteModel({model_class: modelClassName, model_id: model.id()})
      FlashMessage.success(destroyMessage)
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  destroyModel = async (model) => {
    const {destroyMessage} = digs(this.props, "destroyMessage")

    try {
      await model.destroy()
      FlashMessage.success(destroyMessage)
    } catch (response) {
      FlashMessage.errorResponse(response)
    }
  }

  onCollapseRows = (event) => {
    const ids = digg(event, "detail", "ids")

    this.setState((prevState) => ({
      expandableContentModelIds: prevState.expandableContentModelIds.filter((element) => !ids.includes(element))
    }))
  }

  onExpandRows = (event) => {
    const ids = digg(event, "detail", "ids")

    this.setState((prevState) => {
      const newIds = prevState.expandableContentModelIds.concat([])

      for (const id of ids) {
        if (!newIds.includes(id)) {
          newIds.push(id)
        }
      }

      return {
        expandableContentModelIds: newIds
      }
    })
  }

  onCollapseAllClicked = () => {
    this.setState({
      expandableContentModelIds: []
    })
  }

  onEditClicked = (e, model) => {
    e.preventDefault()

    const {editableRows} = this.state
    const {onEditCancelled} = this.props

    if (editableRows.includes(model.id())) {
      this.setState((prevState) => ({
        editableRows: prevState.editableRows.filter((editableModelId) => editableModelId != model.id()),
        expandableContentModelIds: prevState.expandableContentModelIds.filter((existingModelId) => existingModelId != model.id())
      }))

      if (onEditCancelled) {
        onEditCancelled({model})
      }
    } else {
      this.setState((prevState) => ({
        editableRows: prevState.editableRows.concat([model.id()]),
        expandableContentModelIds: prevState.expandableContentModelIds.concat([model.id()])
      }))
    }
  }

  onExpandAllClicked = (e) => {
    e.preventDefault()

    if (!this.state.models) {
      return
    }

    this.setState((prevState) => ({
      expandableContentModelIds: prevState.models.map((model) => model.id())
    }))
  }

  onUnSoftDeleteClicked = async (e, model) => {
    e.preventDefault()

    if (!await CustomConfirm.confirm(I18n.t("js.global.confirmation"))) {
      return
    }

    const modelClassName = digg(model.modelClassData(), "name")

    try {
      await User.unSoftDeleteModel({model_class: modelClassName, model_id: model.id()})
    } catch (error) {
      FlashMessage.errorResponse(error)
    }
  }

  rowModelClassName() {
    return `${Inflection.dasherize(this.props.modelClass.modelClassData().paramKey)}-row`
  }

  rowClassName = (model) => {
    const {editableRows} = this.state
    const classNames = [this.rowModelClassName()]

    if (editableRows.includes(model.id())) {
      classNames.push("editable-row")
    }

    if (this.props.selectedModelId === model.id()) {
      classNames.push("table-primary")
    }

    return classNames.join(" ")
  }

  selectHeaderColumn() {
    const {query} = this.state
    const [currentUser] = this.context

    if (query) {
      return (
        <Header className="workplace-select-header text-center">
          <SortByScopeLink
            className="sort-by-in-current-workplace-link"
            query={query}
            scope="sort_by_in_current_workplace"
            title={<Icon icon="compress-arrows-alt" size="small" />}
            value={{user_id: currentUser?.id()}}
          />
        </Header>
      )
    }
  }
}

SharedModelsTable.contextType = UserContext
export default SharedModelsTable
