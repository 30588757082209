import AddToBasketButton from "merfoto/components/tenant-photo-products/add-to-basket-button"

export default class TenantPhotoProductsWithPhotosCardProductActions extends React.Component {
  static propTypes = {
    photos: PropTypes.arrayOf(PropTypes.instanceOf(Photo)).isRequired,
    photoSessionGroupContact: PropTypes.instanceOf(PhotoSessionGroupContact).isRequired,
    tenantPhotoProduct: PropTypes.instanceOf(TenantPhotoProduct).isRequired
  }

  render() {
    const {className, photos, photoSessionGroupContact, tenantPhotoProduct, ...restProps} = this.props

    return (
      <div
        className={classNames("align-items-center", "d-flex", "p-1", "merfoto-components-tenant-photo-produccts-with-photos-card-product-actions", className)}
        {...restProps}
      >
        <AddToBasketButton
          photoSessionGroupContact={photoSessionGroupContact}
          photos={photos}
          tenantPhotoProduct={tenantPhotoProduct}
        />
      </div>
    )
  }
}
