import DeliveryMethodOption from "merfoto/components/order-deliveries/delivery-methods/delivery-method-option"
import logo from "./logo"
import Money from "js-money"

DigitalDownloadDeliveryMethod.propTypes = {
  currentDeliveryMethodOption: PropTypes.object.isRequired
}
export default function DigitalDownloadDeliveryMethod({currentDeliveryMethodOption, order, orderDelivery, ...restProps}) {
  const {deliveryMethod} = currentDeliveryMethodOption

  return (
    <DeliveryMethodOption
      checked={deliveryMethod === "digital_download"}
      deliveryMethod="digital_download"
      identifier="digital-download"
      label={I18n.t("js.merfoto.order_deliveries.delivery_methods.digital_download.options.digital_download")}
      logo={logo}
      priceWithVat={new Money(0, Money.DKK)}
      {...restProps}
    />
  )
}
