export default class MerfotoComponentsOrderLinesEditResourcesBundleBundleTenantPhotoProductPartial extends BaseComponent {
  static propTypes = {
    bundleOrderLine: PropTypes.instanceOf(BundleOrderLine).isRequired,
    bundleTenantPhotoProduct: PropTypes.instanceOf(BundleTenantPhotoProduct).isRequired,
    cacheKey: PropTypes.string.isRequired,
    className: PropTypes.string,
    photos: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    const bundleOrderLineElement = this.initialBundleOrderLineElement()
    const {bundleTenantPhotoProduct} = digs(this.props, "bundleTenantPhotoProduct")
    const tenantPhotoProduct = bundleTenantPhotoProduct.tenantPhotoProduct()
    const photoProduct = tenantPhotoProduct.photoProduct()
    const resourceTypeClassName = `${Inflection.camelize(photoProduct.resourceType())}OrderLine`
    const resourceTypeMethodName = `${Inflection.camelize(photoProduct.resourceType(), true)}OrderLine`

    let resource

    if (bundleOrderLineElement.isNewRecord()) {
      const modelClass = digg(require("@kaspernj/api-maker/src/models"), resourceTypeClassName)

      resource = new modelClass()
    } else {
      if (!(resourceTypeMethodName in bundleOrderLineElement)) {
        throw new Error(`${digg(bundleOrderLineElement, "constructor", "name")} didn't respond to ${resourceTypeMethodName}`)
      }

      resource = bundleOrderLineElement[resourceTypeMethodName]()
    }

    this.state = ({
      bundleOrderLineElement,
      resource,
      resourceTypeClassName,
      resourceTypeMethodName
    })
  }

  initialBundleOrderLineElement() {
    const {bundleOrderLine, bundleTenantPhotoProduct} = digs(this.props, "bundleOrderLine", "bundleTenantPhotoProduct")

    if (bundleOrderLine.isNewRecord()) {
      return new BundleOrderLineElement()
    }

    const foundBundleOrderLineElement = bundleOrderLine.bundleOrderLineElements().loaded().find((bundleOrderLineElement) =>
      bundleOrderLineElement.bundleTenantPhotoProductId() == bundleTenantPhotoProduct.id()
    )

    if (!foundBundleOrderLineElement) {
      throw new Error(`No bundle order line element could be found: ${bundleOrderLine.bundleOrderLineElements().loaded().length}`)
    }

    return foundBundleOrderLineElement
  }

  render() {
    const {bundleOrderLine, bundleTenantPhotoProduct, cacheKey, className, photos, ...restProps} = this.props
    const {bundleOrderLineElement, resource, resourceTypeClassName, resourceTypeMethodName} = this.state
    const inputPre = `order_line[orderable_attributes][bundle_order_line_elements_attributes][${bundleTenantPhotoProduct.uniqueKey()}]`
    const tenantPhotoProduct = bundleTenantPhotoProduct.tenantPhotoProduct()
    const photoProduct = tenantPhotoProduct.photoProduct()
    const Inflection = require("inflection") // Webpack can't figure out to resolve it unless specifically required here. Feel free to remove if it still works.
    const requireFileName = Inflection.dasherize(Inflection.underscore(photoProduct.resourceType()))
    const ResourceComponent = require(`merfoto/components/order-lines/edit/resources/${requireFileName}/content`).default
    const resourceComponentProps = {
      className: classNames("merfoto-components-order-lines-edit-resources-bundle-bundle-tenant-photo-product-partial", className),
      photos,
      tenantPhotoProduct,
      ...restProps
    }

    if (!ResourceComponent) throw new Error(`Imported component wasn't there: ${requireFileName}`)
    if (!resource) throw new Error("'resource' not set")

    resourceComponentProps[resourceTypeMethodName] = resource

    return (
      <>
        {bundleOrderLineElement.isPersisted() &&
          <input name={`${inputPre}[id]`} type="hidden" value={bundleOrderLineElement.id()} />
        }
        <input name={`${inputPre}[bundle_tenant_photo_product_id]`} type="hidden" value={bundleTenantPhotoProduct.id()} />
        <input name={`${inputPre}[touchy]`} type="hidden" value="true" />
        <input name={`${inputPre}[resource_type]`} type="hidden" value={resourceTypeClassName} />
        {resource.isPersisted() &&
          <input name={`${inputPre}[resource_id]`} type="hidden" value={resource.id()} />
        }
        <ResourceComponent inputPre={`${inputPre}[resource_attributes]`} {...resourceComponentProps} />
      </>
    )
  }
}
