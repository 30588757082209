import SheetLayoutsPresentation from "components/sheet-layouts/presentation"

export default class MerfotoComponentsOrderLinesEditResourcesBundleHeadersPhotoSheetProduct extends BaseComponent {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    bundleOrderLineElement: PropTypes.instanceOf(BundleOrderLineElement),
    bundleTenantPhotoProduct: PropTypes.instanceOf(BundleTenantPhotoProduct).isRequired
  }

  render() {
    const {active, bundleOrderLineElement, bundleTenantPhotoProduct, className, ...restProps} = this.props
    const photoSheetProduct = bundleTenantPhotoProduct.tenantPhotoProduct().photoProduct().photoSheetProduct()
    const photoSheetProductOrderLine = bundleOrderLineElement?.photoSheetProductOrderLine()

    let sheetLayout

    if (photoSheetProductOrderLine) {
      sheetLayout = photoSheetProductOrderLine.sheetLayout()
    } else {
      sheetLayout = photoSheetProduct.sheetLayout()
    }

    return (
      <div className={classNames("merfoto-components-order-lines-edit-resources-bundle-headers-photo-sheet-product", className)} {...restProps}>
        <SheetLayoutsPresentation
          active={active}
          photoSheetProductOrderLine={photoSheetProductOrderLine}
          sheetLayout={sheetLayout}
          sheetSize={photoSheetProduct.sheetLayout().sheetSize()}
          size={80}
        />
      </div>
    )
  }
}
