import "./style"
import Money from "js-money"

export default class AttributePresenter extends React.Component {
  static defaultProps = {
    hideIfNotLoaded: false,
    horizontal: false,
    preserveWhiteSpace: true,
    upperCaseLabel: false,
    valueAlignment: "left"
  }

  static propTypes = {
    attribute: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    controls: PropTypes.node,
    hideIfNotLoaded: PropTypes.bool.isRequired,
    horizontal: PropTypes.bool.isRequired,
    label: PropTypes.node,
    model: PropTypes.object,
    preserveWhiteSpace: PropTypes.bool.isRequired,
    upperCaseLabel: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([PropTypes.instanceOf(Money), PropTypes.node]),
    valueAlignment: PropTypes.oneOf(["left", "right"]).isRequired
  }

  render() {
    const {
      attribute,
      children,
      className,
      controls,
      hideIfNotLoaded,
      horizontal,
      model,
      preserveWhiteSpace,
      upperCaseLabel,
      value,
      valueAlignment,
      ...restProps
    } = this.props

    if (hideIfNotLoaded && !model.isAttributeLoaded(attribute)) {
      return null
    }

    return (
      <div className={this.className()} data-attribute={attribute} {...restProps}>
        <div className={this.labelClass()}>
          {this.label()}:
          {controls &&
            <span className="ms-1">
              {controls}
            </span>
          }
        </div>
        <div className="value" data-preserve-white-space={preserveWhiteSpace}>
          {this.content()}
        </div>
      </div>
    )
  }

  className() {
    const {className} = this.props
    const {horizontal, valueAlignment} = digs(this.props, "horizontal", "valueAlignment")
    const floatRight = valueAlignment == "right"

    return classNames(
      "components-shared-attribute-presenter",
      className,
      {
        "d-flex": horizontal,
        "justify-content-between": floatRight
      }
    )
  }

  label() {
    const {attribute, label, model} = this.props

    if ("label" in this.props) {
      return label
    } else if (attribute && model) {
      return model.modelClass().humanAttributeName(attribute)
    }
  }

  labelClass() {
    const {horizontal, upperCaseLabel} = digs(this.props, "horizontal", "upperCaseLabel")

    return classNames(
      "label",
      "text-muted",
      {
        "me-1": horizontal,
        "mb-1": !horizontal,
        "text-uppercase": upperCaseLabel
      }
    )
  }

  content() {
    const {attribute, children, model, value} = this.props

    let valueToUse

    if (children) {
      return children
    } else if ("value" in this.props) {
      valueToUse = value
    } else if (attribute && model) {
      valueToUse = model[attribute]()
    } else {
      return children
    }

    if (valueToUse instanceof Date) {
      return I18n.l("time.formats.default", valueToUse)
    } else if (valueToUse instanceof Money) {
      return MoneyFormatter.format(valueToUse)
    } else if (typeof valueToUse == "boolean") {
      if (valueToUse) {
        return I18n.t("js.global.yes")
      }

      return I18n.t("js.global.no")
    }

    return valueToUse
  }
}
