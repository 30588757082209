import ActionsColumn from "./actions-column"
import {Collection} from "@kaspernj/api-maker"
import Column from "./column"
import Cycler from "shared/cycler"
import ExpandableRow from "./expandable-row"
import LogsTable from "admin/components/logs/table"
import pathAndAttributeResolver from "./path-and-attribute-resolver"
import presentValue from "./present-value"
import TaggingsTaggable from "admin/components/taggings/taggable"
import {useNavigate} from "react-router-dom"
import WorkerPluginsModelCheckbox from "admin/components/worker-plugins/model-checkbox"

class SharedModelsTableRow extends React.Component {
  static propTypes = {
    actions: PropTypes.array,
    actionsContent: PropTypes.func,
    additionalTablesEnabled: PropTypes.bool.isRequired,
    afterRow: PropTypes.func,
    afterRowBeforeExpandableContent: PropTypes.func,
    className: PropTypes.string.isRequired,
    cycler: PropTypes.instanceOf(Cycler),
    draggable: PropTypes.bool,
    index: PropTypes.number.isRequired,
    model: PropTypes.object.isRequired,
    onDestroyModelClicked: PropTypes.func.isRequired,
    onDragOver: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onEditClicked: PropTypes.func.isRequired,
    onExpandRowClicked: PropTypes.func.isRequired,
    onModelSelected: PropTypes.func.isRequired,
    onModelDoubleClick: PropTypes.func,
    onSubmitEditableRowClicked: PropTypes.func.isRequired,
    onUnSoftDeleteClicked: PropTypes.func.isRequired,
    orderByDraggingEnabled: PropTypes.bool.isRequired,
    query: PropTypes.instanceOf(Collection).isRequired,
    table: PropTypes.object
  }

  render() {
    const {
      additionalTablesEnabled,
      afterRow,
      afterRowBeforeExpandableContent,
      className,
      cycler,
      draggable,
      index,
      model,
      onDestroyModelClicked,
      onModelDoubleClick,
      onSubmitEditableRowClicked,
      onUnSoftDeleteClicked
    } = this.props
    const {columns, columnsContent, expandableContent, expandableContentPadding, onSelectModel, workplace} = this.props.table.props
    const {columnsWithOrder, colSpan, editableRows, expandableContentModelIds} = this.props.table.state
    const editable = editableRows.includes(model.id())
    const rowClassName = cycler.forCount(index)
    const expandableContentExpanded = expandableContentModelIds.includes(model.id())

    return (
      <React.Fragment>
        <tr
          className={classNames(className, rowClassName)}
          data-index={index}
          data-model-id={model.id()}
          onDoubleClick={onModelDoubleClick ? (e) => onModelDoubleClick(e, model) : undefined}
          onDragOver={this.onDragOver}
          onDrop={this.onDrop}
        >
          {draggable &&
            <Column>
              <Icon
                className={this.orderByDragButtonClassNames()}
                draggable={draggable}
                icon="ellipsis-v"
                onClick={this.onOrderByDragButtonClicked}
                onDragStart={this.onDragStart}
                size="small"
                title={this.orderByDragButtonTitle()}
              />
            </Column>
          }
          {workplace && this.workplaceColumn(model)}
          {onSelectModel &&
            <Column><Icon className="select-model-button" icon="check-circle" onClick={this.onSelectModelButtonClicked} size="small" /></Column>
          }
          {columns && columnsWithOrder && this.columnsFromGivenColumns()}
          {columnsContent && columnsContent(this.columnsContentArgs(model))}
          <ActionsColumn
            columnsContentArgs={this.columnsContentArgs(model)}
            model={model}
            onDestroyModelClicked={onDestroyModelClicked}
            onEditClicked={this.onEditClicked}
            onSubmitEditableRowClicked={onSubmitEditableRowClicked}
            onUnSoftDeleteClicked={onUnSoftDeleteClicked}
            tableProps={this.props.table.props}
            tableState={this.props.table.state}
          />
          <Column className={classNames("expand-column", "text-end", {"expanded": expandableContentModelIds.includes(model.id())})}>
            <TableExpandButton
              className="expand-row-button"
              expanded={expandableContentModelIds.includes(model.id())}
              onClick={this.onExpandRowButtonClicked}
            />
          </Column>
        </tr>
        {expandableContent && expandableContentExpanded &&
          <tr className={`expandable-content-row ${rowClassName}`} data-expandable-model-id={model.id()}>
            <Column className={classNames({"p-0": !expandableContentPadding})} colSpan={colSpan}>
              {expandableContent(this.expandableContentArgs(model))}
            </Column>
          </tr>
        }
        {afterRowBeforeExpandableContent && expandableContentExpanded && afterRowBeforeExpandableContent({colSpan, editable, model, rowClassName})}
        {additionalTablesEnabled && expandableContentExpanded &&
          <>
            <ExpandableRow
              colSpan={colSpan}
              label={Log.modelName().human({count: 2})}
              model={model}
              rowClassName={classNames("logs-expandable-row", rowClassName)}
            >
              <LogsTable
                card={false}
                collection={this.logsQuery()}
                cyclerIndex={index}
                filters={false}
                showHeader={false}
              />
            </ExpandableRow>
            <ExpandableRow
              colSpan={colSpan}
              label={Tagging.modelName().human({count: 2})}
              model={model}
              rowClassName={classNames("taggings-expandable-row", rowClassName)}
            >
              <div className="m-4">
                <TaggingsTaggable model={model} />
              </div>
            </ExpandableRow>
          </>
        }
        {afterRow && expandableContentExpanded && afterRow({colSpan, editable, model, rowClassName})}
      </React.Fragment>
    )
  }

  columnsContentArgs = (model) => {
    const {editableRows, models, qParams, query} = digs(this.props.table.state, "editableRows", "models", "qParams", "query")
    const modelParamKey = Inflection.camelize(digg(model.modelClassData(), "paramKey"), true)
    const args = {
      editable: editableRows.includes(model.id()),
      model,
      models,
      qParams,
      query
    }

    // Add an alias for the model so it passes 'user' or 'activityParticipant' with the correct name
    args[modelParamKey] = model

    return args
  }

  columnsFromGivenColumns() {
    const {columnsWithOrder} = digs(this.props.table.state, "columnsWithOrder")

    return columnsWithOrder.filter((column) => this.props.table.dynamicColumns.showColumn(column)).map((args) => (
      <Column
        className={classNames(args.columnClassName, {"text-center": args.textCenter, "text-md-right": args.textRight})}
        data-identifier={args.identifier}
        key={args.identifier}
      >
        {this.contentForDynamicColumn(args)}
      </Column>
    ))
  }

  contentForDynamicColumn = (column) => {
    const {model} = this.props
    const {attribute, content, path, type} = column
    const {editableRows} = digs(this.props.table.state, "editableRows") // TODO wtf - why access the state on another component?

    if (content) {
      if (attribute) {
        const {isAttributeLoaded} = pathAndAttributeResolver({
          attribute,
          model,
          path: path || []
        })

        if (!isAttributeLoaded) {
          return null
        }
      }

      return content(this.columnsContentArgs(model))
    }

    if (attribute && column.editable && editableRows.includes(model.id())) {
      return (
        <EditableContent attribute={attribute} editable model={model} type={type} />
      )
    }

    if (attribute && !path && model.isAttributeLoaded(attribute)) {
      return presentValue(model.readAttribute(attribute))
    } else if (attribute && path) {
      const {value} = pathAndAttributeResolver({attribute, model, path})

      return presentValue(value)
    }
  }

  expandableContentArgs(model) {
    return this.columnsContentArgs(model)
  }

  logsQuery() {
    if (!this.logsQueryCache) this.logsQueryCache = this.newLogsQuery()

    return this.logsQueryCache
  }

  newLogsQuery() {
    const {model} = this.props
    const {logsCollection} = this.props.table.props

    if (logsCollection) {
      return logsCollection({model})
    }

    return Log.ransack({trackable_id_eq: model.id(), trackable_type_eq: digg(model.modelClassData(), "name")})
  }

  onExpandRowButtonClicked = (e) => {
    e.preventDefault()

    this.props.onExpandRowClicked(this.props.model)
  }

  onDragOver = (e) => this.props.onDragOver && this.props.onDragOver(e, this.props.model)
  onDrop = (e) => this.props.onDrop && this.props.onDrop(e, this.props.model)
  onDragStart = (e) => this.props.onDragStart && this.props.onDragStart(e, this.props.model)
  onEditClicked = (e) => this.props.onEditClicked(e, this.props.model)
  onSelectModelButtonClicked = (e) => this.props.onModelSelected(e, this.props.model)

  workplaceColumn(model) {
    return (
      <Column className="workplace-select-column">
        <WorkerPluginsModelCheckbox className="mx-auto" model={model} />
      </Column>
    )
  }

  orderByDragButtonClassNames() {
    const {orderByDraggingEnabled} = this.props
    const classNames = ["order-by-drag-button"]

    if (orderByDraggingEnabled) {
      classNames.push("dragging-enabled")
    } else {
      classNames.push("dragging-disabled text-muted")
    }

    return classNames.join(" ")
  }

  orderByDragButtonTitle() {
    const {orderByDraggingEnabled} = digs(this.props, "orderByDraggingEnabled")

    if (!orderByDraggingEnabled) {
      return I18n.t("js.shared.table.row.order_by_dragging_is_disabled_click_to_enable")
    }
  }

  onOrderByDragButtonClicked = (e) => {
    e.preventDefault()

    const {orderByDraggingEnabled} = digs(this.props, "orderByDraggingEnabled")

    if (!orderByDraggingEnabled) {
      const {query} = this.props
      const searchKey = query.queryArgs.searchKey || "q"
      const newParams = {}

      newParams[searchKey] = {s: "position"}

      this.props.navigate(Params.updatedPathWithchangedParams(newParams))
    }
  }
}

export default function WrappedSharedModelsTableRow(props) {
  const navigate = useNavigate()

  return (
    <SharedModelsTableRow navigate={navigate} {...props} />
  )
}
