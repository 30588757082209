import {selector, selectorFamily} from "recoil"
import {tenantAtom} from "../atoms/tenant-atom"

const tenantPhotoProductsSelector = selector({
  key: "tenantPhotoProductsSelector",
  get: async ({get}) => {
    const tenantPhotoProducts = await TenantPhotoProduct.ransack({
      tenant_id_eq: get(tenantAtom).id,
      visible_in_shop_eq: true
    })
      .preload([
        "photo_product.bundle.bundle_tenant_photo_products.tenant_photo_product.photo_product.photo_sheet_product.sheet_layout.boxes",
        "photo_product.bundle.bundle_tenant_photo_products.tenant_photo_product.photo_product.photo_sheet_product.sheet_layout.sheet_size",
        "photo_product.photo_sheet_product.sheet_layout.boxes",
        "photo_product.photo_sheet_product.sheet_layout.sheet_size"
      ])
      .select({
        Bundle: ["id", "name"],
        BundleTenantPhotoProduct: ["id"],
        PhotoProduct: ["name", "resourceId", "resourceType"],
        PhotoSheetProduct: ["id"],
        SheetLayout: ["id"],
        SheetLayoutBox: ["heightPercent", "id", "leftPercent", "photoTypeId", "topPercent", "widthPercent"],
        SheetSize: ["heightMm", "id", "widthMm"],
        TenantPhotoProduct: ["id", "name", "photoSessionType", "salesPriceWithVat"]
      })
      .ransack({s: "position asc"})
      .toArray()

    return tenantPhotoProducts
  }
})

const tenantPhotoProductsForPhotoSessionTypeSelectorFamily = selectorFamily(({
  key: "tenantPhotoProductsForPhotoSessionTypeSelectorFamily",
  get: (photoSessionType) => ({get}) => {
    const tenantPhotoProducts = get(tenantPhotoProductsSelector)

    return tenantPhotoProducts
      .filter((tenantPhotoProduct) => tenantPhotoProduct.photoSessionType() === photoSessionType || !tenantPhotoProduct.photoSessionType())
  }
}))

export {tenantPhotoProductsSelector, tenantPhotoProductsForPhotoSessionTypeSelectorFamily}
