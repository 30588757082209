import "./style"

export default class MerfotoLayoutTopBarSignedInUserButtonMenuModalMenuItem extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
  }

  render() {
    const {className, icon, label, to, ...restProps} = this.props

    return (
      <Link
        className={classNames("merfoto-components-layout-top-bar-signed-in-user-button-menu-modal-menu-item", className)}
        to={to || "#"}
        {...restProps}
      >
        <div className="d-flex">
          <div className="align-self-center menu-item-label-container flex-grow-1">
            {label}
          </div>
          <div className="align-self-center menu-item-icon-container">
            <i className={`la la-${icon}`} />
          </div>
        </div>
      </Link>
    )
  }
}
