export default class MerfotoComponentsOrderLinesEditResourcesPhotoSheetProductPhotoAction extends React.Component {
  static propTypes = {
    className: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.string,
    linkProps: PropTypes.func,
    sheetLayoutBox: PropTypes.instanceOf(SheetLayoutBox).isRequired,
    to: PropTypes.string
  }

  render() {
    const {className, icon, label, linkProps, sheetLayoutBox, to, ...restProps} = this.props

    return (
      <Link
        className={classNames("photo-action", className)}
        to={to || "#"}
        {...linkProps && linkProps({sheetLayoutBox})}
        {...restProps}
      >
        <Icon className="me-1" icon={icon} />
        <span className="photo-action-label">
          {label}
        </span>
      </Link>
    )
  }
}
